import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

import { useForm } from 'react-hook-form';
import Actions from './Actions';
import Main from './Main';
import Sections from './Sections';
import QuestionAnswerTree from '../QuestionAnswerTree';
import { useStyles } from './Content.styles';
import { useQuestionsContext } from '../../../../context/questionsContext';
import { Loading } from '../../Loading';
import {
  cloneQuestion,
  deleteQuestion,
  updateQuestion,
  restoreQuestion,
} from '../../../../services/QuestionServices';
import useNotifications from '../../../../hooks/useNotifications';
import RemoveModal from '../../../input/RemoveModal';
import { useMenuContext } from '../../../../context/menuContext';

function Content({ question }) {
  const classes = useStyles();
  const {
    setSelectedQuestion,
    updateQuestionAnswer,
    deleteQuestionAnswer,
    insertQuestionAnswer,
  } = useQuestionsContext();
  const form = useForm({
    defaultValues: question,
    shouldUnregister: false,
  });
  const { control, reset, getValues } = form;
  const { selectedRestaurant } = useMenuContext();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { enqueueSnackbar } = useNotifications();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    reset(question);
  }, [question, reset]);

  const handleDeleteMakeItAvailable = useCallback(async () => {
    if (question?.available) setModalIsOpen(true);
    else {
      setLoading(true);
      try {
        await restoreQuestion({
          restId: selectedRestaurant?.id,
          id: question?.id,
        });
        updateQuestionAnswer(question.root, question.id, { available: true });
        setSelectedQuestion(null);
      } catch (err) {
        enqueueSnackbar({
          message: err.message,
          options: {
            variant: 'error',
          },
        });
      } finally {
        setLoading(false);
      }
    }
  }, [
    enqueueSnackbar,
    question,
    selectedRestaurant,
    updateQuestionAnswer,
    setSelectedQuestion,
  ]);

  const handleSave = useCallback(async () => {
    setLoading(true);
    try {
      await updateQuestion({
        restId: selectedRestaurant?.id,
        questionId: question?.id,
        question: getValues(),
      });
      updateQuestionAnswer(question.root, question.id, { ...getValues() });
    } catch (err) {
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error',
        },
      });
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    getValues,
    question,
    selectedRestaurant.id,
    updateQuestionAnswer,
  ]);

  const handleClone = useCallback(async () => {
    setLoading(true);
    try {
      const response = await cloneQuestion({
        restId: selectedRestaurant?.id,
        questionId: question.id,
      });
      insertQuestionAnswer(0, 0, response);
    } catch (err) {
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error',
        },
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, insertQuestionAnswer, question.id, selectedRestaurant]);

  const handleDelete = useCallback(
    async (params) => {
      setLoading(true);
      try {
        await deleteQuestion({
          restId: selectedRestaurant?.id,
          questionId: question.id,
          params,
        });

        if (params?.permanent)
          deleteQuestionAnswer(question.root, question.parent, question.id);
        else
          updateQuestionAnswer(question.root, question.id, {
            available: false,
          });
      } catch (err) {
        enqueueSnackbar({
          message: err.message,
          options: {
            variant: 'error',
          },
        });
      } finally {
        setSelectedQuestion(null);
        setLoading(false);
      }
    },
    [
      selectedRestaurant?.id,
      question,
      deleteQuestionAnswer,
      enqueueSnackbar,
      setSelectedQuestion,
      updateQuestionAnswer,
    ]
  );

  if (loading) return <Loading />;

  return (
    <Box className={classes.content} data-testid="content">
      <Actions
        onCancel={() => setSelectedQuestion(null)}
        onDelete={handleDeleteMakeItAvailable}
        onSave={handleSave}
        onClone={handleClone}
        onDeleteForever={() => handleDelete({ permanent: true })}
        available={question?.available}
      />
      <RemoveModal
        isOpen={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
        closeModal={() => setSelectedQuestion(null)}
        onDeleteItem={handleDelete}
      />
      {!question.available && (
        <Typography className={classes.unavailableItem}>
          86d for Today
        </Typography>
      )}
      <Box className={classes.panel}>
        <Main control={control} />
        <Box className={classes.divider} />
        <Sections control={control} />
        <Box className={classes.divider} />
        <Typography className={classes.answersHeader}>Answers</Typography>
        <QuestionAnswerTree
          arrayOfQuestions={[question]}
          disableFirstCollapse
        />
      </Box>
    </Box>
  );
}

Content.propTypes = {
  question: PropTypes.object.isRequired,
};

export default Content;
