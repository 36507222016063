import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  dropdownFilter: {
    marginLeft: '24px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
      padding: '1px 0',
    },
  },
  infoText: {
    fontSize: '3rem',
    color: '#282B31',
    fontWeight: 'bold',
  },
  taxesTitle: {
    fontSize: '20px',
    fontWeight: '500',
  },
  taxesColumnTitle: {
    fontSize: '12px',
    fontWeight: 600,
    color: '#171725',
    opacity: '0.4',
    flex: 1,
  },
  taxesInfo: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#171725',
    opacity: '0.6',
    flex: 1,
  },
}));
