import { takeLeading, call, put } from 'redux-saga/effects';
import {
  signInRequest,
  signInSuccess,
  error,
  meRequest,
  meSuccess,
  initialLoadRequest,
  initialLoadSuccess,
  initialLoadUnauthenticated,
  logoutRequest,
  logoutSuccess,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  resetPasswordRequest,
  resetPasswordSuccess,
} from '../../ducks/UserDucks';
import history from '../../../utils/history';
import { ROOT_PATH } from '../../../constants/routes';
import Authentication from '../../../utils/authentication';
import { enqueueSnackbar } from '../../ducks/SnackbarDucks';

function* signIn(action) {
  try {
    const { email, password } = action.payload;
    yield call(Authentication.login, email, password);
    const profile = yield call(Authentication.getProfile);
    yield put(
      signInSuccess({
        user: profile,
      })
    );
    history.push(ROOT_PATH);
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error',
        },
      })
    );
    yield put(error({}));
    yield call(Authentication.logout);
  }
}

function* fetchUser() {
  try {
    const profile = yield call(Authentication.getProfile);
    yield put(
      meSuccess({
        user: profile,
      })
    );
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error',
        },
      })
    );
    yield put(error({ message: err.message }));

    yield call(Authentication.logout);
  }
}

function* signOut(action) {
  const message = yield call(Authentication.logout, action.payload);
  yield put(logoutSuccess());
  if (message) {
    yield put(enqueueSnackbar(message));
  }
}

function* initialUserLoad() {
  try {
    const token = yield call(Authentication.getToken);
    if (!token) return yield put(initialLoadUnauthenticated());
    const profile = yield call(Authentication.getProfile);
    yield put(
      initialLoadSuccess({
        user: profile,
      })
    );
  } catch (err) {
    yield put(error({ message: err.message }));
  }
}

function* forgotPassword(action) {
  try {
    const { email } = action.payload;
    yield call(Authentication.forgotPasswordRequest, email);
    yield put(forgotPasswordSuccess());
    yield put(
      enqueueSnackbar({
        message: 'Please check your email',
      })
    );
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error',
        },
      })
    );
    yield put(error({}));
  }
}

function* resetPassword(action) {
  try {
    const { email, code, password } = action.payload;
    yield call(Authentication.resetPasswordRequest, {
      email,
      resetCode: code,
      password,
    });
    yield put(resetPasswordSuccess());
    yield put(
      enqueueSnackbar({
        message: 'Password updated',
      })
    );
    history.push(ROOT_PATH);
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error',
        },
      })
    );
    yield put(error({}));
  }
}

export default function* watchUser() {
  yield takeLeading(signInRequest, signIn);
  yield takeLeading(logoutRequest, signOut);
  yield takeLeading(meRequest, fetchUser);
  yield takeLeading(initialLoadRequest, initialUserLoad);
  yield takeLeading(forgotPasswordRequest, forgotPassword);
  yield takeLeading(resetPasswordRequest, resetPassword);
}
