import React from 'react';
import PropTypes from 'prop-types';
import { opacify } from 'polished';
import { useTooltip } from '@nivo/tooltip';

export const getPath = (x, y, width, height, borderRadius) => {
  const path = '';
  if (!(height > 0) || !(width > 0)) return path;

  return `M ${x} ${y + borderRadius.top.left}A ${borderRadius.top.left} ${
    borderRadius.top.left
  } 0 0 1 ${x + borderRadius.top.left} ${y}H ${
    width + x - borderRadius.top.right
  }A ${borderRadius.top.right} ${borderRadius.top.right} 0 0 1 ${width + x} ${
    y + borderRadius.top.right
  }V ${height - borderRadius.bottom.right + y}A ${borderRadius.bottom.right} ${
    borderRadius.bottom.right
  } 0 0 1 ${x + width - borderRadius.bottom.right} ${height + y}H ${
    x + borderRadius.bottom.left
  }A ${borderRadius.bottom.left} ${borderRadius.bottom.left} 0 0 1 ${x} ${
    y + height - borderRadius.bottom.left
  }Z`;
};

export function Tooltip({ currentBar }) {
  return (
    <div
      data-testid="tooltip-chart"
      style={{
        background: '#fff',
        borderRadius: '4px',
        border: '1px solid #DADCE0',
        padding: '4px 8px',
      }}
    >
      <strong>${currentBar.value.toFixed(2)}</strong>
    </div>
  );
}

Tooltip.propTypes = {
  currentBar: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.number,
  }),
};

export function CustomBar({
  x,
  y,
  width,
  height,
  color,
  background,
  backgroundHeight,
  backgroundWidth,
  data,
  borderRadius,
  layout,
}) {
  const { showTooltipFromEvent, hideTooltip } = useTooltip();

  return (
    <g
      data-testid="bar"
      onMouseMove={(event) => {
        showTooltipFromEvent(<Tooltip currentBar={data} />, event);
      }}
      onMouseLeave={() => {
        hideTooltip();
      }}
    >
      {background &&
        (layout === 'horizontal' ? (
          <path
            d={getPath(0, y, backgroundWidth, height, borderRadius)}
            stroke="none"
            fill={opacify(-0.9, color)}
          />
        ) : (
          <path
            d={getPath(x, 0, width, backgroundHeight, borderRadius)}
            stroke="none"
            fill={opacify(-0.9, color)}
          />
        ))}
      <path
        d={getPath(x, y, width, height, borderRadius)}
        stroke="none"
        fill={color}
      />
    </g>
  );
}

CustomBar.propTypes = {
  color: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  background: PropTypes.bool,
  backgroundWidth: PropTypes.number,
  backgroundHeight: PropTypes.number,
  data: PropTypes.object,
  borderRadius: PropTypes.shape({
    top: PropTypes.shape({
      left: PropTypes.number,
      right: PropTypes.number,
    }),
    bottom: PropTypes.shape({
      left: PropTypes.number,
      right: PropTypes.number,
    }),
  }),
  layout: PropTypes.string,
};

export function CustomSymbolShape({
  x,
  y,
  size,
  fill,
  borderWidth,
  borderColor,
}) {
  return (
    <rect
      data-testid="symbol"
      x={x}
      y={y}
      rx={4}
      ry={4}
      fill={fill}
      strokeWidth={borderWidth}
      stroke={borderColor}
      width={size}
      height={size}
      style={{ pointerEvents: 'none' }}
    />
  );
}

CustomSymbolShape.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  size: PropTypes.number,
  borderWidth: PropTypes.number,
  borderColor: PropTypes.string,
};
