import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Divider, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import Actions from '../Content/Actions/Actions';
import { useStyles } from './MenuItem.styles';
import { formatCurrencyValue } from '../../../../utils/FormatUtils/FormatUtils';
import {
  saveItem,
  cloneItem,
  makeItemAvailable,
  deleteItem,
} from '../../../../services/MenuServices';
import { Loading } from '../../Loading/Loading';
import { Error as ErrorComponent } from '../../Error/Error';
import { useItemContext } from '../../../../context/itemsContext';
import { RemoveModal } from '../../../input/RemoveModal';
import { useMenuContext } from '../../../../context/menuContext';
import useNotifications from '../../../../hooks/useNotifications/useNotifications';
import QuestionAnswerTree from '../QuestionAnswerTree';

function CurrencyFormat({ onChange, ...rest }) {
  return (
    <NumberFormat
      {...rest}
      prefix="$"
      placeholder="Price"
      thousandSeparator
      fixedDecimalScale
      decimalScale={2}
      data-testid="currency-input"
      onValueChange={(v) => onChange(v.value)}
    />
  );
}

CurrencyFormat.propTypes = {
  onChange: PropTypes.func,
};

export function Prices({ prices }) {
  const classes = useStyles();
  const p = Object.values(prices);
  return (
    <>
      {prices.delivery && (
        <Typography className={classes.sideText}>
          Delivery: {formatCurrencyValue(prices.delivery)}
        </Typography>
      )}
      {p.length > 1 && prices.delivery && (
        <Divider orientation="vertical" flexItem />
      )}
      {prices.takeout && (
        <Typography className={classes.sideText}>
          Takeout: {formatCurrencyValue(prices.takeout)}
        </Typography>
      )}
    </>
  );
}

Prices.propTypes = {
  prices: PropTypes.shape({
    delivery: PropTypes.number,
    takeout: PropTypes.number,
  }),
};

export function MenuItem() {
  const classes = useStyles();
  const { setSelectedItem, selectedItem, getItem, getMenuItems } =
    useItemContext();
  const { enqueueSnackbar } = useNotifications();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState({
    base: selectedItem?.prices?.takeout?.base || 0,
    delivery: selectedItem?.prices?.delivery?.retail || 0,
    takeout: selectedItem?.prices?.takeout?.retail || 0,
  });
  const actionRef = useRef(null);
  const { selectedRestaurant } = useMenuContext();
  const restaurantSettings = useSelector((state) => state.restaurant.settings);
  const [item, setItem] = useState({
    ...selectedItem,
    prices: {
      delivery: {
        base: selectedItem?.prices?.delivery?.base || 0,
        retail: selectedItem?.prices?.delivery?.retail || 0,
      },
      takeout: {
        base: selectedItem?.prices?.takeout?.base || 0,
        retail: selectedItem?.prices?.takeout?.retail || 0,
      },
    },
  });

  useEffect(() => {
    setItem({
      ...selectedItem,
      name,
      description,
      prices: {
        delivery: {
          base: selectedItem?.prices?.delivery?.base || 0,
          retail:
            selectedItem?.prices?.delivery?.base *
            (1 + (restaurantSettings?.fees?.markup?.delivery || 0)),
        },
        takeout: {
          base: selectedItem?.prices?.takeout?.base || 0,
          retail:
            selectedItem?.prices?.takeout?.base *
            (1 + (restaurantSettings?.fees?.markup?.takeout || 0)),
        },
      },
    });
    // eslint-disable-next-line
  }, [selectedItem, restaurantSettings]);

  useEffect(() => {
    const loadItem = async () => {
      try {
        setLoading(true);
        setError(false);

        const result = await getItem(selectedRestaurant?.id, item);

        setName(result?.name || '');
        setDescription(result?.description || '');
        setPrice({
          base: result?.prices?.takeout?.base || 0,
          delivery: result?.prices?.delivery?.retail || 0,
          takeout: result?.prices?.takeout?.retail || 0,
        });
      } catch (err) {
        setError(true);

        enqueueSnackbar({
          message: err.message || 'Something went wrong!',
          options: {
            variant: 'error',
          },
        });
      } finally {
        setLoading(false);
      }
    };

    loadItem();
    // eslint-disable-next-line
  }, [selectedRestaurant, item.id, enqueueSnackbar]);

  const handlePriceChange = useCallback(
    (value) => {
      setPrice({
        base: value,
        delivery:
          value * (1 + (restaurantSettings?.fees?.markup?.delivery || 0)),
        takeout: value * (1 + (restaurantSettings?.fees?.markup?.takeout || 0)),
      });

      setItem({
        ...item,
        prices: {
          delivery: {
            retail:
              value * (1 + (restaurantSettings?.fees?.markup?.delivery || 0)),
          },
          takeout: {
            base: value,
            retail:
              value * (1 + (restaurantSettings?.fees?.markup?.takeout || 0)),
          },
        },
      });
    },
    [restaurantSettings, item]
  );

  const handleClickDeleteMakeItAvailableButton = useCallback(async () => {
    if (item?.available) setModalIsOpen(true);
    else {
      setLoading(true);
      try {
        await makeItemAvailable({
          restId: selectedRestaurant?.id,
          menuId: item?.menuId,
          id: item?.id,
          item,
        });
        setSelectedItem({
          ...item,
          name,
          description,
          prices: { ...item?.prices, takeout: { base: price.base } },
          status: 'active',
          available: true,
        });
        setLoading(false);

        await getMenuItems(selectedRestaurant?.id);
      } catch (err) {
        enqueueSnackbar({
          message: err.message,
          options: {
            variant: 'error',
          },
        });
      } finally {
        setLoading(false);
      }
    }
  }, [
    enqueueSnackbar,
    item,
    selectedRestaurant,
    setSelectedItem,
    name,
    description,
    price,
    getMenuItems,
  ]);

  const handleDeleteItem = useCallback(
    async ({ start, end, permanent }) => {
      try {
        await deleteItem({
          id: item?.id,
          menuId: item?.menuId,
          restId: selectedRestaurant?.id,
          start,
          end,
          permanent,
        });
        setSelectedItem(null);
        await getMenuItems(selectedRestaurant?.id);
      } catch (err) {
        enqueueSnackbar({
          message: err.message || 'Something went wrong!',
          options: {
            variant: 'error',
          },
        });
      }
    },
    [item, selectedRestaurant, setSelectedItem, getMenuItems, enqueueSnackbar]
  );

  const handleSaveItem = useCallback(async () => {
    setLoading(true);
    try {
      await saveItem({
        restId: selectedRestaurant?.id,
        menuId: item?.menuId,
        id: item?.id,
        item: { ...item, name, description, price: price.base },
      });
      setLoading(false);

      await getMenuItems(selectedRestaurant?.id);
    } catch (err) {
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error',
        },
      });
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    selectedRestaurant,
    item,
    name,
    description,
    price,
    getMenuItems,
  ]);

  const handleCloneItem = useCallback(async () => {
    setLoading(true);
    try {
      await cloneItem({
        id: item?.id,
        menuId: item?.menuId,
        restId: selectedRestaurant?.id,
      });
      setLoading(false);

      await getMenuItems(selectedRestaurant?.id);
    } catch (err) {
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error',
        },
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, item, selectedRestaurant, getMenuItems]);

  if (loading) return <Loading />;

  if (error)
    return (
      <Box flex="1" alignItems="center" justifyContent="center" display="flex">
        <ErrorComponent />
      </Box>
    );

  return (
    <>
      <Actions
        onCancel={() => setSelectedItem(null)}
        onDelete={handleClickDeleteMakeItAvailableButton}
        onDeleteForever={() => handleDeleteItem({ permanent: true })}
        onSave={handleSaveItem}
        onClone={handleCloneItem}
        available={selectedItem?.available}
        buttonRef={actionRef}
      />
      <RemoveModal
        isOpen={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
        data={selectedItem}
        closeModal={() => setSelectedItem(null)}
        onDeleteItem={handleDeleteItem}
        position={{
          top:
            actionRef?.current?.offsetHeight +
            actionRef?.current?.offsetTop +
            10,
          left: actionRef?.current?.offsetLeft,
        }}
      />
      {!selectedItem.available && (
        <Typography className={classes.unavailableItem}>
          86d for Today
        </Typography>
      )}
      <Box className={classes.info}>
        <Box className={classes.name}>
          <TextField
            className={classes.itemName}
            name="name"
            value={name}
            inputProps={{ 'data-testid': 'name-input' }}
            variant="outlined"
            label="Name"
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
        <Box className={classes.name}>
          <TextField
            className={classes.itemDescription}
            name="description"
            value={description}
            inputProps={{ 'data-testid': 'description-input' }}
            variant="outlined"
            label="Description"
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={2}
          />
        </Box>
        <Box marginBottom="20px">
          <Box>
            <Typography className={classes.fieldTitle}>
              Baseline price
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <TextField
              className={classes.itemBaseline}
              value={price.base}
              variant="outlined"
              onChange={handlePriceChange}
              InputProps={{
                inputComponent: CurrencyFormat,
              }}
            />
            <Box display="flex">
              <Prices prices={price} />
            </Box>
          </Box>
        </Box>
      </Box>
      {selectedItem.questions?.length > 0 && (
        <Box style={{ paddingBottom: '15px' }}>
          <Typography className={classes.questionsHeader}>
            Questions & Answers for this Item
          </Typography>
          <QuestionAnswerTree
            arrayOfQuestions={selectedItem.questions}
            onlyRootQuestions
          />
        </Box>
      )}
    </>
  );
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    menuId: PropTypes.number,
    available: PropTypes.bool,
    sort: PropTypes.number,
    category: PropTypes.number,
    prices: PropTypes.shape({
      delivery: PropTypes.number,
      takeout: PropTypes.number,
    }),
  }),
};
