import React, { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import {
  AddPhotoAlternate,
  Collections,
  DeleteOutline,
  Publish,
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './LogoDropZone.styles';
import GalleryModal from '../../layout/GalleryModal/GalleryModal';
import { DropZoneImage } from '../../layout/GalleryModal/DropZone/DropZoneImage';
import { Gallery } from '../../layout/GalleryModal/Gallery/Gallery';

export function DropZoneLogoPlaceHolder({
  logo,
  logoLoaded,
  setLogoLoaded,
  disabled,
}) {
  const classes = useStyles();

  const handleLoad = () => {
    setLogoLoaded(true);
  };

  if (logo?.length > 0) {
    return (
      <div className={classes.dropzoneLogoThumb}>
        {!logoLoaded && (
          <CircularProgress style={{ margin: 'auto' }} data-testid="progress" />
        )}
        <img
          data-testid={logo[0].name}
          alt="Restaurant logo preview"
          src={logo[0].url}
          onLoad={handleLoad}
          className={clsx(classes.dropzoneImg, {
            [classes.showImage]: !logoLoaded,
          })}
        />
        <Box height="0" width="100%">
          {!disabled && (
            <Box className={clsx(classes.uploadLogo)}>
              <AddPhotoAlternate
                style={{
                  fontSize: 14,
                  fill: '#333',
                  filter: 'drop-shadow( 1px 1px 2px #fff)',
                }}
              />
              <p>Upload logo</p>
            </Box>
          )}
        </Box>
      </div>
    );
  }
  return (
    <section
      className={clsx(classes.dropzone, {
        [classes.dropzoneDisabled]: disabled,
      })}
    >
      <AddPhotoAlternate
        style={{
          fontSize: 32,
          fill: `${disabled ? 'rgba(0,0,0,0.3)' : '#333'}`,
          marginBottom: 8,
        }}
      />
      <p style={{ color: `${disabled ? 'rgba(0,0,0,0.3)' : '#333'}` }}>
        Upload logo
      </p>
    </section>
  );
}

DropZoneLogoPlaceHolder.propTypes = {
  logo: PropTypes.array,
  logoLoaded: PropTypes.bool,
  setLogoLoaded: PropTypes.func,
  disabled: PropTypes.bool,
};

export function LogoDropZone({
  logo,
  setLogo,
  setLogoLoaded,
  handleDeleteUpload,
  handleDrop,
  logoLoaded,
  disabled = false,
}) {
  const classes = useStyles();
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const components = [
    {
      label: 'Upload File',
      component: DropZoneImage,
      icon: <Publish fontSize="small" style={{ marginRight: '8px' }} />,
    },
    {
      label: 'User Uploads',
      component: Gallery,
      icon: <Collections fontSize="small" style={{ marginRight: '8px' }} />,
    },
  ];

  const handleCloseGallery = () => {
    setIsGalleryOpen(false);
  };

  const handleOpenGallery = () => {
    !disabled && setIsGalleryOpen(true);
  };

  const handleSaveLogo = (data) => {
    handleDrop(data, setLogo, 'logo');
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        {logo?.length > 0 && !disabled && (
          <Box
            data-test-id="duE5GqTM_YY4jg5AFYa7w"
            display="block"
            height="0"
            alignSelf="flex-end"
            onClick={() =>
              handleDeleteUpload(logo, setLogo, 'logo', setLogoLoaded)
            }
            style={{ cursor: 'pointer', height: '0' }}
            data-testid="delete-logo-image"
          >
            <Box className={classes.deleteLogo}>
              <DeleteOutline
                style={{
                  fontSize: 14,
                  fill: '#FF3100',
                  filter: 'drop-shadow( 1px 1px 2px #fff)',
                }}
              />
            </Box>
          </Box>
        )}
        <Box
          data-test-id="fgtZt-K2yH8cPKmW5dh1x"
          onClick={handleOpenGallery}
          style={{
            display: 'flex',
            marginBottom: '32px',
            height: '256px',
            width: '256px',
          }}
          data-testid="dropzone"
        >
          <DropZoneLogoPlaceHolder
            disabled={disabled}
            logo={logo}
            logoLoaded={logoLoaded}
            setLogoLoaded={setLogoLoaded}
          />
        </Box>
      </Box>
      {isGalleryOpen && (
        <GalleryModal
          open={isGalleryOpen}
          handleClose={handleCloseGallery}
          setFile={handleSaveLogo}
          file={logo}
          components={components}
        />
      )}
    </>
  );
}

LogoDropZone.propTypes = {
  logo: PropTypes.array,
  setLogo: PropTypes.func,
  setLogoLoaded: PropTypes.func,
  handleDeleteUpload: PropTypes.func,
  handleDrop: PropTypes.func,
  mimeTypes: PropTypes.object,
  logoLoaded: PropTypes.bool,
  disabled: PropTypes.bool,
};
