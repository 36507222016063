import { formatResponse } from '../utils/FormatUtils/formatResponse';
import { api } from './Api';

export const getQuestions = async ({ restId }) => {
  const response = await api.get(`/restmanager/${restId}/questions`);
  const formattedResponse = formatResponse({
    data: { ...response },
    type: 'questions',
  });
  return formattedResponse;
};

export const cloneQuestion = async ({ restId, questionId }) => {
  const response = await api.put(
    `/restmanager/${restId}/questions/${questionId}`
  );
  const formattedResponse = formatResponse({
    data: { ...response },
    type: 'question',
  });
  return formattedResponse;
};

export const deleteQuestion = async ({ restId, questionId, params }) => {
  const response = await api.delete(
    `/restmanager/${restId}/questions/${questionId}`,
    params
  );
  return response;
};

export const updateQuestion = async ({ restId, questionId, question }) => {
  const { name, text } = question;
  const response = await api.post(
    `/restmanager/${restId}/questions/${questionId}`,
    {
      name,
      text,
    }
  );
  return response;
};

export const restoreQuestion = async ({ restId, id }) => {
  const response = await api.put(
    `/restmanager/${restId}/questions/${id}/restore`
  );
  return response;
};
