import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../../Theme/Theme';

export const useStyles = makeStyles({
  modal: {
    position: 'absolute',
    overflow: 'scroll',
    height: '100%',
    display: 'block',
  },
  modalContainer: {
    position: 'absolute',
    width: '40%',
    backgroundColor: 'white',
    padding: '24px',
    border: '1px solid #DADCE0',
    borderRadius: '4px',
    opacity: 1,
    bottom: '35%',
    right: '13%',
    '@media (max-width: 780px)': {
      left: '5%',
      width: '90%',
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  mb30: {
    marginBottom: '30px',
  },
  answerPriceContainer: {
    display: 'flex',
  },
  input: {
    backgroundColor: '#00000005',
  },
  priceInfo: {
    alignSelf: 'center',
    marginLeft: '15px',
    font: 'normal normal 600 10px/14px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.33px',
    color: '#00000099',
    opacity: '0.87',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '15px',
  },
  button: {
    border: '1px solid #0000001F',
    borderRadius: '4px',
    opacity: 1,
    marginRight: '32px',
  },
  buttonText: {
    font: 'normal normal 400 14px/21px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0px',
    color: '#282B31',
    opacity: 1,
  },
  buttonBlue: {
    border: '1px solid #0000001F',
    borderRadius: '4px',
    opacity: 1,
    backgroundColor: '#0058FF',
    '&:hover': {
      backgroundColor: 'rgba(0, 88, 255, 0.5)',
    },
  },
  buttonBlueText: {
    color: '#FFFFFF',
    font: 'normal normal 400 14px/21px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0px',
    opacity: 1,
  },
});
