import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { fromUnixTime } from 'date-fns';
import { startCase } from 'lodash';
import { useStyles } from './OrderErrorsList.styles';
import {
  formatCurrencyValue,
  formatRelativeDate,
} from '../../../utils/FormatUtils/FormatUtils';
import { useMenuContext } from '../../../context/menuContext';

function OrderError({ error }) {
  const { selectedRestaurant } = useMenuContext();
  const classes = useStyles();

  const snakeCaseToTitleCase = (text) => startCase(text.replace(/_/i, ' '));

  return (
    <Box className={classes.container}>
      <Box>
        <Typography className={classes.title}>Time</Typography>
        <Typography className={classes.primaryValue}>
          {formatRelativeDate(
            fromUnixTime(error.created),
            selectedRestaurant?.timezone
          )}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.title}>Problem</Typography>
        <Typography className={classes.primaryValue}>
          {snakeCaseToTitleCase(error.type)}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.title}>Solution</Typography>
        <Typography className={classes.primaryValue}>
          {snakeCaseToTitleCase(error.solution)}
        </Typography>
      </Box>
      {error?.data && (
        <Box>
          <Typography className={classes.title}>Amount</Typography>
          <Typography className={classes.primaryValue}>
            {formatCurrencyValue(error.data.amount)}
          </Typography>
        </Box>
      )}
      <Box className={classes.lastValue}>
        <Typography className={classes.title}>Note</Typography>
        <Typography className={classes.secondaryValue}>
          {error?.description ? `"${error.description}"` : '-'}
        </Typography>
      </Box>
    </Box>
  );
}

OrderError.propTypes = {
  error: PropTypes.shape({
    created: PropTypes.number,
    type: PropTypes.string,
    solution: PropTypes.string,
    data: { amount: PropTypes.number },
    description: PropTypes.string,
  }).isRequired,
};

function OrderErrorsList({ orderErrors }) {
  return (
    <Box>
      {orderErrors.map((error) => (
        <OrderError key={error.id} error={error} />
      ))}
    </Box>
  );
}

OrderErrorsList.propTypes = {
  orderErrors: PropTypes.array.isRequired,
};

export default OrderErrorsList;
