import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  header: {
    justifyContent: 'space-between',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginBottom: '24px',
    padding: '0px 25px',
    [theme.breakpoints.down('md')]: {
      margin: '0 -15px 24px',
      background: 'white',
      boxShadow: '0 3px 6px #00000029',
      padding: '12px 16px',
      position: 'fixed',
      zIndex: '2',
      top: '80px',
      width: '100%',
    },
  },
  pageTitle: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  hoursSection: {
    paddingTop: '40px',
    width: '100%',
  },
  hoursSectionTitle: {
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    width: '100%',
  },
  hoursSectionHeader: {
    justifyContent: 'space-between',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    alignSelf: 'flex-start',
    padding: '10px',
    marginBottom: '20px',
  },
  hoursSectionAction: {
    cursor: 'pointer',
  },
  hoursSectionBody: {
    width: '100%',
  },
}));
