import { formatWorkingHours } from '../FormatUtils';
import formatPhoneNumber from '../FormatUtils/formatPhoneNumber';

const sliderImg = [
  'first_slide',
  'second_slide',
  'third_slide',
  'fourth_slide',
  'fifth_slide',
  'sixth_slide',
];

const sliderTitle = (payload) => {
  const array = payload?.slider.map((slide, i) => {
    if (slide?.sliderTitle)
      return {
        name: `slider_${`0${i + 1}`.slice(-2)}_title`,
        locale: navigator.language.split('-')[0],
        text: slide.sliderTitle,
      };
    return undefined;
  });

  return array.filter((d) => d !== undefined);
};

const subtitles = (payload) => {
  const array = payload?.slider.map((slide, i) => {
    if (slide.sliderSubtitle)
      return {
        name: `slider_${`0${i + 1}`.slice(-2)}_sub`,
        locale: navigator.language.split('-')[0],
        text: slide.sliderSubtitle,
      };
    return undefined;
  });

  return array.filter((d) => d !== undefined);
};

const footerField = (payload) => {
  const array = payload.meta?.additional.map((info, i) => ({
    name: `field_title_${`0${i + 1}`.slice(-2)}`,
    locale: navigator.language.split('-')[0],
    text: info.field,
  }));

  return array;
};

const footerInfo = (payload) => {
  const array = payload.meta?.additional.map((info, i) => ({
    name: `field_text_${`0${i + 1}`.slice(-2)}`,
    locale: navigator.language.split('-')[0],
    text: info.info,
  }));

  return array;
};

export const metaToText = (payload) => {
  const array = [];
  if (payload.meta?.title)
    array.push({
      name: 'title',
      locale: navigator.language.split('-')[0],
      text: payload.meta.title,
    });
  if (payload.meta?.about_us_title)
    array.push({
      name: 'about_us_title',
      locale: navigator.language.split('-')[0],
      text: payload.meta.about_us_title,
    });
  if (payload.meta?.about_us_desc)
    array.push({
      name: 'about_us_desc',
      locale: navigator.language.split('-')[0],
      text: payload.meta.about_us_desc,
    });
  if (payload.meta?.description)
    array.push({
      name: 'description',
      locale: navigator.language.split('-')[0],
      text: payload.meta.description,
    });
  if (payload.meta?.keywords)
    array.push({
      name: 'keywords',
      locale: navigator.language.split('-')[0],
      text: payload.meta.keywords,
    });
  if (payload?.slider) {
    array.push(...sliderTitle(payload));
    array.push(...subtitles(payload));
  }
  if (payload?.meta.additional) {
    array.push(...footerField(payload));
    array.push(...footerInfo(payload));
  }

  return array;
};

export const haveSite = (payload) => {
  if (payload.haveSite) {
    return { domain: payload.site };
  }
  return null;
};

export const editRestaurantWebsiteETL = (payload) => {
  const media = () => {
    const array = [];
    if (payload?.logo?.length > 0)
      array.push({
        name: 'logo',
        upload_id: payload.logo[0].id,
        // url: payload.logo[0].url,
      });
    if (payload?.about_bg?.length > 0)
      array.push({
        name: 'about_bg',
        upload_id: payload.logo[0].id,
        // url: payload.logo[0].url,
      });
    if (payload?.favicon?.length > 0)
      array.push({
        name: 'favicon',
        upload_id: payload.favicon[0].id,
        // url: payload.favicon[0].url,
      });

    if (payload?.slider?.length > 0) {
      array.push(
        ...payload.slider.map((slide, i) => {
          if (slide?.image?.length > 0 && slide?.image[0]?.id !== '') {
            return {
              name: sliderImg[i],
              upload_id: slide?.image[0]?.id,
              // url: slide?.image[0]?.url,
            };
          }
          return undefined;
        })
      );
    }

    return array?.filter((e) => e?.upload_id !== undefined);
  };

  const data = {
    text: metaToText(payload),
    media: media(),
  };

  return data;
};

export const editRestaurantGeneralETL = (payload) => {
  const address = {
    street: payload.address.street,
    city: payload.address.city,
    state: payload.address.state,
    zip: payload.address.zip,
    location: {
      latitude: payload.address.location.lat,
      longitude: payload.address.location.lng,
    },
  };

  const data = {
    name: payload.name,
    address,
    phone: formatPhoneNumber(payload.phone),
    category: payload.cuisine,
    tags: payload.tags,
    description: payload.description,
  };

  return data;
};

export const editManageHoursETL = (payload) => {
  const businessHours = formatWorkingHours(payload.business);

  const { same: deliveryIsSame, ...expectedDeliveryHours } = payload.delivery;
  const { same: takeoutIsSame, ...expectedTakeoutHours } = payload.takeout;

  const deliveryHours = formatWorkingHours(
    deliveryIsSame ? payload.business : expectedDeliveryHours
  );
  const takeoutHours = formatWorkingHours(
    takeoutIsSame ? payload.business : expectedTakeoutHours
  );

  const data = {
    schedule: {
      business_hours: businessHours,
      delivery_hours: deliveryHours,
      takeout_hours: takeoutHours,
    },
  };

  return data;
};

export const editManageMediaETL = (payload) => {
  const media = () => {
    const imageNames = [
      'logo',
      'favicon',
      'consumer_restaurant_list_logo',
      'consumer_restaurant_map_logo',
      'consumer_restaurant_page_logo',
      'consumer_restaurant_page_background',
      'consumer_restaurant_checkout_logo',
      'consumer_restaurant_stripe_logo',
      'consumer_restaurant_order_success_logo',
      'consumer_restaurant_tracking_item_logo',
      'consumer_restaurant_tracking_details_logo',
      'consumer_restaurant_history_item_logo',
      'consumer_restaurant_email_logo',
      'first_slide',
      'second_slide',
      'third_slide',
    ];

    const array = [];

    const includeImage = (imageName) => {
      payload.forEach((ev) => {
        if (ev?.name === imageName)
          array.push({
            name: imageName,
            upload_id: ev?.upload_id,
          });
      });
    };

    imageNames.forEach(includeImage);

    return array?.filter((e) => e?.upload_id !== undefined);
  };

  const data = {
    media: media(),
  };

  return data;
};
