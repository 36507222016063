import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  list: {
    display: 'flex',
    overflowX: 'hidden',
    overflowY: 'auto',
    flexFlow: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    height: 'calc(100% - 50px)',
    padding: '18px 16px',
    scrollbarWidth: 'none',

    border: '1px solid #DADCE0',
    background: '#FFFFFF',
    borderRadius: '12px',
    opacity: '1',
  },
});
