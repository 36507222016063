import React, { useCallback, useRef, useState } from 'react';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemSecondaryAction,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import {
  ExpandMore,
  ExpandLess,
  Create,
  RemoveShoppingCart,
  ShoppingCart,
} from '@mui/icons-material';
import clsx from 'clsx';
import { useStyles } from './QuestionAnswerTree.styles';
import {
  deleteAnswer,
  editAnswer,
  restoreAnswer,
} from '../../../../services/AnswerServices';
import { formatCurrencyValue } from '../../../../utils/FormatUtils/FormatUtils';
import EditAnswerModal from '../Content/EditAnswerModal/EditAnswerModal';
import { useMenuContext } from '../../../../context/menuContext';
import { useQuestionsContext } from '../../../../context/questionsContext/questionsContext';
import useNotifications from '../../../../hooks/useNotifications/useNotifications';
import { RemoveModal } from '../../../input/RemoveModal/RemoveModal';
import Button from '../../../input/Button/Button';
import changeSortOrder from '../../../../utils/changeSortOrder';

export function Answer({ answer }) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useNotifications();
  const { selectedRestaurant } = useMenuContext();
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [deleteParams, setDeleteParams] = useState({});
  const { updateQuestionAnswer, deleteQuestionAnswer } = useQuestionsContext();

  const onDelete = useCallback(
    async (restId, questionId, answer, params) => {
      setOpenRemoveModal(false);
      setIsLoading(answer.id);
      try {
        await deleteAnswer({
          restId,
          questionId,
          id: answer.id,
          params,
        });

        if (params.permanent)
          deleteQuestionAnswer(answer.root, answer.parent, answer.id);
        else updateQuestionAnswer(answer.root, answer.id, { available: false });
      } catch (err) {
        enqueueSnackbar({
          message: err.message,
          options: {
            variant: 'error',
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [deleteQuestionAnswer, enqueueSnackbar, updateQuestionAnswer]
  );

  const onRestore = useCallback(
    async (answer) => {
      setOpenRemoveModal(false);
      setIsLoading(answer.id);
      try {
        await restoreAnswer({
          restId: selectedRestaurant?.id,
          questionId: answer.parent,
          id: answer.id,
        });

        updateQuestionAnswer(answer.root, answer.id, { available: true });
      } catch (err) {
        enqueueSnackbar({
          message: err.message,
          options: {
            variant: 'error',
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar, selectedRestaurant, updateQuestionAnswer]
  );

  return (
    <Box data-testid={`answer-id-${answer.id}`}>
      <Box className={classes.answerWrapper}>
        <Box display="flex">
          {answer?.prices?.takeout.base > 0 && (
            <Typography
              className={clsx(classes.answerPrice, {
                [classes.unavailable]: !answer?.available,
              })}
            >
              + {formatCurrencyValue(answer?.prices.takeout.base)}
            </Typography>
          )}
          <Typography
            className={clsx(classes.answersText, {
              [classes.unavailable]: !answer?.available,
            })}
          >
            {answer?.text}
          </Typography>
        </Box>
        <Box display="flex" style={{ justifyContent: 'flex-end' }}>
          <Button
            data-test-id="-WSGzYf2GoHrNwHlQVU2h"
            className={clsx(classes.button, classes.editButton)}
            onClick={() => setIsOpen(true)}
            data-testid="open-edit-modal"
          >
            <Create className={clsx(classes.icon, classes.editIcon)} />
            Edit
          </Button>
          <Button
            data-test-id="Y2T--kq1A-Ai_Lcb43Ugm"
            className={clsx(classes.button, {
              [classes.deleteButton]: answer?.available,
              [classes.restoreButton]: !answer?.available,
            })}
            onClick={() => {
              if (answer?.available) {
                setDeleteParams({
                  parent: answer.parent,
                  answer,
                });
                setOpenRemoveModal(true);
              } else {
                onRestore(answer);
              }
            }}
            data-testid="delete-item"
            loading={isLoading}
          >
            {answer?.available ? (
              <>
                <RemoveShoppingCart
                  className={clsx(classes.icon, classes.deleteIcon)}
                />
                86 it
              </>
            ) : (
              <>
                <ShoppingCart
                  className={clsx(classes.icon, classes.editIcon)}
                />
                Restore
              </>
            )}
          </Button>
        </Box>
      </Box>
      {openRemoveModal && (
        <RemoveModal
          isOpen={openRemoveModal}
          handleClose={() => setOpenRemoveModal(false)}
          onDeleteItem={(params) =>
            onDelete(
              selectedRestaurant?.id,
              deleteParams.parent,
              deleteParams.answer,
              params
            )
          }
        />
      )}
      {isOpen && (
        <EditAnswerModal
          isOpenModal={isOpen}
          handleCloseModal={() => setIsOpen(false)}
          selectedAnswer={answer}
        />
      )}
      {answer.children.map((question) => (
        <Box key={question.id}>
          <Question question={question} />
        </Box>
      ))}
    </Box>
  );
}

Answer.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    prices: PropTypes.shape({
      takeout: PropTypes.shape({ base: PropTypes.number }),
      delivery: PropTypes.shape({ base: PropTypes.number }),
    }),
    available: PropTypes.bool,
    parent: PropTypes.number,
    root: PropTypes.number,
    children: PropTypes.array,
  }).isRequired,
};

export function Question({ question, disableCollapse }) {
  const classes = useStyles();
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const { updateQuestionAnswer, insertQuestionAnswer, deleteQuestionAnswer } =
    useQuestionsContext();
  const ref = useRef(null);

  const onAdd = useCallback(() => {
    const newAnswer = {
      id: 0,
      text: '',
      prices: { takeout: { base: 0 } },
      available: true,
      parent: question.id,
      root: question.root,
      children: [],
      child: [],
    };
    setSelectedAnswer(newAnswer);
    setIsOpenEdit(true);
    insertQuestionAnswer(question.root, question.id, newAnswer);
  }, [insertQuestionAnswer, question]);

  const onCancel = useCallback(() => {
    deleteQuestionAnswer(question.root, question.id, 0);
  }, [deleteQuestionAnswer, question]);

  const expandItem = useCallback(
    (expand) => {
      updateQuestionAnswer(question.root, question.id, { expand });
    },
    [question, updateQuestionAnswer]
  );

  return (
    <>
      {question && (
        <Box className={classes.question}>
          {!disableCollapse && (
            <Box className={classes.questionTitleContainer}>
              <Typography
                className={clsx(classes.questionTitle, {
                  [classes.unavailable]: !question?.available,
                })}
              >
                {question.text}
              </Typography>
              <Button
                data-test-id="qo-Ekm4PBztcGBQtyblA_"
                onClick={() => expandItem(!question.expand)}
                className={classes.expandButton}
                data-testid="expand-button"
              >
                {question.expand ? (
                  <ExpandLess className={classes.expandIcons} />
                ) : (
                  <ExpandMore className={classes.expandIcons} />
                )}
              </Button>
            </Box>
          )}
          <Collapse in={question.expand || disableCollapse}>
            <Box marginTop="30px">
              <Droppable
                droppableId={String(question?.id)}
                type={`question-${question?.id}`}
              >
                {(provided) => (
                  <div ref={provided.innerRef}>
                    <List
                      className={classes.list}
                      ref={ref}
                      style={{
                        minHeight: question.expand
                          ? `${ref?.current?.clientHeight || 0}px`
                          : 0,
                      }}
                    >
                      {question.children.map((answer, index) => (
                        <Draggable
                          draggableId={JSON.stringify(answer)}
                          key={answer.id}
                          index={index}
                        >
                          {(providedItem) => (
                            <ListItem
                              ref={providedItem.innerRef}
                              key={answer.id}
                              role={undefined}
                              dense
                              button
                              className={classes.listItem}
                              ContainerComponent="li"
                              ContainerProps={{ ref: providedItem.innerRef }}
                              {...providedItem.draggableProps}
                              {...providedItem.dragHandleProps}
                            >
                              <Answer answer={answer} />
                              <ListItemSecondaryAction />
                            </ListItem>
                          )}
                        </Draggable>
                      ))}
                    </List>
                  </div>
                )}
              </Droppable>
              <Button
                data-test-id="EgClPrsJohCwYg6DoL72d"
                className={classes.addNewAnswer}
                onClick={onAdd}
                data-testid="item-question-add-answer"
              >
                <Typography className={classes.addNewAnswerIcon}>+</Typography>
                <Typography className={classes.addNewAnswerText}>
                  Add new answer
                </Typography>
              </Button>
            </Box>
          </Collapse>
        </Box>
      )}
      {isOpenEdit && (
        <EditAnswerModal
          isOpenModal={isOpenEdit}
          handleCloseModal={() => setIsOpenEdit(false)}
          selectedAnswer={selectedAnswer}
          isNew
          isItem
          onCancel={onCancel}
        />
      )}
    </>
  );
}

Question.propTypes = {
  question: PropTypes.object.isRequired,
  disableCollapse: PropTypes.bool,
};

Question.defaultProps = {
  disableCollapse: false,
};

export function QuestionAnswerTree({
  arrayOfQuestions,
  disableFirstCollapse,
  onlyRootQuestions,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useNotifications();
  const { selectedRestaurant } = useMenuContext();
  const { flatQuestionsList, updateQuestionAnswer, getQuestionAnswer } =
    useQuestionsContext();

  const updateAnswersSort = useCallback(
    async (answer, sort) => {
      try {
        await editAnswer({
          questionId: answer.parent,
          restId: selectedRestaurant?.id,
          ...answer,
          weight: answer.weight || 1,
          price: answer.prices.takeout.base,
          sort,
        });
      } catch (err) {
        enqueueSnackbar({
          message: err.message,
          options: {
            variant: 'error',
          },
        });
      }
    },
    [enqueueSnackbar, selectedRestaurant?.id]
  );

  const onDragEnd = useCallback(
    async ({ source, destination, draggableId }) => {
      if (destination === undefined || destination === null) return null;

      if (destination.index === source.index) return null;

      const answer = JSON.parse(draggableId);
      const question = getQuestionAnswer(
        answer?.root,
        parseInt(source.droppableId)
      );

      const sort = changeSortOrder(destination, source, question.children);

      updateQuestionAnswer(question.root, answer.id, { sort });

      updateAnswersSort(answer, sort);
    },
    [getQuestionAnswer, updateQuestionAnswer, updateAnswersSort]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd} data-testid="dnd-context">
      {arrayOfQuestions.map((question) => {
        const q = flatQuestionsList.find((q) => q.id === question.id);
        return (
          <Box key={question.id} className={classes.questionWrapper}>
            {(!onlyRootQuestions || !question.sub) && (
              <Question disableCollapse={disableFirstCollapse} question={q} />
            )}
          </Box>
        );
      })}
    </DragDropContext>
  );
}

QuestionAnswerTree.propTypes = {
  arrayOfQuestions: PropTypes.array.isRequired,
  disableFirstCollapse: PropTypes.bool,
  onlyRootQuestions: PropTypes.bool,
};

QuestionAnswerTree.defaultProps = {
  disableFirstCollapse: false,
  onlyRootQuestions: false,
};

export default QuestionAnswerTree;
