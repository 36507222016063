import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import PropTypes from 'prop-types';
import { Filter } from './Filter';

import { useStyles } from './CommonStyles.styles';

import { chartColor } from '../../../utils/ChartUtils/chart-constants';
import { CenteredMetric } from '../../../utils/ChartUtils/chart-util';
import { getSales } from '../../../services/ReportService';
import { useMenuContext } from '../../../context/menuContext';
import { Loading } from '../Loading';
import { Error } from '../Error';
import { formatCurrencyValue } from '../../../utils/FormatUtils/formatCurrencyValue';

export function MenuCategory({ timeFilter }) {
  const classes = useStyles();
  const [filter, setFilter] = useState('Total Sales');
  const { selectedRestaurant } = useMenuContext();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const infoType = useMemo(
    () =>
      ({
        'Total Sales': 'sales',
        'Total Wholesale': 'wholesale',
        'Total In-House': 'inhouse',
        'Total Profit': 'profit',
      }[filter]),
    [filter]
  );

  useEffect(() => {
    if (selectedRestaurant?.id)
      (async () => {
        try {
          setError(false);
          setLoading(true);
          const response = await getSales(
            selectedRestaurant.id,
            { ...timeFilter, group: 'category' },
            'sales-category'
          );
          setData(response);
        } catch {
          setError(true);
        } finally {
          setLoading(false);
        }
      })();

    // eslint-disable-next-line
  }, [selectedRestaurant, timeFilter]);

  return (
    <Box flex="1" display="flex" flexDirection="column">
      <Box className={classes.containerTitleWrapper}>
        <Typography className={classes.outsideContainerTitle}>
          By Menu Category
        </Typography>

        <Filter filter={filter} setFilter={setFilter} />
      </Box>
      <Box className={classes.infoContainer}>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error ? (
              <Error />
            ) : (
              <Box className={classes.flexToBlock}>
                <Box style={{ height: '300px', width: '100%' }}>
                  <ResponsivePie
                    data={data[infoType].chart}
                    key={JSON.stringify(data[infoType].chart)}
                    colors={chartColor}
                    innerRadius={0.75}
                    cornerRadius={4}
                    enableRadialLabels={false}
                    enableSliceLabels={false}
                    margin={{ right: 20, left: 15 }}
                    layers={[
                      'arcs',
                      'arcLabels',
                      'radialLabels',
                      'legends',
                      CenteredMetric,
                    ]}
                  />
                </Box>
                <Box className={classes.categoryLegendWrapper}>
                  {data[infoType].chart.map((d, i) => (
                    <Box key={d.id} className={classes.legend}>
                      <Box display="flex" alignItems="center">
                        <Box
                          style={{
                            marginRight: '6px',
                            width: '10px',
                            height: '10px',
                            background: `${chartColor[i]}`,
                            borderRadius: '3px',
                          }}
                        />
                        {d.id}
                      </Box>
                      <Typography>{formatCurrencyValue(d.total)}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

MenuCategory.propTypes = {
  timeFilter: PropTypes.shape({
    start: PropTypes.object,
    end: PropTypes.object,
  }),
};

export default MenuCategory;
