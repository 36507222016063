import { makeStyles } from '@mui/styles';
import { theme } from '../../Theme/Theme';

export const useStyles = makeStyles({
  noContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  title: {
    display: 'inline-flex',
    fontSize: '32px',
    font: 'normal normal bold 32px/48px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.23px',
    color: '#171725',
    opacity: '0.1',
  },
});
