import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { Cancel } from '@mui/icons-material';
import NumberFormat from 'react-number-format';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { editRestaurantGeneralETL } from '../../../../../utils/ExtractTransformLoad/EditRestaurantETL';
import { AddressModal } from '../../../../input/AddressModal';
import { updateSettings } from '../../../../../redux/ducks/RestaurantDucks';
import Button from '../../../../input/Button/Button';
import { Loading } from '../../../../layout/Loading';
import { getLocation } from '../../../../../services/LocationService';
import { useStyles } from './General.styles';

const FormSchema = yup.object().shape({
  name: yup.string().required('Restaurant name is required'),
  address: yup.object().shape({
    formattedAddress: yup.string().required('Restaurant address is required'),
  }),
  phone: yup.string().required('Restaurant phone is required'),
  tags: yup
    .array()
    .of(yup.string())
    .min(1, 'Add at least one tag')
    .required('Add at least one tag'),
  cuisine: yup.string().required('Add cuisine type').nullable(),
  description: yup.string().required('Description is required'),
});

export const initial = {
  name: '',
  address: {
    formattedAddress: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    location: {
      lat: 0,
      lng: 0,
    },
  },
  phone: '',
  tags: [],
  rawTags: '',
  cuisine: '',
  description: '',
};

function NumberFormatCustom({ inputRef, ...other }) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      format="(###) ###-####"
      placeholder="Restaurant Phone"
      mask="_"
      type="tel"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.any,
};

export function General() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const restaurantSettings = useSelector((state) => state.restaurant.settings);
  const rawSettings = useSelector((state) => state.restaurant.rawSettings);
  const isLoading = useSelector((state) => state.restaurant.isLoading);
  const [tags, setTags] = useState(initial.tags);
  const [openModal, setOpenModal] = useState(false);
  const [searching, setSearching] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [address, setAddress] = useState({
    street: '',
    city: '',
    state: '',
    zip: '',
  });

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: initial,
    resolver: yupResolver(FormSchema),
  });

  const getErrors = useCallback(
    (path) => ({
      error: !!get(methods.formState.errors, path),
      helperText:
        get(methods.formState.errors, path) &&
        get(methods.formState.errors, path).message,
      objecto: get(methods.formState.errors, path),
    }),
    [methods.formState.errors]
  );

  const formObj = { ...methods, getErrors };

  const { control, getValues, reset, template, trigger, setValue } = formObj;

  useEffect(() => {
    if (restaurantSettings) {
      const initial = {
        name: restaurantSettings.name,
        address: restaurantSettings.address,
        phone: restaurantSettings.phone,
        tags: restaurantSettings.tags,
        rawTags: '',
        cuisine: restaurantSettings.cuisine,
        description: restaurantSettings.description,
      };
      reset(initial);
      setAddress(restaurantSettings.address);
      setTags(initial.tags);
    }
  }, [restaurantSettings, reset]);

  const handleCancel = useCallback(() => {
    if (restaurantSettings) {
      const initial = {
        name: restaurantSettings.name,
        address: restaurantSettings.address,
        phone: restaurantSettings.phone,
        tags: restaurantSettings.tags,
        rawTags: '',
        cuisine: restaurantSettings.cuisine,
        description: restaurantSettings.description,
      };
      reset(initial);
      setAddress(restaurantSettings.address);
      setTags(initial.tags);
    }
  }, [restaurantSettings, reset]);

  const handleSave = useCallback(async () => {
    const correctData = await trigger([
      'name',
      'address',
      'phone',
      'tags',
      'cuisine',
      'description',
    ]);

    const {
      id,
      config,
      name,
      description,
      address,
      category,
      tags,
      schedule,
      text,
      phone,
      media,
    } = rawSettings;

    const data = {
      name,
      description,
      address,
      category,
      tags,
      schedule,
      text,
      phone,
      media,
      ...editRestaurantGeneralETL(getValues()),
      config: {
        costs_food_ratio: config.costs_food_ratio,
        current_preparation_time: config.current_preparation_time,
      },
    };

    if (correctData && id)
      dispatch(
        updateSettings({
          id,
          settings: data,
        })
      );
  }, [trigger, getValues, rawSettings, dispatch]);

  const handleAddTag = () => {
    if (getValues('rawTags')) {
      const newTags = [
        ...tags,
        ...getValues('rawTags')
          .split(',')
          .map((tag) => tag.trim()),
      ];
      setTags(newTags);
      reset({ ...getValues(), tags: newTags, rawTags: '' });
    }
  };

  const handleDeleteTag = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags);
    reset({ ...getValues(), tags: newTags });
  };

  const handleSearchAddress = useCallback(
    async (address) => {
      setSearching(true);
      try {
        const response = await getLocation(address);
        setValue('address', response);
        handleCloseModal();
        setSearching(false);
      } catch (err) {
        setValue('address', address);
        setLocationError(true);
        setSearching(false);
      }
    },
    [setValue]
  );

  const handleOpenModal = () => {
    setLocationError(false);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <Box className={classes.header}>
        <Typography variant="h1" className={classes.pageTitle}>
          General Settings
        </Typography>
        <Box className={classes.buttonBox}>
          <Box display="flex" alignItems="center">
            <Button
              data-test-id="YoOfTW1zFImeW5uM4p0or"
              variant="outlined"
              color="inherit"
              className={classes.button}
              onClick={handleCancel}
              data-testid="cancel-button"
            >
              <Typography className={classes.buttonText}>Cancel</Typography>
            </Button>
            <Button
              data-test-id="cDdM2sk8dmzu_jMFeDUUL"
              variant="outlined"
              color="inherit"
              className={classes.buttonBlue}
              onClick={handleSave}
              data-testid="save-button"
            >
              <Typography className={classes.buttonBlueText}>Save</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className={classes.boxContainer}>
        <Box className={classes.inputContainer}>
          <Typography className={classes.label}>Restaurant name</Typography>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                placeholder="Restaurant name"
                className={classes.input}
                inputProps={{ 'data-testid': 'restaurant-name-input' }}
                {...getErrors('name')}
              />
            )}
          />
          <Typography className={classes.label} style={{ marginTop: '24px' }}>
            Restaurant address
          </Typography>
          <Controller
            name="address.formattedAddress"
            control={control}
            render={({ field }) => (
              <TextField
                data-test-id="o85uQMtH5DecyRDKDrSA_"
                {...field}
                fullWidth
                variant="outlined"
                placeholder="Restaurant Address"
                inputProps={{ 'data-testid': 'restaurant-address' }}
                className={classes.formInputBox}
                onClick={handleOpenModal}
                {...getErrors('address.formattedAddress')}
              />
            )}
          />
          <AddressModal
            openModal={openModal}
            handleCloseModal={handleCloseModal}
            searching={searching}
            locationError={locationError}
            handleSearchAddress={handleSearchAddress}
            address={address}
            setAddress={setAddress}
          />
          <Typography className={classes.label} style={{ marginTop: '24px' }}>
            Phone number
          </Typography>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                fullWidth
                className={classes.input}
                variant="outlined"
                {...getErrors('phone')}
              />
            )}
            name="phone"
            control={control}
          />
          <Typography className={classes.label} style={{ marginTop: '24px' }}>
            Cuisine category
          </Typography>
          <Controller
            render={({ field: { onChange, ...props } }) => (
              <Autocomplete
                options={template?.categories || []}
                data-testid="cuisine-autocomplete"
                freeSolo
                disablePortal
                blurOnSelect
                autoSelect
                ListboxProps={{ style: { maxHeight: '30vh' } }}
                renderInput={({ ...params }) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Cuisine Category"
                    className={classes.input}
                    style={{ marginBottom: 0 }}
                    {...getErrors('cuisine')}
                  />
                )}
                onChange={(_, newValue) => {
                  onChange(newValue);
                }}
                {...props}
              />
            )}
            defaultValue={getValues('cuisine')}
            name="cuisine"
            control={control}
          />
          <Typography className={classes.label} style={{ marginTop: '24px' }}>
            Tags
          </Typography>
          <Box display="flex" alignItems="center">
            <Controller
              name="rawTags"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  placeholder="Type tags like “Pizza”, “Tacos”, “Lobster Ro…”"
                  className={classes.input}
                  inputProps={{ 'data-testid': 'tags-input' }}
                  style={{ width: '100%' }}
                  {...getErrors('tags')}
                />
              )}
            />
            <Button
              data-test-id="MK-uv3_-sEMTR0UxYngB5"
              data-testid="add-tag"
              className={classes.buttonBlue}
              onClick={handleAddTag}
              style={{ marginLeft: '24px', height: '53px', width: '100px' }}
            >
              <Typography className={classes.buttonBlueText}>
                Add Tag
              </Typography>
            </Button>
          </Box>
          <Box display="flex" marginTop="14px">
            {tags &&
              tags.map((tag, i) => (
                <Box
                  data-testid={`tag-${i}`}
                  className={classes.tags}
                  key={tag}
                >
                  {tag}
                  <IconButton
                    data-test-id="2wiXbTaEVrixhpkqBg_pY"
                    data-testid={`delete-tag-${i}`}
                    style={{ padding: 0, marginLeft: '8px' }}
                    onClick={() => handleDeleteTag(i)}
                    size="large"
                  >
                    <Cancel style={{ fontSize: '16px' }} />
                  </IconButton>
                </Box>
              ))}
          </Box>
          <Typography className={classes.label} style={{ marginTop: '24px' }}>
            Description
          </Typography>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                placeholder="Description"
                className={classes.areaInput}
                multiline
                rows={2}
                inputProps={{ 'data-testid': 'description-input' }}
                {...getErrors('address')}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default General;
