import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  formInsideTitle: {
    font: 'normal normal 600 24px/35px Poppins',
    letterSpacing: '0.17px',
    color: '#282B31',
    opacity: 0.6,
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-start',
      marginTop: '8px',
      fontSize: '1.125rem',
    },
  },
  formInsideSubtitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
      margin: 0,
    },
  },
  dropdownSelect: {
    width: 115,
    height: 32,
    margin: '8px',
    backgroundColor: '#F5F5F5',

    '& > div': {
      paddingTop: 8,
      paddingBottom: 7,
    },

    [theme.breakpoints.down('md')]: {
      flex: 1,
      width: 'unset',
      margin: '5px 0',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '3px 0',
    },
  },
  formControlSelect: {
    [theme.breakpoints.down('md')]: {
      margin: '0',
    },
  },
  selectInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(724)]: {
      flex: 1,
    },
  },
  selectControl: {
    minWidth: '96px',
    [theme.breakpoints.down(724)]: {
      minWidth: 'unset',
      width: '100%',
    },
  },
  divider: {
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      margin: '0 15px 0 15px',
    },
  },
  selectWrapper: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addShiftButton: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #0000001F',
    borderRadius: '4px',
    opacity: 1,
    marginRight: '32px',
    marginLeft: '10px',
    height: 40,
    width: 120,

    [theme.breakpoints.down('724')]: {
      display: 'none',
    },
  },
  addShiftMobile: {
    display: 'none',
    [theme.breakpoints.down('724')]: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      background: 'rgba(67, 87, 239, 0.05)',

      '&:hover': {
        background: 'rgba(67, 87, 239, 0.15)',
      },

      '&:last-child': {
        marginTop: '11px',
        marginBottom: '5px',
      },
    },
  },
  removeShiftButton: {
    padding: '6px',
    background: '#FF31000F',
    borderRadius: '4px',

    '&:hover': {
      background: '#FF310028',
    },

    '& > span ': {
      '& > svg': {
        fill: '#FF3100',
      },
    },

    [theme.breakpoints.down('md')]: {
      '&:last-child': {
        margin: '0 0 0 6px',
      },
    },
  },
  shiftButtonIcon: {
    fill: '#4357EF',
    marginRight: '4px',
  },
  shiftButtonText: {
    font: 'normal normal 600 14px/21px Poppins',
    letterSpacing: '0px',
    color: '#0058FF',

    opacity: 1,
  },
  weekDay: {
    textTransform: 'capitalize',
  },
  closed: {
    marginRight: 17,
  },
  weekWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,

    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  select: {
    [theme.breakpoints.down(724)]: {
      '& > div': {
        width: '100%',
      },
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  hidden: {
    visibility: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hiddenButton: {
    [theme.breakpoints.down(724)]: {
      display: 'none',
    },
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '472px',
    minHeight: '38px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse',

      '& > h6': {
        marginRight: 8,
      },
    },
  },
  sameSwitch: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  error: {
    color: '#FF3100',
  },
  boxContainer: {
    border: '1px solid #DADCE0',
    padding: '24px',
    borderRadius: '10px',
    marginBottom: '24px',
  },
}));
