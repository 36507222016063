import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import { WorkHours } from '../WorkHours';
import { useStyles } from './BusinessHours.styles';
import Button from '../Button';

export function BusinessHours({
  data,
  onChange,
  deliveryState,
  takeoutState,
  collapsed,
  onShow,
  handleSave,
  handleCancel,
}) {
  const classes = useStyles();
  const [deliverySame, setDeliverySame] = deliveryState;
  const [takeoutSame, setTakeoutSame] = takeoutState;

  useEffect(() => {
    if (takeoutSame) onChange((old) => ({ ...old, takeout: old.business }));
  }, [takeoutSame, onChange]);

  useEffect(() => {
    if (deliverySame) onChange((old) => ({ ...old, delivery: old.business }));
  }, [deliverySame, onChange]);

  const handleChange = (value) => {
    onChange((old) => ({
      ...old,
      ...value,
      ...(deliverySame ? { delivery: value.business } : {}),
      ...(takeoutSame ? { takeout: value.business } : {}),
    }));
  };

  return (
    <div style={{ width: '100%' }}>
      {collapsed ? (
        <div
          data-test-id="SDw5x2yzsDJ5Nxm0DMNBW"
          className={classes.showHoursContainer}
          onClick={onShow}
        >
          <Typography variant="subtitle1">Show hours</Typography>
        </div>
      ) : (
        <>
          <Box className={classes.header}>
            <Box className={classes.buttonBox}>
              <Box display="flex" alignItems="center">
                <Button
                  data-test-id="-9aX-GKUM5wZR1F8b6o51"
                  variant="outlined"
                  color="inherit"
                  className={classes.button}
                  onClick={handleCancel}
                  data-testid="cancel-button"
                >
                  <Typography className={classes.buttonText}>Cancel</Typography>
                </Button>
                <Button
                  data-test-id="s2bVhmqanlzAMpGc1iFle"
                  variant="outlined"
                  color="inherit"
                  className={classes.buttonBlue}
                  onClick={handleSave}
                  data-testid="save-button"
                >
                  <Typography className={classes.buttonBlueText}>
                    Save
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
          <WorkHours
            title="Business hours"
            disableSameToggle
            value={data?.business}
            onChange={(value) => handleChange({ business: value })}
          />
          <WorkHours
            title="Delivery hours"
            value={data?.delivery}
            same={deliverySame}
            setSame={setDeliverySame}
            onChange={(value) => handleChange({ delivery: value })}
          />
          <WorkHours
            title="Takeout hours"
            value={data?.takeout}
            same={takeoutSame}
            setSame={setTakeoutSame}
            onChange={(value) => handleChange({ takeout: value })}
          />
        </>
      )}
    </div>
  );
}

BusinessHours.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  takeoutState: PropTypes.array.isRequired,
  deliveryState: PropTypes.array.isRequired,
  collapsed: PropTypes.bool.isRequired,
  onShow: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default BusinessHours;
