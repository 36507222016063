import React, { useState, useEffect, useMemo } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';

import PropTypes from 'prop-types';

import { endOfDay, endOfWeek, startOfDay, format } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useStyles } from './TempClosingHours.style';

function TempClosingHoursAddNew({ onClose, onCreate, timezone }) {
  const classes = useStyles();
  const [commonSelected, setCommonSelected] = useState(null);
  const [startPeriod, setStartPeriod] = useState(null);
  const [endPeriod, setEndPeriod] = useState(null);

  const onStartChange = (value) => {
    if (value) {
      setCommonSelected(null);

      const tzValue = utcToZonedTime(value, timezone);
      setStartPeriod({
        label: '',
        value: tzValue,
      });
    }
  };

  const onEndChange = (value) => {
    if (value) {
      setCommonSelected(null);

      const tzValue = utcToZonedTime(value, timezone);
      setEndPeriod({
        label: '',
        value: tzValue,
      });
    }
  };

  const onCommonSelected = (value) => {
    setCommonSelected(value);

    let startVal = utcToZonedTime(new Date(), timezone);
    let endVal = utcToZonedTime(new Date(), timezone);

    if (value === 'today') {
      endVal = zonedTimeToUtc(endOfDay(startVal), timezone);
    } else if (value === 'tomorrow') {
      startVal = zonedTimeToUtc(startOfDay(startVal), timezone);
      startVal = new Date(startVal.getTime() + 60 * 60 * 24 * 1000);
      endVal = new Date(startVal.getTime() + 60 * 60 * 24 * 1000 - 1);
    } else if (value === 'thisweek') {
      endVal = zonedTimeToUtc(endOfWeek(startVal), timezone);
    } else if (value === 'nextweek') {
      startVal = zonedTimeToUtc(endOfWeek(startVal), timezone);
      startVal = new Date(startVal.getTime() + 1);
      endVal = zonedTimeToUtc(endOfWeek(startVal), timezone);
    }

    setStartPeriod({
      label: '',
      value: startVal,
    });
    setEndPeriod({
      label: '',
      value: endVal,
    });
  };

  useEffect(() => {
    if (startPeriod === null || endPeriod === null) {
      onCommonSelected('today');
    }
  }, []);

  const periodSummaryText = useMemo(() => {
    if (startPeriod !== null && endPeriod !== null) {
      return `${format(startPeriod.value, 'MM/dd/yyyy hh:mm a')} - ${format(
        endPeriod.value,
        'MM/dd/yyyy hh:mm a'
      )}`;
    }

    return null;
  }, [startPeriod, endPeriod]);

  return (
    <Box className={classes.presetHoursContainer}>
      <Box className={classes.presetHoursHeader}>
        <Typography variant="h4">Common Intervals</Typography>
      </Box>
      <Box className={classes.presetHoursCommonButtons}>
        <Button
          data-test-id="Wd2pWfxIAp794jLL1TkWR"
          variant={commonSelected === 'today' ? 'contained' : 'outlined'}
          onClick={() => onCommonSelected('today')}
          className={classes.presetHoursButton}
          disabled={commonSelected === 'today'}
        >
          Today
        </Button>
        <Button
          data-test-id="kjsGRrDYn0Jlb1KTXiUh3"
          variant={commonSelected === 'tomorrow' ? 'contained' : 'outlined'}
          onClick={() => onCommonSelected('tomorrow')}
          className={classes.presetHoursButton}
          disabled={commonSelected === 'tomorrow'}
        >
          Tomorrow
        </Button>
        <Button
          data-test-id="dhABZbY7w6-RO5W5dITE0"
          variant={commonSelected === 'thisweek' ? 'contained' : 'outlined'}
          onClick={() => onCommonSelected('thisweek')}
          className={classes.presetHoursButton}
          disabled={commonSelected === 'thisweek'}
        >
          This Week
        </Button>
        <Button
          data-test-id="wkRX9NdoW50HLNPfMHetK"
          variant={commonSelected === 'nextweek' ? 'contained' : 'outlined'}
          onClick={() => onCommonSelected('nextweek')}
          className={classes.presetHoursButton}
          disabled={commonSelected === 'nextweek'}
        >
          Next Week
        </Button>
      </Box>
      <Box className={classes.presetHoursHeader}>
        <Typography variant="h4">Select Closing Hours</Typography>
        <Typography variant="h5">
          What times will the restaurant be closed?
        </Typography>
        <Box className={classes.presetHourSummaryBox}>
          <Box className={classes.presetHourSummaryBoxDateBox}>
            <Typography variant="h4" sx={{ paddingBottom: '10px' }}>
              From
            </Typography>
            <DateTimePicker
              label={startPeriod?.label ?? ''}
              value={startPeriod?.value}
              onChange={onStartChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          <Box className={classes.presetHourSummaryBoxDateBox}>
            <Typography variant="h4" sx={{ paddingBottom: '10px' }}>
              To
            </Typography>
            <DateTimePicker
              label={endPeriod?.label ?? ''}
              value={endPeriod?.value}
              onChange={onEndChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </Box>
      </Box>
      {periodSummaryText !== null && (
        <Box className={classes.hoursSummaryBox}>
          <Typography variant="h5">
            Are you sure you want to close during the time below?
          </Typography>
          <Typography variant="h3" sx={{ padding: '20px' }}>
            {periodSummaryText}
          </Typography>
        </Box>
      )}
      <Box className={classes.hoursActionButtonsBox}>
        <Box className={classes.hoursActionButtonBox}>
          <Button
            data-test-id="DHEs-zrfbBddIC0rjvHU1"
            variant="outlined"
            onClick={onClose}
            className={classes.actionButton}
          >
            Cancel
          </Button>
        </Box>
        <Box className={classes.hoursActionButtonBox}>
          <Button
            data-test-id="D0V6VIH1KL5JjSvks4V_D"
            variant="contained"
            onClick={() => {
              onCreate({ start: startPeriod.value, end: endPeriod.value });
            }}
            className={classes.actionButton}
            disabled={periodSummaryText === null}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

TempClosingHoursAddNew.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default TempClosingHoursAddNew;
