import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  header: {
    justifyContent: 'space-between',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginBottom: '24px',
    padding: '0px 25px',
    [theme.breakpoints.down('md')]: {
      margin: '0 -15px 24px',
      background: 'white',
      boxShadow: '0 3px 6px #00000029',
      padding: '12px 16px',
      position: 'fixed',
      zIndex: '2',
      top: '80px',
      width: '100%',
    },
  },
  pageTitle: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  subTitle: {
    font: 'normal normal 600 24px/35px Poppins',
    letterSpacing: '0.17px',
    color: '#282B31',
    opacity: 0.6,
  },
  boxContainer: {
    border: '1px solid #DADCE0',
    padding: '24px',
    borderRadius: '10px',
    marginBottom: '24px',
  },
  inputContainer: {
    display: 'flex',
    minWidth: '336px',
    flexDirection: 'column',
    width: '50%',
    marginTop: '32px',
  },
  input: {
    borderRadius: '8px',

    '& label.Mui-focused': {
      color: '#282B3199',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#DADCE0',
      },
      '&:hover fieldset': {
        borderColor: '#DADCE0',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #282B3199',
      },
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '12px',
    },
  },
  areaInput: {
    borderRadius: '8px',
    width: '100%',

    '& label.Mui-focused': {
      color: '#282B3199',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#DADCE0',
      },
      '&:hover fieldset': {
        borderColor: '#DADCE0',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #282B3199',
      },
    },
  },
  label: {
    marginLeft: '5px',
    font: 'normal normal 600 12px/24px Poppins',
    letterSpacing: '0.11px',
    color: '#282B31',
    opacity: 0.9,
  },
  button: {
    border: '1px solid #0000001F',
    borderRadius: '4px',
    opacity: 1,
    marginRight: '32px',
  },
  buttonText: {
    font: 'normal normal 600 14px/21px Poppins',
    letterSpacing: '0px',
    color: '#0058FF',
    opacity: 1,
  },
  buttonBlue: {
    border: '1px solid #0000001F',
    borderRadius: '4px',
    opacity: 1,
    backgroundColor: '#0058FF',
    '&:hover': {
      backgroundColor: 'rgba(0, 88, 255, 0.5)',
    },
  },
  buttonBlueText: {
    color: '#FFFFFF',
    font: 'normal normal 400 14px/21px Poppins',
    letterSpacing: '0px',
    opacity: 1,
  },
  footerText: {
    font: 'normal normal normal 14px/21px Poppins',
    letterSpacing: '0',
    color: '#333333',
    opacity: 0.87,
  },
  iconButton: {
    width: '32px',
    minWidth: '32px',
    height: '29px',
    background: '#282B310F 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: 1,
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '32px',
    '@media (max-width: 780px)': {
      marginTop: '15px',
    },
  },
  webContent: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 780px)': {
      flexDirection: 'column',
    },
  },
}));
