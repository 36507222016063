import { api } from './Api';

export const addAnswer = async ({ restId, questionId, text, price, sort }) => {
  const response = await api.put(
    `/restmanager/${restId}/questions/${questionId}/answer`,
    {
      text,
      price,
      sort,
      weight: 1,
    }
  );
  return response;
};

export const editAnswer = async ({
  restId,
  questionId,
  id,
  weight,
  text,
  price,
  sort,
}) => {
  const response = await api.post(
    `/restmanager/${restId}/questions/${questionId}/answer/${id}`,
    {
      text,
      price,
      sort,
      weight,
    }
  );
  return response;
};

export const deleteAnswer = async ({ restId, questionId, id, params }) => {
  const response = await api.delete(
    `/restmanager/${restId}/questions/${questionId}/answer/${id}`,
    params
  );
  return response;
};

export const restoreAnswer = async ({ restId, questionId, id }) => {
  const response = await api.put(
    `/restmanager/${restId}/questions/${questionId}/answer/${id}/restore`
  );
  return response;
};
