import React from 'react';
import { Box, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';

import { Controller } from 'react-hook-form';
import { useStyles } from './Sections.styles';

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'white',
    maxWidth: '300px',
    boxShadow: '0 3px 6px #00000029',
    letterSpacing: '0.4px',
    color: '#00000099',
    padding: '10px',
  },
}))(Tooltip);

function Sections({ control }) {
  const classes = useStyles();

  return (
    <Box className={classes.selections}>
      <LightTooltip title='e.g. "2" if the user must select at least 2 toppings.'>
        <Box className={classes.selection}>
          <Controller
            name="min"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.input}
                variant="outlined"
                label="Min Selections"
                inputProps={{ 'data-testid': 'min-input' }}
                disabled
              />
            )}
          />
        </Box>
      </LightTooltip>
      <LightTooltip title='e.g. "6" if the restaurant will not allow more than 6 toppings on a pizza.'>
        <Box className={classes.selection}>
          <Controller
            name="max"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.input}
                variant="outlined"
                label="Max Selections"
                disabled
              />
            )}
          />
        </Box>
      </LightTooltip>
      <LightTooltip title='e.g. "2" means the user can order Double Pepperoni, but the restaurant will not allow 3x pepperoni or 4x pepperoni, etc.'>
        <Box className={classes.selection}>
          <Controller
            name="max_per_answer"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.input}
                variant="outlined"
                label="Max Per Answer"
                disabled
              />
            )}
          />
        </Box>
      </LightTooltip>
      <LightTooltip title='e.g. "3" if the first 3 toppings are included. A fourth topping will change the price.'>
        <Box className={classes.selection}>
          <Controller
            name="num_free"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.input}
                variant="outlined"
                label="Free Selections"
                disabled
              />
            )}
          />
        </Box>
      </LightTooltip>
    </Box>
  );
}

Sections.propTypes = {
  control: PropTypes.object,
};

export default Sections;
