import { Box, TextField, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';
import { useStyles } from './Register.styles';

function NumberFormatCustom({ inputRef, ...other }) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      format="(###) ###-####"
      placeholder="App user Phone"
      mask="_"
      type="tel"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.any,
};

function Register({ title, form: { control, getErrors } }) {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h5" className={classes.formTitle}>
        {title}
      </Typography>
      <Box display="flex">
        <Box flex={1}>
          <Box className={classes.ownerName}>
            <Box flex={1}>
              <Typography variant="h6" className={classes.formTextFieldLabel}>
                First name
              </Typography>
              <Controller
                name="registerFirstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    id="registerFirstName"
                    placeholder="First Name"
                    className={classes.formInputBox}
                    {...getErrors('registerFirstName')}
                  />
                )}
              />
            </Box>
            <Box flex={1}>
              <Typography variant="h6" className={classes.formTextFieldLabel}>
                Last name
              </Typography>
              <Controller
                name="registerLastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    id="registerLastName"
                    placeholder="Last Name"
                    className={classes.formInputBox}
                    {...getErrors('registerLastName')}
                  />
                )}
              />
            </Box>
          </Box>
          <Typography variant="h6" className={classes.formTextFieldLabel}>
            Phone number
          </Typography>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                fullWidth
                className={classes.formInputBox}
                variant="outlined"
                {...getErrors('registerPhoneNumber')}
              />
            )}
            defaultValue={null}
            name="registerPhoneNumber"
            control={control}
          />
          <Typography variant="h6" className={classes.formTextFieldLabel}>
            Email
          </Typography>
          <Controller
            control={control}
            name="registerEmail"
            render={({ field }) => (
              <TextField
                {...field}
                className={clsx(classes.formInputBox, classes.lowerCase)}
                variant="outlined"
                fullWidth
                id="registerEmail"
                placeholder="Type your email"
                type="email"
                {...getErrors('registerEmail')}
              />
            )}
          />
          <Typography variant="h6" className={classes.formTextFieldLabel}>
            Password
          </Typography>
          <Controller
            name="registerPassword"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.formInputBox}
                variant="outlined"
                fullWidth
                placeholder="Type your password"
                type="password"
                id="registerPassword"
                autoComplete="current-password"
                inputProps={{ 'data-testid': 'login:password-input' }}
                {...getErrors('registerPassword')}
              />
            )}
          />
        </Box>
      </Box>
    </>
  );
}

Register.propTypes = {
  form: PropTypes.object,
  title: PropTypes.string,
};

export default Register;
