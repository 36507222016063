import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  listButton: {
    width: '100%',
    justifyContent: 'start',

    '&:hover': {
      background: 'none',
    },
  },
  listTitle: {
    marginLeft: '8px',
    color: '#171725',
    opacity: '0.8',
    fontWeight: '500',
  },
});
