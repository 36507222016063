import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '379px',
    padding: '14px 24px',
    height: '48px',
    background: '#fff',
    border: '1px solid #DADCE0',
    borderRadius: '12px',
  },
  input: {
    display: 'inline-flex',
    flex: '1',
    height: '23px',
    fontSize: '16px',
    border: 'none',
    color: '#171725',

    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
  },
  icon: {
    width: '20px',
    height: '20px',
    letterSpacing: '0.14px',
    color: '#171725',
    opacity: '0.56',
    border: 'none',
    cursor: 'pointer',
  },
  iconSearch: {
    marginRight: '14px',
  },
  iconSort: {
    marginLeft: '14px',
  },
});
