export const numberWithCommas = (x) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatCurrencyValue = (value) =>
  `$${numberWithCommas((parseFloat(value) || 0).toFixed(2))}`;

export const formatCurrencyDisplay = (value) =>
  `$${numberWithCommas(parseFloat(value).toFixed(value > 9999 ? 0 : 2))}`;

export default formatCurrencyValue;
