import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useMenuContext } from '../../../context/menuContext';
import { getHighestSales } from '../../../services/ReportService';
import { Loading } from '../Loading';
import { Error } from '../Error';
import { formatCurrencyValue } from '../../../utils/FormatUtils/formatCurrencyValue';
import { useStyles } from './CommonStyles.styles';
import DropdownFilter from '../../input/DropdownFilter/DropdownFilter';

export function Filter({ filter, setFilter, style, className }) {
  return (
    <DropdownFilter
      items={{
        filters: ['By Category', 'By Zone', 'By Shift', 'By Type'],
        filter,
        setFilter,
      }}
      onlyString
      style={style}
      className={className}
      buttonWidth="167px"
    />
  );
}

Filter.propTypes = {
  filter: PropTypes.string,
  className: PropTypes.string,
  setFilter: PropTypes.func,
  style: PropTypes.object,
};

export function HighestSale({ timeFilter }) {
  const classes = useStyles();
  const [filter, setFilter] = useState('By Category');
  const { selectedRestaurant } = useMenuContext();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const infoType = useMemo(
    () =>
      ({
        'By Category': 'category',
        'By Zone': 'zone',
        'By Shift': 'shift',
        'By Type': 'type',
      }[filter]),
    [filter]
  );

  useEffect(() => {
    if (selectedRestaurant?.id)
      (async () => {
        try {
          setLoading(true);
          setError(false);
          const response = await getHighestSales(
            selectedRestaurant.id,
            {
              ...timeFilter,
            },
            'sales',
            infoType
          );
          setData(response);
          setLoading(false);
        } catch {
          setError(true);
          setLoading(false);
        }
      })();

    // eslint-disable-next-line
  }, [selectedRestaurant, timeFilter, infoType]);

  if (loading) {
    return (
      <Box className={classes.infoContainer}>
        <Loading />
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={classes.infoContainer}>
        <Error />
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.containerTitleWrapper}>
        <Typography className={classes.outsideContainerTitle}>
          Highest Sales Items
        </Typography>

        <Filter filter={filter} setFilter={setFilter} />
      </Box>
      <Box className={classes.infoContainer}>
        {data.length === 0 ? (
          <Box className={classes.highestSaleWrapper}>No records found</Box>
        ) : (
          <Box className={classes.highestSaleWrapper}>
            <Box className={classes.highestSaleColumnWrapper}>
              {data.slice(0, Math.ceil(data.length / 2)).map((info) => (
                <Box key={info.id}>
                  <Typography className={classes.category}>
                    {info.group}
                  </Typography>
                  <Box className={classes.highestSaleInfoWrapper}>
                    <Typography>{info.name}</Typography>
                    <Typography>{formatCurrencyValue(info.score)}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box className={classes.highestSaleColumnWrapper}>
              {data.slice(-Math.ceil(data.length / 2)).map((info) => (
                <Box key={info.id}>
                  <Typography className={classes.category}>
                    {info.group}
                  </Typography>
                  <Box className={classes.highestSaleInfoWrapper}>
                    <Typography>{info.name}</Typography>
                    <Typography>{formatCurrencyValue(info.score)}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}

HighestSale.propTypes = {
  timeFilter: PropTypes.shape({
    start: PropTypes.object,
    end: PropTypes.object,
  }),
};

export default HighestSale;
