import React, { useEffect } from 'react';
import { Box } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { Component, useMenuContext } from '../../../context/menuContext';
import QuestionsPage from '../QuestionsPage/QuestionsPage';
import ItemsPage from '../ItemsPage/ItemsPage';
import { ItemContextProvider } from '../../../context/itemsContext';
import QuestionsContextProvider from '../../../context/questionsContext/questionsContext';
import { Loading } from '../../layout/Loading/Loading';
import { Error } from '../../layout/Error/Error';
import { NoRestaurants } from '../../layout/NoRestaurants/NoRestaurants';
import { getSettings } from '../../../redux/ducks/RestaurantDucks';

export function Menu() {
  const {
    selectedRestaurant,
    isLoading,
    error: restaurantError,
  } = useMenuContext();
  const dispatch = useDispatch();
  const restaurantSettings = useSelector((state) => state.restaurant.settings);

  useEffect(() => {
    const loadSettingsData = async (id) => {
      if (!id) return;
      if (id === restaurantSettings?.id) return;
      dispatch(getSettings({ id }));
    };

    loadSettingsData(selectedRestaurant?.id);
    // eslint-disable-next-line
  }, [selectedRestaurant, dispatch]);

  if (isLoading) {
    return <Loading />;
  }

  if (restaurantError) {
    return <Error />;
  }

  if (!selectedRestaurant || !selectedRestaurant?.id) {
    return <NoRestaurants />;
  }
  return (
    <Box padding="0 15px" display="flex" flex="1">
      <Component
        components={[
          {
            label: 'Items',
            component: (
              <QuestionsContextProvider>
                <ItemContextProvider>
                  <ItemsPage />
                </ItemContextProvider>
              </QuestionsContextProvider>
            ),
          },
          {
            label: 'Questions/Answers',
            component: (
              <QuestionsContextProvider>
                <QuestionsPage />
              </QuestionsContextProvider>
            ),
          },
        ]}
      />
    </Box>
  );
}
