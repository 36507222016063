import React, { useState } from 'react';
import { Box } from '@mui/material';
import {
  AddPhotoAlternate,
  Collections,
  DeleteOutline,
  Publish,
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './FaviconDropZone.styles';
import GalleryModal from '../../layout/GalleryModal/GalleryModal';
import { DropZoneImage } from '../../layout/GalleryModal/DropZone/DropZoneImage';
import { Gallery } from '../../layout/GalleryModal/Gallery/Gallery';

export function DropZoneFaviconPlaceholder({ favicon, disabled }) {
  const classes = useStyles();
  if (favicon?.length > 0) {
    return (
      <>
        <div className={classes.dropzoneFaviconThumb}>
          <div className={classes.dropzoneThumbInner}>
            <img
              data-testid={favicon[0].name}
              alt=""
              src={favicon[0].url}
              className={clsx(classes.dropzoneImg, classes.faviconImg)}
            />
          </div>
        </div>
        <section
          style={{
            display: 'flex',
            border: '1px dashed #E0E0E0',
            borderLeft: 'none',
            borderRadius: '0 4px 4px 0',
            background: '#FDFDFD',
            alignItems: 'center',
            paddingLeft: 16,
            width: '100%',
          }}
        >
          <p style={{ fontSize: 12, color: '#00000099' }}>Edit favicon</p>
        </section>
      </>
    );
  }
  return (
    <>
      <section
        className={clsx(classes.dropzoneIcon, {
          [classes.dropzoneDisabled]: disabled,
        })}
      >
        <AddPhotoAlternate
          fontSize="small"
          style={{ fill: `${disabled ? 'rgba(0,0,0,0.3)' : '#333'}` }}
        />
      </section>
      <section
        className={clsx(classes.dropzone, {
          [classes.dropzoneDisabled]: disabled,
        })}
      >
        <p
          style={{
            fontSize: 12,
            color: `${disabled ? 'rgba(0,0,0,0.3)' : '#333'}`,
          }}
        >
          Upload favicon
        </p>
      </section>
    </>
  );
}
DropZoneFaviconPlaceholder.propTypes = {
  favicon: PropTypes.array,
  disabled: PropTypes.bool,
};

export function FaviconDropZone({
  favicon,
  setFavicon,
  handleDeleteUpload,
  handleDrop,
  disabled = false,
}) {
  const classes = useStyles();
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const components = [
    {
      label: 'Upload File',
      component: DropZoneImage,
      icon: <Publish fontSize="small" style={{ marginRight: '8px' }} />,
    },
    {
      label: 'User Uploads',
      component: Gallery,
      icon: <Collections fontSize="small" style={{ marginRight: '8px' }} />,
    },
  ];

  const handleCloseGallery = () => {
    setIsGalleryOpen(false);
  };

  const handleSaveFavicon = (data) => {
    handleDrop(data, setFavicon, 'favicon');
  };

  const handleOpenGallery = () => {
    !disabled && setIsGalleryOpen(true);
  };

  return (
    <>
      <Box className={classes.faviconDropzone}>
        <Box
          data-test-id="_xR3Z71oDQ4lFfXvJKHjK"
          name="favicon"
          className={classes.faviconLogo}
          onClick={handleOpenGallery}
          data-testid="favicon-placeholder"
        >
          <DropZoneFaviconPlaceholder favicon={favicon} disabled={disabled} />
        </Box>
        {favicon.length > 0 && !disabled && (
          <Box
            data-test-id="dk4EFWPcqXsARL6gbbsjV"
            className={classes.deleteFavicon}
            onClick={() => handleDeleteUpload(favicon, setFavicon, 'favicon')}
            style={{ cursor: 'pointer' }}
            data-testid="delete-favicon-image"
          >
            <DeleteOutline style={{ fontSize: 14, fill: '#FF3100' }} />
          </Box>
        )}
      </Box>
      {isGalleryOpen && (
        <GalleryModal
          open={isGalleryOpen}
          handleClose={handleCloseGallery}
          setFile={handleSaveFavicon}
          file={favicon}
          components={components}
        />
      )}
    </>
  );
}

FaviconDropZone.propTypes = {
  favicon: PropTypes.array,
  setFavicon: PropTypes.func,
  handleDeleteUpload: PropTypes.func,
  handleDrop: PropTypes.func,
  mimeTypes: PropTypes.object,
  disabled: PropTypes.bool,
};
