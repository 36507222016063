import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Typography, Pagination } from '@mui/material';
import PropTypes from 'prop-types';
import { useStyles as useCommonStyles } from '../ReportSubItems.styles';
import { useStyles } from './POSReconciliation.styles';
import DropdownFilter from '../../../../input/DropdownFilter/DropdownFilter';
import { Loading } from '../../../../layout/Loading/Loading';
import { Error } from '../../../../layout/Error/Error';
import { getPOSReconciliation } from '../../../../../services/ReportService';
import { useMenuContext } from '../../../../../context/menuContext';
import { formatCurrencyValue } from '../../../../../utils/FormatUtils/formatCurrencyValue';
import { DatePicker } from '../../../../input/DatePicker/DatePicker';
import { thisWeek, yesterday } from '../../../../../utils/date-utils';

export function DaySummary({ info }) {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      data-testid="day-summary"
      className={classes.infoContainer}
    >
      <Box display="flex" justifyContent="space-between" flex="8">
        <Typography className={classes.summaryInfo}>TOTALS</Typography>
        <Typography className={classes.summaryInfo} />
        <Typography className={classes.summaryInfo} />
        <Typography className={classes.summaryInfo}>
          {formatCurrencyValue(info?.inhouse)}
        </Typography>
        <Typography className={classes.summaryInfo}>
          {formatCurrencyValue(info?.retail)}
        </Typography>
        <Typography className={classes.summaryInfo}>
          {+parseFloat(info?.discount * 100).toFixed(2)}%
        </Typography>
      </Box>
      <Box display="flex" flex="1" justifyContent="flex-end">
        <Typography className={classes.summaryInfoTotal}>
          {formatCurrencyValue(info?.wholesale)}
        </Typography>
      </Box>
    </Box>
  );
}

DaySummary.propTypes = {
  info: PropTypes.shape({
    date: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    inhouse: PropTypes.number,
    retail: PropTypes.number,
    discount: PropTypes.number,
    wholesale: PropTypes.number,
  }).isRequired,
};

export function DayInfo({ info }) {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      padding="6px 0"
      data-testid="day-info"
    >
      <Box display="flex" justifyContent="space-between" flex="8">
        <Typography className={classes.tableInfo}>{info?.time}</Typography>
        <Typography className={classes.tableInfo}>{info?.id}</Typography>
        <Typography className={classes.tableInfo}>{info?.type}</Typography>
        <Typography className={classes.tableInfo}>
          {formatCurrencyValue(info?.inhouse)}
        </Typography>
        <Typography className={classes.tableInfo}>
          {formatCurrencyValue(info?.retail)}
        </Typography>
        <Typography className={classes.tableInfo}>
          {+parseFloat(info?.discount * 100).toFixed(2)}%
        </Typography>
      </Box>
      <Box display="flex" flex="1" justifyContent="flex-end">
        <Typography className={classes.tableInfo} style={{ textAlign: 'end' }}>
          {formatCurrencyValue(info?.wholesale)}
        </Typography>
      </Box>
    </Box>
  );
}

DayInfo.propTypes = {
  info: PropTypes.object.isRequired,
};

export function Week({ info }) {
  const classes = useStyles();

  return (
    <Box className={classes.infoContainer}>
      <Box className={classes.scrollWrapper}>
        <Title titleStyle={classes.titleWrapperMobile} />
        <Box className={classes.wrapperDay}>
          <Typography className={classes.date}>
            {info?.summary?.date}
          </Typography>
          <Box className={classes.infoWeekWrapper}>
            {info?.info?.map((day) => (
              <DayInfo key={day.id} info={day} />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

Week.propTypes = {
  info: PropTypes.object.isRequired,
};

export function Title({ titleStyle }) {
  const classes = useStyles();
  return (
    <Box className={titleStyle}>
      <Box display="flex" justifyContent="space-between" flex="8">
        <Typography className={classes.columnTitle}>DATE/TIME</Typography>
        <Typography className={classes.columnTitle}>ID</Typography>
        <Typography className={classes.columnTitle}>TYPE</Typography>
        <Typography className={classes.columnTitle}>IN-HOUSE PRICE</Typography>
        <Typography className={classes.columnTitle}>RETAIL PRICE</Typography>
        <Typography className={classes.columnTitle}>DISCOUNT %</Typography>
      </Box>
      <Box display="flex" flex="1" justifyContent="flex-end">
        <Typography className={classes.columnTitle} style={{ width: 'unset' }}>
          NET RECEIVED
        </Typography>
      </Box>
    </Box>
  );
}

Title.propTypes = {
  titleStyle: PropTypes.string,
};

export function POSReconciliation() {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const [filter, setFilter] = useState({ label: 'Yesterday' });
  const { selectedRestaurant } = useMenuContext();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [reloading, setReloading] = useState(false);
  const [error, setError] = useState(false);
  const filterBtnEl = useRef();
  const [popoverEl, setPopoverEl] = useState(null);
  const [params, setParams] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const params = {
      Yesterday: yesterday(),
      'This week': thisWeek(),
    }[filter.label];

    if (params) {
      setParams(params);
    }
  }, [filter]);

  const handleChangePage = useCallback(
    (_, page) => {
      setReloading(true);
      if (selectedRestaurant?.id && params.start)
        (async () => {
          try {
            const {
              total_pages,
              formattedResults,
              page: newPage,
            } = await getPOSReconciliation(selectedRestaurant.id, {
              ...params,
              page,
            });
            setTotalPages(total_pages);
            setPage(Number(newPage));
            setData(formattedResults);
            setLoading(false);
            setReloading(false);
          } catch (err) {
            setError(true);
            setLoading(false);
            setReloading(false);
          }
        })();
    },
    [params, selectedRestaurant]
  );

  useEffect(() => {
    handleChangePage({}, 1);
  }, [handleChangePage]);

  const handlePopoverOpen = () => {
    setPopoverEl(filterBtnEl.current);
  };

  const handlePopoverClose = () => {
    setPopoverEl(null);
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Box flex="1">
      <Box className={commonClasses.header}>
        <Typography variant="h1" className={commonClasses.pageTitle}>
          POS Reconciliation
        </Typography>
        <Box data-testid="filter" ref={filterBtnEl}>
          <DropdownFilter
            items={{
              filters: [
                'Yesterday',
                'This week',
                {
                  label: 'Custom date',
                  open: handlePopoverOpen,
                  close: handlePopoverClose,
                },
              ],
              filter,
              setFilter,
            }}
            style={{ marginLeft: '24px' }}
            buttonWidth="147px"
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        {data?.length ? (
          <>
            <Title titleStyle={classes.titleWrapperDesktop} />
            <Box className={classes.infoWrapper}>
              {reloading ? (
                <Loading />
              ) : (
                <Box className={classes.weekWrapper}>
                  <DaySummary info={data[0]?.summary} />
                  {data.map((info, i) => (
                    <Week key={String(i)} info={info} />
                  ))}
                </Box>
              )}
            </Box>
          </>
        ) : (
          <Box className={classes.noRecords}>No records found</Box>
        )}
      </Box>
      <DatePicker
        popoverEl={popoverEl}
        handleClosePopover={handlePopoverClose}
        setParams={setParams}
      />
      {totalPages > 1 && (
        <Pagination
          page={page}
          count={totalPages}
          size="small"
          onChange={handleChangePage}
          style={{ margin: '15px auto' }}
        />
      )}
    </Box>
  );
}

export default POSReconciliation;
