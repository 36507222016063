import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  formTitle: {
    marginBottom: 32,
  },
  formTextFieldLabel: {
    marginLeft: 16,
    marginBottom: 8,
  },
  formInputBox: {
    marginBottom: 24,
    '& > div > input': {
      fontSize: '16px',
      padding: '14px 16px',
    },
  },
  lowerCase: {
    '& > div > input': {
      textTransform: 'lowercase',
    },
  },
}));
