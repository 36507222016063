import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Typography, Box, Button } from '@mui/material';
import { format } from 'date-fns';
import { useStyles } from './TempClosingHours.style';

function TempClosingHoursList({ hours, onDelete }) {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.existingHourListBox}>
        {hours.map((hour) => (
          <Box className={classes.existingHourBox}>
            <Box className={classes.presetHourSummaryBoxDateBox}>
              <Typography variant="h4" sx={{ paddingBottom: '10px' }}>
                From
              </Typography>
              <Typography variant="h3">
                {format(new Date(hour.start * 1000), 'MM/dd/yyyy hh:mm a')}
              </Typography>
            </Box>
            <Box className={classes.presetHourSummaryBoxDateBox}>
              <Typography variant="h4" sx={{ paddingBottom: '10px' }}>
                To
              </Typography>
              <Typography variant="h3">
                {format(new Date(hour.end * 1000), 'MM/dd/yyyy hh:mm a')}
              </Typography>
            </Box>
            <Box className={classes.existingHourActionBox}>
              <Button
                data-test-id="gW4UT3R8hrqxDZ-JCmukP"
                variant="outlined"
                onClick={() => onDelete({ hour: hour.id })}
                className={classes.presetHoursButton}
              >
                Delete
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </div>
  );
}

TempClosingHoursList.propTypes = {
  hours: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default TempClosingHoursList;
