import makeStyles from '@mui/styles/makeStyles';
import { opacify } from 'polished';

export const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    justifyContent: 'space-between',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginBottom: '32px',
  },
  containerWrapper: {
    display: 'flex',
    marginBottom: '32px',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginBottom: '0px',
    },
  },
  pieChartContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoContainer: {
    background: '#fff',
    padding: '24px',
    border: '1px solid #DADCE0',
    borderRadius: '10px',
    alignItems: 'center',
    flex: 1,

    '&:not(:last-child)': {
      marginRight: '15px',
    },

    [theme.breakpoints.down('md')]: {
      maxWidth: 'calc(100VW - 30px)',
      overflowX: 'auto',
      overflowY: 'hidden',
      marginBottom: '32px',

      '&:not(:last-child)': {
        marginRight: '0px',
      },
    },
  },
  insideContainerTitle: {
    color: opacify(-0.4, '#282B31'),
    fontSize: '14px',
    fontWeight: '500',
  },
  outsideContainerTitle: {
    color: opacify(-0.4, '#282B31'),
    fontSize: '16px',
    fontWeight: '500',
    marginLeft: '20px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px',
    },
  },
  legend: {
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
    padding: '4px 8px',
    borderRadius: '3px',
    '&:hover': {
      background: opacify(-0.95, '#123321'),
    },
  },
  mapLegend: {
    justifyContent: 'space-between',
    display: 'flex',
    padding: '4px 8px',
    borderRadius: '3px',
  },
  groupButtonWrapper: {
    display: 'flex',
    width: 'max-content',
    background: '#F5F5F5',
    borderRadius: '8px',
    marginBottom: '14px',
  },
  groupButton: {
    borderRadius: '8px',
    color: opacify(-0.4, '#282B31'),
    padding: '4px 8px',
    '& > span': { lineHeight: '20px' },
    border: '2px solid #F5F5F5',

    '&:hover': {
      background: '#F5F5F5',
      color: opacify(-0.2, '#282B31'),
    },
  },
  activeButton: {
    border: '2px solid #DADCE0',
    color: '#282B31',

    '&:hover': {
      color: '#282B31',
    },
  },
  mapContainer: {
    marginRight: '24px',

    [theme.breakpoints.down(735)]: {
      margin: '0 auto',
      marginBottom: '24px',
    },
  },
  mapStyle: {
    height: '292px',
    width: '292px',

    [theme.breakpoints.down(735)]: {
      width: '100%',
      zIndex: '1',
    },
  },
  filterWrapper: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  componentWrapper: {
    marginRight: '15px',
    [theme.breakpoints.down('md')]: {
      marginRight: '0px',
    },
  },
  zoneWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  flexToBlock: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  categoryLegendWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '0px 20px',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
      margin: '0px auto',
      marginTop: '20px',
      maxWidth: '400px',
    },
  },
  containerTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
    [theme.breakpoints.down('md')]: {
      padding: '0 15px',
    },
  },
  highestSaleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  highestSaleColumnWrapper: {
    marginRight: '100px',
    flex: 0.45,
    [theme.breakpoints.down('md')]: {
      marginRight: '0px',
      flex: 1,
    },
  },
  category: {
    color: opacify(-0.6, '#282B31'),
    fontSize: '12px',
    fontWeight: '900',
    marginBottom: '12px',
    textTransform: 'capitalize',
  },
  highestSaleInfoWrapper: {
    display: 'flex',
    marginBottom: '12px',
    justifyContent: 'space-between',
    width: '100%',
  },
}));
