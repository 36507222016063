import { darken } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    maxWidth: 716,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  container: {
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: 624,
    padding: '48px 32px',
    border: '1px solid #E0E0E0',
    background: '#fff',
    borderRadius: 24,
    width: '100%',
  },
  formTitle: {
    marginBottom: 32,
  },
  formInsideTitle: {
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-start',
      marginTop: '8px',
      fontSize: '1.125rem',
    },
  },
  formInsideSubtitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
      margin: 0,
    },
  },
  formTextFieldLabel: {
    marginLeft: 16,
    marginBottom: 8,
  },
  formInputBox: {
    marginBottom: 24,
    '& > div > input': {
      fontSize: '16px',
      padding: '14px 16px',
    },
  },
  formContainerMenuUrl: {
    marginBottom: 24,
  },
  formInputMenuUrl: {
    width: '100%',
    '& > div > input': {
      fontSize: '16px',
      padding: '14px 16px',
    },
  },
  formInputLabelMenuUrl: {
    marginTop: 24,
  },
  formInputEnd: {
    width: '50%',

    [theme.breakpoints.down(735)]: {
      width: '100%',
    },
  },
  formInputBoxAddress: {
    heigh: 54,
    width: '100%',
    marginBottom: 24,

    '& > * > *': {
      border: 'none',
    },

    '& > div': {
      padding: '4px 0',
      paddingLeft: 16,
      borderRadius: 4,
      border: '1px solid #0000001F',
      flex: 1,
      overflowY: 'auto',
      overflowX: 'hidden',
    },

    '& > div > div > svg': {
      fill: 'rgba(42, 51, 40, 0.45)',
    },

    '& > div > input': {
      fontWeight: 500,
      fontSize: 14,
      padding: '14px 16px',
      paddingLeft: '0',
    },
  },
  dropdownSelect: {
    width: 115,
    height: 32,
    margin: '8px',

    '& > div': {
      paddingTop: 8,
      paddingBottom: 7,
    },

    [theme.breakpoints.down('md')]: {
      flex: 1,
      width: 'unset',
      margin: '5px 0',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '3px 0',
    },
  },
  formControlSelect: {
    [theme.breakpoints.down('md')]: {
      margin: '0',
    },
  },
  selectInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(724)]: {
      flex: 1,
    },
  },
  selectControl: {
    minWidth: '96px',
    [theme.breakpoints.down(724)]: {
      minWidth: 'unset',
      width: '100%',
    },
  },
  divider: {
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      margin: '0 15px 0 15px',
    },
  },
  selectWrapper: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addShiftButton: {
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      background: 'rgba(67, 87, 239, 0.1)',
    },

    [theme.breakpoints.down('724')]: {
      display: 'none',
    },
  },
  addShiftMobile: {
    display: 'none',
    [theme.breakpoints.down('724')]: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      background: 'rgba(67, 87, 239, 0.05)',

      '&:hover': {
        background: 'rgba(67, 87, 239, 0.15)',
      },

      '&:last-child': {
        marginTop: '11px',
        marginBottom: '5px',
      },
    },
  },
  removeShiftButton: {
    padding: '6px',
    background: '#FF31000F',
    borderRadius: '4px',

    '&:hover': {
      background: '#FF310028',
    },

    '& > span ': {
      '& > svg': {
        fill: '#FF3100',
      },
    },

    [theme.breakpoints.down('md')]: {
      '&:last-child': {
        margin: '0 0 0 6px',
      },
    },
  },
  shiftButtonIcon: {
    fill: '#4357EF',
    marginRight: '4px',
  },
  shiftButtonText: {
    color: '#4357EF',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  display: {
    display: 'none',
  },
  description: {
    marginTop: '-20px',
    marginBottom: '48px',
  },
  rowHeader: {
    opacity: '0.87',
    fontSize: '1rem',
  },
  rowHeaderFee: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  columnHeader: {
    opacity: '0.87',

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
  columnHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%',

    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
  },
  columnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%',

    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
  },
  table: {
    marginBottom: 24,
  },
  tableInputBox: {
    minWidth: 60,
    borderRadius: '4px 0 0 4px',
    border: '1px solid #0000001F',

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },

    '& > div': {
      borderRadius: 8,
      paddingRight: 0,
    },

    '& > div > div > svg': {
      fill: 'rgba(42, 51, 40, 0.45)',
    },

    '& > div > input': {
      fontWeight: 500,
      fontSize: 14,
      padding: '10px 16px',
      color: '#000000',
    },
  },
  removeBorder: {
    border: 'none',
  },
  inputWrapper: {
    display: 'flex',
    width: '94px',
    border: '1px solid #fff',
  },
  inputWrapperError: {
    border: '1px solid #f44336',
    borderRadius: '4px',
  },
  percentage: {
    background: '#0000001F',
    padding: '10px 12px',
    borderRadius: '0 4px 4px 0',
    fontWeight: 700,
  },
  descriptionTitle: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginBottom: 12,
    fontWeight: 700,
  },
  descriptionText: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  weekDay: {
    textTransform: 'capitalize',
  },
  closed: {
    marginRight: 17,
  },
  formContent: {
    display: 'flex',

    [theme.breakpoints.down(735)]: {
      display: 'block',
      width: '100%',
    },
  },

  mapContainer: {
    marginLeft: '30px',

    [theme.breakpoints.down(735)]: {
      marginLeft: '0',
      marginBottom: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  mapStyle: {
    height: '292px',
    width: '292px',

    [theme.breakpoints.down(735)]: {
      width: '100%',
    },
  },
  ownerName: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > div:not(:first-child)': {
      marginLeft: '30px',
    },

    [theme.breakpoints.down('sm')]: {
      display: 'block',

      '& > div:not(:first-child)': {
        marginLeft: '0px',
      },
    },
  },
  deleteSliderButton: {
    padding: '14px 16px',
    background: 'rgba(255, 49, 0, 0.04)',
    color: '#FF3100',
    textTransform: 'uppercase',
    fontWeight: 'medium',

    '& > svg': {
      fill: '#FF3100',
    },
  },
  weekWrapper: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  select: {
    [theme.breakpoints.down(724)]: {
      '& > div': {
        width: '100%',
      },
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  hidden: {
    visibility: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hiddenButton: {
    [theme.breakpoints.down(724)]: {
      display: 'none',
    },
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '472px',
    minHeight: '38px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse',

      '& > h6': {
        marginRight: 8,
      },
    },
  },
  webContent: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  logoImage: {
    flex: '1',
    display: 'flex',
    maxWidth: '256px',
    marginBottom: '32px',
    minHeight: '256px',
    maxHeight: '256px',
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
    },
  },
  faviconLogo: {
    flex: '1',
    display: 'flex',
    width: '256px',
    maxHeight: '32px',
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      width: 'unset',
    },
  },
  faviconDropzone: {
    display: 'flex',
    maxWidth: '256px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
    },
  },
  sliderWrapper: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  sliderInfoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down(670)]: {
      flexDirection: 'column',

      '& > div': {
        width: '100%',
      },
    },
  },
  sliderImage: {
    flex: '1',
    display: 'flex',
    width: '256px',
    minHeight: '160px',
    maxHeight: '160px',
    marginBottom: '32px',
    cursor: 'pointer',

    [theme.breakpoints.down(670)]: {
      width: 'unset',
      minHeight: '200px',
      maxHeight: '200px',
    },
  },
  dropzoneThumb: {
    display: 'inline-flex',
    borderRadius: '4px',
    border: '1px solid #E0E0E0',
    marginBottom: '6px',
    width: '96px',
    height: '96px',
    boxSizing: 'border-box',
    background: '#eee',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropzoneImg: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '4px',
  },
  showImage: {
    display: 'none',
  },
  faviconImg: {
    objectFit: 'contain',
  },
  dropzoneImgWrapper: {
    marginTop: '8px',
    display: 'flex',
  },
  dropzoneDelete: {
    fontSize: '0.75rem',
    background: 'rgba(255, 49, 0, 0.04)',
    width: '100%',

    '&:hover': {
      background: 'rgba(255, 49, 0, 0.14)',
    },

    '& > span > svg': {
      marginRight: '4px',
      fontSize: '0.75rem',
    },
  },
  dropzoneLogoThumb: {
    display: 'inline-flex',
    borderRadius: '4px',
    maxWidth: '100%',
    boxSizing: 'border-box',
    flex: 1,
    background: '#FDFDFD',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  uploadLogo: {
    display: 'flex',
    position: 'relative',
    bottom: '44px',
    width: '100%',
    padding: '12px 0px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0px 0px 4px 4px',
    background: 'rgba(51, 51, 51, 0.16)',
    textShadow: '1px 1px 2px #fff',

    '&:hover': {
      background: 'rgba(51, 51, 51, 0.26)',
    },
  },
  deleteLogo: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    padding: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0px 4px 0px 0px',
    background: 'rgba(255, 49, 0, 0.08)',

    '&:hover': {
      background: 'rgba(255, 49, 0, 0.18)',
    },
  },
  deleteFavicon: {
    display: 'flex',
    padding: '9px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0px 4px 4px 0px',
    background: 'rgba(255, 49, 0, 0.08)',

    '&:hover': {
      background: 'rgba(255, 49, 0, 0.18)',
    },
  },
  dropzoneFaviconThumb: {
    height: 32,
    width: 32,
    display: 'inline-flex',
    borderRadius: '2px',
    border: '1px solid #e0e0e0',
    boxSizing: 'border-box',
    background: '#FDFDFD',
  },
  dropzoneSliderThumb: {
    display: 'inline-flex',
    borderRadius: '2px',
    maxWidth: '100%',
    padding: '4px',
    boxSizing: 'border-box',
    border: '1px dashed #E0E0E0',
    flex: 1,
    background: '#FDFDFD',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  sameSwitch: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  error: {
    color: '#FF3100',
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #E0E0E0',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '8px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logOutButton: {
    backgroundColor: 'rgba(42, 51, 40, 0.05)',
    fontSize: 14,
    fontWeight: 600,
    borderRadius: 8,
    padding: '8px 16px',

    [theme.breakpoints.down('md')]: {
      padding: '2px 4px',
    },
  },
  tags: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 12px',
    border: '1px solid #0000001F',
    background: '#FAFAFA',
    marginRight: '14px',
    borderRadius: '16px',
    textTransform: 'capitalize',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  addTagButton: {
    background: '#3C55E8',
    color: '#fff',
    borderRadius: '4px',
    fontWeight: 'medium',
    padding: '12px 28px',
    marginTop: '10px',

    '&:hover': {
      background: darken('#3C55E8', 0.25),
    },

    [theme.breakpoints.down(735)]: {
      width: '100%',
    },
  },
  searchButton: {
    flex: 1,
    width: '100%',
    background: 'rgba(67, 87, 239, 0.1)',
    '&:hover': {
      background: 'rgba(67, 87, 239, 0.15)',
    },
  },
  descriptionMultiline: {
    '& > div': {
      padding: '14px 16px',
    },
  },
  lowerCase: {
    '& > div > input': {
      textTransform: 'lowercase',
    },
  },
}));
