import { differenceInSeconds } from 'date-fns';
import BaseAuthentication from './BaseAuthentication';
import { AuthLogoutReasons } from '../Constants';

const { FORCED_LOGOUT, EXPIRED_TOKEN, USER_LOGOUT } = AuthLogoutReasons;

/**
 * @access public
 * @class
 *
 * @param {Object} param - Authentication constructor params.
 * @param {Storage} param.storage - A storage instance to store the auth tokens.
 * @param {OAuthentication~loginRequest} param.loginRequest - A login request callback.
 * @param {OAuthentication~getProfileRequest} param.getProfileRequest - A get profile request callback.
 * @param {OAuthentication~refreshTokenRequest} param.refreshTokenRequest - A refresh token request callback.
 * @param {OAuthentication~deauthorizeRequest} param.deauthorizeRequest - A deauthorize request callback.
 * @param {OAuthentication~navigateToLogin} param.navigateToLogin - A callback to navigate to login screen on logout.
 * @param {string} param.rolesAllowed - Comma separated list of allowed roles. E.g. "Admin,Manager".
 * @example
 * import { OAuthentication } from "@eatzy/common-reactjs/Authentication";
 *
 * const Authentication = new OAuthentication({
 *   storage, // Should be an instance of the Storage lib module
 *   loginRequest: ({email, password}) => yourLoginRequestCallback(email, password),
 *   getProfileRequest:  () => yourGetProfileRequestCallback(),
 *   refreshTokenRequest: (token) => yourRefreshTokenRequestCallback(token),
 *   deauthorizeRequest: (token) => yourLogoutRequestCallback(token),
 *   navigateToLogin: () => yourNavigateToLoginCallback(),
 *   rolesAllowed: "Admin,Owner",
 * });
 *
 */
export default class OAuthentication extends BaseAuthentication {
  storage = null;

  constructor({
    storage,
    loginRequest,
    registerRequest,
    getProfileRequest,
    refreshTokenRequest,
    deauthorizeRequest,
    navigateToLogin,
    rolesAllowed,
    forgotPasswordRequest,
    resetPasswordRequest,
  }) {
    super();
    this.storage = storage;
    this.navigateToLogin = navigateToLogin;
    this.loginRequest = loginRequest;
    this.registerRequest = registerRequest;
    this.getProfileRequest = getProfileRequest;
    this.refreshTokenRequest = refreshTokenRequest;
    this.deauthorizeRequest = deauthorizeRequest;
    this.rolesAllowed = rolesAllowed;
    this.forgotPasswordRequest = forgotPasswordRequest;
    this.resetPasswordRequest = resetPasswordRequest;
  }

  /**
   * Method to register user.
   * This method is responsible for calling the registerRequest callback,.
   *
   * @access public
   * @param {object} param - User registering info.
   * @param {string} param.username - Username.
   * @param {string} param.firstname - First name of the user.
   * @param {string} param.lastname - Last name of the user.
   * @param {string} param.password - Password.
   * @param {string} param.email - Email.
   * @param {string} param.source - Source.
   * @param {string} param.profile - Profile.
   * @param {string} param.phone - Phone.
   * @returns {{message: string}} -{message: user created""}.
   * @example
   *
   * async function handleRegister() {
   *  try {
   *    await Authentication.register({
   *      username: "johndoe",
   *      firstname: "John",
   *      lastname: "Doe",
   *      password: "6579e96f76baa00787a28653876c6127",
   *      email: "johndoe@yourdomain.com",
   *      source: "web",
   *      profile: "fd987bakjsh",
   *      phone: "+1 (123) 456-7890"
   *   })
   *  } catch {
   *   handleError()
   *  }
   * }
   */
  register = async ({
    username,
    firstname,
    lastname,
    password,
    email,
    source,
    profile,
    phone,
  }) =>
    await this.registerRequest({
      username,
      firstname,
      lastname,
      password,
      email,
      source,
      profile,
      phone,
    });

  /**
   * Method to login user.
   * This method is responsible for calling the loginRequest callback, get the user token, and store it in storage.
   *
   * @access public
   * @param {string} email - User email.
   * @param {string} password - User password.
   * @example
   *
   * async function handleLogin(email, password) {
   *  try {
   *    await Authentication.login('test@gmail.com', '123456')
   *  } catch {
   *   handleError()
   *  }
   * }
   * // After running successfully, the storage should have a new entry with a key 'user' and value being a OAuthenticationToken
   */
  login = async (email, password) => {
    const authInfo = await this.loginRequest({ email, password });
    await this.storage.set('user', authInfo);
  };

  /**
   * Gets the token from storage, checks if it is expired.
   * If it is expire, it attempts to get a new token by calling the refreshToken method.
   *
   * @access public
   * @returns {{token: string, isExpired: boolean}|null} - Returns:
   * <br>&nbsp;&nbsp;  If the token is not expired, or if the attempt to get a new one succeeds, it returns the new token and indicates it's not expired
   * <br>&nbsp;&nbsp;  If it fails to get a new access token from storage, it returns the old access token and indicates that it is expired.
   * <br>&nbsp;&nbsp;  If there are no tokens currently stored, that means there are no logged users, so it returns null.
   * @example
   * // Refresh token exists and access token is not expired
   * const token = await Authentication.getToken()
   * // token == { token: 'new_token', isExpired: false }
   * @example
   * // Refresh token exists, access token is expired, but successfully refreshes
   * const token = await Authentication.getToken()
   * // token == { token: 'new_token', isExpired: false }
   * @example
   * // Refresh token exists, access token is expired, and fails to refresh
   * const token = await Authentication.getToken()
   * // token == { token: 'old_token', isExpired: true }
   * @example
   * // Refresh token doesn't exist
   * const token = await Authentication.getToken()
   * // token == null
   */
  getToken = async (isBackEndTokenExpired = false) => {
    const authInfo = await this.storage.get('user');

    if (!authInfo) return null;
    const accessToken = authInfo?.access?.token;
    const expiresIn = authInfo?.access?.expires * 1000;

    if (
      !isBackEndTokenExpired &&
      !this._isTokenExpired(accessToken, expiresIn)
    ) {
      return { token: accessToken, isExpired: false };
    }
    try {
      const refreshedToken = await this.refreshToken();
      return {
        token: refreshedToken?.access?.token,
        isExpired: this._isTokenExpired(
          refreshedToken?.access?.token,
          refreshedToken?.access?.expires * 1000
        ),
      };
    } catch (err) {
      await this.logout(EXPIRED_TOKEN);
      return { token: accessToken, isExpired: true };
    }
  };

  _hasRolePermissions = async (roles) => {
    const rolesAllowed = JSON.parse(this.rolesAllowed);

    if (!Object.keys(rolesAllowed).length) return true;

    const allowed = Object.entries(rolesAllowed).reduce(
      (acc, [key, value]) => acc || value.includes(roles[key]),
      false
    );

    return allowed;
  };

  /**
   * Gets the currently logged in user profile.
   *
   * @access public
   * @returns {UserProfile} The user profile.
   * @throws {{message: "Insufficient permissions"}} If the user doesn't have sufficient permission to access the portal.
   * @throws {{message: "Unable to get profile"}} If something went wrong with the getProfile request.
   * @example
   *
   * async function handleGetProfile() {
   *  const profile = await Authentication.getProfile()
   *  return profile
   * }
   *
   * // profile ==  {id: "123", username: "johndoe", firstname: "john", lastname: "doe", email: "john@doe.com", roles: {DeliveryOperationsPortal: "Admin,Manager"}
   *
   */
  getProfile = async () => {
    try {
      const profile = await this.getProfileRequest();
      const hasPermissions = await this._hasRolePermissions(profile.roles);
      if (!hasPermissions) {
        throw new Error('Insufficient permissions');
      }
      return profile;
    } catch (err) {
      if (err?.message === 'Insufficient permissions') throw err;
      throw Error('Unable to get profile');
    }
  };

  _isTokenExpired = (token, expiresIn) =>
    !token || differenceInSeconds(new Date(expiresIn), new Date()) <= 0;

  /**
   * Manually request for a token refresh. The getToken() method already calls this if necessary, so you shouldn't need to use this directly.
   *
   * @access public
   * @returns {TokenObject} The new access token with the new expiration date.
   * @throws {{message: "Unable to refresh token"}} If it's unable to refresh the current token for any reason.
   * @example
   * async function handleRefreshToken() {
   *  const newToken = await Authentication.refreshToken() // sets in storage and returns
   *  return newToken
   * }
   */
  refreshToken = async () => {
    const authInfo = await this.storage.get('user');

    if (authInfo?.refresh) {
      try {
        const refreshToken = await this.refreshTokenRequest(
          authInfo?.refresh?.token
        );
        if (!refreshToken) throw new Error();
        const newToken = {
          ...authInfo,
          access: refreshToken,
        };
        await this.storage.set('user', newToken);
        return newToken;
      } catch (err) {
        throw Error('Unable to refresh token');
      }
    } else throw Error('Unable to refresh token');
  };

  /**
   * Signs out the user, and removes the token from storage.
   *
   * @access public
   * @param {import('../Constants').AuthLogoutReason} reason - AuthLogoutReason for the logout action.
   * @param {boolean} shouldRedirect - Boolean that states if the navigateToLogin() callback should be dispatched.
   * @returns {{message: string, options: {variant: string}}|null} An object with the relevant logout reason that can be used to display information to the suer.
   * @example
   * async function handleLogout() {
   *  await Authentication.logout()
   * }
   */
  logout = async (reason, shouldRedirect = true) => {
    try {
      const authInfo = await this.storage.get('user');
      if (authInfo?.refresh) {
        await this.deauthorizeRequest(authInfo?.refresh?.token);
      }

      await this.storage.remove('user');
      if (shouldRedirect) this.navigateToLogin();
      return this.logoutCallback(reason);
    } catch {
      console.error('Error deauthorizing account');
      await this.storage.remove('user');
      if (shouldRedirect) this.navigateToLogin();
      return this.logoutCallback(reason);
    }
  };

  logoutCallback = async (reason) => {
    switch (reason) {
      case EXPIRED_TOKEN:
        return {
          message: 'Session Expired',
          options: {
            key: EXPIRED_TOKEN,
            variant: 'warning',
          },
        };
      case FORCED_LOGOUT:
        return {
          message: 'Session invalidated',
          options: {
            key: FORCED_LOGOUT,
            variant: 'error',
          },
        };
      case USER_LOGOUT:
        return {
          message: 'Success logout',
          options: {
            key: USER_LOGOUT,
            variant: 'success',
          },
        };

      default:
        return null;
    }
  };
}

/**
 * @typedef {{token: string, expire: number}} TokenObject
 * @access public
 */

/**
 * @typedef {{refresh: TokenObject, access: TokenObject}} OAuthenticationToken
 * @access public
 */

/**
 * @typedef {{
 * id: string,
 * username: string,
 * firstname: string,
 * lastname: string,
 * email: string,
 * roles: Object.<string, string>
 * }} UserProfile
 *
 * @access public
 */

/**
 * @callback OAuthentication~loginRequest
 * @access public
 * @param {{email: string, password: string}} param
 * @returns {OAuthenticationToken} Authentication token.
 */

/**
 * @callback OAuthentication~getProfileRequest
 * @access public
 * @returns {UserProfile} User profile.
 */

/**
 * @callback OAuthentication~refreshTokenRequest
 * @access public
 * @param {string} refreshToken - The refresh token of the user.
 * @returns {{
   token: string,
   expire: number
  }} The new refresh access token with new expiration date.
 */

/**
 * @callback OAuthentication~deauthorizeRequest
 * @access public
 * @param {string} refreshToken - The refresh token of the user.
 */

/**
 * Callback to navigate back to login screen after logout.
 *
 * @callback OAuthentication~navigateToLogin
 * @access public
 */
