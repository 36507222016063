import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  container: {
    border: '1px solid #DADCE0',
    display: 'grid',
    gridTemplateColumns: '2fr 2fr 4fr 1fr',
    width: '100%',
    columnGap: '5px',
    rowGap: '10px',
    padding: '13px',
    marginBottom: '10px',
  },
  title: {
    color: '#282B31',
    fontWeight: 'bold',
    fontSize: '12px',
    opacity: 0.4,
  },
  primaryValue: {
    color: '#282B31',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  secondaryValue: {
    color: '#282B31',
    fontSize: '14px',
  },
  lastValue: {
    gridColumn: '1 / span 8',
  },
}));
