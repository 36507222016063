import {
  subDays,
  startOfYear as soy,
  startOfMonth as som,
  startOfWeek as sow,
  startOfDay as sod,
  endOfYear as eoy,
  endOfMonth as eom,
  endOfWeek as eow,
  endOfDay as eod,
  subMonths,
  subYears,
  isBefore,
  isAfter,
  isValid,
} from 'date-fns';

export const adjustTimezone = (date) => {
  const localTimezoneOffset = window.localTimezoneOffset || 0;
  const receivedTimezoneOffset = date.getTimezoneOffset() * 60 * 1000 * -1;
  const businessTimezoneOffset = window.businessTimezoneOffset || 0;

  if (localTimezoneOffset !== receivedTimezoneOffset) {
    console.warn(
      `Timezones are different. Local is ${localTimezoneOffset}, received: ${receivedTimezoneOffset}`
    );
  }

  const originalTime = date.getTime();

  const result = new Date(0);
  result.setUTCSeconds(
    (originalTime + receivedTimezoneOffset - businessTimezoneOffset) / 1000
  );

  return result;
};

export const newDate = (...options) => {
  const date = new Date(...options);
  const result = adjustTimezone(date);

  return result;
};

export const startOfYear = (input) => {
  const date = soy(input);
  return adjustTimezone(date);
};

export const startOfMonth = (input) => {
  const date = som(input);
  return adjustTimezone(date);
};

export const startOfWeek = (input, op = { weekStartsOn: 0 }) => {
  const date = sow(input, op);
  return adjustTimezone(date);
};

export const startOfDay = (input) => {
  const date = sod(input);
  return adjustTimezone(date);
};

export const endOfYear = (input) => {
  const date = eoy(input);
  return adjustTimezone(date);
};

export const endOfMonth = (input) => {
  const date = eom(input);
  return adjustTimezone(date);
};

export const endOfWeek = (input, op = { weekStartsOn: 0 }) => {
  const date = eow(input, op);
  return adjustTimezone(date);
};

export const endOfDay = (input) => {
  const date = eod(input);
  return adjustTimezone(date);
};

export const lastMonth = (...options) => {
  const start = startOfMonth(subMonths(newDate(...options), 1));
  const end = endOfMonth(subMonths(newDate(...options), 1));

  return { start, end, interval: 'day' };
};

export const lastYear = (...options) => {
  const start = startOfYear(subYears(newDate(...options), 1));
  const end = endOfYear(subYears(newDate(...options), 1));

  return { start, end, interval: 'month' };
};

export const yearToDate = (...options) => {
  const start = startOfYear(newDate(...options));
  const end = endOfDay(newDate(...options));

  return { start, end, interval: 'month' };
};

export const last12Months = (...options) => {
  const end = subDays(endOfDay(newDate(...options)), 1);
  const start = subMonths(end, 12);

  return { start, end, interval: 'month' };
};

export const today = (...options) => {
  const start = startOfDay(newDate(...options));
  const end = endOfDay(newDate(...options));

  return { start, end, interval: 'day' };
};

export const yesterday = (...options) => {
  const start = subDays(startOfDay(newDate(...options)), 1);
  const end = subDays(endOfDay(newDate(...options)), 1);

  return { start, end, interval: 'day' };
};

export const thisWeek = (...options) => {
  const start = startOfWeek(newDate(...options), { weekStartsOn: 1 });
  const end = endOfWeek(newDate(...options), { weekStartsOn: 1 });

  return { start, end, interval: 'day' };
};

export const isBetween = (from, to, date = newDate()) => {
  if (!isValid(from) || !isValid(to)) return false;
  return isBefore(from, date) && isAfter(to, date);
};
