import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import BusinessHours from '../../../../input/BusinessHours/BusinessHours';
import Button from '../../../../input/Button';
import { useStyles } from './ManageHours.styles';
import { Loading } from '../../../../layout/Loading';
import {
  editManageHoursETL,
  editManageMediaETL,
} from '../../../../../utils/ExtractTransformLoad/EditRestaurantETL';
import { updateSettings } from '../../../../../redux/ducks/RestaurantDucks';
import formatPhoneNumber from '../../../../../utils/FormatUtils/formatPhoneNumber';
import TempClosingHours from '../../../../input/TempClosingHours/TempClosingHours';

export function ManageHours() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const restaurantSettings = useSelector((state) => state.restaurant.settings);
  const rawSettings = useSelector((state) => state.restaurant.rawSettings);
  const isLoading = useSelector((state) => state.restaurant.isLoading);
  const [data, setData] = useState({});
  const [deliverySame, setDeliverySame] = useState(false);
  const [takeoutSame, setTakeoutSame] = useState(false);

  useEffect(() => {
    if (restaurantSettings) {
      setData({
        business: { ...restaurantSettings.business },
        delivery: { ...restaurantSettings.delivery },
        takeout: { ...restaurantSettings.takeout },
      });
      setTakeoutSame(!!restaurantSettings.takeout.same);
      setDeliverySame(!!restaurantSettings.delivery.same);
    }
  }, [restaurantSettings]);

  const handleCancel = useCallback(() => {
    if (restaurantSettings) {
      setData({
        business: { ...restaurantSettings.business },
        delivery: { ...restaurantSettings.delivery },
        takeout: { ...restaurantSettings.takeout },
      });
      setTakeoutSame(!!restaurantSettings.takeout.same);
      setDeliverySame(!!restaurantSettings.delivery.same);
    }
  }, [restaurantSettings]);

  const handleSave = useCallback(async () => {
    const {
      id,
      config,
      name,
      description,
      address,
      category,
      tags,
      schedule,
      text,
      phone,
      media,
    } = rawSettings;

    const newData = {
      name,
      description,
      address,
      category,
      tags,
      schedule,
      text,
      media,
      ...editManageMediaETL(media),
      ...editManageHoursETL(data),
      phone: formatPhoneNumber(phone),
      config: {
        costs_food_ratio: config.costs_food_ratio,
        current_preparation_time: config.current_preparation_time,
      },
    };

    if (id)
      dispatch(
        updateSettings({
          id,
          settings: newData,
        })
      );
  }, [data, dispatch, rawSettings]);

  const [tempHoursCollapsed, setTempHoursCollapsed] = useState(false);
  const [regularHoursCollapsed, setRegularHoursCollapsed] = useState(true);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <Box className={classes.header}>
        <Typography variant="h1" className={classes.pageTitle}>
          Manage Hours
        </Typography>
      </Box>
      <Box className={classes.hoursSection}>
        <Box className={classes.hoursSectionHeader}>
          <Box className={classes.hoursSectionTitle}>
            <Typography variant="h3">Temporary Closing Hours</Typography>
          </Box>
          <Box
            data-test-id="WkP0S-x6FTF3sz49xBqv4"
            className={classes.hoursSectionAction}
            onClick={() => setTempHoursCollapsed(!tempHoursCollapsed)}
          >
            <Typography variant="h5">
              {!tempHoursCollapsed && (
                <VisibilityOff size={22} style={{ color: '#0058FF' }} />
              )}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.hoursSectionBody}>
          <TempClosingHours
            collapsed={tempHoursCollapsed}
            onShow={() => setTempHoursCollapsed(!tempHoursCollapsed)}
          />
        </Box>
      </Box>
      <Box className={classes.hoursSection}>
        <Box className={classes.hoursSectionHeader}>
          <Box className={classes.hoursSectionTitle}>
            <Typography variant="h3">Regular Hours</Typography>
          </Box>
          <Box
            data-test-id="FOstngKHrW2Ks7l_seluD"
            className={classes.hoursSectionAction}
            onClick={() => setRegularHoursCollapsed(!regularHoursCollapsed)}
          >
            <Typography variant="h5">
              {!regularHoursCollapsed && (
                <VisibilityOff size={22} style={{ color: '#0058FF' }} />
              )}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.hoursSectionBody}>
          <BusinessHours
            data={data}
            onChange={setData}
            deliveryState={[deliverySame, setDeliverySame]}
            takeoutState={[takeoutSame, setTakeoutSame]}
            collapsed={regularHoursCollapsed}
            onShow={() => setRegularHoursCollapsed(!regularHoursCollapsed)}
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ManageHours;
