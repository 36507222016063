import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  dropzoneFaviconThumb: {
    height: 32,
    width: 32,
    display: 'inline-flex',
    borderRadius: '2px',
    border: '1px solid #e0e0e0',
    boxSizing: 'border-box',
    background: '#FDFDFD',
  },
  deleteFavicon: {
    display: 'flex',
    padding: '9px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0px 4px 4px 0px',
    background: 'rgba(255, 49, 0, 0.08)',

    '&:hover': {
      background: 'rgba(255, 49, 0, 0.18)',
    },
  },
  dropzoneImg: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '4px',
  },
  faviconLogo: {
    flex: '1',
    display: 'flex',
    width: '256px',
    maxHeight: '32px',
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      width: 'unset',
    },
  },
  faviconDropzone: {
    display: 'flex',
    maxWidth: '256px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
    },
  },

  faviconImg: {
    objectFit: 'contain',
  },

  dropzone: {
    display: 'flex',
    border: '1px dashed #E0E0E0',
    borderLeft: 'none',
    borderRadius: '0 4px 4px 0',
    flex: 1,
    background: '#FDFDFD',
    alignItems: 'center',
    paddingLeft: 16,
  },
  dropzoneIcon: {
    display: 'flex',
    border: '1px solid #E0E0E0',
    borderRadius: '4px 0 0 4px',
    height: 32,
    width: 32,
    background: '#F4F4F4',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
  },
  dropzoneDisabled: {
    border: '1px solid #b4b4b4',
    background: '#F2F2F2',

    '&:last-child': {
      borderLeft: 'none',
    },
  },
}));
