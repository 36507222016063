import Environment from '@eatzy/common-reactjs/Environment';

export default class API {
  buildConfig = async ({ endpoint, body, params, method, customConfig }) => {
    const baseUrl = Environment.getEnv('API_URL');

    const url = new URL(baseUrl + endpoint);
    Object.keys(params || {}).forEach((key) => {
      url.searchParams.append(key, params?.[key]);
    });

    const headers = customConfig?.isMultiPart
      ? {}
      : { 'content-type': 'application/json' };

    const config = {
      method,
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig?.headers,
      },
    };

    if (customConfig?.isMultiPart && body) {
      config.body = body;
    } else if (body) {
      config.body = JSON.stringify(body);
    }

    return [url, config];
  };

  improvedFetch = async (
    endpoint,
    { body, params, method, ...customConfig },
    attemptsLeft
  ) => {
    const [url, config] = await this.buildConfig({
      endpoint,
      body,
      params,
      method,
      customConfig,
    });

    return window.fetch(url, config).catch(async (err) => {
      if (attemptsLeft > 0 && err.status === 403) {
        return await this.improvedFetch(
          endpoint,
          { body, params, method, ...customConfig },
          attemptsLeft - 1
        );
      }
      return Promise.reject(err);
    });
  };

  get = async (endpoint, params, options = {}) =>
    this.improvedFetch(endpoint, { method: 'GET', params, ...options }, 1);

  delete = async (endpoint, body, params, options = {}) =>
    this.improvedFetch(
      endpoint,
      { method: 'DELETE', body, params, ...options },
      1
    );

  post = async (endpoint, body, options = {}) =>
    this.improvedFetch(endpoint, { method: 'POST', body, ...options }, 1);

  put = async (endpoint, body, options = {}) =>
    this.improvedFetch(endpoint, { method: 'PUT', body, ...options }, 1);

  upload = async (endpoint, body, options = {}) =>
    this.improvedFetch(
      endpoint,
      {
        method: 'POST',
        body,
        isMultiPart: true,
        ...options,
      },
      1
    );
}

export const api = new API();
