import { formatRelative } from 'date-fns';
import { enUS } from 'date-fns/esm/locale';
import { utcToZonedTime } from 'date-fns-tz';

const formatRelativeLocale = {
  lastWeek: "dd MMM y', at 'hh:mmaaaaa'm'",
  yesterday: "'Yesterday, at' hh:mmaaaaa'm'",
  today: "'Today, at' hh:mmaaaaa'm'",
  tomorrow: "dd MMM y', at 'hh:mmaaaaa'm'",
  nextWeek: "dd MMM y', at 'hh:mmaaaaa'm'",
  other: "dd MMM y', at 'hh:mmaaaaa'm'",
};

const locale = {
  ...enUS,
  formatRelative: (options) => formatRelativeLocale[options],
};

const formatRelativeDate = (date, timezone) =>
  formatRelative(
    utcToZonedTime(date, timezone),
    utcToZonedTime(new Date(), timezone),
    {
      locale,
    }
  );

export default formatRelativeDate;
