// Dependencies
import React from 'react';
import 'leaflet/dist/leaflet.js';
import 'leaflet/dist/leaflet.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import Environment from '@eatzy/common-reactjs/Environment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license-pro';
import registerInterceptors from '../../../services/interceptors/AccountServiceInterceptors';
import Wrapper from '../Wrapper';
import { Root } from '../Root';
import { defaultStore } from '../../../redux/stores/GlobalStore';

LicenseInfo.setLicenseKey(
  process.env.REACT_APP_MUI_LICENSE_KEY ||
    '61628ce74db2c1b62783a6d438593bc5Tz1NVUktRG9jLEU9MTY4MzQ0NzgyMTI4NCxTPXByZW1pdW0sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

Environment.parseInitialEnv();

registerInterceptors(defaultStore);

function App() {
  return (
    <Wrapper>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Root />
        </LocalizationProvider>
      </QueryClientProvider>
    </Wrapper>
  );
}

export default App;
