import React, { useState, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  Typography,
  ListItem,
  ListItemSecondaryAction,
} from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import DragAndDropItemsList from '../DragAndDropItemsList/DragAndDropItemsList';
import { useStyles } from './MenuItems.styles';
import { useItemContext } from '../../../../context/itemsContext/itemsContext';

export function MenuItems({ item, index, startOpen }) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(startOpen);
  const {
    setSelectedItem,
    selectedItem,
    formattedList,
    expandAll,
    setExpandAll,
  } = useItemContext();

  useEffect(() => {
    if (expandAll) setIsOpen(true);
  }, [expandAll]);

  const handleOpenItem = (item) => {
    setSelectedItem(item);
  };

  return (
    <Draggable draggableId={String(item.id)} key={item.id} index={index}>
      {(provided) => (
        <div ref={provided.innerRef}>
          <ListItem
            key={item.id}
            role={undefined}
            className={classes.listButton}
            dense
            button
            ContainerComponent="li"
            ContainerProps={{ ref: provided.innerRef }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Box style={{ flex: 1 }}>
              <Box
                data-test-id="_yCTpcRH_XyBnTd6l4CSG"
                className={classes.listHeader}
                onClick={() => {
                  setIsOpen(!isOpen);
                  setExpandAll(false);
                }}
                data-testid="expand-button-items"
              >
                {isOpen ? (
                  <ExpandMore
                    style={{
                      fill: '#171725',
                      opacity: '0.3',
                      fontSize: '14px',
                    }}
                  />
                ) : (
                  <ChevronRight
                    style={{
                      fill: '#171725',
                      opacity: '0.3',
                      fontSize: '14px',
                    }}
                  />
                )}
                <Typography className={classes.listTitle}>
                  {item.name}
                </Typography>
              </Box>
              <Collapse in={isOpen} style={{ paddingLeft: '30px' }}>
                <DragAndDropItemsList
                  items={item.items}
                  id={String(item.id)}
                  handleOpenItem={handleOpenItem}
                  selectedItem={selectedItem}
                  key={JSON.stringify(formattedList)}
                />
              </Collapse>
            </Box>
            <ListItemSecondaryAction />
          </ListItem>
          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );
}

MenuItems.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  startOpen: PropTypes.bool,
};
