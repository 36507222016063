import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useStyles } from './InfoDisplay.styles';

export function InfoDisplay({
  info,
  size = 1,
  sideComponent: SideComponent,
  secondary = false,
}) {
  const classes = useStyles();

  if (SideComponent)
    return (
      <Box className={classes.infoContainer} style={{ flex: size }}>
        <Box display="flex">
          <Box className={classes.titleWrapper}>
            <Typography className={classes.infoTitle}>{info.title}</Typography>
            <Typography className={classes.infoText}>{info.info}</Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <SideComponent titleStyle={classes.infoTitle} />
        </Box>
      </Box>
    );
  return (
    <Box className={classes.infoContainer} style={{ flex: size }}>
      <Typography className={classes.infoTitle}>{info.title}</Typography>
      {info.percentage ? (
        <Box display="flex" alignItems="baseline">
          <Typography className={classes.secondaryInfoText}>
            {info.info}
          </Typography>
          <Typography className={classes.percentage}>
            {info.percentage}%
          </Typography>
        </Box>
      ) : (
        <Typography
          className={secondary ? classes.secondaryInfoText : classes.infoText}
        >
          {info.info}
        </Typography>
      )}
    </Box>
  );
}

InfoDisplay.propTypes = {
  info: PropTypes.shape({
    title: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    percentage: PropTypes.string,
  }).isRequired,
  size: PropTypes.number,
  sideComponent: PropTypes.func,
  secondary: PropTypes.bool,
};

export default InfoDisplay;
