import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export function NoRestaurants({ className }) {
  return (
    <Box className={className}>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        margin="20px"
      >
        <Typography
          style={{
            fontSize: '24px',
            fontWeight: '600',
          }}
        >
          Please, register your restaurant.
        </Typography>
      </Box>
    </Box>
  );
}

NoRestaurants.propTypes = {
  className: PropTypes.string,
};
