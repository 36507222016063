import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { enqueueSnackbar } from '../../../../../redux/ducks/SnackbarDucks';
import { Loading } from '../../../../layout/Loading';
import { useMenuContext } from '../../../../../context/menuContext';
import { useStyles } from './EditAppUser.styles';
import Authentication from '../../../../input/Authentication/Authentication';

import { registerAppUser } from '../../../../../services/SettingsService';
import {
  postLogin,
  registerAccount,
} from '../../../../../services/AccountService';

export function EditAppUser() {
  const { selectedRestaurant } = useMenuContext();
  const classes = useStyles();
  const [userAlreadySet, setUserAlreadySet] = useState();
  const [changeUser, setChangeUser] = useState(false);
  const rawSettings = useSelector((state) => state.restaurant.rawSettings);
  const isLoading = useSelector((state) => state.restaurant.isLoading);

  useEffect(() => setUserAlreadySet(rawSettings?.appuser), [rawSettings]);

  const handleLogin = useCallback(
    async (data) => {
      if (data && selectedRestaurant?.id) {
        try {
          const { loginEmail, loginPassword } = data;

          const loginResp = await postLogin({
            email: loginEmail,
            password: loginPassword,
          });

          await registerAppUser({
            token: loginResp.access,
            id: selectedRestaurant.id,
          });

          setChangeUser(false);
        } catch (err) {
          enqueueSnackbar({
            message: `Error: ${err.message}`,
            options: {
              variant: 'error',
            },
          });
        }
      }
    },
    [selectedRestaurant]
  );

  const handleCreateUser = useCallback(
    async (data) => {
      if (data && selectedRestaurant?.id) {
        try {
          const {
            registerFirstName,
            registerLastName,
            registerPhoneNumber,
            registerEmail,
            registerPassword,
          } = data;

          await registerAccount({
            firstname: registerFirstName,
            lastname: registerLastName,
            phone: registerPhoneNumber,
            username: registerEmail,
            email: registerEmail,
            password: registerPassword,
            source: 'web',
          });

          const loginResp = await postLogin({
            email: registerEmail,
            password: registerPassword,
          });

          await registerAppUser({
            token: loginResp.access,
            id: selectedRestaurant.id,
          });

          setChangeUser(false);
        } catch (err) {
          enqueueSnackbar({
            message: `Error: ${err.message}`,
            options: {
              variant: 'error',
            },
          });
        }
      }
    },
    [selectedRestaurant]
  );

  const handleSubmit = useCallback(
    (tab, data) => (!tab ? handleLogin(data) : handleCreateUser(data)),
    [handleLogin, handleCreateUser]
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box className={classes.root}>
      <Authentication
        onSubmit={handleSubmit}
        title="Restaurant App Setup"
        isAuthenticated={userAlreadySet && !changeUser}
        onLogout={() => setChangeUser(true)}
      />
    </Box>
  );
}

export default EditAppUser;
