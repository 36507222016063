export const formatPhoneNumber = (phone) => {
  let phoneNumber = phone.replace(/\D/g, '');
  if (phoneNumber.length > 10) {
    phoneNumber = phoneNumber.substring(1);
  }
  return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
    3,
    6
  )}-${phoneNumber.substring(6, 10)}`;
};

export default formatPhoneNumber;
