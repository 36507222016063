import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Card, CardContent } from '@mui/material';
import { resetPasswordRequest } from '../../../redux/ducks/UserDucks';

import { ResetPasswordForm } from '../../input/ResetPasswordForm/ResetPasswordForm';
import { Footer } from '../../layout/Footer';
import { useStyles } from './ResetPasswordPage.styles';
import useIsDesktop from '../../../hooks/useIsDesktop/useIsDesktop';

export function ResetPasswordPage() {
  const isDesktop = useIsDesktop();
  const classes = useStyles({ isDesktop });
  const dispatch = useDispatch();
  const location = useLocation();
  const code = queryString.parse(location?.search);

  const isLoading = useSelector((state) => state.user.isLoading);

  const onSubmit = (data) => {
    dispatch(resetPasswordRequest({ ...data, ...code }));
  };

  return (
    <>
      <Container
        component="main"
        maxWidth="xs"
        className={classes.rootcontainer}
      >
        <Card className={classes.card} variant="outlined">
          <CardContent className={classes.cardContent}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="stretch"
            >
              <Grid item xs={12}>
                <Grid container>
                  <Grid item lg>
                    <ResetPasswordForm
                      onSubmit={onSubmit}
                      isLoading={isLoading}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <Footer />
    </>
  );
}
