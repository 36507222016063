import makeStyles from '@mui/styles/makeStyles';
import { theme as defaultTheme } from '../../Theme';

export const useStyles = makeStyles((theme) => ({
  info: {
    border: '1px solid #DADCE0',
    borderRadius: '12px',
    boxShadow: '0px 5px 12px #0000000F',
    padding: '32px 24px',
    marginBottom: '32px',
  },
  fieldTitle: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#282B31',
  },
  itemName: {
    display: 'flex',
    width: '100%',
    color: '#282B31',
    border: 'none',

    '& label': {
      fontWeight: '500',
    },

    '& input': {
      fontSize: '24px',
      fontWeight: 'bold',
    },

    '& fieldset': {
      border: 'none',
    },
  },
  name: {
    display: 'flex',
    flexFlow: 'column',
    marginLeft: '-15px',
    marginBottom: '20px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#00000008',
      cursor: 'pointer',
    },
  },
  itemDescription: {
    display: 'flex',
    width: '100%',
    color: '#282B31',
    border: 'none',

    '& label': {
      fontWeight: '500',
    },

    '& textarea': {
      fontSize: '14px',
      fontWeight: '500',
    },

    '& fieldset': {
      border: 'none',
    },
  },
  itemBaseline: {
    marginRight: '50px',
    display: 'flex',
    width: '130px',
    color: '#282B31',
    border: 'none',

    '& label': {
      fontWeight: '500',
    },

    '& input': {
      fontSize: '24px',
      fontWeight: 'bold',
    },

    '& fieldset': {
      border: 'none',
    },
  },
  sideText: {
    color: '#171725',
    opacity: '0.4',
    fontSize: '12px',
    marginRight: '8px',

    '&:not(:first-child)': {
      marginLeft: '8px',
    },
  },
  questionsHeader: {
    fontSize: '20px',
    color: '#3E434D',
    fontWeight: '600',
    marginLeft: '12px',
    marginBottom: '24px',
  },
  question: {
    background: '#fff',
    border: '1px solid #D5D5D5',
    borderRadius: '8px',
    padding: '0px 14px 20px 14px',
    boxShadow: '0px 3px 6px #0000000F',
  },
  questionTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '20px',
  },
  questionTitle: {
    fontSize: '14px',
    color: '#282B31',
    fontWeight: '700',
  },
  expandIcons: {
    fontSize: '16px',
    fill: 'rgba(40, 43, 49, 0.8)',
  },
  expandButton: {
    background: 'rgba(40, 43, 49, 0.05)',
    padding: '4px',
    minWidth: 'unset',
  },
  answerWrapper: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  answerPrice: {
    color: '#FF3100',
    fontWeight: '500',
    marginRight: '8px',
  },
  answerText: {
    color: '#282B31',
    fontSize: '14px',
  },
  button: {
    fontSize: '14px',
    padding: '3px 8px',
    minWidth: '74px',
  },
  icon: {
    fontSize: '14px',
    marginRight: '6px',
  },
  editButton: {
    background: '#fff',
    color: '#0058FF',
    border: '1px solid #0000001F',
    marginRight: '14px',
  },
  keepButton: {
    background: '#fff',
    color: '#0058FF',
    border: '1px solid #0000001F',
  },
  editIcon: {
    fill: '#0058ff',
  },
  deleteButton: {
    background: '#FF31001F',
    color: '#FF3100',
    border: '1px solid #FF31001F',

    '&:hover': {
      background: '#FF31002F',
      border: '1px solid #FF31002F',
    },
  },
  restoreButton: {
    background: '#0058FF1F',
    color: '#0058FF',
    border: '1px solid #0058FF1F',

    '&:hover': {
      background: '#0058FF2F',
      border: '1px solid #0058FF2F',
    },
  },
  deleteIcon: {
    fill: '#FF3100',
  },
  unavailable: {
    color: '#FF3100',
    textDecoration: 'line-through',
  },
  unavailableItem: {
    position: 'relative',
    top: '10px',
    left: '24px',
    color: '#ff3100',
  },
  questionWrapper: {
    marginBottom: '14px',

    [theme.breakpoints.down(960)]: {
      '&:last-child': {
        paddingBottom: '15px',
        marginBottom: '70px',
      },
    },
  },
  addNewAnswer: {
    justifyContent: 'center',
    alignItems: 'center',
    background: '#0058FF1F',
    border: '1px solid #0058FF1F',
    marginTop: '8px',
    width: '100%',
  },
  addNewAnswerIcon: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    fontFamily: defaultTheme.typography.fontFamily,
    letterSpacing: '0px',
    color: '#0058FF',
    margin: '0 5px 0 0',
  },
  addNewAnswerText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: defaultTheme.typography.fontFamily,
    letterSpacing: '0px',
    color: '#0058FF',
  },
  listItem: {
    '& div': {
      width: '100%',
    },
  },
}));
