import React, { useCallback, useState } from 'react';
import {
  Tabs,
  Tab,
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import {
  AddPhotoAlternate,
  AddRounded,
  Collections,
  DeleteOutline,
  Publish,
} from '@mui/icons-material';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import GalleryModal from '../../layout/GalleryModal/GalleryModal';
import { useStyles } from './Slider.styles';
import { DropZoneImage } from '../../layout/GalleryModal/DropZone/DropZoneImage';
import { Gallery } from '../../layout/GalleryModal/Gallery/Gallery';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
};

export function SliderPreview({ file, disabled }) {
  const classes = useStyles();
  const [fileLoaded, setFileLoaded] = useState(false);

  if (file && file.id !== '') {
    return (
      <div className={classes.dropzoneSliderThumb}>
        {!fileLoaded && (
          <CircularProgress style={{ margin: 'auto' }} data-testid="progress" />
        )}
        <img
          data-testid={file?.name}
          alt="Slider preview"
          src={file?.url}
          onLoad={() => setFileLoaded(true)}
          className={clsx(classes.dropzoneImg, {
            [classes.showImage]: !fileLoaded,
          })}
        />
      </div>
    );
  }
  return (
    <section
      className={clsx(classes.dropzone, {
        [classes.dropzoneDisabled]: disabled,
      })}
    >
      <AddPhotoAlternate
        style={{
          fontSize: 32,
          fill: `${disabled ? 'rgba(0,0,0,0.3)' : '#333'}`,
          marginBottom: 8,
        }}
      />
      <p style={{ color: `${disabled ? 'rgba(0,0,0,0.3)' : '#333'}` }}>
        Upload Image
      </p>
    </section>
  );
}

SliderPreview.propTypes = {
  file: PropTypes.object,
  disabled: PropTypes.bool,
};

export function Slider({
  handleChange,
  slider,
  tab,
  setFiles,
  handleAddSlider,
  files,
  handleRemoveSlider,
  handleDrop,
  getErrors,
  disabled = false,
  form: { control },
}) {
  const classes = useStyles();
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const components = [
    {
      label: 'Upload File',
      component: DropZoneImage,
      icon: <Publish fontSize="small" style={{ marginRight: '8px' }} />,
    },
    {
      label: 'User Uploads',
      component: Gallery,
      icon: <Collections fontSize="small" style={{ marginRight: '8px' }} />,
    },
  ];

  const handleCloseGallery = () => {
    setIsGalleryOpen(false);
  };

  const handleOpenGallery = () => {
    !disabled && setIsGalleryOpen(true);
  };

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const handleSaveSliderImage = useCallback(
    (data, index) => {
      handleDrop(data, setFiles, index);
    },
    [handleDrop, setFiles]
  );

  const handleDeleteSliderImage = useCallback(
    (index, e) => {
      e.stopPropagation();
      const newFiles = [...files].filter((file, i) => i !== index);
      setFiles(newFiles);
      handleSaveSliderImage([], index);
    },
    [files, handleSaveSliderImage, setFiles]
  );

  return (
    <>
      <Box
        display="inline-flex"
        maxWidth="100%"
        justifyContent="space-between"
        padding="0 24px"
      >
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          style={{ width: '100%' }}
        >
          {slider.map((_, i) => (
            <Tab
              key={i}
              data-testid={`slider-${i}`}
              label={`Slider ${i + 1}`}
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
        {slider.length < 6 && (
          <Button
            data-test-id="bTMXdnkUbKfdnipv7wB4_"
            data-testid="add-slider-button"
            style={{
              marginLeft: 4,
              color: `${disabled ? 'rgba(0,0,0,0.3)' : '#3C55E8'}`,
              maxWidth: 40,
              minWidth: 40,
              background: 'rgba(60, 85, 232, 0.03)',
            }}
            onClick={handleAddSlider}
            disabled={disabled}
          >
            <AddRounded />
          </Button>
        )}
      </Box>
      {slider.map((_, i) => (
        <TabPanel key={i} value={tab} index={i}>
          <Box className={classes.sliderWrapper}>
            <Box className={classes.sliderInfoWrapper}>
              <Box display="flex" flexDirection="column">
                <Typography variant="h6" className={classes.formTextFieldLabel}>
                  Slider image
                </Typography>
                <Box
                  data-test-id="vGQv2girpMrifdmGQmjVa"
                  name={`slider-image-${i}`}
                  className={classes.sliderImage}
                  onClick={handleOpenGallery}
                >
                  <SliderPreview file={files[i]} disabled={disabled} />
                  {files[i]?.id && !disabled && (
                    <Box
                      data-test-id="MFLd-ey1aJarZlCAtQIDK"
                      display="block"
                      height="0"
                      alignSelf="flex-end"
                      onClick={(e) => handleDeleteSliderImage(i, e)}
                      style={{
                        cursor: 'pointer',
                        height: '0',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                      }}
                      data-testid="delete-logo-image"
                    >
                      <Box className={classes.deleteLogo}>
                        <DeleteOutline
                          style={{
                            fontSize: 14,
                            fill: '#FF3100',
                            filter: 'drop-shadow( 1px 1px 2px #fff)',
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
                {isGalleryOpen && (
                  <GalleryModal
                    open={isGalleryOpen}
                    handleClose={handleCloseGallery}
                    setFile={(data) => handleSaveSliderImage(data, i)}
                    file={[files[i]]}
                    components={components}
                  />
                )}
              </Box>
              <Box width="50%">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="h6"
                    className={classes.formTextFieldLabel}
                  >
                    Slide title
                  </Typography>
                </Box>
                <Controller
                  name={`slider[${i}].sliderTitle`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={disabled}
                      fullWidth
                      variant="outlined"
                      id="slideTitle"
                      placeholder="Slide Title"
                      className={classes.formInputBox}
                      {...getErrors(`slider[${i}].sliderTitle`)}
                    />
                  )}
                />
                <Typography variant="h6" className={classes.formTextFieldLabel}>
                  Slide subtitle
                </Typography>
                <Controller
                  name={`slider[${i}].sliderTitle`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={disabled}
                      fullWidth
                      variant="outlined"
                      id="slideSubtitle"
                      name={`slider[${i}].sliderSubtitle`}
                      placeholder="Slide subtitle"
                      className={classes.formInputBox}
                      {...getErrors(`slider[${i}].sliderSubtitle`)}
                    />
                  )}
                />
              </Box>
            </Box>
            {slider.length > 3 && !disabled && (
              <Button
                data-test-id="c8_ODHcEbYcbiv2l8AHZL"
                data-testid={`remove-slider-${i}`}
                onClick={() => handleRemoveSlider(i, slider.length)}
                className={clsx(classes.deleteSliderButton)}
                disabled={disabled}
              >
                <DeleteOutline fontSize="small" />
                <span>Delete this slider</span>
              </Button>
            )}
          </Box>
        </TabPanel>
      ))}
    </>
  );
}

Slider.propTypes = {
  handleChange: PropTypes.func,
  slider: PropTypes.array,
  tab: PropTypes.number,
  mimeTypes: PropTypes.object,
  setFiles: PropTypes.func,
  handleAddSlider: PropTypes.func,
  files: PropTypes.array,
  form: PropTypes.shape({ control: PropTypes.object }),
  handleRemoveSlider: PropTypes.func,
  getErrors: PropTypes.func,
  handleDrop: PropTypes.func,
  disabled: PropTypes.bool,
};
