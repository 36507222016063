import { darken } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '32px',
    padding: '8px 0',
    backgroundColor: '#fff',

    [theme.breakpoints.down('md')]: {
      marginBottom: '0px',
      position: 'fixed',
      bottom: '0px',
      zIndex: 1,
      height: 'unset',
      width: 'calc(100% - 30px)',
      paddingBottom: '32px',
    },
  },
  leftActions: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0',
    margin: '0',
  },
  rightActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0',
    margin: '0',
  },
  buttonDelete: {
    display: 'inline-flex',
    alignItems: 'center',
    background: '#FF31001F',
    border: '1px solid #fdcec3',
    height: '32px',
    borderRadius: '4px',
    padding: '6px 16px',
    marginRight: '24px',

    '&:hover': {
      background: '#FF31002A',
    },
  },
  iconDelete: {
    display: 'inline-flex',
    width: '14px',
    height: '14px',
    color: '#FF3100',
  },
  titleDelete: {
    display: 'inline-flex',
    justifyContent: 'center',
    minWidth: 'min-content',
    height: '20px',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0px',
    color: '#FF3100',
    marginLeft: '8px',

    [theme.breakpoints.down('md')]: {
      minWidth: 'max-content',
    },
  },
  buttonCancel: {
    display: 'inline-flex',
    height: '32px',
    background: '#FFFFFF',
    border: '1px solid #0000001F',
    borderRadius: '4px',
    marginRight: '24px',
    padding: '6px 16px',

    [theme.breakpoints.down('md')]: {
      marginRight: '6px',
    },
  },
  titleCancel: {
    display: 'inline-flex',
    minWidth: '64px',
    justifyContent: 'center',
    height: '20px',
    fontWeight: 'medium',
    fontSize: '14px',
    letterSpacing: '0px',
    color: '#0058FF',
  },
  buttonSave: {
    display: 'inline-flex',
    alignItems: 'center',
    height: '32px',
    background: '#0058FF',
    border: '1px solid #0000001F',
    borderRadius: '4px',
    padding: '6px 16px',

    '&:hover': {
      background: darken('#0058FF', 0.05),
    },
  },
  titleSave: {
    display: 'inline-flex',
    justifyContent: 'center',
    height: '20px',
    minWidth: '64px',
    fontWeight: 'medium',
    fontSize: '14px',
    letterSpacing: '0px',
    color: '#FFFFFF',
  },
  buttonAvailable: {
    display: 'inline-flex',
    alignItems: 'center',
    height: '32px',
    background: '#0058FF1F',
    border: '1px solid #0058FF20',
    borderRadius: '4px',
    padding: '6px 16px',
    marginRight: '24px',

    '&:hover': {
      background: darken('#0058FF1F', 0.05),
    },
  },
  iconAvailable: {
    display: 'inline-flex',
    width: '14px',
    height: '14px',
    color: '#0058FF',
    marginRight: '8px',
  },
  titleAvailable: {
    display: 'inline-flex',
    minWidth: '64px',
    justifyContent: 'center',
    height: '20px',
    fontWeight: 'medium',
    fontSize: '14px',
    letterSpacing: '0px',
    color: '#0058FF',
  },
}));
