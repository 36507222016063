import { useCallback } from 'react';
import { get, values } from 'lodash';

export const useGetErrors = ({ errors }) => {
  const getErrors = useCallback(
    (path) => ({
      error: !!get(errors, path),
      helperText: get(errors, path) && get(errors, path).message,
    }),
    [errors]
  );

  const hasAnyError = () =>
    values(errors).reduce((acc, cur) => acc || !!cur, false);

  return { getErrors, hasAnyError };
};
