import React from 'react';
import PropTypes from 'prop-types';
import { opacify } from 'polished';
import {
  addDays,
  addYears,
  differenceInMonths,
  format,
  addMonths,
} from 'date-fns';

export function CenteredMetric({ dataWithArc, centerX, centerY }) {
  let total = 0;
  let title = '';
  dataWithArc.forEach((datum) => {
    if (datum.value > total) {
      total = datum.value;
      title = datum.id;
    }
  });

  return (
    <>
      <text
        x={String(centerX)}
        y={String(centerY - 15)}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '48px',
          fontWeight: '500',
          color: opacify(-40, '#282B31'),
        }}
      >
        {total}%
      </text>
      <text
        x={String(centerX)}
        y={String(centerY + 25)}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '16px',
          fontWeight: '500',
          color: '#282B31',
        }}
      >
        {title}
      </text>
    </>
  );
}

CenteredMetric.propTypes = {
  dataWithArc: PropTypes.array,
  centerX: PropTypes.number,
  centerY: PropTypes.number,
};

export const formatScale = (value) =>
  `$ ${value >= 1000 ? `${value / 1000}k` : value}`;

export const addEmpty = ({ field, add, date }) => {
  const total = field;
  for (
    let j = total.length;
    j < differenceInMonths(date.end, date.start);
    j++
  ) {
    total.push({
      month: format(add.func(date.start, j), add.format),
      Profits: 0,
      Sales: 0,
    });
  }
  return total;
};

const formatData = (date, interval, data) => {
  const dateFormatter = {
    day: { func: addDays, format: 'dd' },
    month: { func: addMonths, format: 'MMMM' },
    year: { func: addYears, format: 'yyyy' },
  }[interval];
  const total = [];
  const delivery = [];
  const takeout = [];

  for (let i = 0; i < data.length; i++) {
    total.push({
      month: format(dateFormatter.func(date.start, i), dateFormatter.format),
      Profits: data[i].profit.takeouts + data[i].profit.deliveries,
      Sales: data[i].sales.takeouts + data[i].sales.deliveries,
    });
    delivery.push({
      month: format(dateFormatter.func(date.start, i), dateFormatter.format),
      Profits: data[i].profit.deliveries,
      Sales: data[i].sales.deliveries,
    });
    takeout.push({
      month: format(dateFormatter.func(date.start, i), dateFormatter.format),
      Profits: data[i].profit.takeouts,
      Sales: data[i].sales.takeouts,
    });
  }

  return {
    total: addEmpty({ field: total, add: dateFormatter, date }),
    delivery: addEmpty({ field: delivery, add: dateFormatter, date }),
    takeout: addEmpty({ field: takeout, add: dateFormatter, date }),
  };
};

export const formatToBarChart = (data) =>
  formatData({ start: data.start, end: data.end }, data.interval, data.data);
