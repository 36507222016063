import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useStyles } from './NoContent.styles';

function NoContent({ type = 'question' }) {
  const classes = useStyles();

  return (
    <Box className={classes.noContent}>
      <Typography className={classes.title}>
        Select {type === 'question' ? 'Question' : 'Item'}
      </Typography>
    </Box>
  );
}

NoContent.propTypes = {
  type: PropTypes.oneOf(['question', 'item']),
};

export default NoContent;
