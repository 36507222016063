import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { Typography, Box, Button, CircularProgress } from '@mui/material';
import { useStyles } from './TempClosingHours.style';
import TempClosingHoursAddNew from './TempClosingHoursAddNew';
import {
  addTempClosingHours,
  requestTempClosingHours,
  deleteTempClosingHour,
} from '../../../redux/ducks/RestaurantDucks';
import TempClosingHoursList from './TempClosingHoursList';

function TempClosingHours({ collapsed, onShow }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const existingTempHours = useSelector(
    (state) => state?.restaurant?.tempClosingHours
  );

  const restaurantId = useSelector(
    (state) => state?.restaurant?.rawSettings?.id
  );
  const restaurantTimezone = useSelector(
    (state) => state?.restaurant?.rawSettings?.timezone
  );

  const [onUpdatingHours, setOnUpdatingHours] = useState(false);
  const [showAddHoursSection, setShowAddHoursSection] = useState(false);

  const onAddClose = () => {
    setShowAddHoursSection(!showAddHoursSection);
  };

  const onAddCreate = ({ start, end }) => {
    setShowAddHoursSection(!showAddHoursSection);
    setOnUpdatingHours(true);
    dispatch(addTempClosingHours({ start, end, restaurant: restaurantId }));
  };

  const onDeleteHour = ({ hour }) => {
    setShowAddHoursSection(!showAddHoursSection);
    setOnUpdatingHours(true);
    dispatch(deleteTempClosingHour({ hour, restaurant: restaurantId }));
  };

  useEffect(() => {
    if (!existingTempHours) {
      dispatch(requestTempClosingHours({ restaurant: restaurantId }));
    }
  }, []);

  return restaurantTimezone && restaurantId && existingTempHours ? (
    <div style={{ width: '100%' }}>
      {collapsed ? (
        <div
          data-test-id="y5wm6EiuzXxbofeaCpTPb"
          className={classes.showHoursContainer}
          onClick={onShow}
        >
          <Typography variant="subtitle1">Show hours</Typography>
        </div>
      ) : (
        <Box className={classes.showHoursContainer}>
          <Box className={classes.showHoursContents}>
            {onUpdatingHours ? (
              <Box className={classes.hoursList}>
                <CircularProgress />
              </Box>
            ) : (
              <Box className={classes.hoursList}>
                {existingTempHours && existingTempHours.length > 0 ? (
                  <TempClosingHoursList
                    hours={existingTempHours ?? []}
                    onDelete={onDeleteHour}
                  />
                ) : (
                  <Typography variant="h2">
                    No temporary closing hours
                  </Typography>
                )}
                <Box className={classes.hoursAddControl}>
                  {showAddHoursSection ? (
                    <TempClosingHoursAddNew
                      onClose={onAddClose}
                      onCreate={onAddCreate}
                      timezone={restaurantTimezone}
                    />
                  ) : (
                    <Button
                      data-test-id="_M7aij8U38LFVEqdYGxbq"
                      variant="outlined"
                      onClick={() =>
                        setShowAddHoursSection(!showAddHoursSection)
                      }
                      className={classes.presetHoursButton}
                    >
                      Add New
                    </Button>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </div>
  ) : (
    <Box className={classes.showHoursContainer}>
      <CircularProgress />
    </Box>
  );
}

TempClosingHours.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  onShow: PropTypes.func.isRequired,
};

export default TempClosingHours;
