import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  dropzoneSliderThumb: {
    display: 'inline-flex',
    borderRadius: '2px',
    maxWidth: '100%',
    padding: '4px',
    boxSizing: 'border-box',
    border: '1px dashed #E0E0E0',
    flex: 1,
    background: '#FDFDFD',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  dropzoneImg: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '4px',
  },
  showImage: {
    display: 'none',
  },
  deleteLogo: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    padding: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0px 4px 0px 0px',
    background: 'rgba(255, 49, 0, 0.08)',

    '&:hover': {
      background: 'rgba(255, 49, 0, 0.18)',
    },
  },
  sliderWrapper: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  sliderInfoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down(670)]: {
      flexDirection: 'column',

      '& > div': {
        width: '100%',
      },
    },
  },
  sliderImage: {
    flex: '1',
    display: 'flex',
    width: '256px',
    minHeight: '160px',
    maxHeight: '160px',
    marginBottom: '32px',
    cursor: 'pointer',
    position: 'relative',

    [theme.breakpoints.down(670)]: {
      width: 'unset',
      minHeight: '200px',
      maxHeight: '200px',
    },
  },
  formTextFieldLabel: {
    marginLeft: 16,
    marginBottom: 8,
  },
  formInputBox: {
    marginBottom: 24,
    '& > div > input': {
      fontSize: '16px',
      padding: '14px 16px',
    },
  },
  deleteSliderButton: {
    padding: '14px 16px',
    background: 'rgba(255, 49, 0, 0.04)',
    color: '#FF3100',
    textTransform: 'uppercase',
    fontWeight: 'medium',

    '& > svg': {
      fill: '#FF3100',
    },
  },
  disabledDeleteSliderButton: {
    color: '#FC8162',
    '& > svg': {
      fill: '#FC8162',
    },
  },
  dropzone: {
    display: 'flex',
    border: '1px dashed #E0E0E0',
    borderRadius: 4,
    flex: 1,
    background: '#FDFDFD',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  dropzoneDisabled: {
    border: '1px solid #b4b4b4',
    background: '#F2F2F2',
  },
}));
