import { TextField, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import { useStyles } from './Login.styles';

function Login({ title, form: { control, getErrors } }) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h5" className={classes.formTitle}>
        {title}
      </Typography>
      <Typography variant="h6" className={classes.formTextFieldLabel}>
        Email
      </Typography>
      <Controller
        name="loginEmail"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            variant="outlined"
            placeholder="Type your email"
            className={clsx(classes.formInputBox, classes.lowerCase)}
            {...getErrors('loginEmail')}
          />
        )}
      />
      <Typography variant="h6" className={classes.formTextFieldLabel}>
        Password
      </Typography>
      <Controller
        name="loginPassword"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            variant="outlined"
            placeholder="Type your password"
            className={classes.formInputBox}
            type="password"
            {...getErrors('loginPassword')}
          />
        )}
      />
    </>
  );
}

Login.propTypes = {
  form: PropTypes.object,
  title: PropTypes.string,
};

export default Login;
