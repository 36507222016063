import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@mui/material';
import {
  Delete,
  RemoveShoppingCart,
  ShoppingCart,
  FileCopyOutlined,
} from '@mui/icons-material';
import { useStyles } from './Actions.styles';

function Actions({
  onSave,
  onCancel,
  onDelete,
  onDeleteForever,
  available = true,
  buttonRef,
  onClone,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.actions}>
      <Box className={classes.leftActions}>
        <Button
          data-test-id="AlE_04YmReHSTQXICJ65j"
          className={available ? classes.buttonDelete : classes.buttonAvailable}
          onClick={onDelete}
          data-testid="delete-button"
          ref={buttonRef}
        >
          {available ? (
            <>
              <RemoveShoppingCart className={classes.iconDelete} />
              <Typography className={classes.titleDelete}>86 it</Typography>
            </>
          ) : (
            <>
              <ShoppingCart className={classes.iconAvailable} />
              <Typography className={classes.titleAvailable}>
                Make it Available
              </Typography>
            </>
          )}
        </Button>
        {!available ? (
          <Button
            data-test-id="mHnWcn-eC9RWYFBa7hIpB"
            className={classes.buttonDelete}
            onClick={onDeleteForever}
            data-testid="remove-button"
            ref={buttonRef}
          >
            <Delete className={classes.iconDelete} />
            <Typography className={classes.titleDelete}>Remove</Typography>
          </Button>
        ) : (
          <Button
            data-test-id="zTXjgt7JJHbuCDp8VyrkH"
            className={classes.buttonCancel}
            onClick={onClone}
            data-testid="clone-button"
            ref={buttonRef}
          >
            <FileCopyOutlined className={classes.iconAvailable} />
            <Typography className={classes.titleCancel}>Clone</Typography>
          </Button>
        )}
      </Box>
      <Box className={classes.rightActions}>
        <Button
          data-test-id="lC9wU2M6ZgUMrh-Y8dHyj"
          className={classes.buttonCancel}
          onClick={onCancel}
          data-testid="cancel-button"
        >
          <Typography className={classes.titleCancel}>Cancel</Typography>
        </Button>
        <Button
          data-test-id="ZKaajyVjGHI224KU_tykg"
          className={classes.buttonSave}
          onClick={onSave}
          data-testid="save-button"
        >
          <Typography className={classes.titleSave}>Save</Typography>
        </Button>
      </Box>
    </Box>
  );
}

Actions.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onDeleteForever: PropTypes.func,
  available: PropTypes.bool,
  buttonRef: PropTypes.object,
  onClone: PropTypes.func,
};

Actions.defaultProps = {
  onDelete: () => {},
  onClone: () => {},
};

export default Actions;
