import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  selections: {
    display: 'flex',
    flexFlow: 'row',

    [theme.breakpoints.down('md')]: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gridColumnGap: '10px',
      gridRowGap: '10px',
    },
  },
  selection: {
    height: '57px',
    backgroundColor: '#00000005',

    '&:not(:last-child)': {
      margin: '0 10px 0 0',
    },

    [theme.breakpoints.down('md')]: {
      '&:not(:last-child)': {
        margin: '0px',
      },
    },
  },
  input: {
    display: 'inline-flex',
    height: '48px',
  },
}));
