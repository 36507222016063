import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import {
  Typography,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Switch,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Button from '../Button';
import { useStyles } from './AddErrorModal.styles';
import DropdownFilter from '../DropdownFilter/DropdownFilter';
import formatCurrencyValue from '../../../utils/FormatUtils/formatCurrencyValue';
import { putErrorOrder } from '../../../services/OrdersService';
import useNotifications from '../../../hooks/useNotifications/useNotifications';
import useIsDesktop from '../../../hooks/useIsDesktop/useIsDesktop';

const problems = [
  { label: 'Item missing', value: 'Item missing', key: 'item_missing' },
  {
    label: 'Incorrect preparation',
    value: 'Incorrect preparation',
    key: 'incorrect_preparation',
  },
  { label: 'Late delivery', value: 'Late delivery', key: 'late_delivery' },
  {
    label: 'Wrong item received',
    value: 'Wrong item received',
    key: 'wrong_item_received',
  },
  { label: 'Driver error', value: 'Driver error', key: 'driver_error' },
  { label: 'Cannot deliver', value: 'Cannot deliver', key: 'cannot_deliver' },
  { label: 'Other', value: 'Other', key: 'other' },
];

const solutions = [
  { label: 'Refund now', value: 'Refund now', key: 'refund_now' },
  { label: 'Note', value: 'Note', key: 'note' },
];

function AddErrorModal({
  isOpenModal,
  isRefund,
  restaurantId,
  orderDetails,
  handleCloseModal,
}) {
  const isDesktop = useIsDesktop();
  const classes = useStyles();
  const [filterProblem, setFilterProblem] = useState('Item missing');
  const [filterSolution, setFilterSolution] = useState(
    isRefund ? 'Refund now' : 'Note'
  );
  const [textProblem, setTextProblem] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [includeTax, setIncludeTax] = useState(false);
  const [refundEntireCharge, setRefundEntireCharge] = useState(false);
  const [items, setItems] = useState(
    orderDetails?.items?.map((item) => ({ ...item, checked: false }))
  );
  const { enqueueSnackbar } = useNotifications();

  useEffect(() => {
    if (refundEntireCharge) {
      setTotalAmount(parseFloat(orderDetails?.total.toFixed(2)));
      return;
    }

    setTotalAmount(
      parseFloat(
        items
          ?.reduce(
            (acc, val) =>
              acc +
              (val.checked
                ? (val.amount || 1) *
                  (val.price.charged +
                    (includeTax && val.tax?.amount ? val.tax?.amount : 0))
                : 0),
            0
          )
          .toFixed(2)
      )
    );
  }, [items, includeTax, refundEntireCharge, orderDetails]);

  const handleChangeTotalAmount = (e) => {
    const { value } = e.currentTarget;
    if (!isNaN(value)) setTotalAmount(value <= 0 ? value * -1 : value);
    if (value === '' || value === '0.') setTotalAmount(value);
  };

  const handleBlurTotalAmount = useCallback(
    (e) => {
      const totalValue = items?.reduce(
        (acc, val) =>
          acc +
          (val.amount || 1) *
            (val.price.charged +
              (includeTax && val.tax?.amount ? val.tax?.amount : 0)),
        0
      );

      if (Number(e.currentTarget.value) <= totalValue)
        setTotalAmount(Number(e.currentTarget.value).toFixed(2));
      else setTotalAmount(totalValue.toFixed(2));
    },
    [items, includeTax]
  );

  const cleanAndCloseModal = () => {
    setTextProblem('');
    handleCloseModal();
  };

  const handleSendError = async () => {
    if (filterSolution === 'Refund now' && totalAmount === 0) {
      enqueueSnackbar({
        message: 'Amount value cannot be 0.00',
        options: { variant: 'error' },
      });
      return;
    }

    const problemType = problems.find(
      ({ label }) => label === filterProblem
    )?.key;
    const problemSolution = solutions.find(
      ({ label }) => label === filterSolution
    )?.key;

    const formError = {
      type: problemType,
      solution: problemSolution,
      description: textProblem,
      ...(problemSolution === 'refund_now' && {
        data: {
          amount: totalAmount,
          items: items.filter((item) => item.checked).map((item) => item.id),
          answers: [],
        },
      }),
    };
    setIsLoading(true);
    await putErrorOrder({
      restaurantId,
      orderId: orderDetails.id,
      formError,
    })
      .then(() => {
        cleanAndCloseModal();
        enqueueSnackbar({
          message: 'Success!',
          options: { variant: 'success' },
        });
      })
      .catch((err) =>
        enqueueSnackbar({
          message: err?.message || 'Something went wrong!',
          options: { variant: 'error' },
        })
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      open={isOpenModal}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      data-testid="add-error-modal"
      className={classes.modal}
    >
      <Box className={classes.modalContainer}>
        <Box className={classes.titleModal}>
          <Typography className={classes.titleText}>
            {filterSolution === 'Refund now' ? 'Issue a refund' : 'Add Error'}
          </Typography>
          <CloseIcon
            data-test-id="hEHzlApkhJwgh5UgR5erc"
            className={classes.closeIcon}
            onClick={handleCloseModal}
            data-testid="close-button"
          />
        </Box>
        <hr className={classes.hr} />
        <Box className={classes.contentModal}>
          <Box
            className={clsx({
              [classes.options]: isDesktop,
              [classes.optionsMobile]: !isDesktop,
            })}
          >
            <Box className={classes.mr24}>
              <Typography className={classes.ml6}>Specify problem</Typography>
              <DropdownFilter
                items={{
                  filters: problems.map((problem) => problem.label),
                  filter: filterProblem,
                  setFilter: setFilterProblem,
                }}
                onlyString
                buttonWidth="100%"
              />
            </Box>
            <Box>
              <Typography className={classes.ml6}>Solution</Typography>
              <DropdownFilter
                items={{
                  filters: solutions.map((solution) => solution.label),
                  filter: filterSolution,
                  setFilter: setFilterSolution,
                }}
                onlyString
                buttonWidth="100%"
              />
            </Box>
          </Box>
          <hr className={classes.hr} />
          {filterSolution === 'Refund now' && (
            <Box
              className={clsx({
                [classes.switchContainer]: isDesktop,
                [classes.switchMobileContainer]: !isDesktop,
              })}
            >
              <Box
                className={clsx(classes.switch, {
                  [classes.op60]: !refundEntireCharge,
                })}
              >
                <Switch
                  inputProps={{ 'data-testid': 'refund-entire-charge' }}
                  color="primary"
                  onChange={(e) => setRefundEntireCharge(e.target.checked)}
                  checked={refundEntireCharge}
                />
                <Typography className={classes.ml6}>
                  Refund Entire Charge
                </Typography>
              </Box>

              <Box
                className={clsx(classes.switch, {
                  [classes.op60]: !includeTax,
                })}
              >
                <Switch
                  inputProps={{ 'data-testid': 'include-tax' }}
                  color="primary"
                  onChange={(e) => setIncludeTax(e.target.checked)}
                  checked={includeTax}
                />
                <Typography className={classes.ml6}>Include tax</Typography>
              </Box>
              <Box className={classes.amountContainer}>
                <Typography className={classes.amountLabel}>Amount</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  onChange={handleChangeTotalAmount}
                  onBlur={handleBlurTotalAmount}
                  className={classes.amountInput}
                  value={totalAmount}
                  name="amount"
                  inputProps={{
                    'data-testid': 'amount-input',
                    style: { textAlign: 'right' },
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
        {filterSolution === 'Refund now' && (
          <Box className={classes.checkBoxesContainer}>
            {items?.map((item) => (
              <Box key={item.id}>
                <Box className={classes.itemContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.checked}
                        onChange={(e) =>
                          setItems(
                            items.map((i) =>
                              i.id === item.id
                                ? { ...i, checked: e.target.checked }
                                : i
                            )
                          )
                        }
                        name="item"
                        size="small"
                        color="primary"
                        checkedIcon={<CheckBoxIcon />}
                        inputProps={{ 'data-testid': `item-${item.id}` }}
                      />
                    }
                    className={classes.itemText}
                    label={`${item.amount || 1}x ${item.name}`}
                  />
                  <Typography className={classes.itemText}>
                    {formatCurrencyValue(
                      item.price.charged +
                        (includeTax && item.tax?.amount ? item.tax?.amount : 0)
                    )}
                  </Typography>
                </Box>
                {/* {item.subitem && (
                <Box className={classes.subItemContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // checked={subItem}
                        // onChange={(e) => setSubItem(e.target.checked)}
                        name="sub-item"
                        color="primary"
                        size="small"
                        inputProps={{ "data-testid": "sub-item" }}
                      />
                    }
                    label="Small Fries"
                  />
                  <Typography className={classes.subItemText}>$1.25</Typography>
                </Box>
              )} */}
              </Box>
            ))}
          </Box>
        )}
        <Box className={classes.mb24}>
          <Typography className={classes.mb5}>Note</Typography>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Type a reason or a note"
            multiline
            rows={2}
            className={classes.problemInput}
            value={textProblem}
            onChange={(e) => setTextProblem(e.target.value)}
            inputProps={{ 'data-testid': 'specify-problem-text' }}
            name="specify-problem"
            error
          />
        </Box>
        <hr className={classes.hr} />
        <Box className={classes.buttonBox}>
          <Button
            data-test-id="jtIbNE95vvF9s_6PtsnDd"
            variant="outlined"
            className={classes.buttonBlue}
            style={{ color: '#FFFFFF' }}
            size={18}
            loading={isLoading}
            onClick={handleSendError}
            data-testid="send-error-button"
          >
            <Typography className={classes.buttonBlueText}>
              {filterSolution === 'Note' ? 'Save Note' : 'Issue Refund'}
            </Typography>
          </Button>
          <Button
            data-test-id="NkcUr_-TCpYV6Q9KNh8fo"
            variant="outlined"
            color="inherit"
            className={classes.button}
            onClick={cleanAndCloseModal}
            data-testid="cancel-button"
          >
            <Typography className={classes.buttonText}>Cancel</Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

AddErrorModal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  isRefund: PropTypes.bool.isRequired,
  restaurantId: PropTypes.number.isRequired,
  orderDetails: PropTypes.object.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default AddErrorModal;
