import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Map, PieChart } from '@mui/icons-material';
import { CircleMarker, MapContainer, TileLayer } from 'react-leaflet';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import { getSettings } from '../../../redux/ducks/RestaurantDucks';

import { useStyles } from './CommonStyles.styles';
import { Filter } from './Filter';
import { Loading } from '../Loading';
import { Error } from '../Error';

import { CenteredMetric } from '../../../utils/ChartUtils/chart-util';
import { formatCurrencyValue } from '../../../utils/FormatUtils/formatCurrencyValue';
import { getSales } from '../../../services/ReportService';
import { useMenuContext } from '../../../context/menuContext';
import { chartColor } from '../../../utils/ChartUtils/chart-constants';

export function DeliveryZone({ timeFilter }) {
  const classes = useStyles();
  const [filter, setFilter] = useState('Total Sales');
  const [activeButton, setActiveButton] = useState('Map');
  const { selectedRestaurant } = useMenuContext();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const zones = useSelector((state) => state.restaurant?.settings?.zones || {});
  const restaurantLocation = useSelector(
    (state) =>
      state.restaurant?.settings?.address?.location || { lat: 0, lng: 0 }
  );

  useEffect(() => {
    const loadSettingsData = (id) => {
      if (!id) return;
      dispatch(getSettings({ id }));
    };

    loadSettingsData(selectedRestaurant?.id);
  }, [selectedRestaurant, dispatch]);

  const infoType = useMemo(
    () =>
      ({
        'Total Sales': 'sales',
        'Total Wholesale': 'wholesale',
        'Total In-House': 'inhouse',
        'Total Profit': 'profit',
      }[filter]),
    [filter]
  );

  useEffect(() => {
    if (selectedRestaurant?.id)
      (async () => {
        setError(false);
        setLoading(true);
        try {
          const response = await getSales(
            selectedRestaurant.id,
            { ...timeFilter, group: 'zone', details: 1 },
            'sales-zone'
          );
          setData(response);
        } catch {
          setError(true);
        } finally {
          setLoading(false);
        }
      })();

    // eslint-disable-next-line
  }, [selectedRestaurant, timeFilter]);

  const handleChangeButton = (button) => {
    setActiveButton(button);
  };

  return (
    <>
      <Box className={classes.containerTitleWrapper}>
        <Typography className={classes.outsideContainerTitle}>
          By Delivery Zone
        </Typography>
        <Filter filter={filter} setFilter={setFilter} />
      </Box>
      <Box className={classes.infoContainer}>
        <Box className={classes.groupButtonWrapper}>
          <Button
            data-test-id="aS__0-bWSHknRwyqRcsF5"
            data-testid="map-button"
            className={clsx(classes.groupButton, {
              [classes.activeButton]: activeButton === 'Map',
            })}
            onClick={() => handleChangeButton('Map')}
          >
            <Map style={{ fontSize: '14px' }} />
            Map
          </Button>
          <Button
            data-test-id="zhKd2LO0CpoJI4bfvaFWH"
            data-testid="chart-button"
            className={clsx(classes.groupButton, {
              [classes.activeButton]: activeButton === 'Chart',
            })}
            onClick={() => handleChangeButton('Chart')}
          >
            <PieChart style={{ fontSize: '14px' }} />
            Chart
          </Button>
        </Box>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error ? (
              <Error />
            ) : (
              <Box className={classes.zoneWrapper}>
                {activeButton === 'Map' ? (
                  <Box className={classes.mapContainer}>
                    <MapContainer
                      zoom={11}
                      center={
                        zones[data[infoType].chart[0]?.id]?.latitude
                          ? [
                              zones[data[infoType].chart[0].id]?.latitude,
                              zones[data[infoType].chart[0].id]?.longitude,
                            ]
                          : [restaurantLocation.lat, restaurantLocation.lng]
                      }
                      className={classes.mapStyle}
                    >
                      <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      {data[infoType].chart.map(
                        (zip, i) =>
                          zones[zip?.id] && (
                            <CircleMarker
                              center={[
                                zones[zip.id]?.latitude,
                                zones[zip.id]?.longitude,
                              ]}
                              key={zip.id}
                              radius={
                                5 + Math.round(((50 - 5) * zip.value) / 100)
                              }
                              fillOpacity={0.8}
                              stroke={false}
                              color={chartColor[i]}
                            />
                          )
                      )}
                    </MapContainer>
                  </Box>
                ) : (
                  <Box style={{ height: ' 315px', width: '335px' }}>
                    <ResponsivePie
                      data={data[infoType].chart}
                      colors={chartColor}
                      innerRadius={0.75}
                      cornerRadius={4}
                      enableRadialLabels={false}
                      enableSliceLabels={false}
                      margin={{ bottom: 56 }}
                      layers={[
                        'arcs',
                        'arcLabels',
                        'radialLabels',
                        'legends',
                        CenteredMetric,
                      ]}
                    />
                  </Box>
                )}
                <Box flex="1">
                  {data[infoType].chart.map((d, i) => (
                    <Box key={d.id} className={classes.mapLegend}>
                      <Box display="flex" alignItems="center" flex="1">
                        <Box
                          style={{
                            marginRight: '6px',
                            width: '10px',
                            height: '10px',
                            background: `${chartColor[i]}`,
                            borderRadius: '3px',
                          }}
                        />
                        {d.id}
                      </Box>
                      <Box
                        display="flex"
                        flex="1"
                        justifyContent="space-between"
                      >
                        <Typography>{d.value}%</Typography>
                        <Typography>{formatCurrencyValue(d.total)}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
}

DeliveryZone.propTypes = {
  timeFilter: PropTypes.shape({
    start: PropTypes.object,
    end: PropTypes.object,
  }),
};

export default DeliveryZone;
