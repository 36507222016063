import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';

import { Save } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import DropdownFilter from '../../../../input/DropdownFilter';
import { useStyles } from './Overview.styles';
import { useStyles as useCommonStyles } from '../ReportSubItems.styles';
import { BarChart } from '../../../../layout/Charts';
import { InfoDisplay } from '../../../../layout/InfoDisplay';
import { Loading } from '../../../../layout/Loading';
import { Error } from '../../../../layout/Error';
import {
  formatScale,
  formatToBarChart,
} from '../../../../../utils/ChartUtils/chart-util';
import { getOverview } from '../../../../../services/ReportService';

import {
  formatCurrencyDisplay,
  numberWithCommas,
} from '../../../../../utils/FormatUtils/formatCurrencyValue';
import { useMenuContext } from '../../../../../context/menuContext';
import { yearToDate, last12Months } from '../../../../../utils/date-utils';
import { updateSettings } from '../../../../../redux/ducks/RestaurantDucks';

export function SideComponent({ titleStyle, value }) {
  const [percentage, setPercentage] = useState(value);
  const dispatch = useDispatch();
  const rawSettings = useSelector(
    (state) => state.restaurant.rawSettings || {}
  );
  const isLoading = useSelector((state) => state.restaurant.isLoading);

  const handleChangeTotalAmount = (e) => {
    const { value } = e.currentTarget;
    if (!isNaN(value)) setPercentage(value <= 0 ? value * -1 : value);
    if (value === '' || value === '0.') setPercentage(value);
  };

  const handleSave = useCallback(async () => {
    const {
      id,
      config,
      address,
      category,
      description,
      media,
      name,
      phone,
      schedule,
      text,
      tags,
    } = rawSettings;

    if (id)
      dispatch(
        updateSettings({
          id,
          settings: {
            name,
            description,
            address,
            category,
            tags,
            schedule,
            text,
            phone,
            media,
            config: {
              costs_food_ratio: percentage / 100,
              current_preparation_time: config.current_preparation_time,
            },
          },
        })
      );
  }, [rawSettings, percentage, dispatch]);

  const classes = useStyles();
  return (
    <Box className={classes.sideComponent}>
      <Typography className={titleStyle} style={{ marginBottom: '8px' }}>
        Food Cost
      </Typography>
      <Box className={classes.foodCostField}>
        <TextField
          error={percentage > 100}
          variant="outlined"
          name="food_cost"
          defaultValue={value}
          value={percentage}
          onChange={handleChangeTotalAmount}
          className={classes.percentage}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <IconButton
          data-test-id="JOT2wFSAVTrIAROjA8p0I"
          color="primary"
          data-testid="save-food-cost"
          onClick={handleSave}
          disabled={percentage > 100}
          style={{ marginLeft: '10px' }}
          size="large"
        >
          {isLoading ? <CircularProgress /> : <Save />}
        </IconButton>
      </Box>
    </Box>
  );
}

SideComponent.propTypes = {
  titleStyle: PropTypes.string,
  value: PropTypes.number,
};

export function Overview() {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [filter, setFilter] = useState({ label: 'Year to date' });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [reloading, setReloading] = useState(true);
  const [typeFilter, setTypeFilter] = useState({
    delivery: true,
    takeout: true,
  });
  const [data, setData] = useState();
  const { selectedRestaurant } = useMenuContext();

  useEffect(() => {
    setReloading(true);
    if (selectedRestaurant?.id)
      (async () => {
        try {
          const params = {
            'Year to date': yearToDate(),
            '12 months': last12Months(),
          }[filter.label];

          const response = await getOverview(selectedRestaurant?.id, params);
          setData({
            ...response,
            chart: formatToBarChart(response.total.timeline),
          });
          setPercentage(response.total.food_cost * 100);
          setLoading(false);
          setReloading(false);
        } catch (err) {
          setError(true);
          setLoading(false);
          setReloading(false);
        }
      })();
  }, [filter, selectedRestaurant]);

  const handleCheckbox = useCallback(
    (type) => {
      if (type === 'delivery') {
        setTypeFilter((old) => {
          if (!old.takeout && old.delivery)
            return { takeout: true, delivery: false };
          return { ...old, [type]: !old[type] };
        });
      }
      if (type === 'takeout') {
        setTypeFilter((old) => {
          if (old.takeout && !old.delivery)
            return { takeout: false, delivery: true };
          return { ...old, [type]: !old[type] };
        });
      }
    },
    [setTypeFilter]
  );

  const activeFilter = useMemo(() => {
    if (typeFilter.delivery && typeFilter.takeout) return 'total';
    if (!typeFilter.delivery && typeFilter.takeout) return 'takeout';
    return 'delivery';
  }, [typeFilter]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Box flex="1">
      <Box className={commonClasses.header}>
        <Typography variant="h1" className={commonClasses.pageTitle}>
          Overview
        </Typography>
        <Box className={classes.filterWrapper}>
          <Box display="flex" alignItems="center">
            <Checkbox
              color="primary"
              size="small"
              inputProps={{
                'data-testid': 'delivery-checkbox',
              }}
              checked={typeFilter.delivery}
              onChange={() => handleCheckbox('delivery')}
            />
            <Typography variant="caption">Delivery</Typography>
            <Checkbox
              color="primary"
              size="small"
              inputProps={{
                'data-testid': 'takeout-checkbox',
              }}
              checked={typeFilter.takeout}
              onChange={() => handleCheckbox('takeout')}
            />
            <Typography variant="caption">Takeout</Typography>
          </Box>
          <DropdownFilter
            items={{
              filters: ['Year to date', '12 months'],
              filter,
              setFilter,
            }}
            className={classes.dropdownFilter}
          />
        </Box>
      </Box>
      {reloading ? (
        <Loading />
      ) : (
        <>
          <Box className={commonClasses.infoWrapper}>
            <InfoDisplay
              info={{
                title: 'Extra Revenue',
                info: formatCurrencyDisplay(data[activeFilter].revenue),
              }}
            />
            <InfoDisplay
              info={{
                title: 'Extra Income',
                info: formatCurrencyDisplay(data[activeFilter].income),
              }}
              size={1.5}
              sideComponent={() => <SideComponent value={percentage} />}
            />
            <InfoDisplay
              info={{
                title: 'Average Ticket',
                info: formatCurrencyDisplay(data[activeFilter].average_ticket),
              }}
              size={0.8}
            />
          </Box>
          <Box className={commonClasses.infoWrapper}>
            <InfoDisplay
              info={{
                title: 'Number of Customers',
                info: numberWithCommas(data.total.customers_total),
              }}
              secondary
            />
            <InfoDisplay
              info={{
                title: 'Returning Customers',
                info: numberWithCommas(data.total.returning_customers.total),
                percentage: String(
                  (data.total.returning_customers.percentage * 100).toFixed(0)
                ),
              }}
              secondary
            />
            <InfoDisplay
              info={{
                title: 'New Customers',
                info: numberWithCommas(data.total.new_customers.total),
                percentage: String(
                  (data.total.new_customers.percentage * 100).toFixed(0)
                ),
              }}
              secondary
            />
          </Box>
          <Box
            className={commonClasses.infoContainer}
            style={{ overflowX: 'auto', maxWidth: 'calc(100VW - 30px)' }}
            marginBottom="24px"
          >
            <div className={classes.chartWrapper}>
              <BarChart
                data={data.chart[activeFilter]}
                keys={['Sales', 'Profits']}
                colors={['#6854FF', '#FF82BA']}
                index="month"
                axisLeft={{
                  format: formatScale,
                }}
                margin={{ top: 70, right: 24, bottom: 32, left: 60 }}
                borderRadius={{
                  top: {
                    left: 4,
                    right: 4,
                  },
                  bottom: {
                    left: 0,
                    right: 0,
                  },
                }}
              />
            </div>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Overview;
