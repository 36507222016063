import makeStyles from '@mui/styles/makeStyles';
import { rgba } from 'polished';

export const useStyles = makeStyles((theme) => ({
  infoContainer: {
    background: '#fff',
    padding: '12px',
    border: '1px solid #DADCE0',
    borderRadius: '4px',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      maxWidth: 'calc(100VW - 30px)',
      overflowX: 'auto',
      overflowY: 'hidden',
    },
  },
  wrapperDay: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      minWidth: '850px',
      paddingRight: '10px',
    },
  },
  columnTitle: {
    color: '#171725',
    opacity: '0.6',
    fontSize: '10px',
    width: '72px',
  },
  date: {
    color: '#171725',
    fontSize: '14px',
    width: '72px',
  },
  tableInfo: {
    color: '#171725',
    fontSize: '14px',
    fontWeight: 'normal',
    opacity: 0.6,
    width: '72px',
  },
  tableTotal: {
    color: '#171725',
    fontSize: '14px',
    fontWeight: '500',
    width: '92px',
    textAlign: 'end',
  },
  infoWeekWrapper: {
    marginTop: '20px',
    scrollbarWidth: 'none',

    transition: 'margin-top 200ms',
  },
  collapsed: {
    marginTop: '10px',
  },
  weekWrapper: {
    '& > div': {
      '&:not(:last-child)': {
        marginBottom: '16px',
      },
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',

      '& > div': {
        '&:not(:last-child)': {
          marginBottom: '0px',
        },
      },
      '& > button': {
        '&:not(:last-child)': {
          marginBottom: '16px',
        },
      },
    },
  },
  titleWrapperDesktop: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 14px',
    marginBottom: '12px',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  titleWrapperMobile: {
    display: 'none',

    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '12px',
      minWidth: '850px',
      paddingRight: '10px',
    },
  },
  showButtonDesktop: {
    padding: '6px 16px',
    border: '1px solid #0000001F',
    background: '#fff',
    '&:hover': { background: '#fff' },

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  showButtonMobile: {
    display: 'none',

    [theme.breakpoints.down('md')]: {
      display: 'flex',
      padding: '6px 16px',
      border: '1px solid #0000001F',
      background: '#fff',
      margin: '10px 0',
      width: '100%',
      '&:hover': { background: '#fff' },
    },
  },
  infoWrapper: {
    overflow: 'auto',
    maxHeight: '65vh',
    scrollbarWidth: 'thin',

    [theme.breakpoints.down('md')]: {
      scrollbarWidth: 'none',
    },
  },
  scrollWrapper: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto',
      overflowY: 'hidden',
    },
  },
  noRecords: {
    color: rgba(0, 0, 0, 0.3),
    fontSize: 24,
    marginTop: '70px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  summaryInfo: {
    color: '#0058FF',
    fontSize: '14px',
    fontWeight: 'bold',
    width: '72px',
  },
  summaryInfoTotal: {
    color: '#0058FF',
    fontSize: '14px',
    fontWeight: 'bold',
    width: '92px',
    textAlign: 'end',
  },
}));
