import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Component, useMenuContext } from '../../../context/menuContext';
import storage from '../../../utils/storage';

function ChooseRestaurant() {
  const { selectedRestaurant, restaurantsList, setSelectedRestaurant } =
    useMenuContext();

  const handleSetSelectedRestaurant = async (rest) => {
    setSelectedRestaurant(rest);
    await storage.set('selectedRestaurantId', rest.id);
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography component="h1" variant="h2" style={{ margin: 10 }}>
        Select Restaurant
      </Typography>
      {restaurantsList?.map((rest) => (
        <Button
          data-test-id="QhnpyrnC1upkFjAm_fvsn"
          style={{ margin: 10 }}
          variant="contained"
          disabled={selectedRestaurant?.id === rest?.id}
          color={selectedRestaurant?.id === rest?.id ? 'secondary' : 'primary'}
          data-testid={`restaurant-${rest?.id}`}
          key={rest?.id}
          onClick={() => handleSetSelectedRestaurant(rest)}
        >
          {rest?.name}
        </Button>
      ))}
    </Box>
  );
}

export function PlaceHolderPage() {
  return (
    <div>
      <Component
        components={[
          {
            label: 'Select Restaurant',
            component: <ChooseRestaurant />,
          },
          { label: 'Test 2', component: <div>Test 2</div> },
        ]}
      />
    </div>
  );
}

export default PlaceHolderPage;
