import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  notifications: [],
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    ...INITIAL_STATE,
  },
  reducers: {
    enqueueSnackbar(state, action) {
      const { payload } = action;
      const key = payload.options?.key;
      const notification = {
        ...payload,
        key: key || Date.now() + Math.random(),
      };

      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...notification,
          },
        ],
      };
    },
    closeSnackbar(state, action) {
      const key = action?.payload;
      const dismissAll = !key;

      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          dismissAll || notification.key === key
            ? { ...notification, dismissed: true }
            : { ...notification }
        ),
      };
    },
    removeSnackbar(state, action) {
      const key = action?.payload;
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== key
        ),
      };
    },
  },
});

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } =
  snackbarSlice.actions;

export default snackbarSlice.reducer;
