import React, { useCallback, useState } from 'react';
import { Box, Collapse, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { ChevronRight, ExpandMore } from '@mui/icons-material';
import clsx from 'clsx';
import { useStyles } from './Question.styles';
import { useQuestionsContext } from '../../../../context/questionsContext';

function CollapseIcon({ isOpen }) {
  if (isOpen) {
    return (
      <ExpandMore
        style={{
          fill: '#171725',
          opacity: '0.3',
          fontSize: '14px',
          marginRight: '5px',
        }}
      />
    );
  }

  if (!isOpen) {
    return (
      <ChevronRight
        style={{
          fill: '#171725',
          opacity: '0.3',
          fontSize: '14px',
          marginRight: '5px',
        }}
      />
    );
  }
}

CollapseIcon.propTypes = {
  isOpen: PropTypes.bool,
};

function Answer({ answer, handleSelectQuestion }) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const handleListItemClick = useCallback(() => {
    handleSelectQuestion();
    setIsOpen(!isOpen);
  }, [handleSelectQuestion, isOpen]);

  return (
    <Box data-testid={`item:list-item-${answer.id}`} className={classes.item}>
      <Box style={{ flex: 1 }}>
        <Box
          data-test-id="EpS1seGU2DcpDoRs7pOla"
          onClick={handleListItemClick}
          className={classes.title}
        >
          {!!answer.children.length && <CollapseIcon isOpen={isOpen} />}
          <Typography
            data-testid={`item:title-${answer.id}`}
            className={clsx({
              [classes.titleDeleted]: !answer.available,
              [classes.noChildren]: !!answer.children.length,
            })}
          >
            {answer.text}
          </Typography>
        </Box>
        <Collapse
          in={isOpen}
          style={{
            paddingLeft: '16px',
          }}
        >
          <Box>
            {answer.children.map((item) => (
              <Question key={item.id} question={item} />
            ))}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
}

Answer.propTypes = {
  answer: PropTypes.object,
  handleSelectQuestion: PropTypes.func,
};

function Question({ question }) {
  const classes = useStyles();
  const { setSelectedQuestion, selectedQuestion } = useQuestionsContext();
  const [isOpen, setIsOpen] = useState(false);

  const handleListItemClick = useCallback(() => {
    setSelectedQuestion(question);
    setIsOpen(!isOpen);
  }, [isOpen, question, setSelectedQuestion]);

  return (
    <Box className={classes.item}>
      <Box style={{ flex: 1 }}>
        <Box
          data-test-id="k9MGF-wSY2IM4RO15Ujiu"
          data-testid={`item:list-item-${question.id}`}
          onClick={handleListItemClick}
          className={classes.title}
        >
          {!!question.children.length && <CollapseIcon isOpen={isOpen} />}
          <Typography
            data-testid={`item:title-${question.id}`}
            className={clsx({
              [classes.titleSaved]: question.id === selectedQuestion?.id,
              [classes.titleDeleted]: !question.available,
              [classes.noChildren]: !question.children.length,
            })}
          >
            {question.name}
          </Typography>
        </Box>
        <Collapse
          in={isOpen}
          style={{
            paddingLeft: '16px',
          }}
        >
          <Box>
            {isOpen &&
              question.children.map((item) => (
                <Answer
                  key={item.id}
                  answer={item}
                  handleSelectQuestion={() => setSelectedQuestion(question)}
                />
              ))}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
}

Question.propTypes = {
  question: PropTypes.object,
};

export default Question;
