import React, { useCallback } from 'react';
import { Box, Input } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useStyles } from './Search.styles';

function Search({
  placeholder = '',
  onInputKeyUp,
  onSearchIconClick,
  onSortIconClick,
}) {
  const classes = useStyles();

  const handleInputKeyUp = useCallback(
    /** @param {KeyboardEvent} event */ (event) => {
      onInputKeyUp({
        key: event.key,
        value: event.target.value,
      });
    },
    [onInputKeyUp]
  );

  const handleSearchIconClick = useCallback(() => {
    if (onSearchIconClick) {
      onSearchIconClick();
    }
  }, [onSearchIconClick]);

  const handleSortIconClick = useCallback(() => {
    if (onSortIconClick) {
      onSortIconClick();
    }
  }, [onSortIconClick]);

  return (
    <Box className={classes.search}>
      <SearchIcon
        data-test-id="t42KdBoeQCTnytVeGfNHt"
        data-testid="search:icon-search"
        className={clsx(classes.icon, classes.iconSearch)}
        onClick={handleSearchIconClick}
      />
      <Input
        inputProps={{ 'data-testid': 'search:input' }}
        className={classes.input}
        onKeyUp={handleInputKeyUp}
        placeholder={placeholder}
        name="search"
      />
      <SortIcon
        data-test-id="DGZ-yX7BXQlfUDQ94loGs"
        data-testid="search:icon-sort"
        className={clsx(classes.icon, classes.iconSort)}
        onClick={handleSortIconClick}
      />
    </Box>
  );
}

Search.propTypes = {
  placeholder: PropTypes.string,
  onInputKeyUp: PropTypes.func.isRequired,
  onSearchIconClick: PropTypes.func,
  onSortIconClick: PropTypes.func,
};

export default Search;
