import { api } from './Api';

export const deleteImage = async (data) => {
  await api.upload(`/upload/${data}`, null, { method: 'DELETE' });
};

export const getAllFiles = async () => {
  const response = await api.get('/upload');
  return response;
};

export const getConfigFiles = async () => {
  const response = await api.get('/upload/config');
  return response;
};

export const uploadFile = async (file) => {
  const data = new FormData();
  data.append('file', file[0]);
  const { id } = await api.post('/upload');
  const response = await api.upload(`/upload/${id}`, data);
  return {
    id,
    mime: file[0].type,
    url: response.url,
    size: file[0].size,
  };
};
