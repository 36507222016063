import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#fff',
    borderRadius: '14px',
    border: '1px solid #E0E0E0',
    width: '1024px',
    height: '535px',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    borderBottom: '1px solid #e0e0e0',
    height: '61px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
  },
  title: {
    fontSize: '24px',
    color: '#333',
  },
  content: {
    flex: '1',
    display: 'flex',
  },
  menu: {
    padding: '32px 24px',
  },
  menuItem: {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    borderRadius: '0 12px 12px 0',
    padding: '12px 16px',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'start',
  },
  selected: {
    backgroundColor: 'rgba(60, 85, 232, 0.05)',
    color: '#3C55E8',
    '&:hover': {
      backgroundColor: 'rgba(60, 85, 232, 0.1)',
    },
  },
  spaceUsed: {
    color: '#3C55E8',
    fontWeight: '600',
    padding: '12px 16px',
  },
  menuEnd: {
    marginTop: 'auto',
    padding: '32px 24px',
  },
  availableText: {
    fontSize: '12px',
    fontWeight: '600',
    color: '#3C55E8',
    marginTop: '8px',
  },
  gallery: {
    flex: '1',
    padding: '32px 0px',
    marginRight: '24px',
  },
  footer: {
    borderTop: '1px solid #e0e0e0',
    height: '61px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    padding: '16px 24px',
  },
  button: {
    borderRadius: '4px',
    padding: '6px 12px',
    fontWeight: '600',
    '&:not(:last-child)': {
      marginRight: '32px',
    },
  },
  saveButton: {
    backgroundColor: '#3C55E8',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#324BDE',
    },
  },
  cancelButton: {
    backgroundColor: 'rgba(60, 85, 232, 0.1)',
    color: '#3C55E8',
    '&:hover': {
      backgroundColor: 'rgba(60, 85, 232, 0.15)',
    },
  },
}));
