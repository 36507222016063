import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  getItem,
  getMenuItems,
  // saveItem,
  // cloneItem,
  // makeItemAvailable,
  // deleteItem,
} from '../../services/MenuServices';

export const INIT = {
  items: [],
  selectedItem: null,
  formattedList: [],
  setItems: () => {},
  setSelectedItem: () => {},
  setFormattedList: () => {},
};

export const ItemContext = createContext(INIT);

export const useItemContext = () => useContext(ItemContext);

export function ItemContextProvider({ children }) {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [formattedList, setFormattedList] = useState([]);
  const [expandAll, setExpandAll] = useState(false);

  const handleGetItem = useCallback(async (restaurantId, item) => {
    const response = await getItem({
      restId: restaurantId,
      menuId: item?.menuId,
      id: item?.id,
    });

    setSelectedItem({ ...item, ...response, id: item.id });
    return { ...item, ...response, id: item.id };
  }, []);

  const handleGetMenuItems = useCallback(async (restaurantId) => {
    const response = await getMenuItems({
      restId: restaurantId,
    });
    setItems(response);
  }, []);

  const sortItems = (menu) =>
    menu.map((submenu) => ({
      ...submenu,
      categories: submenu.categories
        .map((category) => ({
          ...category,
          items: category.items.sort((a, b) =>
            a.sort > b.sort ? 1 : b.sort > a.sort ? -1 : 0
          ),
        }))
        .sort((a, b) => (a.sort > b.sort ? 1 : b.sort > a.sort ? -1 : 0)),
    }));

  const handleFormatList = (items) => setFormattedList(sortItems(items));

  useEffect(() => {
    setFormattedList(sortItems(items));
    // eslint-disable-next-line
  }, [items]);

  return (
    <ItemContext.Provider
      value={{
        items,
        selectedItem,
        formattedList,
        setItems,
        setSelectedItem,
        setFormattedList: handleFormatList,
        expandAll,
        setExpandAll,
        getItem: handleGetItem,
        getMenuItems: handleGetMenuItems,
      }}
    >
      {children}
    </ItemContext.Provider>
  );
}

ItemContextProvider.propTypes = {
  children: PropTypes.node,
};

export default ItemContextProvider;
