import React from 'react';
import { Box } from '@mui/material';
import { useStyles } from './Lists.styles';
import { MenuList } from '../MenuList/MenuList';
import { useItemContext } from '../../../../context/itemsContext/itemsContext';

function Lists() {
  const classes = useStyles();
  const { formattedList } = useItemContext();

  return (
    <Box className={classes.list} key={JSON.stringify(formattedList)}>
      {formattedList.map((list) => (
        <MenuList key={list.id} menu={list} />
      ))}
    </Box>
  );
}

export default Lists;
