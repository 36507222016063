import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { Filter } from './Filter';
import { BarChart } from '../Charts';
import { getSales } from '../../../services/ReportService';

import { useStyles } from './CommonStyles.styles';

import { useMenuContext } from '../../../context/menuContext';
import { Loading } from '../Loading';
import { Error } from '../Error';
import { formatScale } from '../../../utils/ChartUtils/chart-util';

export function Summary({ timeFilter }) {
  const classes = useStyles();
  const [filter, setFilter] = useState('Total Sales');
  const { selectedRestaurant } = useMenuContext();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [typeFilter, setTypeFilter] = useState({
    delivery: true,
    takeout: true,
  });

  const infoType = useMemo(
    () =>
      ({
        'Total Sales': 'sales',
        'Total Wholesale': 'wholesale',
        'Total In-House': 'inhouse',
        'Total Profit': 'profit',
      }[filter]),
    [filter]
  );

  useEffect(() => {
    if (selectedRestaurant?.id)
      (async () => {
        try {
          setError(false);
          setLoading(true);
          const response = await getSales(
            selectedRestaurant.id,
            { ...timeFilter, details: 1 },
            'sales-summary'
          );
          setData(response);
        } catch (err) {
          setError(true);
        } finally {
          setLoading(false);
        }
      })();
  }, [selectedRestaurant, timeFilter]);

  const handleCheckbox = useCallback(
    (type) => {
      setTypeFilter((old) => ({ ...old, [type]: !old[type] }));
    },
    [setTypeFilter]
  );

  const activeFilter = useMemo(() => {
    if (typeFilter.delivery === typeFilter.takeout) return 'total';
    if (!typeFilter.delivery && typeFilter.takeout) return 'takeout';
    return 'delivery';
  }, [typeFilter]);

  return (
    <Box
      className={classes.infoContainer}
      style={{ flex: 2 }}
      data-testid="summary"
    >
      <Box className={classes.filterWrapper}>
        <Filter
          filter={filter}
          setFilter={setFilter}
          className={classes.filter}
        />
        <Box display="flex" alignItems="center">
          <Checkbox
            inputProps={{
              'data-testid': 'delivery-checkbox',
            }}
            checked={typeFilter.delivery}
            onChange={() => handleCheckbox('delivery')}
            color="primary"
            size="small"
          />
          <Typography variant="caption">Delivery</Typography>
          <Checkbox
            inputProps={{
              'data-testid': 'takeout-checkbox',
            }}
            checked={typeFilter.takeout}
            onChange={() => handleCheckbox('takeout')}
            color="primary"
            size="small"
          />
          <Typography variant="caption">Takeout</Typography>
        </Box>
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <>
          {error ? (
            <Box
              flex={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Error />
            </Box>
          ) : (
            <Box
              width="100%"
              style={{ overflowX: 'auto', overflowY: 'hidden' }}
            >
              <Box height="300px" minWidth="600px">
                <BarChart
                  data={data[infoType][activeFilter]}
                  background
                  colors={['#0058FF']}
                  keys={['value']}
                  gridY={false}
                  index="day"
                  axisBottom={{
                    tickSize: 0,
                    tickPadding: 8,
                    tickRotation: 0,
                  }}
                  axisLeft={{
                    tickSize: 0,
                    tickPadding: 8,
                    tickRotation: 0,
                    format: formatScale,
                  }}
                  margin={{ top: 30, right: 0, bottom: 32, left: 50 }}
                  borderRadius={{
                    top: {
                      left: 4,
                      right: 4,
                    },
                    bottom: {
                      left: 4,
                      right: 4,
                    },
                  }}
                />
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

Summary.propTypes = {
  timeFilter: PropTypes.shape({
    start: PropTypes.object,
    end: PropTypes.object,
    interval: PropTypes.string,
  }),
};

export default Summary;
