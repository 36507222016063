import React, { useCallback, useState } from 'react';
import { Typography, Box, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { useStyles } from './EditAnswerModal.styles';
import Button from '../../../../input/Button';
import { addAnswer, editAnswer } from '../../../../../services/AnswerServices';
import { useMenuContext } from '../../../../../context/menuContext';
import useNotifications from '../../../../../hooks/useNotifications/useNotifications';
import { useQuestionsContext } from '../../../../../context/questionsContext/questionsContext';
import { useItemContext } from '../../../../../context/itemsContext/itemsContext';

function EditAnswerModal({
  isOpenModal,
  handleCloseModal,
  selectedAnswer,
  isNew,
  isItem,
  onCancel,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [text, setText] = useState(selectedAnswer?.text || '');
  const [price, setPrice] = useState(
    selectedAnswer?.prices?.takeout?.base || 0
  );
  const { selectedRestaurant } = useMenuContext();
  const { selectedItem, setSelectedItem } = useItemContext();
  const { enqueueSnackbar } = useNotifications();
  const restaurantSettings = useSelector((state) => state.restaurant.settings);
  const { updateQuestionAnswer, getQuestionAnswer } = useQuestionsContext();

  const handleCancel = useCallback(() => {
    if (isNew) {
      if (isItem) {
        onCancel();
      } else {
        setSelectedItem({
          ...selectedItem,
          questions: selectedItem.questions.map((question) =>
            question.id === selectedAnswer.parent
              ? { ...question, answers: question.answers.pop() }
              : question
          ),
        });
      }
    } else {
      setSelectedItem(selectedItem); // Might not be necessary
    }
    handleCloseModal();
  }, [
    handleCloseModal,
    selectedItem,
    setSelectedItem,
    selectedAnswer,
    onCancel,
    isNew,
    isItem,
  ]);

  const handleAddAnswer = useCallback(
    async ({ text, price }) => {
      try {
        setIsLoading(true);
        const answer = getQuestionAnswer(selectedAnswer.root, 0);
        const response = await addAnswer({
          questionId: selectedAnswer.parent,
          restId: selectedRestaurant.id,
          text,
          price,
          sort: answer?.sort || 0,
        });
        updateQuestionAnswer(selectedAnswer.root, 0, {
          ...selectedAnswer,
          text,
          prices: { takeout: { base: price } },
          sort: answer?.sort || 0,
          ...response,
        });
        handleCloseModal();
      } catch (err) {
        enqueueSnackbar({
          message: err.message,
          options: {
            variant: 'error',
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      enqueueSnackbar,
      getQuestionAnswer,
      handleCloseModal,
      selectedAnswer,
      selectedRestaurant.id,
      updateQuestionAnswer,
    ]
  );

  const handleEditAnswer = useCallback(
    async ({ text, price }) => {
      try {
        setIsLoading(true);

        await editAnswer({
          questionId: selectedAnswer.parent,
          restId: selectedRestaurant?.id,
          id: selectedAnswer.id,
          weight: selectedAnswer.weight || 1,
          text,
          price,
          sort: selectedAnswer.sort,
        });

        updateQuestionAnswer(selectedAnswer.root, selectedAnswer.id, {
          ...selectedAnswer,
          text,
          prices: { takeout: { base: price } },
        });
        handleCloseModal();
      } catch (err) {
        enqueueSnackbar({
          message: err.message,
          options: {
            variant: 'error',
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      enqueueSnackbar,
      handleCloseModal,
      selectedAnswer,
      selectedRestaurant?.id,
      updateQuestionAnswer,
    ]
  );

  const handleSaveChanges = useCallback(async () => {
    if (!text) {
      setErrorText(true);
      return;
    }
    setErrorText(false);

    if (isNew) {
      await handleAddAnswer({
        text,
        price: price || 0,
      });
    } else {
      await handleEditAnswer({
        text,
        price: price || 0,
      });
    }
  }, [text, price, isNew, handleAddAnswer, handleEditAnswer]);

  return (
    <Modal
      open={isOpenModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      data-testid="edit-answer-modal"
      className={classes.modal}
    >
      <Box className={classes.modalContainer}>
        <Box className={classes.inputContainer}>
          <Box className={classes.mb30}>
            <TextField
              variant="outlined"
              label="Answer Text"
              className={classes.input}
              style={{ width: '100%' }}
              inputProps={{ 'data-testid': 'answer-text' }}
              value={text}
              onChange={(e) => setText(e.target.value)}
              error={errorText && !text}
            />
          </Box>
          <Box className={classes.answerPriceContainer}>
            <TextField
              variant="outlined"
              label="Answer Price"
              className={classes.input}
              inputProps={{
                'data-testid': 'answer-price',
                min: '0',
                step: '0.1',
              }}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              type="number"
            />
            <span className={classes.priceInfo}>
              Delivery: $
              {(
                (1 + (restaurantSettings?.fees?.markup?.delivery || 0)) *
                price
              ).toFixed(2)}{' '}
              | Takeout: $
              {(
                (1 + (restaurantSettings?.fees?.markup?.takeout || 0)) *
                price
              ).toFixed(2)}
            </span>
          </Box>
        </Box>
        <Box className={classes.buttonBox}>
          <Button
            data-test-id="daR__ztSzwpRbsTw18vas"
            variant="outlined"
            color="inherit"
            className={classes.buttonBlue}
            onClick={handleSaveChanges}
            loading={isLoading}
            loadingColor="secondary"
            data-testid="save-changes-button"
          >
            <Typography className={classes.buttonBlueText}>
              Save Changes
            </Typography>
          </Button>
          <Button
            data-test-id="QT-vXIEAWK8yRXktc2fTc"
            variant="outlined"
            color="inherit"
            className={classes.button}
            onClick={handleCancel}
            data-testid="cancel-changes-button"
          >
            <Typography className={classes.buttonText}>Cancel</Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

EditAnswerModal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  selectedAnswer: PropTypes.object.isRequired,
  isNew: PropTypes.bool,
  isItem: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default EditAnswerModal;
