import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  settings: null,
  rawSettings: null,
  tempClosingHours: null,
  isLoading: false,
  error: null,
};

const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState: {
    ...INITIAL_STATE,
  },
  reducers: {
    getSettings(state) {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    setSettings(state, action) {
      return {
        ...state,
        settings: action?.payload?.settings?.res,
        rawSettings: action?.payload?.settings?.raw,
        isLoading: false,
        error: null,
      };
    },
    updateSettings(state) {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    setTempClosingHours(state, action) {
      return {
        ...state,
        tempClosingHours: action.payload,
        isLoading: false,
        error: null,
      };
    },
    addTempClosingHours(state) {
      return {
        ...state,
        tempClosingHours: null,
        isLoading: true,
        error: null,
      };
    },
    deleteTempClosingHour(state) {
      return {
        ...state,
        tempClosingHours: null,
        isLoading: true,
        error: null,
      };
    },
    requestTempClosingHours(state) {
      return {
        ...state,
        tempClosingHours: null,
        isLoading: true,
        error: null,
      };
    },
    error(state, action) {
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.message || 'Unkown Error',
      };
    },
    clearErrors(state) {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    },
  },
});

export const {
  error,
  clearErrors,
  getSettings,
  setSettings,
  updateSettings,
  requestTempClosingHours,
  addTempClosingHours,
  setTempClosingHours,
  deleteTempClosingHour,
} = restaurantSlice.actions;

export default restaurantSlice.reducer;
