import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles as useCommonStyles } from '../ReportSubItems.styles';
import { useStyles } from './SalesReport.styles';
import { DatePicker } from '../../../../input/DatePicker/DatePicker';
import DropdownFilter from '../../../../input/DropdownFilter/DropdownFilter';
import {
  Summary,
  TodayEarnings,
  Type,
  MenuCategory,
  DayTime,
  DeliveryZone,
  HighestSale,
} from '../../../../layout/SalesReport';
import { lastMonth, yearToDate } from '../../../../../utils/date-utils';

export function SalesReport() {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [popoverEl, setPopoverEl] = useState(null);
  const [timeFilter, setTimeFilter] = useState('Last month');
  const filterBtnEl = useRef();

  const [params, setParams] = useState({
    ...lastMonth(),
    timeFilter: 'Last month',
  });

  useEffect(() => {
    if (params?.timeFilter !== timeFilter?.label) {
      const params = {
        'Year to date': yearToDate(),
        'Last month': lastMonth(),
      }[timeFilter.label];

      if (params) {
        setParams({
          ...params,
          timeFilter: timeFilter?.label,
        });
      }
    }
  }, [timeFilter, params]);

  const handlePopoverOpen = () => {
    setPopoverEl(filterBtnEl.current);
  };

  const handlePopoverClose = () => {
    setPopoverEl(null);
  };

  return (
    <>
      <Box flex="1">
        <Box className={commonClasses.header}>
          <Typography variant="h1" className={commonClasses.pageTitle}>
            Sales Report
          </Typography>
          <Box ref={filterBtnEl}>
            <DropdownFilter
              items={{
                filters: [
                  'Last month',
                  'Year to date',
                  {
                    label: 'Custom...',
                    open: handlePopoverOpen,
                    close: handlePopoverClose,
                  },
                ],
                filter: timeFilter,
                setFilter: setTimeFilter,
              }}
              style={{ marginLeft: '24px' }}
            />
          </Box>
        </Box>
        <Box className={classes.containerWrapper}>
          <Summary timeFilter={params} />
          <TodayEarnings />
        </Box>
        <Box className={classes.containerWrapper}>
          <Box flex="1" className={classes.componentWrapper}>
            <Type timeFilter={params} />
          </Box>
          <Box flex="2" display="flex">
            <MenuCategory timeFilter={params} />
          </Box>
        </Box>
        <Box className={classes.containerWrapper}>
          <Box flex="1" className={classes.componentWrapper}>
            <DayTime timeFilter={params} />
          </Box>
          <Box flex="2" display="flex" flexDirection="column">
            <DeliveryZone timeFilter={params} />
          </Box>
        </Box>
        <Box className={classes.containerWrapper}>
          <Box flex="1" className={classes.componentWrapper}>
            <HighestSale timeFilter={params} />
          </Box>
        </Box>
      </Box>
      <DatePicker
        popoverEl={popoverEl}
        handleClosePopover={handlePopoverClose}
        setParams={setParams}
      />
    </>
  );
}

export default SalesReport;
