import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  modal: {
    position: 'absolute',
    backgroundColor: 'white',
    padding: '24px',
    border: '1px solid #DADCE0',
    borderRadius: '4px',
    opacity: 1,
    top: '50%',
    left: '50%',
    width: '65%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 780px)': {
      width: '90%',
      left: '50%',
    },
  },
  titleModal: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '25px',
    alignItems: 'center',
  },
  titleText: {
    font: 'normal normal 600 24px/35px Poppins',
    letterSpacing: '0.17px',
    color: '#282B31',
    opacity: 1,
  },
  closeIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hr: {
    color: '#282B31',
    opacity: 0.2,
  },
  contentModal: {
    display: 'flex',
    marginTop: '24px',
    marginBottom: '24px',
    justifyContent: 'space-between',
    '@media (max-width: 780px)': {
      flex: 1,
      flexWrap: 'wrap',
    },
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '32px',
    '@media (max-width: 780px)': {
      marginTop: '15px',
    },
  },
  button: {
    border: '1px solid #0000001F',
    borderRadius: '4px',
    opacity: 1,
    marginRight: '32px',
  },
  buttonText: {
    font: 'normal normal 600 14px/21px Poppins',
    letterSpacing: '0px',
    color: '#0058FF',
    opacity: 1,
  },
  buttonBlue: {
    border: '1px solid #0000001F',
    borderRadius: '4px',
    opacity: 1,
    backgroundColor: '#0058FF',
    '&:hover': {
      backgroundColor: 'rgba(0, 88, 255, 0.5)',
    },
  },
  buttonBlueText: {
    color: '#FFFFFF',
    font: 'normal normal 400 14px/21px Poppins',
    letterSpacing: '0px',
    opacity: 1,
  },
  inputContainer: {
    display: 'flex',
    minWidth: '336px',
    flexDirection: 'column',
    width: '50%',
    marginTop: '32px',
  },
  label: {
    marginLeft: '5px',
    font: 'normal normal 600 12px/24px Poppins',
    letterSpacing: '0.11px',
    color: '#282B31',
    opacity: 0.9,
  },
  input: {
    borderRadius: '8px',

    '& label.Mui-focused': {
      color: '#282B3199',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F5F5F5',
      },
      '&:hover fieldset': {
        borderColor: '#DADCE0',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #282B3199',
      },
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '12px',
    },
  },
  areaInput: {
    borderRadius: '8px',
    width: '100%',

    '& label.Mui-focused': {
      color: '#282B3199',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F5F5F5',
      },
      '&:hover fieldset': {
        borderColor: '#DADCE0',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #282B3199',
      },
    },
  },
}));
