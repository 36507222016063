import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../../Theme/Theme';

export const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexFlow: 'column',
    minHeight: '49px',
    width: '100%',
    margin: '0',
  },
  name: {
    display: 'flex',
    flexFlow: 'column',
    padding: '0',
    marginBottom: '8px',
    '&:hover': {
      backgroundColor: '#00000008',
      cursor: 'pointer',
    },
  },
  labelName: {
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 'medium',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0px',
    color: '#282B31',
    padding: '0px',
    marginBottom: '6px',
  },
  inputName: {
    display: 'flex',
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
    color: '#282B31',
    border: 'none',

    '& fieldset': {
      border: 'none',
    },
  },
  text: {
    display: 'flex',
    flexFlow: 'column',
    '&:hover': {
      backgroundColor: '#00000008',
      cursor: 'pointer',
    },
  },
  labelText: {
    display: 'flex',
    flexFlow: 'row',
  },
  labelFirstText: {
    display: 'inline-flex',
    fontWeight: 'medium',
    fontSize: '12px',
    lineHeight: '18px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0px',
    color: '#282B31',
  },
  labelSecondText: {
    display: 'inline-flex',
    fontWeight: 'medium',
    fontSize: '12px',
    lineHeight: '18px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0px',
    color: '#282B3199',
  },
  inputText: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '35px',
    padding: '0',
  },
});
