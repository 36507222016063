import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#fff',
    border: '1px solid #EBEBEB',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    padding: '24px',
    width: (props) => (props.showCalendar ? '380px' : '320px'),
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  modalTitle: {
    fontSize: '20px',
    fontWeight: '500',
    color: '#282B31',
    marginBottom: '14px',
  },
  deleteButton: {
    padding: '14px',
    backgroundColor: '#F7F7F7',
    border: '1px solid #EBEBEB',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    marginBottom: '6px',
    justifyContent: 'start',
    color: 'rgba(40, 43, 49, 0.6)',

    '&:last-child': {
      marginBottom: '24px',
    },
  },
  cancelButton: {
    borderRadius: '4px',
    border: '1px solid #0000001F',
    color: '#0058FF',
    padding: '6px 16px',
    alignSelf: 'flex-end',
  },
  saveButton: {
    borderRadius: '4px',
    border: '1px solid #0000001F',
    color: '#0058FF',
    padding: '6px 16px',
    alignSelf: 'flex-end',
  },
  containerButtons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  untilDateButton: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
}));
