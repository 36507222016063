import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../layout/Theme/Theme';

export const useStyles = makeStyles(() => ({
  calendar: {
    marginLeft: '30px',
  },
  popover: {
    marginTop: '6px',
  },
  dateSelectContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  dateSelectInput: {
    maxWidth: '144px',
    backgroundColor: '#00000005',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: 'auto',
  },
  button: {
    border: '1px solid #0000001F',
    borderRadius: '4px',
    opacity: 1,
    marginRight: '32px',
  },
  buttonText: {
    font: 'normal normal 400 14px/21px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0px',
    color: '#282B31',
    opacity: 1,
  },
  buttonBlue: {
    border: '1px solid #0000001F',
    borderRadius: '4px',
    opacity: 1,
    backgroundColor: '#0058FF',
    '&:hover': {
      backgroundColor: 'rgba(0, 88, 255, 0.5)',
    },
  },
  buttonBlueText: {
    color: '#FFFFFF',
    font: 'normal normal 400 14px/21px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0px',
    opacity: 1,
  },
}));
