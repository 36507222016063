import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Modal,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import clsx from 'clsx';
import { useStyles } from './GalleryModal.styles';
import { getAllFiles, getConfigFiles } from '../../../services/UploadService';
import { Loading } from '../Loading/Loading';
import { bytesToMB } from '../../../utils/FormatUtils/formatMediaSize';

export function GalleryModal({ open, handleClose, setFile, file, components }) {
  const classes = useStyles();
  const [config, setConfig] = useState({ quota: 1, used: 0 });
  const [mime, setMime] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(file || []);
  const [selected, setSelected] = useState(0);

  const changeUsedSpace = useCallback(
    async (amount) => {
      setConfig({ ...config, used: config.used + amount });
    },
    [config]
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getAllFiles();
      const configResponse = await getConfigFiles();
      setConfig({
        quota: configResponse.max_user_quota,
        used: response.reduce((acc, cur) => acc + cur.size, 0),
      });
      setMime(configResponse);
      setLoading(false);
    })();
  }, []);

  const selectedComponent = useMemo(() => {
    const Component = components[selected].component;
    if (loading) return <Loading />;

    return (
      <Component
        changeUsedSpace={changeUsedSpace}
        selected={selectedFile}
        setSelected={setSelectedFile}
        mimeTypes={mime}
      />
    );
  }, [
    selected,
    loading,
    mime,
    selectedFile,
    setSelectedFile,
    components,
    changeUsedSpace,
  ]);

  const handleChangeContent = (component) => {
    setSelected(component);
  };

  const handleSave = () => {
    setFile(selectedFile);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box className={classes.container}>
          <Box className={classes.header}>
            <Typography className={classes.title}>Gallery & Upload</Typography>
            <IconButton
              data-test-id="izxJGiCMht9mMgEbpoq0k"
              onClick={handleClose}
              size="large"
            >
              <Close style={{ fill: '#000000cc' }} />
            </IconButton>
          </Box>
          <Box className={classes.content}>
            <Box display="flex" flexDirection="column">
              <Box className={classes.menu}>
                {components.map((component, index) => (
                  <Button
                    data-test-id="pqhmplPnVLPe2iHyEPm1Z"
                    key={component.label}
                    className={clsx(classes.menuItem, {
                      [classes.selected]: selected === index,
                    })}
                    onClick={() => handleChangeContent(index)}
                    data-testid="select-component"
                  >
                    {component.icon}
                    {component.label}
                  </Button>
                ))}
                <Box className={classes.spaceUsed}>
                  {bytesToMB(config.used)} MB Used
                </Box>
              </Box>
              <Box className={classes.menuEnd}>
                <LinearProgress
                  variant="determinate"
                  value={(config.used / config.quota) * 100}
                />
                <Typography className={classes.availableText}>
                  {`${(100 - (config.used / config.quota) * 100).toFixed(
                    2
                  )}% (${bytesToMB(
                    config.quota - config.used
                  )}MB) storage available`}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.gallery}>{selectedComponent}</Box>
          </Box>
          <Box className={classes.footer}>
            <Button
              data-test-id="JFdxjUHeTtO2jUmmdxw_V"
              onClick={handleClose}
              className={clsx(classes.button, classes.cancelButton)}
            >
              cancel
            </Button>
            <Button
              data-test-id="-bKbpXXBV5zinPr6FwvLh"
              onClick={handleSave}
              data-testid="save-btn-gallery"
              className={clsx(classes.button, classes.saveButton)}
            >
              save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

GalleryModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setFile: PropTypes.func,
  file: PropTypes.array,
  components: PropTypes.array,
};

export default GalleryModal;
