import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import { List } from '@mui/material';

import _ from 'lodash';
import { MenuItems } from '../MenuItems';
import { useMenuContext } from '../../../../context/menuContext';
import useNotifications from '../../../../hooks/useNotifications';
import { saveCategory, saveItem } from '../../../../services/MenuServices';
import changeSortOrder from '../../../../utils/changeSortOrder';

function DroppableList({ list, activeItem }) {
  return (
    <Droppable
      droppableId={`categories-${JSON.stringify(list)}`}
      type={`droppableItem-${JSON.stringify(list)}`}
    >
      {(provided) => (
        <div ref={provided.innerRef}>
          <List>
            {list.map((itemObject, index) => (
              <MenuItems
                index={index}
                item={itemObject}
                key={itemObject.id}
                startOpen={activeItem === itemObject.id}
              />
            ))}
            {provided.placeholder}
          </List>
        </div>
      )}
    </Droppable>
  );
}

DroppableList.propTypes = {
  list: PropTypes.array.isRequired,
  activeItem: PropTypes.string.isRequired,
};

function ListComponent({ items }) {
  const { selectedRestaurant } = useMenuContext();
  const { enqueueSnackbar } = useNotifications();

  const [categoriesList, setCategoriesList] = useState(items);
  const [activeItem, setActiveItem] = useState('');

  const setItemsList = useCallback(
    (id, newItemsList) => {
      const categoryIndex = categoriesList.findIndex(
        (list) => String(list.id) === id
      );
      const newCategoriesList = [...categoriesList];
      newCategoriesList[categoryIndex] = {
        ...newCategoriesList[categoryIndex],
        items: newItemsList,
      };

      setCategoriesList(newCategoriesList);
    },
    [categoriesList]
  );

  const updateCategorySort = useCallback(
    async (categoriesList, category, sort) => {
      try {
        await saveCategory({
          restId: selectedRestaurant.id,
          id: category.id,
          category: {
            ...category,
            sort,
          },
        });
      } catch (err) {
        setCategoriesList(categoriesList);
        enqueueSnackbar({
          message: err.message,
          options: {
            variant: 'error',
          },
        });
      }
    },
    [selectedRestaurant, enqueueSnackbar]
  );

  const updateItemSort = useCallback(
    async (itemsList, item, sort, categoryId) => {
      try {
        await saveItem({
          restId: selectedRestaurant.id,
          menuId: item.menuId,
          id: item.id,
          item: {
            ...item,
            sort,
            prices: { takeout: { base: item.prices.base } },
            type: _.snakeCase(item.type.replace(/\s/g, '')),
          },
        });
      } catch (err) {
        setItemsList(categoryId, itemsList);
        enqueueSnackbar({
          message: err.message,
          options: {
            variant: 'error',
          },
        });
      }
    },
    [selectedRestaurant.id, setItemsList, enqueueSnackbar]
  );

  const onDragEndCategory = useCallback(
    async ({ source, destination }) => {
      if (destination.index === source.index) return null;

      const newCategoriesList = categoriesList.filter(
        (_, idx) => idx !== source.index
      );

      const sort = changeSortOrder(destination, source, categoriesList);

      newCategoriesList.splice(destination.index, 0, {
        ...categoriesList[source.index],
        sort,
      });

      setCategoriesList(newCategoriesList);

      updateCategorySort(
        categoriesList,
        categoriesList[source.index],
        destination.index
      );
    },
    [categoriesList, updateCategorySort]
  );

  const onDragEndItem = useCallback(
    async ({ source, destination, itemsList, id }) => {
      if (destination.index === source.index) return null;

      const newItemsList = itemsList.filter((_, idx) => idx !== source.index);

      const sort = changeSortOrder(destination, source, itemsList);

      newItemsList.splice(destination.index, 0, {
        ...itemsList[source.index],
        sort,
      });

      setItemsList(id, newItemsList);

      updateItemSort(itemsList, itemsList[source.index], destination.index, id);
    },
    [setItemsList, updateItemSort]
  );

  const onDragEnd = useCallback(
    async ({ source, destination }) => {
      if (destination === undefined || destination === null) return null;

      const droppableType = source.droppableId.split('-')[0];

      if (droppableType === 'categories') {
        setActiveItem('');
        await onDragEndCategory({ source, destination });
      }

      if (droppableType === 'items') {
        const itemsListId = source.droppableId.split('-')[1];
        setActiveItem(itemsListId);
        const itemsList = categoriesList.find(
          (list) => String(list.id) === itemsListId
        );

        await onDragEndItem({
          source,
          destination,
          itemsList: itemsList?.items,
          id: itemsListId,
        });
      }
    },
    [categoriesList, onDragEndCategory, onDragEndItem]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd} data-testid="dnd-context">
      <DroppableList
        list={categoriesList}
        activeItem={activeItem}
        key={JSON.stringify(categoriesList)}
      />
    </DragDropContext>
  );
}

ListComponent.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ListComponent;
