import { getUnixTime } from 'date-fns';
import { api } from './Api';
import { formatResponse } from '../utils/FormatUtils/formatResponse';

export const getOverview = async (id, { start, end }) => {
  const sales = await api.get(`/restmanager/${id}/report/sales`, {
    details: 1,
    interval: 'month',
    start: getUnixTime(start),
    end: getUnixTime(end),
    group: 'type',
  });
  const customers = await api.get(`/restmanager/${id}/report/customers`, {
    start: getUnixTime(start),
    end: getUnixTime(end),
  });
  const { config } = await api.get(`/restmanager/${id}/settings`);

  return formatResponse({
    data: {
      start,
      end,
      sales,
      customers,
      food_cost: config,
    },
    type: 'overview',
  });
};

export const getSalesTaxSummary = async (id, params) => {
  const response = await api.get(`/restmanager/${id}/report/sales/taxes`, {
    details: 1,
    interval: params.interval,
    sorting: 'desc',
    start: getUnixTime(params.start),
    end: getUnixTime(params.end),
  });

  return formatResponse({ data: { summary: response }, type: 'tax-summary' });
};

export const getSalesTaxTable = async ({ id, params, page }) => {
  const response = await api.get(`/restmanager/${id}/report/sales/taxes`, {
    details: 1,
    interval: params.interval,
    sorting: 'desc',
    start: getUnixTime(params.start),
    end: getUnixTime(params.end),
    page: Number(page),
  });

  return formatResponse({
    data: {
      table: { ...response, start: getUnixTime(params.start) },
    },
    type: 'tax-table',
  });
};

export const getStripeReconciliation = async (id, { start, end, page = 1 }) => {
  const response = await api.get(
    `/restmanager/${id}/report/reconciliation/stripe`,
    {
      start: getUnixTime(start),
      end: getUnixTime(end),
      details: 1,
      sorting: 'desc',
      page,
    }
  );
  return formatResponse({ data: response, type: 'stripe' });
};

export const getPOSReconciliation = async (id, { start, end, page = 1 }) => {
  const response = await api.get(
    `/restmanager/${id}/report/reconciliation/pos`,
    {
      start: getUnixTime(start),
      end: getUnixTime(end),
      details: 1,
      sorting: 'desc',
      page,
    }
  );

  return formatResponse({ data: response, type: 'pos' });
};

export const getSales = async (
  id,
  { start, end, interval = 'day', details = 0, group = 'type' },
  type
) => {
  const response = await api.get(`/restmanager/${id}/report/sales`, {
    details,
    interval,
    start: getUnixTime(start),
    end: getUnixTime(end),
    group,
  });

  return formatResponse({ data: { ...response, interval, start, end }, type });
};

export const getHighestSales = async (id, { start, end }, score, group) => {
  const response = await api.get(
    `/restmanager/${id}/report/sales/leaderboard`,
    {
      scope: 'item',
      start: getUnixTime(start),
      end: getUnixTime(end),
      group,
      limit: 10,
      score,
    }
  );

  return formatResponse({ data: response, type: 'highest-sale' });
};
