import { useEffect } from 'react';

export const useClickOutside = (insideRefs, isVisible, onClose) => {
  useEffect(() => {
    const handleWindowClick = (event) => {
      const someRefContainTarget = insideRefs.some((ref) => {
        if (ref?.current) return ref.current.contains(event.target);
      });

      if (someRefContainTarget) {
        return;
      }

      if (onClose) {
        onClose();
      }
    };

    if (isVisible) {
      window.addEventListener('click', handleWindowClick);
    }

    return () => {
      if (isVisible) {
        window.removeEventListener('click', handleWindowClick);
      }
    };
  }, [isVisible, onClose, insideRefs]);
};
