import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Component, useMenuContext } from '../../../context/menuContext';

import {
  POSReconciliation,
  StripeReconciliation,
  SalesTaxReport,
  Overview,
  SalesReport,
} from './ReportsSubItems';
import { useStyles } from './Reports.styles';
import { Loading } from '../../layout/Loading';
import { Error } from '../../layout/Error';
import { NoRestaurants } from '../../layout/NoRestaurants';
import { getSettings } from '../../../redux/ducks/RestaurantDucks';

export function Reports() {
  const classes = useStyles();
  const {
    selectedRestaurant,
    isLoading,
    error: restaurantError,
  } = useMenuContext();
  const dispatch = useDispatch();

  useEffect(() => {
    const loadSettingsData = async (id) => {
      if (!id) return;
      dispatch(getSettings({ id }));
    };

    loadSettingsData(selectedRestaurant?.id);
  }, [selectedRestaurant, dispatch]);

  if (isLoading) {
    return <Loading />;
  }

  if (restaurantError) {
    return <Error />;
  }

  if (!selectedRestaurant?.id) {
    return <NoRestaurants />;
  }

  return (
    <Box className={classes.reportsWrapper}>
      <Component
        components={[
          { label: 'Overview', component: <Overview /> },
          { label: 'Sales Tax Report', component: <SalesTaxReport /> },
          {
            label: 'Stripe Reconciliation',
            component: <StripeReconciliation />,
          },
          { label: 'POS Reconciliation', component: <POSReconciliation /> },
          { label: 'Sales Reports', component: <SalesReport /> },
        ]}
      />
    </Box>
  );
}
