import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { addMinutes } from 'date-fns';
import storage from '../../../utils/storage';
import { useStyles } from './LogoFavicon.styles';
import { LogoDropZone } from '../LogoDropZone/LogoDropZone';
import { FaviconDropZone } from '../FaviconDropZone/FaviconDropZone';
import { newDate } from '../../../utils/date-utils';

export function LogoFavicon({
  form: { getValues, mimeTypes, setValue },
  aboutUs,
}) {
  const classes = useStyles();
  const [logo, setLogo] = useState(
    getValues('logo')?.length > 0 ? getValues('logo') : []
  );
  const [aboutUsLogo, setAboutUsLogo] = useState(
    getValues('about_bg')?.length > 0 ? getValues('about_bg') : []
  );
  const [favicon, setFavicon] = useState(
    getValues('favicon')?.length > 0 ? getValues('favicon') : []
  );
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [aboutUsLogoLoaded, setAboutUsLogoLoaded] = useState(false);

  const handleDrop = async (data, cb, field) => {
    cb(data);
    setValue(field, data);
    await storage.set('restaurantInfo', getValues(), addMinutes(newDate(), 10));
  };

  const handleDeleteUpload = async (img, cb, field, cbLoad = () => {}) => {
    cb([]);
    setValue(field, []);
    cbLoad(false);
  };

  useEffect(() => {
    setLogo(getValues('logo') || []);
    setFavicon(getValues('favicon') || []);
    setAboutUsLogo(getValues('about_bg') || []);
    // eslint-disable-next-line
  }, [getValues('logo'), getValues('favicon'), getValues('about_bg')]);

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h6" className={classes.formTextFieldLabel}>
        {aboutUs ? 'About Us Image' : 'Restaurant Logo'}
      </Typography>
      {!aboutUs ? (
        <>
          <LogoDropZone
            logo={logo}
            setLogo={setLogo}
            setLogoLoaded={setLogoLoaded}
            handleDeleteUpload={handleDeleteUpload}
            handleDrop={handleDrop}
            mimeTypes={mimeTypes}
            logoLoaded={logoLoaded}
          />
          <Typography variant="h6" className={classes.formTextFieldLabel}>
            Restaurant Favicon
          </Typography>
          <FaviconDropZone
            favicon={favicon}
            setFavicon={setFavicon}
            handleDeleteUpload={handleDeleteUpload}
            handleDrop={handleDrop}
            mimeTypes={mimeTypes}
          />
        </>
      ) : (
        <LogoDropZone
          logo={aboutUsLogo}
          setLogo={setAboutUsLogo}
          setLogoLoaded={setAboutUsLogoLoaded}
          handleDeleteUpload={handleDeleteUpload}
          handleDrop={handleDrop}
          mimeTypes={mimeTypes}
          logoLoaded={aboutUsLogoLoaded}
        />
      )}
    </Box>
  );
}

LogoFavicon.propTypes = {
  form: PropTypes.object,
  aboutUs: PropTypes.bool,
};

export default LogoFavicon;
