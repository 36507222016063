import React from 'react';
import PropTypes from 'prop-types';
import { opacify } from 'polished';
import '@fontsource/montserrat';

import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from '@mui/material/styles';

export const theme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiButton: {
        root: {
          textTransform: 'none',
          //     letterSpacing: 0,
        },
      },
      MuiPickersDesktopDateRangeCalendar: {
        root: {
          display: 'block',
        },
        calendar: {
          minHeight: '254px',
        },
      },
      MuiTabs: {
        root: {
          borderBottom: '1px solid rgba(60, 85, 232, 0.05)',

          '& > div > div > button ': {
            background: 'rgba(60, 85, 232, 0.03)',
            marginRight: 4,
            borderRadius: '8px 8px 0 0',
            padding: '8px 12px',
            color: 'rgba(60, 85, 232, 0.7)',
          },
        },
      },
      MuiTab: {
        root: {
          minWidth: 0,

          '@media (min-width:600px)': {
            minWidth: 70,
          },
        },
      },
      MuiDropzoneArea: {
        root: {
          display: 'flex',
          minHeight: 0,
          color: '#00000099',
        },
        text: {
          fontSize: 16,
        },
        textContainer: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        icon: {
          width: 24,
          height: 24,
          marginLeft: 24,
        },
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: '#fff',
          },
        },
      },
      MuiTextField: {
        root: {
          '& label.Mui-focused': {
            color: '#3C55E8',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#3C55E8',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#0000001F',
            },
            '&:hover fieldset': {
              borderColor: '#0000003D',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#3C55E8',
            },
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#0058FF',
      },
      secondary: {
        main: '#FF28FF',
        dark: '#B418AE',
      },
      driverStatus: {
        busy: '#2176EA',
        free: '#FF28FF',
        soon: '#B418AE',
      },
      background: {
        default: '#FDFDFD',
        paper: '#FDFDFD',
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
      h1: {
        color: '#282B31',
        fontWeight: 500,
        fontSize: '2rem',
      },
      h2: {
        color: '#171725',
        fontWeight: 400,
        fontSize: '1.25rem',
      },
      h3: {
        color: '#171725',
        fontWeight: 600,
        fontSize: '1.25rem',
      },
      h4: {
        color: '#171725',
        fontWeight: 600,
        fontSize: '0.875rem',
      },
      h5: {
        fontWeight: 400,
        fontSize: '0.875rem',
        color: '#171725',
      },
      h6: {
        fontSize: '0.75rem',
        fontWeight: 400,
      },
      subtitle1: {
        color: '#282B31',
        opacity: 0.6,
      },
      subtitle2: {
        fontSize: '0.875rem',
        color: 'rgba(51, 51, 51, 0.57)',
        marginRight: '25px',
        fontWeight: 400,
      },
      body1: {
        fontSize: '0.875rem',
        color: '#282B31',
        fontWeight: 400,
      },
      body2: {},
      button: {},
      caption: {
        fontSize: '0.875rem',
        color: opacify(-0.2, '#282B31'),
        fontWeight: 500,
      },
    },
  })
);

export function Theme({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}

Theme.propTypes = {
  children: PropTypes.node,
};
