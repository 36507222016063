import React, { useEffect, useState } from 'react';
import { Button, Collapse, Typography } from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useStyles } from './MenuList.styles';
import List from '../List';
import { useItemContext } from '../../../../context/itemsContext/itemsContext';

export function MenuList({ menu }) {
  const classes = useStyles();
  const { expandAll, setExpandAll } = useItemContext();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (expandAll) setIsOpen(true);
  }, [expandAll]);

  return (
    <>
      <Button
        data-test-id="c81VZqdJXSSzG8WJvcqcI"
        className={classes.listButton}
        onClick={() => {
          setIsOpen(!isOpen);
          setExpandAll(false);
        }}
        data-testid="expand-button"
      >
        {isOpen ? (
          <ExpandMore
            style={{ fill: '#171725', opacity: '0.3', fontSize: '14px' }}
          />
        ) : (
          <ChevronRight
            style={{ fill: '#171725', opacity: '0.3', fontSize: '14px' }}
          />
        )}
        <Typography className={classes.listTitle}>{menu.name}</Typography>
      </Button>
      <Collapse
        in={isOpen}
        style={{
          paddingLeft: '16px',
          width: '100%',
          minHeight: 'auto !important',
        }}
      >
        <List type="item" items={menu.categories} />
      </Collapse>
    </>
  );
}

MenuList.propTypes = {
  menu: PropTypes.object,
};
