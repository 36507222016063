export const chartColor = [
  '#6854FF',
  '#FF82BA',
  '#00EFA1',
  '#1ECB40',
  '#0058FF',
  '#0058FF1A',
];

export const legends = [
  {
    toggleSerie: true,
    anchor: 'bottom',
    direction: 'row',
    justify: false,
    translateX: 0,
    translateY: 56,
    itemsSpacing: 0,
    itemWidth: 100,
    itemHeight: 18,
    itemTextColor: '#999',
    itemDirection: 'left-to-right',
    itemOpacity: 1,
    symbolSize: 18,
    symbolShape: 'circle',
    effects: [
      {
        on: 'hover',
        style: {
          itemTextColor: '#000',
        },
      },
    ],
  },
];
