import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemSecondaryAction,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { formatCurrencyValue } from '../../../../utils/FormatUtils/FormatUtils';

import { useStyles } from './DragAndDropItemsList.styles';

function DragAndDropItemsList({ handleOpenItem, selectedItem, items, id }) {
  const classes = useStyles();

  return (
    <Droppable droppableId={`items-${id}`} type={`droppableSubItem-${id}`}>
      {(provided) => (
        <div ref={provided.innerRef}>
          <List
            className={classes.list}
            style={{
              minHeight: `${items.length * 29 + 15}px`,
            }}
          >
            {items.map((item, index) => (
              <Draggable
                draggableId={String(item.id)}
                key={item.id}
                index={index}
              >
                {(providedItem) => (
                  <div ref={providedItem.innerRef}>
                    <ListItem
                      data-test-id="LKkKp1kI1BlZ-tEzpwl29"
                      key={item.id}
                      role={undefined}
                      className={clsx(classes.listButton, {
                        [classes.selectedButton]: selectedItem?.id === item.id,
                        [classes.deletedButton]: !item.available,
                        [classes.deletedSelectedButton]:
                          !item.available && selectedItem?.id === item.id,
                      })}
                      dense
                      button
                      ContainerComponent="li"
                      onClick={() => handleOpenItem(item)}
                      ContainerProps={{ ref: providedItem.innerRef }}
                      {...providedItem.draggableProps}
                      {...providedItem.dragHandleProps}
                    >
                      <Box
                        className={classes.itemButton}
                        data-testid={`item:list-item-${item.id}`}
                      >
                        <Typography
                          className={clsx(classes.listItem, {
                            [classes.selectedItem]:
                              selectedItem?.id === item.id,
                            [classes.deletedItem]: !item.available,
                            [classes.deletedSelected]:
                              !item.available && selectedItem?.id === item.id,
                          })}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          className={clsx(classes.listItem, {
                            [classes.selectedItem]:
                              selectedItem?.id === item.id,
                            [classes.deletedItem]: !item.available,
                            [classes.deletedSelected]:
                              !item.available && selectedItem?.id === item.id,
                          })}
                        >
                          {formatCurrencyValue(item.prices.base)}
                        </Typography>
                      </Box>
                      <ListItemSecondaryAction />
                    </ListItem>
                    {providedItem.placeholder}
                  </div>
                )}
              </Draggable>
            ))}
          </List>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

DragAndDropItemsList.propTypes = {
  handleOpenItem: PropTypes.func,
  selectedItem: PropTypes.object,
  items: PropTypes.array,
  id: PropTypes.string,
};

export default DragAndDropItemsList;
