import _ from 'lodash';

const changeSortOrder = (destination, source, list) => {
  const index =
    destination.index > source.index
      ? destination.index
      : destination.index - 1;
  const itemBefore = index >= 0 && !_.isNil(list[index]) ? list[index] : null;
  const sort =
    _.isNil(itemBefore) || _.isNil(itemBefore.sort) ? 0 : itemBefore.sort + 1;

  return sort;
};

export default changeSortOrder;
