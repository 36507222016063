import React, { useEffect, useState } from 'react';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { DatePicker as DatePickerUI } from '@mui/x-date-pickers';
import { Box, Button, Popover, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useStyles } from './DatePicker.styles';
import { startOfDay, endOfDay } from '../../../utils/date-utils';

export function DatePicker({ popoverEl, handleClosePopover, setParams }) {
  const [isOpen, setIsOpen] = useState(false);
  const [customDate, setCustomDate] = useState([null, null]);
  const classes = useStyles();

  const closeDatePicker = () => {
    setIsOpen(false);
    handleClosePopover();
  };

  const handleSave = () => {
    setParams({
      start: startOfDay(customDate[0]).getTime(),
      end: endOfDay(customDate[1]).getTime(),
      interval: 'day',
    });
    setCustomDate([null, null]);
    closeDatePicker();
  };

  const handleSelectedDate = (selectedDates) => {
    if (
      _.isArray(selectedDates) &&
      selectedDates.length === 2 &&
      !_.isNil(selectedDates[0])
    ) {
      const startDate = selectedDates[0];
      const endDate = _.isNil(selectedDates[1]) ? startDate : selectedDates[1];
      setCustomDate([startDate, endDate]);
    } else {
      const now = new Date();
      setCustomDate([now, now]);
    }
  };

  const cleanAndCloseModal = () => {
    closeDatePicker();
  };

  useEffect(() => {
    if (popoverEl) setIsOpen(true);
    else setIsOpen(false);
  }, [popoverEl]);

  return (
    <Popover
      open={isOpen}
      anchorEl={popoverEl}
      onClose={handleClosePopover}
      className={classes.popover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Box data-testid="custom-date" padding="16px">
        <Box className={classes.dateSelectContainer}>
          <DatePickerUI
            disableOpenPicker
            value={customDate?.[0]}
            renderInput={(props) => (
              <TextField
                {...props}
                fullWidth
                helperText=""
                variant="outlined"
                label="From"
                size="small"
                className={classes.dateSelectInput}
                data-test-id="from-text-field"
              />
            )}
          />
          <DatePickerUI
            disableOpenPicker
            value={customDate?.[1]}
            renderInput={(props) => (
              <TextField
                {...props}
                fullWidth
                helperText=""
                variant="outlined"
                label="End"
                size="small"
                className={classes.dateSelectInput}
              />
            )}
          />
        </Box>
        <StaticDateRangePicker
          displayStaticWrapperAs="desktop"
          calendars={1}
          value={customDate}
          className={classes.calendarWrapper}
          onChange={(newValue) => handleSelectedDate(newValue)}
        />
        <Box className={classes.buttonBox}>
          <Button
            data-test-id="LCpxWFHlM0AijniRtF9Xe"
            variant="outlined"
            color="inherit"
            className={classes.buttonBlue}
            onClick={handleSave}
            data-testid="save-button"
          >
            <Typography className={classes.buttonBlueText}>Save</Typography>
          </Button>
          <Button
            data-test-id="S_kjcidoO5jl6mHJrSM-8"
            variant="outlined"
            color="inherit"
            className={classes.button}
            onClick={cleanAndCloseModal}
            data-testid="cancel-button"
          >
            <Typography className={classes.buttonText}>Cancel</Typography>
          </Button>
        </Box>
      </Box>
    </Popover>
  );
}

DatePicker.propTypes = {
  popoverEl: PropTypes.object,
  handleClosePopover: PropTypes.func,
  setParams: PropTypes.func,
};
