import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  header: {
    justifyContent: 'space-between',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginBottom: '24px',
    [theme.breakpoints.down('md')]: {
      margin: '0 -15px 24px',
      background: 'white',
      boxShadow: '0 3px 6px #00000029',
      padding: '12px 16px',
      position: 'fixed',
      zIndex: '2',
      top: '80px',
      width: '100%',
    },
  },
  pageTitle: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  infoContainer: {
    padding: '24px',
    border: '1px solid #DADCE0',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,

    '&:not(:last-child)': {
      marginRight: '12px',
    },
  },
  infoWrapper: {
    display: 'flex',
    flex: '1',
    marginBottom: '20px',

    [theme.breakpoints.down('md')]: {
      padding: '4px 0',
      flexDirection: 'column',
      borderRadius: '10px',
      border: '1px solid #DADCE0',
    },
  },
}));
