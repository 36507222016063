import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import { DeleteOutline, FileCopy } from '@mui/icons-material';
import { useStyles } from './Gallery.styles';

export function DisplayFile({ selected, file, setSelected, deleteFile }) {
  const classes = useStyles();
  const [loadedImage, setLoadedImage] = useState(false);

  return (
    <Box className={clsx(classes.wrapper)}>
      <Box
        data-test-id="AuX0njBP4hFlOwzmfEOa-"
        display="block"
        height="0"
        alignSelf="flex-end"
        onClick={() => deleteFile()}
        style={{ cursor: 'pointer', height: '0' }}
        data-testid="delete-logo-image"
      >
        <Box className={classes.deleteLogo}>
          <DeleteOutline
            style={{
              fontSize: 14,
              fill: '#FF3100',
              filter: 'drop-shadow( 1px 1px 2px #fff)',
            }}
          />
        </Box>
      </Box>
      <Box
        data-test-id="3xDW12r-5Rzb_SyO1PBM7"
        onClick={setSelected}
        data-testid="select-image"
      >
        {file.mime.split('/')[0] === 'image' ? (
          <>
            {!loadedImage && (
              <Box style={{ height: '128px', width: '128px', display: 'flex' }}>
                <CircularProgress
                  style={{ margin: 'auto' }}
                  data-testid="progress"
                />
              </Box>
            )}
            <img
              src={file.url}
              alt=""
              onLoad={() => setLoadedImage(true)}
              className={clsx(
                classes.frame,
                { [classes.selected]: selected },
                { [classes.hide]: !loadedImage }
              )}
            />
          </>
        ) : (
          <Box
            style={{
              width: '128px',
              height: '128px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className={clsx(classes.frame, { [classes.selected]: selected })}
          >
            <FileCopy />
          </Box>
        )}
        <Box className={classes.fileNameWrapper}>
          <Box className={classes.fileName}>{file.id}</Box>
        </Box>
      </Box>
    </Box>
  );
}

DisplayFile.propTypes = {
  selected: PropTypes.bool,
  file: PropTypes.shape({
    url: PropTypes.string,
    id: PropTypes.string,
    mime: PropTypes.string,
  }),
  setSelected: PropTypes.func,
  deleteFile: PropTypes.func,
};
