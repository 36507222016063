import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Drawer, IconButton, Typography } from '@mui/material';
import {
  ExitToAppRounded,
  Menu as MenuIcon,
  Search,
  Restaurant,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { USER_LOGOUT } from '../../../constants/auth';
import { logoutRequest } from '../../../redux/ducks/UserDucks';
import { useStyles } from './Header.styles';
import { Menu } from '../Menu';
import useIsDesktop from '../../../hooks/useIsDesktop/useIsDesktop';
import { useMenuContext } from '../../../context/menuContext';

export function Header() {
  const { pathname } = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const logout = useCallback(() => {
    dispatch(logoutRequest(USER_LOGOUT));
  }, [dispatch]);
  const { pages, selectedPage } = useMenuContext();

  const title = {
    // "/reports": "Reports",
    '/menu': 'Menu',
    '/orders': 'Orders',
    '/settings': 'settings',
  };

  useEffect(() => {
    setOpenMenu(false);
  }, [pathname, selectedPage]);

  const isDesktop = useIsDesktop();

  const handleCloseDrawer = () => {
    setOpenMenu(false);
  };

  return (
    <>
      {isDesktop ? (
        <header className={classes.root}>
          <Box display="flex" alignItems="center">
            <Restaurant style={{ fill: '#0058FF', marginRight: '10px' }} />
            {/* <Link to="/reports" className={classes.link}> */}
            <Link to="/" className={classes.link}>
              <p className={classes.title}>
                EATZY <span>MY RESTAURANT</span>
              </p>
            </Link>
          </Box>
          <Button
            data-test-id="wkb6Br6MmiS2CHF6WWc8g"
            onClick={logout}
            className={classes.logOutButton}
          >
            Log out
            <ExitToAppRounded style={{ marginLeft: '14px', fill: '#7E84A3' }} />
          </Button>
        </header>
      ) : (
        <>
          <header className={classes.root}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flex="1"
            >
              <Box display="flex" alignItems="center">
                <IconButton
                  data-test-id="y6etlQGcfMQD0GHSlgptQ"
                  data-testid="menu-button"
                  className={classes.iconButton}
                  onClick={() => setOpenMenu(!openMenu)}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flex="1"
              >
                <Typography variant="h3" style={{ marginLeft: '20px' }}>
                  {pages[selectedPage]?.label || title[pathname]}
                </Typography>
                <IconButton size="large">
                  <Search />
                </IconButton>
              </Box>
            </Box>
          </header>
          <Drawer
            open={openMenu}
            onClose={handleCloseDrawer}
            classes={{ paperAnchorLeft: classes.drawer }}
            aria-label="drawer menu"
          >
            <Box
              display="flex"
              alignItems="center"
              marginTop="32px"
              marginBottom="24px"
              marginLeft="16px"
              justifyContent="space-between"
            >
              <Box display="flex">
                <Restaurant style={{ fill: '#0058FF', marginRight: '10px' }} />
                {/* <Link to="/reports" className={classes.link}> */}
                <Link to="/" className={classes.link}>
                  <p className={classes.title}>
                    EATZY <span>MY RESTAURANT</span>
                  </p>
                </Link>
              </Box>
              <Button
                data-test-id="NrXjIewICvjoryMTDFj-H"
                onClick={logout}
                className={classes.logOutButton}
              >
                <ExitToAppRounded />
              </Button>
            </Box>
            <Menu mobile />
          </Drawer>
        </>
      )}
    </>
  );
}
