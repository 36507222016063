import { getUnixTime, set } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { snakeToCamel } from '../utils/CaseUtils/CaseUtils';
import { api } from './Api';

export const getOrdersTable = async ({
  id,
  params,
  page,
  timezone,
  sorting = 'desc',
}) => {
  const response = await api.get(`/restmanager/${id}/orders`, {
    ...params,
    start: getUnixTime(
      zonedTimeToUtc(
        set(params.start, { hours: 0, minutes: 0, seconds: 0 }),
        timezone
      )
    ),
    end: getUnixTime(
      zonedTimeToUtc(
        set(params.end, { hours: 23, minutes: 59, seconds: 59 }),
        timezone
      )
    ),
    details: 1,
    page: Number(page),
    sorting,
  });

  return snakeToCamel(response);
};

export const getOrder = async ({ restaurantId, orderId }) => {
  const response = await api.get(
    `/restmanager/${restaurantId}/orders/${orderId}`
  );

  return snakeToCamel(response);
};

export const putErrorOrder = async ({ restaurantId, orderId, formError }) => {
  const response = await api.put(
    `/restmanager/${restaurantId}/orders/${orderId}/error`,
    formError
  );

  return snakeToCamel(response);
};

export const getErrorOrder = async ({ restaurantId, orderId }) => {
  const response = await api.get(
    `/restmanager/${restaurantId}/orders/${orderId}/error`
  );

  return snakeToCamel(response);
};
