import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Search from '../../layout/MenuComponents/Search';
import Types from '../../layout/MenuComponents/Types';
import typesMock from '../../../mocks/types.json';
import Content from '../../layout/MenuComponents/Content';
import NoContent from '../../layout/MenuComponents/NoContent';
import { useStyles } from './QuestionsPage.styles';
import useIsDesktop from '../../../hooks/useIsDesktop';
import { useQuestionsContext } from '../../../context/questionsContext';
import { Loading } from '../../layout/Loading';
import { Error } from '../../layout/Error';
import { useMenuContext } from '../../../context/menuContext';
import Question from '../../layout/MenuComponents/Question';

export function QuestionsList({ types, filterText }) {
  const classes = useStyles();
  const { questions } = useQuestionsContext();

  const shouldFilter = useCallback(
    (item) => {
      let searched = false;

      _.cloneDeepWith(item, (value, key) => {
        if (
          ['name', 'text'].includes(key) &&
          value.toLowerCase().includes(filterText.toLowerCase())
        ) {
          searched = true;
        }
      });

      const type = types.find(({ active }) => active).id;

      if (type === 'all') return searched;
      return item?.status === type && searched;
    },
    [types, filterText]
  );

  return (
    <Box className={classes.list}>
      {questions.map((item) => (
        <div key={item.id}>
          {shouldFilter(item) && <Question question={item} />}
        </div>
      ))}
    </Box>
  );
}

QuestionsList.propTypes = {
  types: PropTypes.array.isRequired,
  filterText: PropTypes.array.isRequired,
};

function QuestionsPage() {
  const isDesktop = useIsDesktop();
  const classes = useStyles();
  const [types, setTypes] = useState(typesMock.questions);
  const { selectedQuestion, getQuestions } = useQuestionsContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [filterText, setFilterText] = useState('');
  const { selectedRestaurant } = useMenuContext();

  useEffect(() => {
    if (selectedRestaurant) {
      setLoading(true);
      (async () => {
        try {
          await getQuestions(selectedRestaurant?.id);
        } catch {
          setError(true);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [getQuestions, selectedRestaurant]);

  const handleSearchInputKeyUp = _.debounce(({ value }) => {
    setFilterText(value);
  }, 300);

  const content = useMemo(
    () =>
      selectedQuestion ? (
        <Content question={selectedQuestion} />
      ) : (
        <NoContent />
      ),
    [selectedQuestion]
  );

  if (loading) return <Loading />;

  if (error)
    return (
      <Box flex="1" alignItems="center" justifyContent="center" display="flex">
        <Error />
      </Box>
    );

  return (
    <>
      {isDesktop ? (
        <Box className={classes.page} data-testid="desktop">
          <Box className={classes.left}>
            <Search
              placeholder="Search for question"
              onInputKeyUp={handleSearchInputKeyUp}
            />
            <Types types={types} onTypesChange={setTypes} />
            <Box className={classes.listContainer}>
              <QuestionsList types={types} filterText={filterText} />
            </Box>
          </Box>
          <Box className={classes.right}>{content}</Box>
        </Box>
      ) : (
        <Box className={classes.page} data-testid="mobile">
          <Box className={classes.divider} />
          {selectedQuestion ? (
            <Box>{content}</Box>
          ) : (
            <>
              <Types types={types} onTypesChange={setTypes} />
              <QuestionsList types={types} filterText={filterText} />
            </>
          )}
        </Box>
      )}
    </>
  );
}

export default QuestionsPage;
