// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { defaultStore } from '../../../redux/stores/GlobalStore';
import { Theme as CustomTheme } from '../Theme';
import { ErrorBoundary } from '../../../utils/errorHandler';

function Wrapper({ children, store }) {
  return (
    <ErrorBoundary>
      <CustomTheme>
        <Provider store={store}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            preventDuplicate
          >
            {children}
          </SnackbarProvider>
        </Provider>
      </CustomTheme>
    </ErrorBoundary>
  );
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  store: PropTypes.object,
};

Wrapper.defaultProps = {
  store: defaultStore,
};

export default Wrapper;
