import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, Typography } from '@mui/material';

import { useStyles } from './Type.styles';

function Type({ type, onTypeChange }) {
  const classes = useStyles();

  const handleTypeClick = useCallback(() => {
    const newType = {
      ...type,
      active: true,
    };
    onTypeChange(newType);
  }, [type, onTypeChange]);

  return (
    <Button
      data-test-id="5idkVtfJ_MDEYPctk7hQw"
      data-testid={`type:button-${type.id}`}
      className={clsx(classes.button, { [classes.buttonActive]: type.active })}
      onClick={handleTypeClick}
    >
      <Typography
        data-testid={`type:title-${type.id}`}
        className={clsx(classes.title, { [classes.titleActive]: type.active })}
      >
        {type.title}
      </Typography>
    </Button>
  );
}

Type.propTypes = {
  type: PropTypes.object.isRequired,
  onTypeChange: PropTypes.func.isRequired,
};

export default Type;
