import React from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

function Input({ control, name, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <TextField {...field} {...rest} />}
    />
  );
}

Input.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
};

export default Input;
