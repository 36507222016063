import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  rootbox: {
    // background:
    //   "url(https://cdn1.eegeo.com/blog-content/20170212-sitepoint/leaflet-osm.png)",
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  pageWrapper: {
    display: 'flex',
    flex: 1,
    // [theme.breakpoints.down("sm")]: {
    //   boxShadow: "0 3px 6px #00000029",
    // },
  },
}));
