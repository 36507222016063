import React from 'react';
import { Router, Switch } from 'react-router-dom';
import {
  CircularProgress,
  Typography,
  CssBaseline,
  Box,
  Hidden,
} from '@mui/material';

import Route from '../../navigation/Routes';
import history from '../../../utils/history';
import { useStyles } from './Root.styles';
import { LoginPage } from '../../page/LoginPage';
import { ForgotPasswordPage } from '../../page/ForgotPasswordPage';
import { ResetPasswordPage } from '../../page/ResetPasswordPage';
import { Reports } from '../../page/Reports';
import { NotFoundPage } from '../../page/NotFoundPage';
import { PlaceHolderPage } from '../../page/PlaceHolderPage';
import { Menu as MenuSection } from '../../page/Menu';
import { OrdersPage } from '../../page/OrdersPage';
import useAppLoading from '../../../hooks/useAppLoading';
import { MenuContextProvider } from '../../../context/menuContext';
import { Settings } from '../../page/Settings';

import { Header } from '../Header';
import { Menu } from '../Menu';

import Notifier from '../Notifier';

const showChart = process.env.REACT_APP_CHARTS_ACTIVE !== 'false';

export function WithHeader() {
  const classes = useStyles();
  return (
    <MenuContextProvider>
      <Box className={classes.root}>
        <Header />
        <Box className={classes.pageWrapper}>
          <Hidden mdDown>
            <Menu />
          </Hidden>
          <Route exact path="/" isPrivate component={PlaceHolderPage} />
          <Route path="/menu" isPrivate component={MenuSection} />
          {showChart && <Route path="/reports" isPrivate component={Reports} />}
          <Route path="/orders" isPrivate exact component={OrdersPage} />
          <Route path="/settings" isPrivate exact component={Settings} />
        </Box>
      </Box>
    </MenuContextProvider>
  );
}

export function Root() {
  const classes = useStyles();

  const { isAppLoading } = useAppLoading();

  if (isAppLoading) {
    return (
      <Box
        data-testid="apploading"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        display="flex"
        height="100%"
      >
        <Box marginBottom={2}>
          <CircularProgress />
        </Box>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.rootbox}>
        <CssBaseline />
        <Router history={history}>
          <Switch>
            <Route path="/login" exact component={LoginPage} />
            <Route
              path="/forgot-password"
              exact
              component={ForgotPasswordPage}
            />
            <Route path="/reset-password" exact component={ResetPasswordPage} />
            <Route
              path={['/', '/reports', '/orders', '/settings', '/menu']}
              component={WithHeader}
            />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Router>
      </Box>
      <Notifier />
    </>
  );
}
