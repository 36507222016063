import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../layout/Theme/Theme';

export const useStyles = makeStyles(() => ({
  contents: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '58px',
    marginLeft: '15px',
    flexDirection: 'column',
  },
  contentsMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    marginLeft: '15px',
    paddingBottom: '15px',
    flexDirection: 'row',
    marginBottom: '5px',
    marginTop: '80px',
    position: 'fixed',
    backgroundColor: 'white',
    zIndex: 5,
    boxShadow: '0 3px 3px #00000029',
  },
  titleContainer: {
    flex: '1 1 0',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 600 32px/48px',
    letterSpacing: '0.23px',
    color: '#282B31',
    opacity: 1,
  },
  filterContainer: {
    display: 'flex',
    height: '100%',
    flex: '1 0',
    justifyContent: 'space-evenly',
  },
  DropDownfilterContainer0: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '15px',
    maxHeight: '43px',
  },
  DropDownfilterContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: '15px',
  },
  filterItems: {
    width: '100%',
    paddingLeft: '8px',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    margin: '15px',
    '@media (max-width: 780px)': {
      overflow: 'scroll',
      margin: '0',
    },
  },
  search: {
    marginRight: '15px',
    marginLeft: '25px',
  },
  inputSearchContainer: {
    display: 'inline-flex',
    border: '1px solid #DADCE0',
    borderRadius: '12px',
    width: '100%',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 600 16px/25px',
    letterSpacing: '0.11px',
    color: 'rgba(23, 23, 37, 0.56)',
    maxWidth: '330px',
    padding: '0 15px',
    '@media (max-width: 780px)': {
      display: 'none',
    },
  },
  inputSearch: {
    height: '130%',
    width: '88%',
    justifyContent: 'center',
  },
  calendar: {
    marginLeft: '30px',
  },
  tableContainer: {
    marginBottom: '45px',
    '@media (max-width: 780px)': {
      padding: '15px',
      marginBottom: '15px',
    },
  },
  table: {
    minWidth: 900,
    borderCollapse: 'separate',
    borderSpacing: '0 6px',
  },
  tableRow: {
    marginBottom: '6px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tableHead: {
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 600 10px/16px',
    letterSpacing: '0.07px',
    color: '#171725',
    textTransform: 'uppercase',
    opacity: 0.6,
    border: 'none',
    padding: '5px 16px',
  },
  tableHeadMiddle: {
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 600 10px/16px',
    letterSpacing: '0.07px',
    color: '#171725',
    textTransform: 'uppercase',
    opacity: 0.6,
    border: 'none',
    padding: '5px 16px',
    '@media (max-width: 780px)': {
      paddingTop: '36px',
    },
  },
  sectionTitle: {
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 600 20px/30px',
    letterSpacing: '0.14px',
    color: '#171725',
    border: 'none',
    padding: '5px 16px',
  },
  sectionValue: {
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 600 20px/30px',
    letterSpacing: '0.14px',
    color: '#0058FF',
    border: 'none',
    padding: '5px 16px',
  },
  subSectionTitle: {
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 600 16px/25px',
    letterSpacing: '0.11px',
    color: '#171725',
    border: 'none',
    padding: '16px 16px 0 16px',
  },
  subSectionValue: {
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 600 16px/25px',
    letterSpacing: '0.11px',
    color: '#0058FF',
    border: 'none',
    padding: '16px 16px 0 16px',
  },
  tableRowLeft: {
    borderLeft: '1px solid #DADCE0',
    borderTop: '1px solid #DADCE0',
    borderBottom: '1px solid #DADCE0',
    padding: '5px 16px',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 600 14px/21px',
    letterSpacing: '0.1px',
    color: 'rgba(23, 23, 37, 0.56)',
  },
  tableRowCenter: {
    borderTop: '1px solid #DADCE0',
    borderBottom: '1px solid #DADCE0',
    padding: '5px 16px',
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 600 14px/21px',
    letterSpacing: '0.1px',
    color: 'rgba(23, 23, 37, 0.56)',
  },
  tableRowRight: {
    borderRight: '1px solid #DADCE0',
    borderTop: '1px solid #DADCE0',
    borderBottom: '1px solid #DADCE0',
    padding: '5px 16px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 600 14px/21px',
    letterSpacing: '0.1px',
    color: 'rgba(23, 23, 37, 0.56)',
  },
  numberOrders: {
    color: '#7D7D85',
  },
  tableService: {
    verticalAlign: 'middle',
    borderTop: '1px solid rgba(218, 220, 224, 1)',
    borderBottom: '1px solid rgba(218, 220, 224, 1)',
    padding: '5px 16px',
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 600 14px/21px',
    letterSpacing: '0.1px',
    color: 'rgba(23, 23, 37, 0.56)',
    textTransform: 'capitalize',
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    '@media (max-width: 780px)': {
      justifyContent: 'center',
      padding: '15px',
    },
  },
  popoverContainer: {
    padding: '20px',
  },
  buttonPopover: {
    border: '1px solid #DADCE0',
    borderRadius: '12px',
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 600 16px/25px',
    letterSpacing: '0.11px',
    color: '#171725',
    opacity: 0.8,
    width: '100%',
    maxWidth: '150px',
  },
  buttonDateFilter: {
    margin: '5px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#0058FF0F',
      color: '#0058FF',
    },
  },
  buttonPopoverMobile: {
    border: '0px !important',
    background: '#F5F5F5',
    height: '36px',
  },
  selectedButtonDateFilter: {
    margin: '5px',
    backgroundColor: '#0058FF0F',
    color: '#0058FF',
    border: '1px solid #0058ff',
  },
  popover: {
    marginTop: '6px',
  },
  dateSelectContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '40px',
  },
  dateSelectInput: {
    maxWidth: '144px',
    backgroundColor: '#00000005',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: 'auto',
  },
  button: {
    border: '1px solid #0000001F',
    borderRadius: '4px',
    opacity: 1,
    marginRight: '32px',
  },
  buttonText: {
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 400 14px/21px',
    letterSpacing: '0px',
    color: '#282B31',
    opacity: 1,
  },
  buttonBlue: {
    border: '1px solid #0000001F',
    borderRadius: '4px',
    opacity: 1,
    backgroundColor: '#0058FF',
    '&:hover': {
      backgroundColor: 'rgba(0, 88, 255, 0.5)',
    },
  },
  buttonBlueText: {
    color: '#FFFFFF',
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 400 14px/21px',
    letterSpacing: '0px',
    opacity: 1,
  },
  price: {
    color: '#171725',
    letterSpacing: '0.1px',
    opacity: 0.8,
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 600 14px/21px',
  },
  mobileResume: {
    display: 'none',
    '@media (max-width: 780px)': {
      width: '95%',
      display: 'flex',
      margin: '10px',
    },
  },
  mobileResumeMiddle: {
    display: 'none',
    '@media (max-width: 780px)': {
      width: '95%',
      display: 'flex',
      position: 'absolute',
      margin: 0,
      marginTop: '5px',
    },
  },
  mobileResumeRow: {
    '@media (max-width: 780px)': {
      display: 'flex',
      flex: '1',
      justifyContent: 'space-between',
    },
  },
  desktopOnly: {
    '@media (max-width: 780px)': {
      display: 'none',
    },
  },
  mobileOnly: {
    '@media (min-width: 780px)': {
      display: 'none',
    },
  },
  mobileSectionTitle: {
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 600 16px/25px',
    letterSpacing: '0.11px',
    color: '#171725',
  },
  mobileSubSectionValue: {
    fontFamily: theme.typography.fontFamily,
    font: 'normal normal 600 16px/25px',
    letterSpacing: '0.11px',
    color: '#0058FF',
  },
}));
