import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 32px',
    alignItems: 'center',
    background: 'white',
    boxShadow: '0 3px 6px #00000029',

    marginBottom: '24px',
    zIndex: 1,

    [theme.breakpoints.down('md')]: {
      border: 'none',
      display: 'flex',
      padding: '16px',
      background: 'white',
      alignItems: 'center',
      justifyContent: 'unset',
      marginBottom: '0px',
      boxShadow: 'none',
      position: 'fixed',
      width: '100%',
      zIndex: '3',
    },
  },
  logo: {
    height: 24,
    width: 24,
    marginRight: 8,
  },
  title: {
    fontSize: 14,
    fontWeight: 800,
    color: '#333',

    '& > span': {
      color: '#0058FF',
    },
  },
  logOutButton: {
    fontSize: 14,
    fontWeight: 600,
    borderRadius: 8,
    padding: '8px 16px',

    '&:hover': {
      background: 'none',
    },

    [theme.breakpoints.down('md')]: {
      padding: '2px 4px',
    },
  },
  link: {
    textDecoration: 'none',
  },

  iconButton: {
    padding: '0',
    margin: '0 12px',

    '& > svg': {
      fill: '#333',
    },
  },
  drawer: {
    marginTop: 8,
    borderTopRightRadius: 16,
    maxHeight: '100vh',
    paddingRight: 8,
  },
}));
