import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  showHoursContainer: {
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer',
  },
  showHoursContents: {
    border: '1px solid #DADCE0',
    padding: '24px',
    borderRadius: '10px',
    marginBottom: '24px',
  },
  presetHoursContainer: {
    alignContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    padding: '10px',
    marginBottom: '20px',
    border: '1px solid #DADCE0',
    borderRadius: '10px',
  },
  hoursSummaryBox: {
    marginTop: '40px',
    marginBottom: '20px',
    alignContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  presetHoursHeader: {
    marginTop: '10px',
    marginBottom: '20px',
    alignContent: 'left',
    textAlign: 'left',
    alignItems: 'left',
    justifyContent: 'left',
  },
  hoursActionButtonsBox: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignContent: 'center',
    textAlign: 'center',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    marginTop: '40px',
  },
  existingHourListBox: {
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    width: '100%',
  },
  existingHourBox: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignContent: 'center',
    textAlign: 'center',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    marginBottom: '32px',
    width: '100%',
  },
  existingHourActionBox: {
    justifyContent: 'right',
    flexDirection: 'row',
    alignContent: 'right',
    textAlign: 'right',
    display: 'flex',
    flex: '1',
    alignItems: 'right',
  },
  hoursActionButtonBox: {
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    width: '100%',
  },
  presetHoursCommonButtons: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignContent: 'center',
    textAlign: 'center',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    marginBottom: '40px',
  },
  presetHoursButton: {
    borderRadius: '10px',
    padding: '16px',
    width: '10%',
  },
  actionButton: {
    borderRadius: '10px',
    padding: '16px',
    width: '50%',
  },
  presetHourSummaryBox: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignContent: 'center',
    textAlign: 'center',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    marginTop: '16px',
    marginBottom: '16px',
  },
  presetHourSummaryBoxDateBox: {
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'left',
    alignContent: 'left',
    textAlign: 'left',
  },
  hoursList: {
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    width: '100%',
  },
  hoursAddControl: {
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    width: '100%',
    marginTop: '40px',
  },
}));
