import React from 'react';
import PropTypes from 'prop-types';
import DropdownFilter from '../../input/DropdownFilter/DropdownFilter';

export function Filter({ filter, setFilter, style, className }) {
  return (
    <DropdownFilter
      items={{
        filters: [
          'Total Sales',
          'Total Wholesale',
          'Total In-House',
          'Total Profit',
        ],
        filter,
        setFilter,
      }}
      onlyString
      style={style}
      className={className}
      buttonWidth="167px"
    />
  );
}

Filter.propTypes = {
  filter: PropTypes.string,
  className: PropTypes.string,
  setFilter: PropTypes.func,
  style: PropTypes.object,
};

export default Filter;
