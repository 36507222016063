import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexFlow: 'column',
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
  },
  divider: {
    display: 'flex',
    background: '#DADCE0',
    height: '1px',
    margin: '32px 0',
  },
  answersHeader: {
    fontSize: '20px',
    color: '#3E434D',
    fontWeight: '600',
    marginLeft: '12px',
    marginBottom: '24px',
  },
  unavailableItem: {
    position: 'relative',
    top: '10px',
    left: '24px',
    color: '#ff3100',
  },
  panel: {
    display: 'flex',
    flexFlow: 'column',
    minWidth: '565px',
    width: '100%',
    height: 'auto',
    padding: '24px',
    background: '#FFFFFF',
    border: '1px solid #EBEBEB',
    borderRadius: '12px',

    [theme.breakpoints.down('md')]: {
      minWidth: 'unset',
      padding: '24px 14px',
      marginBottom: '65px',
    },
  },
}));
