import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import { opacify } from 'polished';
import PropTypes from 'prop-types';

import { useStyles } from './CommonStyles.styles';
import { Filter } from './Filter';
import { chartColor } from '../../../utils/ChartUtils/chart-constants';

import { CenteredMetric } from '../../../utils/ChartUtils/chart-util';
import { Loading } from '../Loading';
import { Error } from '../Error';
import { getSales } from '../../../services/ReportService';
import { useMenuContext } from '../../../context/menuContext';
import { formatCurrencyValue } from '../../../utils/FormatUtils/formatCurrencyValue';

export function DayTime({ timeFilter }) {
  const classes = useStyles();
  const [filter, setFilter] = useState('Total Sales');
  const { selectedRestaurant } = useMenuContext();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const infoType = useMemo(
    () =>
      ({
        'Total Sales': 'sales',
        'Total Wholesale': 'wholesale',
        'Total In-House': 'inhouse',
        'Total Profit': 'profit',
      }[filter]),
    [filter]
  );

  useEffect(() => {
    if (selectedRestaurant?.id)
      (async () => {
        try {
          setError(false);
          setLoading(true);
          const response = await getSales(
            selectedRestaurant.id,
            {
              ...timeFilter,
              group: 'shift',
            },
            'sales-shift'
          );
          setData(response);
        } catch {
          setError(true);
        } finally {
          setLoading(false);
        }
      })();

    // eslint-disable-next-line
  }, [selectedRestaurant, timeFilter]);

  return (
    <>
      <Box className={classes.containerTitleWrapper}>
        <Typography className={classes.outsideContainerTitle}>
          By Day Time
        </Typography>
        <Filter filter={filter} setFilter={setFilter} />
      </Box>
      <Box className={classes.infoContainer}>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error ? (
              <Error />
            ) : (
              <>
                <Box
                  display="flex"
                  marginBottom="32px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h1">
                    {formatCurrencyValue(data[infoType].total)}
                  </Typography>
                  <Box>
                    {data[infoType].chart.map((shift) => (
                      <Box display="flex" marginBottom="12px" key={shift.id}>
                        <Typography
                          style={{
                            color: opacify(-0.6, '#282B31'),
                            fontSize: '12px',
                            fontWeight: '500',
                            marginRight: '4px',
                          }}
                        >
                          {shift.id}:
                        </Typography>
                        <Typography
                          style={{
                            color: opacify(-0.4, '#282B31'),
                            fontSize: '12px',
                            fontWeight: '500',
                          }}
                        >
                          {formatCurrencyValue(shift.total)}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box style={{ height: '300px' }}>
                  <ResponsivePie
                    data={data[infoType].chart}
                    colors={chartColor}
                    innerRadius={0.75}
                    cornerRadius={4}
                    enableRadialLabels={false}
                    enableSliceLabels={false}
                    margin={{ bottom: 56 }}
                    layers={[
                      'arcs',
                      'arcLabels',
                      'radialLabels',
                      'legends',
                      CenteredMetric,
                    ]}
                    legends={[
                      {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 10,
                        translateY: 56,
                        itemsSpacing: 0,
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 18,
                        symbolShape: 'circle',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemTextColor: '#000',
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}

DayTime.propTypes = {
  timeFilter: PropTypes.shape({
    start: PropTypes.object,
    end: PropTypes.object,
  }),
};

export default DayTime;
