import React, { useCallback, useEffect } from 'react';
import { get } from 'lodash';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import { Typography, Box, TextField } from '@mui/material';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '../Button';
import { useStyles } from './FooterInfoModal.styles';

const FormSchema = yup.object().shape({
  label: yup.string().required('Info label is required.'),
  info: yup.string().required('Info value is required.'),
});

function FooterInfoModal({
  editFooterInfo,
  isOpenModal,
  handleCloseModal,
  handleSaveFooterInfo,
}) {
  const classes = useStyles();

  const {
    control,
    getValues,
    formState: { errors },
    trigger,
    reset,
  } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(FormSchema),
  });

  useEffect(() => {
    if (editFooterInfo?.length) {
      reset({
        label: editFooterInfo[0],
        info: editFooterInfo[1],
      });
    } else {
      reset();
    }
  }, [editFooterInfo, reset, isOpenModal]);

  const getErrors = useCallback(
    (path) => ({
      error: !!get(errors, path),
      helperText: get(errors, path) && get(errors, path).message,
      objecto: get(errors, path),
    }),
    [errors]
  );

  const handleSave = async () => {
    const correctData = await trigger();
    if (correctData) {
      const { label, info } = getValues();
      handleSaveFooterInfo(label, info);
      handleCloseModal();
    }
  };

  return (
    <Modal
      open={isOpenModal}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      data-testid="add-info-modal"
    >
      <Box className={classes.modal}>
        <Box className={classes.titleModal}>
          <Typography className={classes.titleText}>Footer Info</Typography>
          <CloseIcon
            data-test-id="NteFrT2m7My9eY7mOyMwg"
            className={classes.closeIcon}
            onClick={handleCloseModal}
            data-testid="close-button"
          />
        </Box>
        <hr className={classes.hr} />
        <Box className={classes.contentModal}>
          <Box
            className={classes.inputContainer}
            style={{ marginTop: '0', width: '100%' }}
          >
            <Typography className={classes.label}>Info Label</Typography>
            <Controller
              name="label"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  placeholder="ex: address"
                  className={classes.input}
                  inputProps={{ 'data-testid': 'info-label-input' }}
                  {...getErrors('label')}
                />
              )}
            />
            <Typography className={classes.label} style={{ marginTop: '24px' }}>
              Info Value
            </Typography>
            <Controller
              name="info"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  placeholder="ex: 36 Kilingworth Turnpike, Clinton, CT 06413"
                  className={classes.areaInput}
                  inputProps={{ 'data-testid': 'info-value-input' }}
                  {...getErrors('info')}
                />
              )}
            />
            <Box className={classes.buttonBox}>
              <Button
                data-test-id="gYHfkNsgV4TQ0lX3T2Nbb"
                variant="outlined"
                color="inherit"
                className={classes.buttonBlue}
                onClick={handleSave}
                data-testid="save-footer-info-button"
              >
                <Typography className={classes.buttonBlueText}>Save</Typography>
              </Button>
              <Button
                data-test-id="F2Ja05VLsA1v1Dnk4FJrk"
                variant="outlined"
                color="inherit"
                className={classes.button}
                onClick={handleCloseModal}
                data-testid="cancel-footer-info-button"
              >
                <Typography className={classes.buttonText}>Cancel</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

FooterInfoModal.propTypes = {
  editFooterInfo: PropTypes.arrayOf(PropTypes.string),
  isOpenModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSaveFooterInfo: PropTypes.func.isRequired,
};

export default FooterInfoModal;
