import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  user: null,
  isLoading: false,
  isInitialLoading: true,
  error: null,
  resetRequested: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState: {
    ...INITIAL_STATE,
  },
  reducers: {
    signInRequest(state) {
      state.isLoading = true;
    },
    signInSuccess(state, action) {
      return {
        ...state,
        user: action.payload.user,
        isLoading: false,
        error: null,
      };
    },
    meRequest(state) {
      state.isLoading = true;
    },
    meSuccess(state, action) {
      return {
        ...state,
        user: action.payload.user,
        isLoading: false,
        error: null,
      };
    },
    initialLoadRequest(state) {
      state.isInitialLoading = true;
    },
    initialLoadUnauthenticated(state) {
      state.isInitialLoading = false;
    },
    initialLoadSuccess(state, action) {
      return {
        ...state,
        user: action.payload.user,
        isLoading: false,
        isInitialLoading: false,
        error: null,
      };
    },
    error(state, action) {
      state.error = action?.payload?.message || 'Unkown Error';
      state.isLoading = false;
      state.isInitialLoading = false;
    },
    clearErrors(state) {
      state.error = null;
      state.isLoading = false;
      state.isInitialLoading = false;
    },
    logoutRequest() {},
    logoutSuccess() {
      return {
        ...INITIAL_STATE,
        isInitialLoading: false,
      };
    },
    forgotPasswordRequest(state) {
      state.isLoading = true;
    },
    forgotPasswordSuccess(state) {
      return {
        ...state,
        isLoading: false,
        error: null,
        resetRequested: true,
      };
    },
    resetPasswordRequest(state) {
      state.isLoading = true;
    },
    resetPasswordSuccess(state) {
      return {
        ...state,
        isLoading: false,
        error: null,
        resetRequested: false,
      };
    },
  },
});

export const {
  signInRequest,
  signInSuccess,
  logoutRequest,
  logoutSuccess,
  error,
  meRequest,
  meSuccess,
  initialLoadRequest,
  initialLoadSuccess,
  initialLoadUnauthenticated,
  clearErrors,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  resetPasswordRequest,
  resetPasswordSuccess,
} = userSlice.actions;

export default userSlice.reducer;
