import { formatResponse } from '../utils/FormatUtils/formatResponse';
import { api } from './Api';

export const getMenuItems = async ({ restId }) => {
  const response = await api.get(`/restmanager/${restId}/menus`);
  const formattedResponse = formatResponse({
    data: response,
    type: 'items',
  });
  return formattedResponse;
};

export const getItem = async ({ restId, menuId, id }) => {
  const response = await api.get(
    `/restmanager/${restId}/menus/${menuId}/items/${id}`
  );
  const formattedResponse = formatResponse({
    data: { ...response },
    type: 'item',
  });
  return formattedResponse;
};

export const cloneItem = async ({ restId, menuId, id }) => {
  const response = await api.put(
    `/restmanager/${restId}/menus/${menuId}/items/${id}`
  );
  const formattedResponse = formatResponse({
    data: { ...response },
    type: 'item',
  });
  return formattedResponse;
};

export const deleteItem = async ({
  restId,
  menuId,
  id,
  start,
  end,
  permanent,
}) => {
  const response = await api.delete(
    `/restmanager/${restId}/menus/${menuId}/items/${id}`,
    {
      ...(start && { start }),
      ...(end && { end }),
      ...(permanent && { permanent }),
    }
  );
  return response;
};

export const saveItem = async ({ restId, menuId, id, item }) => {
  const {
    media,
    prices,
    description,
    name,
    category,
    service,
    preparation,
    type,
    meta_category,
    sort,
  } = item;
  await api.post(`/restmanager/${restId}/menus/${menuId}/items/${id}`, {
    description,
    sort,
    preparation,
    type,
    name,
    meta_category,
    category,
    price: prices.takeout.base,
    service,
    media: media?.length !== 0 ? media : {},
  });
  const formattedResponse = formatResponse({
    data: { ...item },
    type: 'item',
  });
  return formattedResponse;
};

export const saveCategory = async ({ restId, id, category }) => {
  const { name, sort } = category;

  await api.post(`/restmanager/${restId}/categories/${id}`, {
    sort,
    name,
  });
};

export const makeItemAvailable = async ({ restId, menuId, id, item }) => {
  await api.put(`/restmanager/${restId}/menus/${menuId}/items/${id}/restore`);
  const formattedResponse = formatResponse({
    data: { ...item, status: 'active', available: true },
    type: 'item',
  });
  return formattedResponse;
};
