import { takeEvery, call, put, takeLeading } from 'redux-saga/effects';
import {
  error,
  getSettings,
  setSettings,
  updateSettings,
  addTempClosingHours,
  requestTempClosingHours,
  setTempClosingHours,
  deleteTempClosingHour,
} from '../../ducks/RestaurantDucks';
import { enqueueSnackbar } from '../../ducks/SnackbarDucks';
import * as SettingsService from '../../../services/SettingsService';

function* fetchSettings(action) {
  try {
    const { id } = action.payload;
    const resp = yield call(SettingsService.getSettings, id);
    yield put(
      setSettings({
        settings: resp,
      })
    );
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: 'Something went wrong!',
        options: {
          variant: 'error',
        },
      })
    );
    yield put(error({ message: err.message }));
  }
}

function* updateSettingsInfo(action) {
  try {
    const { id, settings } = action.payload;
    const { updated } = yield call(
      SettingsService.updateSettings,
      id,
      settings
    );

    if (updated) {
      yield put(getSettings({ id }));
    } else {
      yield put(
        enqueueSnackbar({
          message: 'Something went wrong!',
          options: {
            variant: 'error',
          },
        })
      );
      yield put(error({ message: 'Something went wrong!' }));
    }
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: 'Something went wrong!',
        options: {
          variant: 'error',
        },
      })
    );
    yield put(error({ message: err.message }));
  }
}

function* getTempClosingHours(action) {
  try {
    const { restaurant } = action.payload;

    const response = yield call(SettingsService.getTempClosingHours, {
      restaurant,
    });

    yield put(setTempClosingHours(response));
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: 'Something went wrong!',
        options: {
          variant: 'error',
        },
      })
    );
    yield put(error({ message: err.message }));
  }
}

function* newTempClosingHours(action) {
  try {
    const { restaurant, start, end } = action.payload;
    yield call(SettingsService.addTempClosingHour, {
      restaurant,
      start: Math.round(start.getTime() / 1000),
      end: Math.round(end.getTime() / 1000),
    });

    yield put(
      enqueueSnackbar({
        message: 'Created',
        options: {
          variant: 'success',
        },
      })
    );

    const response = yield call(SettingsService.getTempClosingHours, {
      restaurant,
    });

    yield put(setTempClosingHours(response));
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: 'Something went wrong!',
        options: {
          variant: 'error',
        },
      })
    );
    yield put(error({ message: err.message }));
  }
}

function* removeTempClosingHour(action) {
  try {
    const { restaurant, hour } = action.payload;
    yield call(SettingsService.deleteTempClosingHour, {
      restaurant,
      hour,
    });

    yield put(
      enqueueSnackbar({
        message: 'Removed',
        options: {
          variant: 'success',
        },
      })
    );

    const response = yield call(SettingsService.getTempClosingHours, {
      restaurant,
    });

    yield put(setTempClosingHours(response));
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: 'Something went wrong!',
        options: {
          variant: 'error',
        },
      })
    );
    yield put(error({ message: err.message }));
  }
}

export default function* watchUser() {
  yield takeLeading(getSettings, fetchSettings);
  yield takeLeading(updateSettings, updateSettingsInfo);
  yield takeLeading(requestTempClosingHours, getTempClosingHours);
  yield takeLeading(addTempClosingHours, newTempClosingHours);
  yield takeLeading(deleteTempClosingHour, removeTempClosingHour);
}
