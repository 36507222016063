import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import {
  removeSnackbar,
  closeSnackbar as closeSnackbarAction,
} from '../../../redux/ducks/SnackbarDucks';

export let displayed = [];

export const resetDisplayed = () => {
  displayed = [];
};

export const storeDisplayed = (id) => {
  displayed = [...displayed, id];
};

export const removeDisplayed = (id) => {
  displayed = [...displayed.filter((key) => id !== key)];
};

function Notifier() {
  const dispatch = useDispatch();
  const notifications = useSelector((store) => store.snackbar.notifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const action = useCallback(
    (key) => (
      <IconButton
        data-test-id="CfKvV2oXKwdcinYmeLlhe"
        color="inherit"
        aria-label="notification close icon"
        onClick={() => {
          dispatch(closeSnackbarAction(key));
        }}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    ),
    [dispatch]
  );

  React.useEffect(() => {
    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) {
          console.warn('duplicate key');
          return;
        }

        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          ...options,
          action,
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            dispatch(removeSnackbar(myKey));
            removeDisplayed(myKey);
          },
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, action, dispatch]);

  return null;
}

export default Notifier;
