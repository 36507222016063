import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { get } from 'lodash';

import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import WeekHours from './WeekHours';
import { useStyles } from './WorkHours.style';

const shifSchema = yup.object().shape({
  open: yup.number().required().lessThan(yup.ref('close')),
  close: yup.number().required().moreThan(yup.ref('open')),
});

const partialSchema = yup.object().shape({
  open: yup.bool(),
  shift: yup
    .array()
    .when('open', { is: true, then: yup.array().of(shifSchema) }),
});

const FormSchema = yup.object().shape({
  monday: partialSchema,
  tuesday: partialSchema,
  wednesday: partialSchema,
  thursday: partialSchema,
  friday: partialSchema,
  saturday: partialSchema,
  sunday: partialSchema,
});

const dailyInitial = {
  open: true,
  shift: [
    {
      open: 480,
      close: 1320,
    },
  ],
};

export const initial = {
  monday: dailyInitial,
  tuesday: dailyInitial,
  wednesday: dailyInitial,
  thursday: dailyInitial,
  friday: dailyInitial,
  saturday: dailyInitial,
  sunday: dailyInitial,
};

export function WorkHours({
  title,
  value,
  disableSameToggle,
  sameToggleText,
  same,
  setSame,
  onChange,
  disabled,
  hoursSectionOpen,
}) {
  const classes = useStyles();
  const methods = useForm({
    shouldUnregister: false,
    defaultValues: value,
    resolver: yupResolver(FormSchema),
  });
  const {
    getValues,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    const newValue = { ...value };
    Object.keys(newValue).forEach((day) => {
      if (!newValue[day].shift && day !== 'same')
        newValue[day] = {
          open: newValue[day].open,
          shift: [...dailyInitial.shift],
        };
    });
    reset(newValue);
  }, [value, reset]);

  const getErrors = useCallback(
    (path) => ({
      error: !!get(errors, path),
      objecto: get(errors, path),
    }),
    [errors]
  );

  const formObj = { ...methods, getErrors };

  const handleAddShift = async (day) => {
    const newShift = getValues();
    if (newShift[`${day}`].shift) {
      newShift[`${day}`].shift.push({ open: 900, close: 1200 });
    } else {
      newShift[`${day}`].shift = [{ open: 900, close: 1200 }];
    }
    reset(newShift);
    onChange && onChange(newShift);
  };

  const handleRemoveShift = useCallback(
    (day, i) => {
      const newShift = getValues();
      newShift[`${day}`].shift.splice(i, 1);
      onChange && onChange(newShift);
      reset(newShift);
    },
    [getValues, reset, onChange]
  );

  const handleUpdateValues = () => {
    onChange && onChange(getValues());
  };

  return (
    <Box style={{ display: 'flex' }}>
      <Box flex={1}>
        <Box className={classes.boxContainer}>
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom={same ? '0' : '32px'}
            alignItems="center"
          >
            <Box className={classes.hoursHeader}>
              <Typography variant="h2" className={classes.formInsideTitle}>
                {title}
              </Typography>
            </Box>
            <Box>
              {!disableSameToggle && (
                <Box className={classes.sameSwitch}>
                  <Typography
                    variant="subtitle2"
                    className={classes.formInsideSubtitle}
                  >
                    {sameToggleText}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        name="sameToggle"
                        color="primary"
                        checked={same}
                        disabled={disabled}
                        onChange={(e) => setSame && setSame(e.target.checked)}
                      />
                    }
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Collapse in={!same}>
            <WeekHours
              disabled={disabled}
              handleUpdateValues={handleUpdateValues}
              form={formObj}
              handleAddShift={handleAddShift}
              handleRemoveShift={handleRemoveShift}
            />
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
}

WorkHours.propTypes = {
  value: PropTypes.object,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  same: PropTypes.bool,
  setSame: PropTypes.func,
  title: PropTypes.string,
  sameToggleText: PropTypes.string,
  disableSameToggle: PropTypes.bool,
  disabled: PropTypes.bool,
  hoursSectionOpen: PropTypes.bool,
};

WorkHours.defaultProps = {
  value: initial,
  isLoading: false,
  title: '',
  same: false,
  sameToggleText: 'Same as business hours',
  disableSameToggle: false,
  disabled: false,
  hoursSectionOpen: false,
};

export default WorkHours;
