/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fromUnixTime } from 'date-fns';
import WarningIcon from '@mui/icons-material/Warning';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import { Typography, Box, CircularProgress } from '@mui/material';

import Button from '../Button';
import {
  formatCurrencyValue,
  formatRelativeDate,
} from '../../../utils/FormatUtils/FormatUtils';
import AddErrorModal from '../AddErrorModal/AddErrorModal';
import { getErrorOrder, getOrder } from '../../../services/OrdersService';
import { useStyles } from './OrderModal.styles';
import { useMenuContext } from '../../../context/menuContext';
import OrderErrorsList from '../../layout/OrderErrorsList';

export function Loading() {
  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      minHeight="500px"
      alignItems="center"
    >
      <CircularProgress style={{ marginBottom: 5 }} />
      <Typography>Loading info...</Typography>
    </Box>
  );
}

export const recursivelyMap = (array, item, type = 'question') => {
  const childList = type === 'question' ? array.answers : array.questions;
  const children = childList?.filter(({ parent }) => parent === item.id);
  const child =
    type === 'question'
      ? {
          answers: children.map((value) =>
            recursivelyMap(array, value, 'answer')
          ),
        }
      : {
          subQuestions: children.map((value) =>
            recursivelyMap(array, value, 'question')
          ),
        };
  return {
    ...item,
    ...child,
  };
};

function OrderModal({
  isOpenModal,
  handleCloseModal,
  restaurant,
  selectedOrder,
}) {
  const classes = useStyles();
  const [isOpenAddErrorModal, setIsOpenAddErrorModal] = useState(false);
  const [isRefund, setIsRefund] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [orderErrors, setOrderErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedRestaurant } = useMenuContext();

  const recursivelyQuestions = useCallback(
    (answers) =>
      answers?.map((answer) => (
        <Fragment key={answer.id}>
          {answer.text}
          {answer.notes && `("${answer.notes}")`}
          <br />
          {answer.subQuestions?.map((subQuestion) => (
            <Box key={subQuestion.id} className={classes.subQuestion}>
              <br />
              <span className={classes.strongFont}>{subQuestion.text}</span>
              {subQuestion.answers.map((subAnswer) => (
                <Fragment key={subAnswer.id}>
                  <span>{subAnswer.text}</span>
                  {subAnswer.notes && `("${subAnswer.notes}")`}
                  {recursivelyQuestions(subAnswer.subQuestions)}
                </Fragment>
              ))}
            </Box>
          ))}
        </Fragment>
      )),
    [classes.strongFont, classes.subQuestion]
  );

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const response = await getOrder({
          restaurantId: restaurant?.id,
          orderId: selectedOrder,
        });
        const nestedResponse = {
          ...response,
          items: response.items.map((item) => {
            if (item.questions?.length) {
              return {
                ...item,
                questions: item.questions.map((question) =>
                  recursivelyMap(item, question)
                ),
              };
            }
            return item;
          }),
        };
        setOrderDetails(nestedResponse);

        const errors = await getErrorOrder({
          restaurantId: restaurant?.id,
          orderId: selectedOrder,
        });
        setOrderErrors(errors);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [selectedOrder, restaurant?.id]);

  const handleOpenAddErrorModal = (isRefund) => {
    setIsRefund(isRefund);
    setIsOpenAddErrorModal(true);
  };
  const handleCloseAddErrorModal = async () => {
    setIsOpenAddErrorModal(false);
    const errors = await getErrorOrder({
      restaurantId: restaurant?.id,
      orderId: selectedOrder,
    });
    setOrderErrors(errors);
  };

  return (
    <>
      <Modal
        open={isOpenModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-testid="order-modal"
        className={classes.modal}
      >
        <Box className={classes.modalContainer}>
          <Box className={classes.titleModal}>
            <Typography className={classes.orderInfo}>Order Info</Typography>
            <CloseIcon
              data-test-id="rj2TkZUSXx0J4aLPRi3Nz"
              className={classes.closeIcon}
              onClick={handleCloseModal}
              data-testid="close-order-modal"
            />
          </Box>
          <hr className={classes.hr} />
          {loading ? (
            <Loading />
          ) : (
            <>
              <Box className={classes.detailsModal}>
                <Button
                  data-test-id="L1a0btFSNOs_i9db1gnir"
                  variant="outlined"
                  color="inherit"
                  className={classes.button}
                  startIcon={<WarningIcon style={{ fontSize: 15 }} />}
                  onClick={() => handleOpenAddErrorModal(false)}
                  data-testid="open-add-error-modal"
                >
                  <Typography className={classes.buttonWithIcon}>
                    Add Error
                  </Typography>
                </Button>
                <Button
                  data-test-id="5MzFOG2DZq-1R4RqBITUT"
                  variant="outlined"
                  color="inherit"
                  className={classes.button}
                  startIcon={<MoneyOffIcon style={{ fontSize: 15 }} />}
                  onClick={() => handleOpenAddErrorModal(true)}
                  data-testid="open-issue-refund-modal"
                >
                  <Typography className={classes.buttonWithIcon}>
                    Issue Refund
                  </Typography>
                </Button>
              </Box>
              {orderErrors.length > 0 && (
                <Box>
                  <OrderErrorsList orderErrors={orderErrors} />
                </Box>
              )}
              <Box className={classes.flex}>
                <Box className={classes.customerInfoModal}>
                  <Typography className={classes.titleInfo}>
                    Customer Name
                  </Typography>
                  <Typography className={classes.descriptionInfo}>
                    {orderDetails.customer?.name}
                  </Typography>
                  <Typography className={classes.titleInfo}>
                    Customer Phone Number
                  </Typography>
                  <Typography className={classes.descriptionInfo}>
                    {orderDetails.customer?.phone}
                  </Typography>
                  {orderDetails.address && (
                    <>
                      <Typography className={classes.titleInfo}>
                        Delivery Address
                      </Typography>
                      <Typography className={classes.descriptionInfoMb}>
                        {orderDetails.address}
                      </Typography>
                    </>
                  )}
                  {orderDetails.driver?.name && (
                    <>
                      <Typography className={classes.titleInfo}>
                        Driver Name
                      </Typography>
                      <Typography className={classes.descriptionInfo}>
                        {orderDetails.driver.name}
                      </Typography>
                    </>
                  )}
                  {orderDetails.progress?.placed && (
                    <>
                      <Typography className={classes.titleInfo}>
                        Placed at
                      </Typography>
                      <Typography className={classes.descriptionInfo}>
                        {formatRelativeDate(
                          fromUnixTime(orderDetails.progress.placed),
                          selectedRestaurant?.timezone
                        )}
                      </Typography>
                    </>
                  )}
                  {orderDetails.progress?.received && (
                    <>
                      <Typography className={classes.titleInfo}>
                        Received at
                      </Typography>
                      <Typography className={classes.descriptionInfo}>
                        {formatRelativeDate(
                          fromUnixTime(orderDetails.progress.received),
                          selectedRestaurant?.timezone
                        )}
                      </Typography>
                    </>
                  )}
                  {orderDetails.progress?.confirmed && (
                    <>
                      <Typography className={classes.titleInfo}>
                        Confirmed at
                      </Typography>
                      <Typography className={classes.descriptionInfo}>
                        {formatRelativeDate(
                          fromUnixTime(orderDetails.progress.confirmed),
                          selectedRestaurant?.timezone
                        )}
                      </Typography>
                    </>
                  )}
                  {orderDetails.schedule?.pickup && (
                    <>
                      <Typography className={classes.titleInfo}>
                        Picked Up at
                      </Typography>
                      <Typography className={classes.descriptionInfo}>
                        {formatRelativeDate(
                          fromUnixTime(orderDetails.schedule.pickup),
                          selectedRestaurant?.timezone
                        )}
                      </Typography>
                    </>
                  )}
                  {orderDetails.progress?.completed && (
                    <>
                      <Typography className={classes.titleInfo}>
                        Delivered
                      </Typography>
                      <Typography className={classes.descriptionInfo}>
                        {formatRelativeDate(
                          fromUnixTime(orderDetails.progress.completed),
                          selectedRestaurant?.timezone
                        )}
                      </Typography>
                    </>
                  )}
                </Box>
                <hr className={classes.hr} />
                <Box className={classes.orderInfoModal}>
                  <Box className={classes.titleTable}>
                    <p className={classes.itemInfo}>Item info</p>
                    <p className={classes.basePrice}>Baseprice</p>
                    <p>Charged</p>
                  </Box>
                  <br />

                  {orderDetails.items?.map((item) => (
                    <Fragment key={item.id}>
                      <Box className={classes.itemDetails}>
                        <span className={classes.itemInfo}>
                          {item.amount || 1}x {item.name}
                        </span>
                        <span className={classes.basePrice}>
                          {formatCurrencyValue(item.price.base)}
                        </span>
                        <span className={classes.chargedPrice}>
                          {formatCurrencyValue(item.price.charged)}
                        </span>
                      </Box>
                      <br />
                      {item?.questions?.map((question) => (
                        <Box key={question.id} className={classes.obsContent}>
                          <hr className={classes.hr} />
                          <Box className={classes.obsQuestions}>
                            <span className={classes.strongFont}>
                              {question?.text}
                            </span>
                            {recursivelyQuestions(question.answers)}
                          </Box>
                        </Box>
                      ))}
                      {item?.notes && (
                        <Typography className={classes.comment}>
                          {`"${item?.notes}"`}
                        </Typography>
                      )}
                      <hr className={classes.hr} />
                    </Fragment>
                  ))}
                  <Box className={classes.orderPriceInfo}>
                    <Box className={classes.orderPriceDetails}>
                      <Typography className={classes.orderPriceDescription}>
                        Subtotal
                      </Typography>
                      <Typography className={classes.orderPriceValue}>
                        {formatCurrencyValue(orderDetails.subtotal)}
                      </Typography>
                    </Box>
                    {orderDetails?.fees?.map((fee) => (
                      <Box key={fee.id} className={classes.orderPriceDetails}>
                        <Typography className={classes.orderPriceDescription}>
                          {fee.name}
                        </Typography>
                        <Typography className={classes.orderPriceValue}>
                          {formatCurrencyValue(fee.amount)}
                        </Typography>
                      </Box>
                    ))}
                    {orderDetails?.taxes?.map((tax) => (
                      <Box key={tax.id} className={classes.orderPriceDetails}>
                        <Typography className={classes.orderPriceDescription}>
                          Sales Tax
                        </Typography>
                        <Typography className={classes.orderPriceValue}>
                          {formatCurrencyValue(tax.amount)}
                        </Typography>
                      </Box>
                    ))}
                    {orderDetails?.adjustments?.map((adjustment) => (
                      <Box
                        key={adjustment.id}
                        className={classes.orderPriceDetails}
                      >
                        <Typography className={classes.orderPriceDescription}>
                          {adjustment.name}
                        </Typography>
                        <Typography className={classes.orderPriceValue}>
                          {formatCurrencyValue(adjustment.amount)}
                        </Typography>
                      </Box>
                    ))}
                    <Box className={classes.orderPriceDetails}>
                      <Typography className={classes.orderPriceDescription}>
                        Total
                      </Typography>
                      <Typography className={classes.orderPriceValue}>
                        {formatCurrencyValue(orderDetails.total)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.mobileOnly}>
                  <hr className={classes.hr} />
                  <Box className={classes.mobileOnlyCloseContainer}>
                    <Button
                      data-test-id="-OYEz9QB5MjEhIxiZMyh9"
                      variant="outlined"
                      color="inherit"
                      className={classes.button}
                      onClick={handleCloseModal}
                      data-testid="close-modal"
                    >
                      <Typography>Close</Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Modal>
      {isOpenAddErrorModal && (
        <AddErrorModal
          isOpenModal={isOpenAddErrorModal}
          isRefund={isRefund}
          restaurantId={restaurant?.id}
          orderDetails={orderDetails}
          handleCloseModal={handleCloseAddErrorModal}
        />
      )}
    </>
  );
}

OrderModal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  restaurant: PropTypes.object.isRequired,
  selectedOrder: PropTypes.number.isRequired,
};

export default OrderModal;
