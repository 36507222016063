/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { addDays, addYears, fromUnixTime, isSameDay } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import PropTypes from 'prop-types';

import { Typography, Box, CircularProgress } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import Popover from '@mui/material/Popover';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SearchIcon from '@mui/icons-material/Search';
import { DriveEta, DirectionsWalk } from '@mui/icons-material';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';

import Button from '../../input/Button';
import {
  formatCurrencyValue,
  formatRelativeDate,
  formatRelativeDateOnly,
} from '../../../utils/FormatUtils/FormatUtils';
import OrderModal from '../../input/OrderModal/OrderModal';
import { useOrders } from './OrdersPage.hook';
import { useMenuContext } from '../../../context/menuContext';

import { useStyles } from './OrdersPage.styles';
import { Error } from '../../layout/Error/Error';
import { NoRestaurants } from '../../layout/NoRestaurants/NoRestaurants';
import DropDown from '../../input/DropDown/DropDown';
import useIsDesktop from '../../../hooks/useIsDesktop';
import { thisWeek, yesterday } from '../../../utils/date-utils';

const defaultServiceFilterForm = {
  delivery: true,
  takeout: true,
};

const defaultStatusFilterForm = {
  confirmed: false,
  completed: false,
  received: false,
  ready: false,
};

export function Header({
  setServiceFilterForm,
  serviceFilterForm,
  setStatusFilterForm,
  statusFilterForm,
  handleOpenPopover,
  filterDateButton,
  setSearchRequest,
}) {
  const isDesktop = useIsDesktop();
  const classes = useStyles();
  const { control, getValues } = useForm();

  const handleChangeServiceCheckbox = (event) => {
    setServiceFilterForm({
      ...serviceFilterForm,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeStatusRadio = (event) => {
    setStatusFilterForm({
      ...defaultStatusFilterForm,
      [event.target.name]: event.target.checked,
    });
  };

  const resetServiceFilters = () => {
    setServiceFilterForm(defaultServiceFilterForm);
  };

  const resetStatusFilters = () => {
    setStatusFilterForm(defaultStatusFilterForm);
  };

  return isDesktop ? (
    <Box className={classes.contents}>
      <Box className={classes.DropDownfilterContainer0}>
        <Box className={classes.titleContainer}>
          <Typography variant="h1" className={classes.title}>
            Orders
          </Typography>
        </Box>
        <Box className={classes.filterContainer}>
          <Button
            data-test-id="1T48iA7035Dj8xh41hQfR"
            variant="outlined"
            color="inherit"
            name="open-calendar"
            data-testid="open-calendar"
            className={classes.buttonPopover}
            onClick={handleOpenPopover}
            startIcon={<DateRangeIcon />}
          >
            {filterDateButton}
          </Button>
          <Box className={classes.inputSearchContainer}>
            <SearchIcon />
            <Controller
              control={control}
              name="search"
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.inputSearch}
                  id="standard-basic"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setSearchRequest(getValues('search'));
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    placeholder: 'Search for order, customer, etc.',
                  }}
                  inputProps={{
                    'data-testid': 'search-input',
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.DropDownfilterContainer}>
        <Typography>Filter by:</Typography>
        <DropDown title="Service" className={classes.marginLeft}>
          <FormControlLabel
            className={classes.filterItems}
            control={
              <Checkbox
                checked={serviceFilterForm.delivery}
                onChange={handleChangeServiceCheckbox}
                name="delivery"
                color="primary"
                size="small"
              />
            }
            label="Delivery"
          />
          <FormControlLabel
            className={classes.filterItems}
            control={
              <Checkbox
                checked={serviceFilterForm.takeout}
                onChange={handleChangeServiceCheckbox}
                name="takeout"
                color="primary"
                size="small"
              />
            }
            label="Takeout"
          />
          <Button
            data-test-id="YYrr9mG-VSY5m2QOHIoNI"
            className={classes.filterItems}
            onClick={resetServiceFilters}
            data-testid="reset-btn"
          >
            Reset filter
          </Button>
        </DropDown>
        <DropDown title="Status" className={classes.marginLeft}>
          <FormControlLabel
            className={classes.filterItems}
            control={
              <Radio
                checked={statusFilterForm.confirmed}
                onChange={handleChangeStatusRadio}
                name="confirmed"
                color="primary"
                size="small"
              />
            }
            label="Confirmed"
          />
          <FormControlLabel
            className={classes.filterItems}
            control={
              <Radio
                checked={statusFilterForm.completed}
                onChange={handleChangeStatusRadio}
                name="completed"
                color="primary"
                size="small"
              />
            }
            label="Completed"
          />
          <FormControlLabel
            className={classes.filterItems}
            control={
              <Radio
                checked={statusFilterForm.received}
                onChange={handleChangeStatusRadio}
                name="received"
                color="primary"
                size="small"
              />
            }
            label="Received"
          />
          <FormControlLabel
            className={classes.filterItems}
            control={
              <Radio
                checked={statusFilterForm.ready}
                onChange={handleChangeStatusRadio}
                name="ready"
                color="primary"
                size="small"
              />
            }
            label="Ready"
          />
          <Button
            data-test-id="c-pw7aqtKBzVMTC7isXx0"
            className={classes.filterItems}
            onClick={resetStatusFilters}
            data-testid="reset-btn"
          >
            Reset filter
          </Button>
        </DropDown>
      </Box>
    </Box>
  ) : (
    <Box style={{ marginTop: '80px' }} className={classes.contentsMobile}>
      <Button
        data-test-id="dYQXzTCHM7Xkhl29I18a8"
        variant="outlined"
        color="inherit"
        name="open-calendar"
        data-testid="open-calendar"
        className={classes.buttonPopoverMobile}
        onClick={handleOpenPopover}
      >
        {filterDateButton}
      </Button>
      <DropDown title="Service" className={classes.marginLeft}>
        <FormControlLabel
          className={classes.filterItems}
          control={
            <Checkbox
              checked={serviceFilterForm.delivery}
              onChange={handleChangeServiceCheckbox}
              name="delivery"
              color="primary"
              size="small"
            />
          }
          label="Delivery"
        />
        <FormControlLabel
          className={classes.filterItems}
          control={
            <Checkbox
              checked={serviceFilterForm.takeout}
              onChange={handleChangeServiceCheckbox}
              name="takeout"
              color="primary"
              size="small"
            />
          }
          label="Takeout"
        />
        <Button
          data-test-id="NsY2LZbjH85CMQQwYtU7n"
          className={classes.filterItems}
          onClick={resetServiceFilters}
          data-testid="reset-btn"
        >
          Reset filter
        </Button>
      </DropDown>
      <DropDown title="Status" className={classes.marginLeft}>
        <FormControlLabel
          className={classes.filterItems}
          control={
            <Radio
              checked={statusFilterForm.confirmed}
              onChange={handleChangeStatusRadio}
              name="confirmed"
              color="primary"
              size="small"
            />
          }
          label="Confirmed"
        />
        <FormControlLabel
          className={classes.filterItems}
          control={
            <Radio
              checked={statusFilterForm.completed}
              onChange={handleChangeStatusRadio}
              name="completed"
              color="primary"
              size="small"
            />
          }
          label="Completed"
        />
        <FormControlLabel
          className={classes.filterItems}
          control={
            <Radio
              checked={statusFilterForm.received}
              onChange={handleChangeStatusRadio}
              name="received"
              color="primary"
              size="small"
            />
          }
          label="Received"
        />
        <FormControlLabel
          className={classes.filterItems}
          control={
            <Radio
              checked={statusFilterForm.ready}
              onChange={handleChangeStatusRadio}
              name="ready"
              color="primary"
              size="small"
            />
          }
          label="Ready"
        />
        <Button
          data-test-id="ZRJmfWe6cEidLsXHLZadD"
          className={classes.filterItems}
          onClick={resetStatusFilters}
          data-testid="reset-btn"
        >
          Reset filter
        </Button>
      </DropDown>
    </Box>
  );
}

Header.propTypes = {
  setServiceFilterForm: PropTypes.func,
  serviceFilterForm: PropTypes.object,
  setStatusFilterForm: PropTypes.func,
  statusFilterForm: PropTypes.object,
  handleOpenPopover: PropTypes.func,
  filterDateButton: PropTypes.string,
  setSearchRequest: PropTypes.func,
};

export function Loading() {
  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      minHeight="500px"
      alignItems="center"
    >
      <CircularProgress style={{ marginBottom: 5 }} />
      <Typography>Loading info...</Typography>
    </Box>
  );
}

export function OrdersPage() {
  const classes = useStyles();
  const [serviceFilterForm, setServiceFilterForm] = useState(
    defaultServiceFilterForm
  );
  const [statusFilterForm, setStatusFilterForm] = useState(
    defaultStatusFilterForm
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchRequest, setSearchRequest] = useState('');
  const [filterDateButton, setFilterDateButton] = useState('This week');
  const [filterDateButtonBackup, setFilterDateButtonBackup] =
    useState('This week');
  const [filterDate, setFilterDate] = useState([
    thisWeek().start,
    thisWeek().end,
  ]);
  const [popoverEl, setPopoverEl] = useState(null);
  const [customDate, setCustomDate] = useState([null, null]);
  const [page, setPage] = useState(1);
  const [days, setDays] = useState([]);
  const {
    selectedRestaurant,
    isLoading,
    error: restaurantError,
  } = useMenuContext();

  const { data, totalPages, loading } = useOrders({
    id: selectedRestaurant?.id,
    params: {
      start: filterDate[0],
      end: filterDate[1],
      ...(Object.values(serviceFilterForm).some(Boolean) && {
        ...Object.keys(serviceFilterForm)
          .map((key) => (serviceFilterForm[key] ? key : null))
          .filter(Boolean)
          .reduce((result, item, index) => {
            result[`types[${index}]`] = item;
            return result;
          }, {}),
      }),
      ...(Object.values(statusFilterForm).some(Boolean) && {
        ...Object.keys(statusFilterForm)
          .map((key) => (statusFilterForm[key] ? key : null))
          .filter(Boolean)
          .reduce((result, item) => {
            result.status = item;
            return result;
          }, {}),
      }),
      ...(searchRequest && { search: searchRequest }),
      exclusive: Object.values(statusFilterForm).some(Boolean) ? 1 : 0,
    },
    page,
    timezone: selectedRestaurant?.timezone,
    enabled: Boolean(selectedRestaurant?.id),
  });

  useEffect(() => {
    setDays(
      data?.dailySummary
        ?.map((day) => {
          const orders = data.results.filter((result) =>
            isSameDay(
              utcToZonedTime(
                fromUnixTime(result.schedule?.pickup),
                selectedRestaurant?.timezone
              ),
              zonedTimeToUtc(
                addDays(filterDate[0], day.sort),
                selectedRestaurant?.timezone
              )
            )
          );
          return {
            day: zonedTimeToUtc(
              addDays(filterDate[0], day.sort),
              selectedRestaurant?.timezone
            ),
            earned: day.earned,
            numberOrders: day.orders,
            orders,
          };
        })
        .filter((day) => day.orders.length > 0)
        .reverse()
    );
  }, [data]);

  const handleOpenModal = (id) => {
    setSelectedOrder(id);
    setIsOpenModal(true);
  };

  const handleCloseModal = () => setIsOpenModal(false);

  const handleOpenPopover = (e) => setPopoverEl(e.currentTarget);

  const handleClosePopover = () => {
    if (filterDateButton === 'Custom') {
      if (filterDate !== customDate) {
        setFilterDateButton(filterDateButtonBackup);
      }
    }
    setPopoverEl(false);
  };

  const handleChangeFilterDate = (dateString, startDate, endDate) => {
    setFilterDateButton(dateString);
    setFilterDate([startDate, endDate]);
    setPage(1);
    handleClosePopover();
  };

  const cleanAndCloseModal = () => {
    if (filterDate[0] && filterDateButtonBackup === 'Custom') {
      setCustomDate(filterDate);
    } else {
      setCustomDate([null, null]);
    }
    setFilterDateButton(filterDateButtonBackup);
    handleClosePopover();
  };

  const handleSave = () => {
    if (!customDate[0] || !customDate[1]) {
      return;
    }
    setFilterDateButtonBackup('Custom');
    setFilterDate(customDate);
    setPage(1);
    setPopoverEl(false);
  };

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const isOpenPopover = Boolean(popoverEl);

  const id = isOpenPopover ? 'simple-popover' : undefined;

  if (restaurantError) return <Error />;

  if (!selectedRestaurant?.id) return <NoRestaurants />;

  return (
    <>
      <Box className={classes.main}>
        <Header
          setServiceFilterForm={setServiceFilterForm}
          serviceFilterForm={serviceFilterForm}
          setStatusFilterForm={setStatusFilterForm}
          statusFilterForm={statusFilterForm}
          handleOpenPopover={handleOpenPopover}
          filterDateButton={filterDateButton}
          setSearchRequest={setSearchRequest}
        />

        {loading || isLoading ? (
          <Loading />
        ) : (
          <>
            <Box
              className={classes.mobileResume}
              style={{ marginTop: '150px' }}
            >
              <Box className={classes.mobileResumeRow}>
                <Box>
                  <span className={classes.mobileSectionTitle}>Total </span>
                  <span className={classes.numberOrders}>
                    ({data?.totalResults} orders)
                  </span>
                </Box>
                <span className={classes.mobileSubSectionValue}>
                  {formatCurrencyValue(data?.totalEarned)}
                </span>
              </Box>
            </Box>

            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.desktopOnly}>
                  <TableRow>
                    <TableCell className={classes.tableHead} align="left">
                      ID
                    </TableCell>
                    <TableCell className={classes.tableHead} align="left">
                      Pick up time
                    </TableCell>
                    <TableCell className={classes.tableHead} align="left">
                      Service
                    </TableCell>
                    <TableCell className={classes.tableHead} align="left">
                      Customer
                    </TableCell>
                    <TableCell className={classes.tableHead} align="left">
                      Status
                    </TableCell>
                    <TableCell className={classes.tableHead} align="left">
                      Driver
                    </TableCell>
                    <TableCell className={classes.tableHead} align="right">
                      Order subtotal
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.desktopOnly}>
                  <TableRow>
                    <TableCell className={classes.sectionTitle} colSpan={6}>
                      Total{' '}
                      <span className={classes.numberOrders}>
                        ({data?.totalResults} orders)
                      </span>
                    </TableCell>
                    <TableCell
                      className={classes.sectionValue}
                      colSpan={6}
                      align="right"
                    >
                      {formatCurrencyValue(data?.totalEarned)}
                    </TableCell>
                  </TableRow>
                </TableBody>
                {days?.map((day, index) => (
                  <React.Fragment key={day.day}>
                    <TableBody className={classes.desktopOnly}>
                      <TableRow>
                        <TableCell
                          className={classes.subSectionTitle}
                          colSpan={6}
                        >
                          {formatRelativeDateOnly(
                            day.day,
                            selectedRestaurant?.timezone
                          )}{' '}
                          <span className={classes.numberOrders}>
                            ({day.numberOrders} orders)
                          </span>
                        </TableCell>
                        <TableCell
                          className={classes.subSectionValue}
                          colSpan={6}
                          align="right"
                        >
                          {formatCurrencyValue(day.earned)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <Box
                      className={classes.mobileResumeMiddle}
                      style={{ marginTop: index === 0 && '-70px' }}
                    >
                      <Box className={classes.mobileResumeRow}>
                        <Box>
                          <span className={classes.mobileSectionTitle}>
                            {formatRelativeDateOnly(
                              day.day,
                              selectedRestaurant?.timezone
                            )}{' '}
                          </span>
                          <span className={classes.numberOrders}>
                            ({day.numberOrders} orders)
                          </span>
                        </Box>
                        <span className={classes.mobileSubSectionValue}>
                          {formatCurrencyValue(day.earned)}
                        </span>
                      </Box>
                    </Box>

                    <TableHead className={classes.mobileOnly}>
                      <TableRow>
                        <TableCell
                          className={classes.tableHeadMiddle}
                          align="left"
                        >
                          ID
                        </TableCell>
                        <TableCell
                          className={classes.tableHeadMiddle}
                          align="left"
                        >
                          Pick up time
                        </TableCell>
                        <TableCell
                          className={classes.tableHeadMiddle}
                          align="left"
                        >
                          Service
                        </TableCell>
                        <TableCell
                          className={classes.tableHeadMiddle}
                          align="left"
                        >
                          Customer
                        </TableCell>
                        <TableCell
                          className={classes.tableHeadMiddle}
                          align="left"
                        >
                          Status
                        </TableCell>
                        <TableCell
                          className={classes.tableHeadMiddle}
                          align="left"
                        >
                          Driver
                        </TableCell>
                        <TableCell
                          className={classes.tableHeadMiddle}
                          align="right"
                        >
                          Order subtotal
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {day?.orders?.map((row, index) => (
                        <TableRow
                          data-test-id="v6Ka-VFb7pDwDGEFELFs3"
                          className={classes.tableRow}
                          key={row.id}
                          data-testid={`order-row-${index}`}
                          onClick={() => handleOpenModal(row.id)}
                        >
                          <TableCell
                            className={classes.tableRowLeft}
                            scope="row"
                            align="left"
                          >
                            #{row.id}
                          </TableCell>
                          <TableCell
                            className={classes.tableRowCenter}
                            align="left"
                          >
                            {formatRelativeDate(
                              fromUnixTime(row.schedule?.pickup),
                              selectedRestaurant?.timezone
                            )}
                          </TableCell>
                          <TableCell
                            className={classes.tableService}
                            align="left"
                          >
                            {row.type === 'takeout' ? (
                              <DirectionsWalk
                                style={{ verticalAlign: 'middle' }}
                              />
                            ) : (
                              <DriveEta style={{ verticalAlign: 'middle' }} />
                            )}
                            {row.type}
                          </TableCell>
                          <TableCell
                            className={classes.tableRowCenter}
                            align="left"
                          >
                            {row.customer?.name}
                          </TableCell>
                          <TableCell
                            className={classes.tableRowCenter}
                            align="left"
                            style={{ textTransform: 'capitalize' }}
                          >
                            {
                              Object.keys(row.progress).filter(
                                (key) =>
                                  row.progress[key] ===
                                  Math.max(...Object.values(row.progress))
                              )[0]
                            }
                          </TableCell>
                          <TableCell
                            className={classes.tableRowCenter}
                            align="left"
                          >
                            {row.driver?.name}
                          </TableCell>
                          <TableCell
                            className={classes.tableRowRight}
                            align="right"
                          >
                            <Typography className={classes.price}>
                              {formatCurrencyValue(row.total)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </React.Fragment>
                ))}
              </Table>
            </TableContainer>
            <Box className={classes.paginationContainer}>
              <Pagination
                page={page}
                count={totalPages}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
              />
            </Box>
          </>
        )}
      </Box>

      {isOpenModal && (
        <OrderModal
          isOpenModal={isOpenModal}
          handleCloseModal={handleCloseModal}
          restaurant={selectedRestaurant}
          selectedOrder={selectedOrder}
        />
      )}

      <Popover
        id={id}
        data-testid={id}
        open={isOpenPopover}
        anchorEl={popoverEl}
        onClose={handleClosePopover}
        className={classes.popover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.popoverContainer}>
          <Box>
            <Button
              data-test-id="FHlRcZpPThx9oZCJJyI9S"
              className={
                filterDateButton === 'Today'
                  ? classes.selectedButtonDateFilter
                  : classes.buttonDateFilter
              }
              onClick={() =>
                handleChangeFilterDate(
                  'Today',
                  thisWeek().start,
                  thisWeek().end
                )
              }
              data-testid="today-button"
            >
              Today
            </Button>
            <Button
              data-test-id="a5HpSzHw0LT7Str2WyccR"
              className={
                filterDateButton === 'Yesterday'
                  ? classes.selectedButtonDateFilter
                  : classes.buttonDateFilter
              }
              onClick={() =>
                handleChangeFilterDate(
                  'Yesterday',
                  yesterday().start,
                  yesterday().end
                )
              }
              data-testid="yesterday-button"
            >
              Yesterday
            </Button>
            <Button
              data-test-id="YU4MbbrlkysKPjygvbctC"
              className={
                filterDateButton === 'This week'
                  ? classes.selectedButtonDateFilter
                  : classes.buttonDateFilter
              }
              onClick={() =>
                handleChangeFilterDate(
                  'This week',
                  thisWeek().start,
                  thisWeek().end
                )
              }
              data-testid="this-week-button"
            >
              This week
            </Button>
            <Button
              data-test-id="yhfVETYsU8Imqtwae8T1d"
              className={
                filterDateButton === 'Custom'
                  ? classes.selectedButtonDateFilter
                  : classes.buttonDateFilter
              }
              data-testid="open-custom-date"
              onClick={() => setFilterDateButton('Custom')}
            >
              Custom
            </Button>
          </Box>
          {filterDateButton === 'Custom' && (
            <Box data-testid="custom-date">
              <Box className={classes.dateSelectContainer} />
              <StaticDateRangePicker
                displayStaticWrapperAs="desktop"
                calendars={1}
                maxDate={addYears(customDate?.[0], 1)}
                value={customDate}
                onChange={(newValue) => setCustomDate(newValue)}
              />
              <Box className={classes.buttonBox}>
                <Button
                  data-test-id="7Gde0zKG91qboI4WfxfZf"
                  variant="outlined"
                  color="inherit"
                  className={classes.buttonBlue}
                  onClick={handleSave}
                  data-testid="save-button"
                >
                  <Typography className={classes.buttonBlueText}>
                    Save
                  </Typography>
                </Button>
                <Button
                  data-test-id="Pb3fOCenFj89eecsaaYGC"
                  variant="outlined"
                  color="inherit"
                  className={classes.button}
                  onClick={cleanAndCloseModal}
                  data-testid="cancel-button"
                >
                  <Typography className={classes.buttonText}>Cancel</Typography>
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Popover>
    </>
  );
}
