import { useQuery } from 'react-query';
import { getOrdersTable } from '../../../services/OrdersService';

export const useOrders = ({ id, params, page, enabled, timezone }) => {
  const { data, isFetching, isError, refetch, ...others } = useQuery(
    ['orders', page, params],
    () => getOrdersTable({ id, params, page, timezone }),
    {
      placeholderData: [],
      keepPreviousData: true,
      enabled,
    }
  );

  return {
    data,
    totalPages: data?.totalPages,
    loading: isFetching,
    error: isError,
    refetch,
    ...others,
  };
};
