import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: 'repeat(5, auto)',
    justifyContent: 'start',
    maxHeight: '346px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  frame: {
    height: '128px',
    width: '128px',
    backgroundColor: '#FAFAFA',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    objectFit: 'contain',
  },
  selected: {
    border: '2px solid #3C55E8',
  },
  fileNameWrapper: {
    height: '0',
    width: '128px',
    position: 'relative',
    bottom: '24px',
  },
  fileName: {
    padding: '5px 6px',
    backgroundColor: 'rgba(0,0,0,0.3)',
    color: '#fff',
    fontSize: '10px',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  deleteLogo: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    padding: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0px 2px 0px 0px',
    background: 'rgba(255, 49, 0, 0.08)',

    '&:hover': {
      background: 'rgba(255, 49, 0, 0.18)',
    },
  },
  hide: {
    display: 'none',
  },
}));
