import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import DropDown from '../DropDown';

function DropdownFilter({
  items,
  className,
  buttonWidth = '141px',
  buttonHeight = '36px',
  onlyString,
  style,
  keepOpen = false,
}) {
  const handleSelect = (item, e) => {
    if (item?.open) {
      item.open(e);
      items.setFilter(item);
    } else if (onlyString) {
      items.setFilter(item);
    } else {
      items.setFilter({ label: item });
    }
  };

  return (
    <DropDown
      title={items.filter?.label ? items.filter?.label : items.filter}
      className={className}
      style={style}
      buttonWidth={buttonWidth}
      buttonHeight={buttonHeight}
      keepOpen={keepOpen}
    >
      {items.filters.map((item) => (
        <Button
          data-test-id="gebwUENaVyNhozXNKzZps"
          key={item}
          onClick={(e) => handleSelect(item, e)}
        >
          {item?.label ? item.label : item}
        </Button>
      ))}
    </DropDown>
  );
}

DropdownFilter.propTypes = {
  items: PropTypes.shape({
    filters: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          label: PropTypes.string,
          open: PropTypes.func,
          close: PropTypes.func,
          isOpen: PropTypes.bool,
        }),
      ])
    ),
    filter: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        open: PropTypes.func,
        close: PropTypes.func,
        isOpen: PropTypes.bool,
      }),
    ]),
    setFilter: PropTypes.func,
  }),
  className: PropTypes.string,
  buttonWidth: PropTypes.string,
  buttonHeight: PropTypes.string,
  keepOpen: PropTypes.bool,
  onlyString: PropTypes.bool,
  style: PropTypes.object,
};

DropdownFilter.defaultProps = {
  onlyString: false,
  style: {},
};

export default DropdownFilter;
