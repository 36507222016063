import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

import { Component, useMenuContext } from '../../../context/menuContext';
import { General, ManageHours, EditAppUser, Website } from './SettingsSubItems';
import { useStyles } from './Settings.styles';
import { getSettings } from '../../../redux/ducks/RestaurantDucks';
import { Loading } from '../../layout/Loading';
import { Error } from '../../layout/Error';
import { NoRestaurants } from '../../layout/NoRestaurants';

export function Settings() {
  const classes = useStyles();
  const {
    setPage,
    selectedPage,
    selectedRestaurant,
    isLoading,
    error: restaurantError,
  } = useMenuContext();
  const dispatch = useDispatch();

  const pages = useMemo(
    () => [
      {
        label: 'General',
        component: <General />,
      },
      {
        label: 'Stripe',
        component: <div />,
      },
      {
        label: 'Website',
        component: <Website />,
      },
      {
        label: 'Restaurant App Setup',
        component: <EditAppUser />,
      },
      {
        label: 'Manage Hours',
        component: <ManageHours />,
      },
    ],
    []
  );

  useEffect(() => {
    if (pages[selectedPage].label === 'Stripe') {
      setPage(0);
      window.open('https://dashboard.stripe.com/');
    }
  }, [selectedPage, pages, setPage]);

  useEffect(() => {
    const loadSettingsData = async (id) => {
      if (!id) return;
      dispatch(getSettings({ id }));
    };

    loadSettingsData(selectedRestaurant?.id);
  }, [selectedRestaurant, dispatch]);

  if (isLoading) {
    return <Loading />;
  }

  if (restaurantError) {
    return <Error />;
  }

  if (!selectedRestaurant?.id) {
    return <NoRestaurants />;
  }

  return (
    <Box className={classes.settingsWrapper}>
      <Component components={pages} />
    </Box>
  );
}

export default Settings;
