import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FileCopy } from '@mui/icons-material';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { DropZonePlaceHolder } from './DropZonePlaceHolder';
import { useStyles } from './DropZone.styles';
import { uploadFile } from '../../../../services/UploadService';
import useNotifications from '../../../../hooks/useNotifications/useNotifications';

export function DropZoneImage({
  changeUsedSpace,
  selected,
  setSelected,
  mimeTypes,
}) {
  const classes = useStyles();
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(false);
  const { enqueueSnackbar } = useNotifications();

  useEffect(() => setSelected([]), [setSelected]);

  const handleUploadFile = async (data) => {
    if (!data.length) {
      enqueueSnackbar({
        message: 'File type/size not supported.',
        options: {
          variant: 'error',
        },
      });
      return;
    }

    const isImageFile = data.reduce(
      (acc, file) => file.type.includes('image') && acc,
      true
    );

    if (!isImageFile) {
      enqueueSnackbar({
        message: 'The file has to be an image.',
        options: {
          variant: 'error',
        },
      });
      return;
    }

    setUploading(true);
    try {
      const resp = await uploadFile(data);
      enqueueSnackbar({
        message: 'File uploaded',
        options: {
          variant: 'success',
        },
      });
      setSelected([resp]);
      changeUsedSpace(resp.size);
    } catch {
      enqueueSnackbar({
        message: 'Error uploading file',
        options: {
          variant: 'error',
        },
      });
    } finally {
      setUploading(false);
    }
  };

  if (uploading)
    return (
      <Box className={classes.dropZone}>
        <CircularProgress />
        Uploading File...
      </Box>
    );

  if (selected.length)
    return (
      <Box className={classes.imageContainer}>
        {!error ? (
          <img
            src={selected[0]?.url}
            alt="uploaded"
            data-testid="uploaded"
            onError={() => setError(true)}
            type={selected[0]?.mime}
            className={classes.imageUploaded}
          />
        ) : (
          <Box data-testid="img-display-error" className={classes.imageError}>
            <FileCopy />
            {selected[0]?.mime}
          </Box>
        )}
      </Box>
    );

  return (
    <Box className={classes.dropZone}>
      <Dropzone
        onDrop={handleUploadFile}
        accept={mimeTypes?.allowed_mime_types}
        maxSize={mimeTypes?.max_user_quota}
        maxFiles={1}
      >
        {({ getRootProps, getInputProps }) => (
          <Box
            name="dropZone"
            className={classes.inputDrop}
            {...getRootProps()}
          >
            <input
              {...getInputProps()}
              name="dropZone"
              data-testid="dropzone"
            />
            <DropZonePlaceHolder />
          </Box>
        )}
      </Dropzone>
    </Box>
  );
}

DropZoneImage.propTypes = {
  mimeTypes: PropTypes.object,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  changeUsedSpace: PropTypes.func,
};
