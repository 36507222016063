import React, { useState, useEffect } from 'react';
import { opacify } from 'polished';
import { Box, Divider, Typography } from '@mui/material';
import { DirectionsCar, DirectionsWalk } from '@mui/icons-material';

import { BarChart } from '../Charts/Charts';
import { getSales } from '../../../services/ReportService';
import { useMenuContext } from '../../../context/menuContext';
import { Loading } from '../Loading';
import { Error } from '../Error';
import { today } from '../../../utils/date-utils';

import { formatCurrencyDisplay } from '../../../utils/FormatUtils/formatCurrencyValue';

import { useStyles } from './CommonStyles.styles';

export function TodayEarning() {
  const classes = useStyles();
  const { selectedRestaurant } = useMenuContext();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (selectedRestaurant?.id)
      (async () => {
        try {
          setError(false);
          setLoading(true);
          const response = await getSales(
            selectedRestaurant.id,
            today(),
            'sales-today'
          );
          setData(response);
        } catch (err) {
          setError(true);
        } finally {
          setLoading(false);
        }
      })();
  }, [selectedRestaurant]);

  return (
    <Box className={classes.infoContainer}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {error ? (
            <Error />
          ) : (
            <>
              <Box>
                <Typography className={classes.insideContainerTitle}>
                  Today’s Earnings
                </Typography>
                <Typography variant="h1">
                  {formatCurrencyDisplay(data.total)}
                </Typography>
              </Box>
              <Divider style={{ marginTop: '10px', marginBottom: '16px' }} />
              <Box display="flex" flex={1} style={{ marginBottom: '24px' }}>
                <Box flex={1} padding="8px 0">
                  <Box display="flex" alignItems="center" marginBottom="8px">
                    <DirectionsCar
                      style={{
                        fontSize: '16px',
                        fill: opacify(-0.4, '#282B31'),
                      }}
                    />
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontSize: '12px',
                        fontWeight: '500',
                      }}
                    >
                      Delivery
                    </Typography>
                  </Box>
                  <Typography variant="h1" style={{ fontSize: '18px' }}>
                    {formatCurrencyDisplay(data.delivery)}
                  </Typography>
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: '0 20px' }}
                />
                <Box flex={1} padding="8px 0">
                  <Box display="flex" alignItems="center" marginBottom="8px">
                    <DirectionsWalk
                      style={{
                        fontSize: '16px',
                        fill: opacify(-0.4, '#282B31'),
                      }}
                    />
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontSize: '12px',
                        fontWeight: '500',
                      }}
                    >
                      Takeout
                    </Typography>
                  </Box>
                  <Typography variant="h1" style={{ fontSize: '18px' }}>
                    {formatCurrencyDisplay(data.takeout)}
                  </Typography>
                </Box>
              </Box>
              <Box height="200px">
                <BarChart
                  data={data.chart}
                  keys={['Delivery', 'Takeout']}
                  colors={['#6854FF', '#FF82BA']}
                  gridY={false}
                  index="type"
                  axisBottom={null}
                  axisLeft={null}
                  margin={{ top: 0, right: 0, bottom: 108, left: 0 }}
                  layout="horizontal"
                  padding={0.2}
                  borderRadius={{
                    top: {
                      left: 4,
                      right: 4,
                    },
                    bottom: {
                      left: 4,
                      right: 4,
                    },
                  }}
                  maxValue={100}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'bottom-left',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 23,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  groupMode="stacked"
                  background
                  percentage
                />
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default TodayEarning;
