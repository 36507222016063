import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { getTimezoneOffset } from 'date-fns-tz';
import _ from 'lodash';
import { getRestaurants } from '../services/RestaurantServices';
import storage from '../utils/storage';

export const MenuContext = createContext({});

export const useMenuContext = () => useContext(MenuContext);

export function MenuContextProvider({ children }) {
  const [pages, setPages] = useState([]);
  const [selectedPage, setPage] = useState(0);
  const [selectedRestaurant, setSelectedRestaurant] = useState();
  const [restaurantsList, setRestaurantsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const component = useMemo(
    () => pages[selectedPage]?.component,
    [pages, selectedPage]
  );

  const reset = useCallback(() => {
    setPage(0);
    setPages([]);
  }, [setPages, setPage]);

  const handleSetSelectedRestaurant = useCallback(async ({ id }) => {
    try {
      setIsLoading(true);

      const response = await getRestaurants();
      setRestaurantsList(response);

      let restaurant = response.find((rest) => rest.id === id);
      if (_.isNil(restaurant)) {
        restaurant = response[0];
      }

      if (_.isNil(restaurant)) {
        setError(true);
      } else {
        setSelectedRestaurant(restaurant);

        // set timezone
        if (_.isNil(window.localTimezoneOffset)) {
          const now = new Date();
          window.localTimezoneOffset = now.getTimezoneOffset() * 60 * 1000 * -1;
        }

        window.businessTimezone = restaurant.timezone;
        window.businessTimezoneOffset = getTimezoneOffset(restaurant.timezone);

        setError(false);
      }
    } catch (err) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const id = await storage.get('selectedRestaurantId');
      await handleSetSelectedRestaurant({ id });
    })();
  }, [handleSetSelectedRestaurant]);

  return (
    <MenuContext.Provider
      value={{
        setPages,
        pages,
        selectedPage,
        setPage,
        component,
        reset,
        selectedRestaurant,
        restaurantsList,
        setSelectedRestaurant: handleSetSelectedRestaurant,
        isLoading,
        error,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}

MenuContextProvider.propTypes = {
  children: PropTypes.node,
};

export function Component({ components }) {
  const { component, setPages, reset } = useMenuContext();

  useEffect(() => {
    setPages(components);

    return () => reset();
    // eslint-disable-next-line
  }, []);

  return <>{component}</>;
}

Component.propTypes = {
  components: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      component: PropTypes.node,
    })
  ),
};
