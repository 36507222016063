import React from 'react';
import { Publish } from '@mui/icons-material';
import { useStyles } from './DropZone.styles';

export function DropZonePlaceHolder() {
  const classes = useStyles();
  return (
    <section className={classes.dropZonePlaceHolder}>
      <Publish style={{ fill: '#00000099' }} />
      Drag & Drop or Upload File
    </section>
  );
}
