import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  dropzoneLogoThumb: {
    display: 'inline-flex',
    borderRadius: '4px',
    maxWidth: '100%',
    boxSizing: 'border-box',
    flex: 1,
    background: '#FDFDFD',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  dropzoneImg: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '4px',
  },
  showImage: {
    display: 'none',
  },
  uploadLogo: {
    display: 'flex',
    position: 'relative',
    bottom: '44px',
    width: '100%',
    padding: '12px 0px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0px 0px 4px 4px',
    background: 'rgba(51, 51, 51, 0.16)',
    textShadow: '1px 1px 2px #fff',

    '&:hover': {
      background: 'rgba(51, 51, 51, 0.26)',
    },
  },
  dropzoneFaviconThumb: {
    height: 32,
    width: 32,
    display: 'inline-flex',
    borderRadius: '2px',
    border: '1px solid #e0e0e0',
    boxSizing: 'border-box',
    background: '#FDFDFD',
  },
  dropzoneImgWrapper: {
    marginTop: '8px',
    display: 'flex',
  },
  faviconImg: {
    objectFit: 'contain',
  },
  deleteLogo: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    padding: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0px 4px 0px 0px',
    background: 'rgba(255, 49, 0, 0.08)',

    '&:hover': {
      background: 'rgba(255, 49, 0, 0.18)',
    },
  },
  logoImage: {
    flex: '1',
    display: 'flex',
    maxWidth: '256px',
    marginBottom: '32px',
    minHeight: '256px',
    maxHeight: '256px',
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
    },
  },
  faviconDropzone: {
    display: 'flex',
    maxWidth: '256px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
    },
  },
  faviconLogo: {
    flex: '1',
    display: 'flex',
    width: '256px',
    maxHeight: '32px',
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      width: 'unset',
    },
  },
  deleteFavicon: {
    display: 'flex',
    padding: '9px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0px 4px 4px 0px',
    background: 'rgba(255, 49, 0, 0.08)',

    '&:hover': {
      background: 'rgba(255, 49, 0, 0.18)',
    },
  },
}));
