import makeStyles from '@mui/styles/makeStyles';
import { opacify } from 'polished';

export const useStyles = makeStyles({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 256,
    minHeight: '100%',
    borderRight: '1px solid #d5d5d4',
    background: '#fff',
    padding: '32px 16px 83px 0',
    marginTop: '-24px',
  },

  mobile: {
    border: 'none',
    padding: 0,
    paddingRight: 16,
    paddingBottom: 48,
    minHeight: '0',
  },

  listItem: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    padding: '0',
    background: 'rgba(0, 0, 0, 0.02)',

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.05)',
    },
  },
  listItemIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fill: '#000',
    marginRight: 8,
  },
  textMenu: {
    color: '#000',
    fontWeight: 500,
  },
  active: {
    background: opacify(-0.95, '#0058ff'),
    marginBottom: '0px',

    '& > div > div > svg': {
      fill: '#0058ff',
    },

    '& > div > div > p': {
      color: '#0058ff',
    },

    '&:hover': {
      background: opacify(-0.95, '#0058ff'),
    },
  },
  bar: {
    border: '2px solid #0058ff',
    borderRadius: '8px',
    visibility: 'hidden',
  },
  activeBar: {
    visibility: 'unset',
  },
  subItem: {
    marginBottom: '6px',
    padding: '6px 0px 6px 80px',
    borderRadius: '0 10px 10px 0',
    width: '100%',
    justifyContent: 'start',

    '&:hover': {
      '& > span > span': {
        color: '#282B31',
      },
    },
  },
  activeSubItem: {
    background: opacify(-0.95, '#0058FF'),
    '& > span > span': { color: '#0058FF' },

    '&:hover': {
      background: opacify(-0.95, '#0058FF'),
      '& > span > span': { color: '#0058FF' },
    },
  },
});
