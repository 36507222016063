import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    flexFlow: 'row',
    flex: '2',
    height: 'calc(100vh - 80px)',
    justifyContent: 'stretch',
    paddingBottom: '15px',

    [theme.breakpoints.down('md')]: {
      display: 'block',
      paddingBottom: '0px',
      marginTop: '90px',
    },
  },
  left: {
    display: 'flex',
    flex: '1 379px',
    flexFlow: 'column',
    width: '379px',
    height: 'auto',
  },
  right: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    height: '100%',
    marginLeft: '25px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
}));
