import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  filterWrapper: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
    },
  },
  dropdownFilter: {
    marginLeft: '24px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
    },
  },
  percentage: {
    '& .MuiOutlinedInput-root': {
      background: '#F5F5F5',
      fontWeight: '500',
      borderRadius: '8px',
      border: '0',
      width: '80px',
      color: '#282B31',
      '&:hover': {
        background: '#DADCE0',
      },
      '&:before': {
        border: '0',
      },
      '&:after': {
        border: '0',
      },
    },
    '& MuiFilledInput-underline:after': {
      border: '0 !important',
    },
  },
  sideComponent: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: '14px',

    [theme.breakpoints.down('md')]: {
      marginLeft: '10px',
    },
  },
  chartWrapper: {
    height: '354px',
    width: '100%',

    [theme.breakpoints.down('md')]: {
      minWidth: '850px',
    },
  },
  foodCostField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
}));
