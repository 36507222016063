import { useQuery } from 'react-query';
import { getSalesTaxTable } from '../../../../../services/ReportService';

export const useTableTax = ({ id, params, page }) => {
  const { data, isFetching, isError, refetch, ...others } = useQuery(
    ['table-tax', page, params],
    () => getSalesTaxTable({ id, params, page }),
    {
      placeholderData: [],
      keepPreviousData: true,
    }
  );

  return {
    data,
    totalPages: data.total_pages,
    loading: isFetching,
    error: isError,
    refetch,
    ...others,
  };
};
