import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from '@nivo/bar';
import { ResponsiveWrapper } from '@nivo/core';
import { CustomBar, CustomSymbolShape } from './BarChartComponents';

const defaultLegend = [
  {
    symbolShape: CustomSymbolShape,
    dataFrom: 'keys',
    anchor: 'top-left',
    direction: 'row',
    justify: false,
    translateX: 0,
    translateY: -50,
    itemsSpacing: 2,
    itemWidth: 100,
    itemHeight: 20,
    itemDirection: 'left-to-right',
    itemOpacity: 0.85,
    symbolSize: 14,
    effects: [
      {
        on: 'hover',
        style: {
          itemOpacity: 1,
        },
      },
    ],
  },
];

export function BarChart({
  data,
  background = false,
  keys,
  colors,
  gridY,
  index,
  axisBottom,
  axisLeft,
  margin = { top: 0, right: 0, bottom: 0, left: 0 },
  legends = defaultLegend,
  layout = 'vertical',
  padding = 0.3,
  borderRadius = { top: { left: 0, right: 0 }, bottom: { left: 0, right: 0 } },
  maxValue = 'auto',
  groupMode = 'grouped',
}) {
  return (
    <ResponsiveWrapper>
      {({ width, height }) => (
        <Bar
          width={width}
          height={height}
          barComponent={({ bar }) => (
            <CustomBar
              {...bar}
              background={background}
              backgroundHeight={height - margin.top - margin.bottom}
              backgroundWidth={width}
              borderRadius={borderRadius}
              layout={layout}
            />
          )}
          data={data}
          keys={keys}
          indexBy={index}
          groupMode={groupMode}
          margin={margin}
          padding={padding}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={colors}
          enableLabel={false}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={axisBottom}
          axisLeft={axisLeft}
          enableGridY={gridY}
          theme={{
            grid: {
              line: {
                strokeWidth: 2,
                strokeDasharray: '4 12',
              },
            },
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          layout={layout}
          legends={[{ ...legends[0], symbolShape: CustomSymbolShape }]}
          animate={false}
          isInteractive
          maxValue={maxValue}
        />
      )}
    </ResponsiveWrapper>
  );
}

const borderPropType = PropTypes.shape({
  left: PropTypes.number,
  right: PropTypes.number,
});

BarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  keys: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  gridY: PropTypes.bool,
  index: PropTypes.string,
  axisBottom: PropTypes.object,
  axisLeft: PropTypes.object,
  margin: PropTypes.object,
  legends: PropTypes.array,
  layout: PropTypes.string,
  padding: PropTypes.number,
  maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  background: PropTypes.bool,
  backgroundHeight: PropTypes.number,
  borderRadius: PropTypes.shape({
    top: borderPropType,
    bottom: borderPropType,
  }),
  groupMode: PropTypes.string,
};

export default BarChart;
