import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  list: {
    display: 'flex',
    padding: '8px 13px',

    [theme.breakpoints.down('md')]: {
      overflowX: 'auto',
      overflowY: 'hidden',
      scrollbarWidth: 'none',
    },
  },
}));
