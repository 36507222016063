import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Slider } from '../Slider/Slider';

export function HomePageSlide({
  form: { control, getValues, reset, getErrors, mimeTypes, setValue },
}) {
  const [tab, setTab] = useState(0);
  const slider = getValues('slider') ? getValues('slider') : [];
  const [files, setFiles] = useState(
    getValues('slider')
      ? getValues('slider').map((slide) =>
          slide.image ? slide.image[0] : undefined
        )
      : []
  );

  const handleSliderDrop = async (data, cb, i) => {
    cb((old) => {
      const newFiles = old;
      newFiles[i] = data[0];
      return newFiles;
    });
    setValue(`slider[${i}].image`, data);
  };

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  const handleAddSlider = async () => {
    const storageData = getValues();
    storageData.slider.push({
      sliderTitle: '',
      sliderSubtitle: '',
      image: [],
    });
    setTab(storageData.slider.length - 1);
    reset(storageData);
  };

  const handleRemoveSlider = useCallback(
    async (i) => {
      const storageData = getValues();
      const newImages = [...files];
      newImages.splice(i, 1);
      storageData.slider.splice(i, 1);
      i >= storageData.slider.length && setTab(storageData.slider.length - 1);
      reset(storageData);
      setFiles(newImages);
    },
    [files, reset, getValues]
  );

  return (
    <Slider
      getErrors={getErrors}
      form={{ control, setValue }}
      mimeTypes={mimeTypes}
      tab={tab}
      slider={slider}
      files={files}
      setFiles={setFiles}
      handleChange={handleChange}
      handleRemoveSlider={handleRemoveSlider}
      handleAddSlider={handleAddSlider}
      handleDrop={handleSliderDrop}
    />
  );
}

HomePageSlide.propTypes = {
  form: PropTypes.object,
};

export default HomePageSlide;
