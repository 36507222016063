import { formatRelative } from 'date-fns';
import { enUS } from 'date-fns/esm/locale';
import { utcToZonedTime } from 'date-fns-tz';

const formatRelativeLocale = {
  lastWeek: "dd MMM y'.'",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "dd MMM y'.'",
  nextWeek: "dd MMM y'.'",
  other: "dd MMM y'.'",
};

const locale = {
  ...enUS,
  formatRelative: (options) => formatRelativeLocale[options],
};

const formatRelativeDateOnly = (date, timezone) =>
  formatRelative(
    utcToZonedTime(date, timezone),
    utcToZonedTime(new Date(), timezone),
    {
      locale,
    }
  );

export default formatRelativeDateOnly;
