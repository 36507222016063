import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  formTitle: {
    marginBottom: 32,
  },
  formTextFieldLabel: {
    marginLeft: 16,
    marginBottom: 8,
  },
  formInputBox: {
    marginBottom: 24,
    '& > div > input': {
      fontSize: '16px',
      padding: '14px 16px',
    },
  },
  lowerCase: {
    '& > div > input': {
      textTransform: 'lowercase',
    },
  },
  ownerName: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > div:not(:first-child)': {
      marginLeft: '30px',
    },

    [theme.breakpoints.down('sm')]: {
      display: 'block',

      '& > div:not(:first-child)': {
        marginLeft: '0px',
      },
    },
  },
}));
