import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  button: {
    background: '#F5F5F5',
    border: '1px solid #F5F5F5',
    padding: '5px 10px',
    borderRadius: '6px',
    justifyContent: 'space-between',

    '&:hover': {
      border: '1px solid #DADCE0',

      '& > span > p': {
        color: '#000',
      },
    },
  },
  buttonLabel: {
    fontWeight: '500',
  },
}));
