import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  infoContainer: {
    padding: '24px',
    border: '1px solid #DADCE0',
    borderRadius: '10px',
    flex: 1,

    '&:not(:last-child)': {
      marginRight: '12px',
    },

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      borderRadius: '10px',
      border: 'none',
      padding: '16px 16px 0',

      '&:not(:last-child)': {
        marginRight: '0px',
      },

      '&:last-child': {
        paddingBottom: '16px',
      },
    },
  },
  infoText: {
    fontSize: '3rem',
    color: '#282B31',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
    },
  },
  secondaryInfoText: {
    color: '#282B31',
    fontWeight: 400,
    fontSize: '2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    },
  },
  percentage: {
    marginLeft: '12px',
    fontSize: '24px',
    opacity: '0.6',
    color: '#282B31',
    fontWeight: 'normal',
  },
  titleWrapper: {
    flex: '1.2',
    marginRight: '10px',
  },
  infoTitle: {
    fontSize: '1rem',
    color: '#282B31',
    opacity: '0.6',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
    },
  },
}));
