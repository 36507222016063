import React from 'react';
import PropTypes from 'prop-types';
import { Box, InputBase, TextField, Typography } from '@mui/material';

import { Controller } from 'react-hook-form';
import { useStyles } from './Main.styles';

function Main({ control }) {
  const classes = useStyles();

  return (
    <Box className={classes.main} data-testid="main-content">
      <Box className={classes.name} data-testid="name-div">
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className={classes.inputName}
              inputProps={{ 'data-testid': 'name-input' }}
              variant="outlined"
              label="Name"
            />
          )}
        />
      </Box>
      <Box className={classes.text} data-testid="text-div">
        <Box className={classes.labelText}>
          <Typography className={classes.labelFirstText}>Text&nbsp;</Typography>
          <Typography className={classes.labelSecondText}>
            (This is the question the customer will see)
          </Typography>
        </Box>
        <Controller
          name="text"
          control={control}
          render={({ field }) => (
            <InputBase
              {...field}
              className={classes.inputText}
              multiline
              rows={2}
            />
          )}
        />
      </Box>
    </Box>
  );
}

Main.propTypes = {
  control: PropTypes.object,
};

export default Main;
