import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography, Pagination } from '@mui/material';
import DropdownFilter from '../../../../input/DropdownFilter';
import { useStyles } from './SalesTaxReport.styles';
import { useStyles as useCommonStyles } from '../ReportSubItems.styles';
import InfoDisplay from '../../../../layout/InfoDisplay';
import { Loading } from '../../../../layout/Loading';
import { Error } from '../../../../layout/Error';
import { getSalesTaxSummary } from '../../../../../services/ReportService';
import { useMenuContext } from '../../../../../context/menuContext';
import { formatCurrencyDisplay } from '../../../../../utils/FormatUtils/formatCurrencyValue';
import { useTableTax } from './SalesTaxReport.hook';
import { DatePicker } from '../../../../input/DatePicker';
import {
  last12Months,
  lastMonth,
  lastYear,
  yearToDate,
} from '../../../../../utils/date-utils';

export function Table() {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const [filter, setFilter] = useState('Year to date');
  const [page, setPage] = useState(1);
  const { selectedRestaurant } = useMenuContext();

  const { data, loading, error, refetch, totalPages } = useTableTax({
    id: selectedRestaurant?.id,
    params: {
      'Year to date': yearToDate(),
      '12 months': last12Months(),
    }[filter],
    page,
  });

  if (error) {
    return (
      <>
        <Error />
        <Button
          data-test-id="NyLakPXVykMGIgnwufQCI"
          padding={2}
          onClick={refetch}
        >
          Retry
        </Button>
      </>
    );
  }
  const handleChangePage = (_, value) => {
    setPage(value);
  };

  return (
    <Box
      className={commonClasses.infoContainer}
      style={{ height: '518px', paddingBottom: '0px' }}
      marginBottom="20px"
    >
      <Box display="flex" justifyContent="space-between" marginBottom="32px">
        <Typography className={classes.taxesTitle}>Taxes</Typography>
        <DropdownFilter
          items={{
            filters: ['Year to date', '12 months'],
            filter,
            setFilter,
          }}
          style={{ marginLeft: '24px' }}
        />
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="20px"
          >
            <Box display="flex" justifyContent="space-between" flex="1">
              <Typography className={classes.taxesColumnTitle}>Date</Typography>
              <Typography className={classes.taxesColumnTitle}>
                Sales
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" flex="1">
              <Typography
                className={classes.taxesColumnTitle}
                style={{ textAlign: 'end' }}
              >
                Collected Amount
              </Typography>
            </Box>
          </Box>
          {data.table_info.map((info) => (
            <Box
              display="flex"
              justifyContent="space-between"
              marginBottom="12px"
              key={info.date}
            >
              <Box display="flex" justifyContent="space-between" flex="1">
                <Typography className={classes.taxesInfo}>
                  {info.date}
                </Typography>
                <Typography className={classes.taxesInfo}>
                  {formatCurrencyDisplay(info.sales)}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end" flex="1">
                <Typography
                  className={classes.taxesInfo}
                  style={{ textAlign: 'end', opacity: 1 }}
                >
                  {formatCurrencyDisplay(info.collected)}
                </Typography>
              </Box>
            </Box>
          ))}
        </>
      )}
      {totalPages > 0 && (
        <Pagination
          page={page}
          count={totalPages}
          size="small"
          onChange={handleChangePage}
          style={{ margin: '15px auto' }}
        />
      )}
    </Box>
  );
}

export function SalesTaxReport() {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [filter, setFilter] = useState({ label: 'Last month' });
  const { selectedRestaurant } = useMenuContext();
  const [loading, setLoading] = useState(true);
  const [reloading, setReloading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState();
  const [popoverEl, setPopoverEl] = useState(null);
  const [params, setParams] = useState(lastMonth());
  const filterBtnEl = useRef();

  const handlePopoverOpen = () => {
    setPopoverEl(filterBtnEl.current);
  };

  const handlePopoverClose = () => {
    setPopoverEl(null);
  };

  useEffect(() => {
    if (filter.label !== 'Custom...') {
      const params = {
        'Last month': lastMonth(),
        'Last year': lastYear(),
      }[filter.label];

      setParams(params);
    }
  }, [filter]);

  useEffect(() => {
    setReloading(true);
    if (selectedRestaurant?.id)
      (async () => {
        try {
          const summary = await getSalesTaxSummary(
            selectedRestaurant?.id,
            params
          );
          setData(summary);
          setLoading(false);
          setReloading(false);
        } catch (err) {
          setError(true);
          setLoading(false);
          setReloading(false);
        }
      })();
  }, [selectedRestaurant, params]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <>
      <Box flex="1">
        <Box className={commonClasses.header}>
          <Typography variant="h1" className={commonClasses.pageTitle}>
            Sales Tax Report
          </Typography>
          <Box ref={filterBtnEl}>
            <DropdownFilter
              items={{
                filters: [
                  'Last month',
                  'Last year',
                  {
                    label: 'Custom...',
                    open: handlePopoverOpen,
                    close: handlePopoverClose,
                  },
                ],
                filter,
                setFilter,
              }}
              className={classes.dropdownFilter}
            />
          </Box>
        </Box>
        <Box className={commonClasses.infoWrapper}>
          {reloading ? (
            <Box
              height="146px"
              display="flex"
              alignItems="center"
              flex={1}
              justifyContent="center"
            >
              <Loading />
            </Box>
          ) : (
            <>
              <InfoDisplay
                info={{
                  title: 'Sales',
                  info: formatCurrencyDisplay(data.info.sales),
                }}
              />
              <InfoDisplay
                info={{
                  title: 'Tax Collected',
                  info: formatCurrencyDisplay(data.info.collected),
                }}
              />
              <InfoDisplay
                info={{
                  title: 'To be Remitted',
                  info: formatCurrencyDisplay(data.info.remitted),
                }}
              />
            </>
          )}
        </Box>
        <Table />
      </Box>
      <DatePicker
        popoverEl={popoverEl}
        handleClosePopover={handlePopoverClose}
        setParams={setParams}
      />
    </>
  );
}

export default SalesTaxReport;
