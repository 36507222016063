import { api } from './Api';

export const getRestaurants = async () => {
  const response = await api.get('/restmanager/restaurants');
  return response;
};

export const getTemplate = async () => {
  const response = await api.get('/localareamng/restaurant/template');
  return response;
};

export const getBusiness = async () => {
  const response = await api.get('/localareamng/business/all');
  return response;
};

export const getRestaurantList = async ({ page }) => {
  const response = await api.get('/localareamng/restaurant/all', { page });
  return response;
};
