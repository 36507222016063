import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../layout/Theme/Theme';

export const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    '@media (max-width: 780px)': {
      flexDirection: 'column',
    },
  },
  modal: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflow: 'scroll',
    height: '100%',
    display: 'block',
  },
  modalContainer: {
    position: 'absolute',
    width: '70%',
    backgroundColor: 'white',
    padding: '24px',
    border: '1px solid #DADCE0',
    borderRadius: '4px',
    opacity: 1,
    top: '10%',
    left: '13%',
    height: '100%',
    overflow: 'scroll',
    '@media (max-width: 780px)': {
      left: '5%',
      width: '90%',
    },
  },
  titleModal: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '25px',
    alignItems: 'center',
  },
  orderInfo: {
    font: 'normal normal 600 24px/35px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.17px',
    color: '#282B31',
    opacity: 1,
  },
  closeIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  detailsModal: {
    marginTop: '25px',
    marginBottom: '15px',
    display: 'flex',
  },
  customerInfoModal: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '20px',
    paddingBottom: '20px',
    paddingTop: '20px',
  },
  orderInfoModal: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '20px',
    '@media (max-width: 780px)': {
      padding: '20px 0',
    },
  },
  titleTable: {
    display: 'flex',
    font: 'normal normal 600 10px/16px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.07px',
    color: '#282B31',
    textTransform: 'uppercase',
    opacity: 0.6,
    paddingLeft: '15px',
    '@media (max-width: 780px)': {
      paddingLeft: 0,
    },
  },
  itemInfo: {
    flex: 5,
    '@media (max-width: 780px)': {
      flex: 3,
    },
  },
  basePrice: {
    flex: 1,
  },
  titleInfo: {
    font: 'normal normal 600 12px/18px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.09px',
    color: '#282B31',
    opacity: 0.4,
  },
  itemDetails: {
    display: 'flex',
    font: 'normal normal 600 14px/21px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.1px',
    color: '#282B31',
    opacity: 1,
    marginTop: '5px',
    '@media (max-width: 780px)': {
      font: 'normal normal 600 12px/18px',
      fontFamily: theme.typography.fontFamily,
    },
  },
  chargedPrice: {
    font: 'normal normal bold 14px/21px',
    fontFamily: theme.typography.fontFamily,
    '@media (max-width: 780px)': {
      font: 'normal normal 600 12px/18px',
      fontFamily: theme.typography.fontFamily,
    },
  },
  obsContent: {
    display: 'flex',
    marginBottom: '14px',
    paddingLeft: '15px',
    font: 'normal normal normal 12px/18px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.09px',
    color: '#282B31',
    opacity: 0.6,
  },
  strongFont: {
    font: 'normal normal 600 12px/18px',
    fontFamily: theme.typography.fontFamily,
  },
  obsQuestions: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '5px',
  },
  subQuestion: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  descriptionInfo: {
    font: 'normal normal 600 14px/21px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.1px',
    color: '#282B31',
    opacity: 0.8,
    marginBottom: '12px',
  },
  descriptionInfoMb: {
    font: 'normal normal 600 14px/21px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.1px',
    color: '#282B31',
    opacity: 0.8,
    marginBottom: '28px',
    whiteSpace: 'pre',
  },
  hr: {
    color: '#282B31',
    opacity: 0.4,
  },
  button: {
    border: '1px solid #0000001F',
    borderRadius: '4px',
    opacity: 1,
    marginRight: '15px',
    minWidth: '119px',
    '@media (max-width: 780px)': {
      height: '32px',
    },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonWithIcon: {
    font: 'normal normal 600 14px/21px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0px',
    color: '#282B31',
    opacity: 1,
  },
  orderPriceInfo: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '15px',
    paddingTop: '10px',
  },
  orderPriceDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '6px',
  },
  orderPriceDescription: {
    font: 'normal normal 600 14px/21px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.1px',
    color: '#282B31',
    opacity: 0.4,
  },
  orderPriceValue: {
    font: 'normal normal 600 14px/21px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.1px',
    color: '#282B31',
    opacity: 1,
  },
  comment: {
    marginBottom: '14px',
    font: 'italic normal 600 12px/18px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.09px',
    color: '#282B31',
    opacity: '0.6',
    marginLeft: '15px',
  },
  mobileOnly: {
    '@media (min-width: 780px)': {
      display: 'none',
    },
  },
  mobileOnlyCloseContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '15px',
  },
}));
