export const config = {
  AUTH_ROLES_ALLOWED: JSON.stringify({
    RestaurantManager: ['Admin', 'Owner', 'Manager'],
    LocalAreaManager: ['Admin', 'Manager'],
  }),
  TOKEN_ERROR_MESSAGE: 'token not found',
};

export const getConfig = (key = '') => config[key];

export default config;
