import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { DisplayFile } from './DisplayFile';
import { useStyles } from './Gallery.styles';
import { deleteImage, getAllFiles } from '../../../../services/UploadService';
import useNotifications from '../../../../hooks/useNotifications';
import { Loading } from '../../Loading/Loading';

export function Gallery({ selected, setSelected, changeUsedSpace }) {
  const [files, setFiles] = useState([]);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { enqueueSnackbar } = useNotifications();

  const loadFiles = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getAllFiles();
      setFiles(response);
    } catch {
      setError(true);
      enqueueSnackbar({
        message: 'Error loading file',
        options: {
          variant: 'error',
        },
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadFiles();
    // eslint-disable-next-line
  }, []);

  const handleDelete = (id) => {
    (async () => {
      setDeleting(true);
      try {
        await deleteImage(id);
        changeUsedSpace(-files.find((file) => file.id === id).size);
        setFiles(files.filter((file) => file.id !== id));
        selected[0]?.id === id && setSelected([]);
        enqueueSnackbar({
          message: 'File deleted',
          options: {
            variant: 'success',
          },
        });
      } catch {
        enqueueSnackbar({
          message: 'Error deleting file',
          options: {
            variant: 'error',
          },
        });
      }
      setDeleting(false);
    })();
  };

  const handleSelectFile = (file) => {
    setSelected([file]);
  };

  if (deleting)
    return (
      <Box
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CircularProgress />
        Deleting File
      </Box>
    );

  if (loading) return <Loading />;

  if (error)
    return (
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        Error Loading Files.
        <Button
          data-test-id="cHh5ALbwkdVaPyG-nIQXu"
          style={{ marginLeft: '8px' }}
          onClick={loadFiles}
        >
          Retry
        </Button>
      </Box>
    );

  return (
    <Box className={classes.container}>
      {files.map((file) => (
        <DisplayFile
          key={file.id}
          selected={selected[0]?.id === file.id}
          setSelected={() => handleSelectFile(file)}
          deleteFile={() => handleDelete(file.id)}
          file={file}
        />
      ))}
    </Box>
  );
}

Gallery.propTypes = {
  files: PropTypes.array,
  setFiles: PropTypes.func,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  changeUsedSpace: PropTypes.func,
};
