import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  dropZone: {
    display: 'flex',
    border: '2px dashed #E0E0E0',
    backgroundColor: '#FAFAFA',
    borderRadius: 4,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '200px',
  },
  dropZonePlaceHolder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '500',
    color: '#00000099',
  },
  inputDrop: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flex: '1',
    cursor: 'pointer',
  },
  imageContainer: {
    height: 350,
    width: '100%',
  },
  imageUploaded: {
    objectFit: 'contain',
    height: '100%',
    width: '100%',
  },
  imageError: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px dashed #E0E0E0',
    backgroundColor: '#FAFAFA',
    borderRadius: 4,
    height: '200px',
    width: '100%',
  },
}));
