import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  rootcontainer: {
    flexGrow: 1,
    height: '100%',
    marginTop: ({ isDesktop }) => isDesktop && theme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0,
  },
  card: {
    borderWidth: ({ isDesktop }) => (isDesktop ? 1 : 0),
    borderRadius: theme.spacing(3),
  },
  cardContent: {
    padding: ({ isDesktop }) =>
      isDesktop ? theme.spacing(6, 5) : theme.spacing(4, 2),

    '&:last-child': {
      padding: ({ isDesktop }) =>
        isDesktop ? theme.spacing(6, 5) : theme.spacing(4, 2),
    },
  },
}));
