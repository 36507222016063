import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { cloneDeepWith } from 'lodash';
import PropTypes from 'prop-types';
import { getQuestions } from '../../services/QuestionServices';

export const INIT = {
  questions: [],
  selectedQuestion: null,
  formattedList: [],
  flatQuestionsList: [],
  getQuestions: () => {},
  setSelectedQuestion: () => {},
  setFormattedList: () => {},
  updateQuestionAnswer: () => {},
  getQuestionAnswer: () => {},
  deleteQuestionAnswer: () => {},
  insertQuestionAnswer: () => {},
};

export const QuestionsContext = createContext(INIT);

export const useQuestionsContext = () => useContext(QuestionsContext);

export function QuestionsContextProvider({ children }) {
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [formattedList, setFormattedList] = useState([]);
  const [flatQuestionsList, setFlatQuestionsList] = useState([]);

  const handleGetQuestions = useCallback(async (restaurantId) => {
    const resp = await getQuestions({ restId: restaurantId });
    setQuestions(resp);
    return resp;
  }, []);

  const flatternQuestionTree = (arrayOftrees) => {
    const flatList = [];
    arrayOftrees.forEach((questionTree) => {
      cloneDeepWith(questionTree, (question) => {
        if (question?.type === 'question') flatList.push(question);
      });
    });
    return flatList;
  };

  const updateQuestionAnswer = useCallback(
    (rootId, id, newProperties) => {
      const updateNodeInTree = (parentNode, nodeId, newNode) => {
        const newTree = { ...parentNode };
        if (parentNode.id == nodeId) {
          return {
            ...parentNode,
            ...newNode,
          };
        }
        if (newTree.children.length > 0) {
          newTree.children = newTree.children
            .map((node) => updateNodeInTree(node, nodeId, newNode))
            .sort((a, b) => a.sort - b.sort);
        }
        return newTree;
      };
      let rootQuestion;

      const filteredQuestions = questions.filter((q) => {
        if (q.id === rootId) rootQuestion = { ...q };
        return q.id !== rootId;
      });

      const newRootQuestion = updateNodeInTree(rootQuestion, id, newProperties);

      setQuestions([newRootQuestion, ...filteredQuestions]);
    },
    [questions]
  );

  const getQuestionAnswer = useCallback(
    (rootId, id) => {
      const getNodeFromTree = (parentNode, nodeId) => {
        if (parentNode.id === nodeId) {
          return parentNode;
        }
        if (parentNode.children.length > 0) {
          let result = null;
          for (
            let i = 0;
            result === null && i < parentNode.children.length;
            i++
          ) {
            result = getNodeFromTree(parentNode.children[i], nodeId);
          }
          return result;
        }
        return null;
      };

      if (rootId) {
        const tree = questions.find((q) => q.id === rootId);
        return getNodeFromTree(tree, id);
      }
    },
    [questions]
  );

  const deleteQuestionAnswer = useCallback(
    (rootId, parentId, id) => {
      const deleteNodeFromTree = (parentNode, parentId, nodeId) => {
        const newTree = { ...parentNode };
        if (parentNode.id == parentId) {
          return {
            ...parentNode,
            children: parentNode.children.filter((node) => node.id !== nodeId),
            child: parentNode.child.filter((childId) => childId !== nodeId),
          };
        }
        if (newTree.children.length > 0) {
          newTree.children = newTree.children.map((node) =>
            deleteNodeFromTree(node, parentId, nodeId)
          );
        }
        return newTree;
      };

      let rootQuestion;

      const filteredQuestions = questions.filter((q) => {
        if (q.id === rootId) rootQuestion = { ...q };
        return q.id !== rootId;
      });

      const newRootQuestion = deleteNodeFromTree(rootQuestion, parentId, id);

      setQuestions([newRootQuestion, ...filteredQuestions]);
    },
    [questions]
  );

  const insertQuestionAnswer = useCallback(
    (rootId, parentId, newNode) => {
      const insertNodeInTree = (parentNode, nodeId, newNode) => {
        const newTree = { ...parentNode };
        if (parentNode.id == nodeId) {
          if (newTree.children.length > 0) {
            const sort = Math.max.apply(
              Math,
              newTree.children.map((o) => o.sort)
            );
            newTree.children.push({ ...newNode, sort: sort + 1 });
            return newTree;
          }
          return { ...newTree, children: [{ ...newNode, sort: 0 }] };
        }
        if (newTree.children.length > 0) {
          newTree.children = newTree.children.map((node) =>
            insertNodeInTree(node, nodeId, newNode)
          );
        }
        return newTree;
      };

      if (rootId === parentId) {
        setQuestions([{ sort: 0, ...newNode }, ...questions]);
      } else {
        let rootQuestion;

        const filteredQuestions = questions.filter((q) => {
          if (q.id === rootId) rootQuestion = { ...q };
          return q.id !== rootId;
        });

        const newRootQuestion = insertNodeInTree(
          rootQuestion,
          parentId,
          newNode
        );

        setQuestions([newRootQuestion, ...filteredQuestions]);
      }
    },
    [questions]
  );

  useEffect(() => {
    setFormattedList(questions);
    setFlatQuestionsList(flatternQuestionTree(questions));
    // eslint-disable-next-line
  }, [questions])

  return (
    <QuestionsContext.Provider
      value={{
        questions,
        selectedQuestion,
        formattedList,
        setSelectedQuestion,
        setFormattedList,
        updateQuestionAnswer,
        getQuestionAnswer,
        deleteQuestionAnswer,
        insertQuestionAnswer,
        getQuestions: handleGetQuestions,
        flatQuestionsList,
      }}
    >
      {children}
    </QuestionsContext.Provider>
  );
}

QuestionsContextProvider.propTypes = {
  children: PropTypes.node,
};

export default QuestionsContextProvider;
