import makeStyles from '@mui/styles/makeStyles';
import { rgba } from 'polished';
import { theme } from '../../Theme/Theme';

export const useStyles = makeStyles({
  item: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    padding: '6px 12px 6px 12px',
    margin: '0',
    width: '100%',
  },
  itemSaved: {
    background: rgba(0, 88, 255, 0.05),
  },
  title: {
    fontWeight: 'medium',
    fontSize: '12px',
    lineHeight: '18px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.09px',
    color: '#171725',
    opacity: '0.8',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  titleDeleted: {
    color: '#FF3100',
    textDecoration: 'line-through',
  },
  titleSaved: {
    color: '#0058FF',
    opacity: '0.8',
  },
  price: {
    fontWeight: 'medium',
    fontSize: '12px',
    lineHeight: '18px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.09px',
    color: '#282B31',
    opacity: '0.8',
    fonSize: '12',
  },
  priceDeleted: {
    color: '#FF3100',
    textDecoration: 'line-through',
  },
  priceSaved: {
    color: '#0058FF',
    opacity: '0.8',
  },
  listHeader: {
    width: '100%',
    justifyContent: 'start',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',

    '&:hover': {
      background: 'none',
    },
  },
  noChildren: {
    marginLeft: '20px',
  },
});
