import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../layout/Theme/Theme';

export const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
  },
  mr24: {
    marginRight: '24px',
  },
  mr60: {
    marginRight: '60px',
    '@media (max-width: 780px)': {
      marginRight: 0,
    },
  },
  ml6: {
    marginLeft: '6px',
  },
  mb24: {
    marginBottom: '24px',
  },
  mb5: {
    marginBottom: '5px',
  },
  modal: {
    height: '100%',
    overflow: 'scroll',
    display: 'block',
  },
  modalContainer: {
    position: 'absolute',
    backgroundColor: 'white',
    padding: '24px',
    border: '1px solid #DADCE0',
    borderRadius: '4px',
    opacity: 1,
    top: '20%',
    left: '20%',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '50%',
    '@media (max-width: 780px)': {
      width: '90%',
      left: '5%',
    },
  },
  titleModal: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    alignItems: 'center',
  },
  titleText: {
    fontSize: '24px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.17px',
    color: '#282B31',
  },
  closeIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hr: {
    color: '#282B31',
    opacity: 0.2,
  },
  contentModal: {
    marginTop: '24px',
    marginBottom: '24px',
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    marginRight: '10px',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 0.5,
    marginTop: '24px',
  },
  contentModalRefund: {
    justifyContent: 'space-between',
    '@media (max-width: 780px)': {
      flex: 1,
      flexWrap: 'wrap',
    },
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '15px',
  },
  button: {
    border: '1px solid #0000001F',
    borderRadius: '4px',
    opacity: 1,
    marginRight: '32px',
  },
  buttonText: {
    font: 'normal normal 400 14px/21px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0px',
    color: '#282B31',
    opacity: 1,
  },
  buttonBlue: {
    border: '1px solid #0000001F',
    borderRadius: '4px',
    opacity: 1,
    backgroundColor: '#0058FF',
    '&:hover': {
      backgroundColor: 'rgba(0, 88, 255, 0.5)',
    },
  },
  buttonBlueText: {
    color: '#FFFFFF',
    font: 'normal normal 400 14px/21px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0px',
    opacity: 1,
  },
  problemInput: {
    background: '#F5F5F5',
    borderRadius: '8px',
    width: '100%',

    '& label.Mui-focused': {
      color: '#282B3199',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F5F5F5',
      },
      '&:hover fieldset': {
        borderColor: '#DADCE0',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #282B3199',
      },
    },
  },
  mobileOnly: {
    '@media (min-width: 780px)': {
      display: 'none',
    },
  },
  flex0: {
    flex: 0,
    '@media (max-width: 780px)': {
      marginTop: '15px',
    },
  },
  checkBoxesContainer: {
    marginBottom: '15px',
  },
  itemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemText: {
    font: 'normal normal 600 14px/21px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.1px',
    color: '#282B31',
    opacity: 1,
    '& > span': {
      '&:last-child': {
        fontWeight: 600,
      },
    },
  },
  options: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '30px',
    marginBottom: '20px',
  },
  optionsMobile: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: '5px',
    marginBottom: '10px',
    '& > div': {
      width: '100%',
    },
  },
  amountContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    '& > div': {
      width: '85px',
    },
  },
  amountContainerMobile: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  amountInput: {
    background: '#F5F5F5',
    borderRadius: '8px',
    minWidth: '120px',
    textAlign: 'right',

    '& label.Mui-focused': {
      color: '#282B3199',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F5F5F5',
      },
      '&:hover fieldset': {
        borderColor: '#DADCE0',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #282B3199',
      },
    },
  },
  amountLabel: {
    fontSize: '12px',
  },
  op60: {
    opacity: '60%',
  },
}));
