import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  list: {
    display: 'flex',
    overflowX: 'hidden',
    overflowY: 'auto',
    flexFlow: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    padding: '6px 0px',
    background: '#FFFFFF',
    borderRadius: '12px',
    opacity: '1',

    '& > li': {
      width: '100%',
    },
  },
  listButton: {
    width: '100%',
    justifyContent: 'start',
    borderRadius: '6px',
    paddingRight: '6px !important',

    '&:hover': {
      background: 'rgba(23, 23, 37, 0.1)',
    },
  },
  listItem: {
    color: 'rgba(23, 23, 37, 0.6)',
  },
  selectedItem: {
    color: '#0058FF',
  },
  itemButton: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  selectedButton: {
    background: '#0058FF0D',

    '&:hover': {
      background: '#0058FF1D',
    },
  },
  deletedItem: {
    color: 'rgba(255, 49, 0, 0.6)',
    textDecoration: 'line-through',
  },
  deletedButton: {
    '&:hover': {
      background: 'rgba(255, 49, 0, 0.1)',
    },
  },
  deletedSelected: {
    color: '#FF3100',
    textDecoration: 'line-through',
  },
  deletedSelectedButton: {
    background: 'rgba(255, 49, 0, 0.1)',

    '&:hover': {
      background: 'rgba(255, 49, 0, 0.15)',
    },
  },
});
