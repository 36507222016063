import React, { useCallback, useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import * as yup from 'yup';
import { get } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../../input/Button/Button';
import HomePageSlide from '../../../../input/HomePageSlide/HomePageSlide';
import LogoFavicon from '../../../../input/LogoFavicon/LogoFavicon';
import FooterInfoModal from '../../../../input/FooterInfoModal/FooterInfoModal';
import { useStyles } from './Website.styles';
import { Loading } from '../../../../layout/Loading/Loading';
import { updateSettings } from '../../../../../redux/ducks/RestaurantDucks';
import { editRestaurantWebsiteETL } from '../../../../../utils/ExtractTransformLoad/EditRestaurantETL';
import formatPhoneNumber from '../../../../../utils/FormatUtils/formatPhoneNumber';

const FormSchema = yup.object().shape({
  meta: yup.object().shape({
    title: yup.string().required('Restaurant title is required'),
    description: yup.string().required('Restaurant description is required'),
    additional: yup.array().of(
      yup.object().shape({
        field: yup.string(),
        info: yup.string(),
      })
    ),
  }),
  slider: yup.array().of(
    yup.object().shape({
      sliderTitle: yup.string().required('Slider title is required'),
      sliderSubtitle: yup.string(),
      image: yup.array(),
    })
  ),
  logo: yup.array(),
  favicon: yup.array(),
});

export const initial = {
  meta: {
    title: '',
    about_us_title: '',
    description: '',
    about_us_desc: '',
    additional: [],
  },
  slider: [
    {
      sliderTitle: '',
      sliderSubtitle: '',
      image: [],
    },
    {
      sliderTitle: '',
      sliderSubtitle: '',
      image: [],
    },
    {
      sliderTitle: '',
      sliderSubtitle: '',
      image: [],
    },
  ],
  logo: [],
  favicon: [],
  about_bg: [],
};

export function Website() {
  const classes = useStyles();
  // const [mimeTypes, setMimeTypes] = useState({});
  const mimeTypes = {};
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [listFooterInfo, setListFooterInfo] = useState([]);
  const [editFooterInfo, setEditFooterInfo] = useState([]);
  const dispatch = useDispatch();

  const restaurantSettings = useSelector((state) => state.restaurant.settings);

  const rawSettings = useSelector((state) => state.restaurant.rawSettings);

  const isLoading = useSelector((state) => state.restaurant.isLoading);

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: initial,
    resolver: yupResolver(FormSchema),
  });

  const {
    formState: { errors },
    control,
    reset,
    trigger,
    setValue,
    getValues,
  } = methods;

  const getErrors = useCallback(
    (path) => ({
      error: !!get(errors, path),
      helperText: get(errors, path) && get(errors, path).message,
      objecto: get(errors, path),
    }),
    [errors]
  );

  const formObj = { ...methods, mimeTypes, getErrors };

  const handleOpenModal = (info) => {
    if (info.length) setEditFooterInfo(info);
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setEditFooterInfo([]);
    setIsOpenModal(false);
  };

  const handleSaveFooterInfo = useCallback(
    (field, info) => {
      const values = [...getValues('meta.additional'), { field, info }];
      setListFooterInfo(values.map((info, index) => ({ ...info, id: index })));
      setValue('meta.additional', values);
    },
    [getValues, setValue]
  );

  const handleDeleteFooterInfo = (id) => {
    const info = listFooterInfo.find((inf) => inf.id === id);
    setListFooterInfo(listFooterInfo.filter((inf) => inf.id !== id));

    const values = getValues('meta.additional').filter(
      (inf) => inf.field !== info.field && inf.info !== info.info
    );
    setValue('meta.additional', values);
  };

  const handleCancel = useCallback(() => {
    if (restaurantSettings) {
      const initial = {
        meta: {
          title: '',
          description: '',
          about_us_title: '',
          about_us_desc: '',
          additional: [],
          ...restaurantSettings.meta,
        },
        slider: restaurantSettings.slider,
        logo: restaurantSettings.logo,
        favicon: restaurantSettings.favicon,
        about_bg: restaurantSettings.about_bg,
      };
      setListFooterInfo(
        initial.meta.additional.map((info, index) => ({ ...info, id: index }))
      );
      reset(initial);
    }
  }, [restaurantSettings, reset]);

  const handleSave = useCallback(async () => {
    const correctData = await trigger(['meta', 'slider', 'logo', 'favicon']);

    const {
      id,
      config,
      name,
      description,
      address,
      category,
      tags,
      schedule,
      text,
      phone,
      media,
    } = rawSettings;

    const newData = {
      name,
      description,
      address,
      category,
      tags,
      schedule,
      text,
      media,
      ...editRestaurantWebsiteETL(getValues()),
      phone: formatPhoneNumber(phone),
      config: {
        costs_food_ratio: config.costs_food_ratio,
        current_preparation_time: config.current_preparation_time,
      },
    };

    if (correctData && id)
      dispatch(
        updateSettings({
          id,
          settings: newData,
        })
      );
  }, [trigger, getValues, rawSettings, dispatch]);

  useEffect(() => {
    if (restaurantSettings) {
      const initial = {
        meta: {
          title: '',
          description: '',
          additional: [],
          about_us_title: '',
          about_us_desc: '',
          ...restaurantSettings.meta,
        },
        slider: restaurantSettings.slider,
        logo: restaurantSettings.logo,
        favicon: restaurantSettings.favicon,
        about_bg: restaurantSettings.about_bg,
      };
      setListFooterInfo(initial.meta.additional);
      reset(initial);
    }
  }, [restaurantSettings, reset]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Box>
        <Box className={classes.header}>
          <Typography variant="h1" className={classes.pageTitle}>
            Website
          </Typography>
          <Box className={classes.buttonBox}>
            <Box display="flex" alignItems="center">
              <Button
                data-test-id="xYxg4kCrYoqb0MEKun0jA"
                variant="outlined"
                color="inherit"
                className={classes.button}
                onClick={handleCancel}
                data-testid="cancel-button"
              >
                <Typography className={classes.buttonText}>Cancel</Typography>
              </Button>
              <Button
                data-test-id="xrdDhutg9x7QSJTRMhv8A"
                variant="outlined"
                color="inherit"
                className={classes.buttonBlue}
                onClick={handleSave}
                data-testid="save-button"
              >
                <Typography className={classes.buttonBlueText}>Save</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className={classes.boxContainer}>
          <Typography className={classes.subTitle}>
            Website Content and SEO
          </Typography>
          <Box className={classes.webContent}>
            <Box className={classes.inputContainer}>
              <Typography className={classes.label}>
                Restaurant title
              </Typography>
              <Controller
                name="meta.title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder="restaurant title"
                    className={classes.input}
                    inputProps={{ 'data-testid': 'restaurant-title-input' }}
                    {...getErrors('meta.title')}
                  />
                )}
              />
              <Typography
                className={classes.label}
                style={{ marginTop: '24px' }}
              >
                Description
              </Typography>
              <Controller
                name="meta.description"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder="description"
                    className={classes.areaInput}
                    multiline
                    rows={2}
                    inputProps={{ 'data-testid': 'description-input' }}
                    {...getErrors('meta.description')}
                  />
                )}
              />
            </Box>
            <LogoFavicon form={formObj} />
          </Box>
        </Box>
        <Box className={classes.boxContainer}>
          <Typography className={classes.subTitle}>About Us</Typography>
          <Box className={classes.webContent}>
            <Box className={classes.inputContainer}>
              <Typography className={classes.label}>Title</Typography>
              <Controller
                name="meta.about_us_title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder="about us title"
                    className={classes.input}
                    inputProps={{ 'data-testid': 'restaurant-about-us-input' }}
                    {...getErrors('meta.about_us_title')}
                  />
                )}
              />
              <Typography
                className={classes.label}
                style={{ marginTop: '24px' }}
              >
                Description
              </Typography>
              <Controller
                name="meta.about_us_desc"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder="about us description"
                    className={classes.areaInput}
                    multiline
                    rows={2}
                    inputProps={{ 'data-testid': 'about-us-description-input' }}
                    {...getErrors('meta.about_us_desc')}
                  />
                )}
              />
            </Box>
            <LogoFavicon form={formObj} aboutUs />
          </Box>
        </Box>
        <Box className={classes.boxContainer}>
          <Typography className={classes.subTitle}>Homepage Slider</Typography>
          <Box className={classes.inputContainer} style={{ width: '100%' }}>
            <HomePageSlide key="slider" form={formObj} />
            <Typography>{getErrors('slider').helperText}</Typography>
          </Box>
        </Box>
        <Box className={classes.boxContainer}>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography className={classes.subTitle}>Footer Info</Typography>
            <Button
              data-test-id="Av4SnWUTomnWDfo5QB9jF"
              variant="outlined"
              color="inherit"
              className={classes.button}
              onClick={handleOpenModal}
              data-testid="open-footer-info-modal-button"
              style={{ marginRight: '0' }}
            >
              <Typography className={classes.buttonText}>Add Info</Typography>
            </Button>
          </Box>
          {listFooterInfo.map((footerInfo, index) => (
            <Box
              key={footerInfo.id}
              style={{ marginTop: index === 0 ? '32px' : '16px' }}
            >
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className={classes.footerText}>
                  <strong>{footerInfo.field}:</strong> {footerInfo.info}
                </Typography>
                <Box>
                  <Button
                    data-test-id="HmPyxgTi525AWZdZR1HjI"
                    className={classes.iconButton}
                    style={{ marginRight: '5px' }}
                    data-testid={`edit-footer-info-modal-button-${index}`}
                    onClick={() =>
                      handleOpenModal([footerInfo.field, footerInfo.info])
                    }
                  >
                    <EditIcon fontSize="small" />
                  </Button>
                  <Button
                    data-test-id="Zzoct8HK5rXVt46c2UjxB"
                    className={classes.iconButton}
                    data-testid={`delete-footer-info-modal-button-${index}`}
                    onClick={() => handleDeleteFooterInfo(footerInfo.id)}
                  >
                    <DeleteIcon fontSize="small" color="error" />
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <FooterInfoModal
        editFooterInfo={editFooterInfo}
        isOpenModal={isOpenModal}
        handleCloseModal={handleCloseModal}
        handleSaveFooterInfo={handleSaveFooterInfo}
      />
    </>
  );
}

export default Website;
