import { api } from './Api';
import { formatResponse } from '../utils/FormatUtils/formatResponse';

export const getSettings = async (id) => {
  const response = await api.get(`/restmanager/${id}/settings`);

  return formatResponse({ data: response, type: 'settings' });
};

export const updateSettings = async (id, restaurant) => {
  const response = await api.post(`/restmanager/${id}/settings`, restaurant);

  return response;
};

export const addTempClosingHour = async ({ restaurant, start, end }) => {
  const response = await api.post(
    `/localareamng/restaurant/${restaurant}/closing`,
    { start, end }
  );
  return response;
};

export const deleteTempClosingHour = async ({ restaurant, hour }) => {
  const response = await api.delete(
    `/localareamng/restaurant/${restaurant}/closing/${hour}`
  );
  return response;
};

export const getTempClosingHours = async ({ restaurant }) => {
  const response = await api.get(
    `/localareamng/restaurant/${restaurant}/closing`
  );
  return response;
};

export const registerAppUser = async ({ token: { token }, id }) =>
  await api.post(`/localareamng/restaurant/${id}/app/user`, {
    token,
  });
