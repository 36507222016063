import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Popper, Typography } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { useStyles } from './DropDown.styles';
import { useClickOutside } from '../../../hooks/useClickOutside';

function DropDown({
  className,
  buttonWidth = '120px',
  buttonHeight = '36px',
  title = 'Select',
  keepOpen = false,
  children = <div />,
}) {
  const classes = useStyles();
  const [anchorPopover, setAnchorPopover] = useState(null);
  const open = Boolean(anchorPopover);
  const popperRef = useRef(null);
  const buttonRef = useRef(null);

  const handleOpenSelect = (event) => {
    setAnchorPopover(event.currentTarget);
  };

  const handleCloseSelect = () => {
    setAnchorPopover(null);
  };

  useClickOutside([popperRef, buttonRef], open, handleCloseSelect);

  return (
    <Box className={className}>
      <Button
        data-test-id="9tl3xFVSr4cNi-CTVM8CQ"
        ref={buttonRef}
        data-testid="dropdown-button"
        onClick={handleOpenSelect}
        className={classes.button}
        style={{ width: buttonWidth, height: buttonHeight }}
      >
        <Typography className={classes.buttonLabel}>{title}</Typography>
        {!open ? (
          <ExpandMore style={{ marginLeft: '8px' }} />
        ) : (
          <ExpandLess style={{ marginLeft: '8px' }} />
        )}
      </Button>
      <Popper
        data-test-id="msZbh0afGP8zDAmDm-Gau"
        ref={popperRef}
        data-testid="popover-dropdown"
        open={open}
        onClick={() => (keepOpen ? null : handleCloseSelect())}
        anchorEl={anchorPopover}
        onClose={handleCloseSelect}
        disablePortal
        style={{
          marginTop: '8px',
          borderRadius: '6px',
          flexDirection: 'column',
          paddingBottom: '10px',
          display: 'flex',
          width: buttonRef?.current?.parentElement?.clientWidth || buttonWidth,
          background: '#F5F5F5',
          zIndex: 1,
        }}
      >
        {children}
      </Popper>
    </Box>
  );
}

DropDown.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  buttonWidth: PropTypes.string,
  buttonHeight: PropTypes.string,
  children: PropTypes.node,
  keepOpen: PropTypes.bool,
};

export default DropDown;
