import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import debounce from 'lodash.debounce';

import Search from '../../layout/MenuComponents/Search/Search';
import Lists from '../../layout/MenuComponents/Lists';
import Types from '../../layout/MenuComponents/Types/Types';
import NoContent from '../../layout/MenuComponents/NoContent/NoContent';
import { useStyles } from './ItemsPage.styles';
import useIsDesktop from '../../../hooks/useIsDesktop/useIsDesktop';
import { useItemContext } from '../../../context/itemsContext';
import { MenuItem } from '../../layout/MenuComponents/MenuItem';
import typesMock from '../../../mocks/types.json';
import { Loading } from '../../layout/Loading';
import { Error } from '../../layout/Error/Error';
import { useMenuContext } from '../../../context/menuContext';
import { useQuestionsContext } from '../../../context/questionsContext';

function ItemsPage() {
  const isDesktop = useIsDesktop();
  const classes = useStyles();
  const [types, setTypes] = useState(typesMock.items);
  const { selectedItem, setFormattedList, items, setExpandAll, getMenuItems } =
    useItemContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [filterText, setFilterText] = useState('');
  const { selectedRestaurant } = useMenuContext();
  const { getQuestions } = useQuestionsContext();

  useEffect(() => {
    if (selectedRestaurant?.id) {
      setLoading(true);
      (async () => {
        try {
          await getMenuItems(selectedRestaurant.id);
          await getQuestions(selectedRestaurant?.id);
          setLoading(false);
        } catch {
          setError(true);
          setLoading(false);
        }
      })();
    }
    // eslint-disable-next-line
  }, [selectedRestaurant]);

  const filterByType = useCallback(
    (items, types) => {
      types.map((type) => {
        if (type.id === 'all' && type.active) {
          setFormattedList(items);
        } else if (type.id === 'inactive' && type.active) {
          setFormattedList(
            formatItems({
              items,
              available: false,
            })
          );
        } else if (type.id === 'active' && type.active) {
          setFormattedList(
            formatItems({
              items,
              available: true,
            })
          );
        }
        return null;
      });
    },
    [setFormattedList]
  );

  const handleSearchInputKeyUp = useCallback(
    ({ value, skipFilter }) => {
      setFilterText(value);
      setExpandAll(true);
      if (value.length !== 0) {
        const formattedValue = value.toLowerCase();
        const filteredItems = items
          .map((item) => ({
            ...item,
            categories: item?.categories
              .map((category) => ({
                ...category,
                items: category.items.filter(
                  (subItem) =>
                    subItem.name?.toLowerCase().indexOf(formattedValue) !== -1
                ),
              }))
              .filter((category) => category.items.length > 0),
          }))
          .filter((item) => item.categories.length > 0);
        if (!skipFilter) {
          filterByType(filteredItems, types);
        }
        return filteredItems;
      }
      if (!skipFilter) {
        filterByType(items, types);
      }
      return items;
    },
    [items, filterByType, types, setExpandAll]
  );

  const formatItems = ({ items, available }) => {
    const formattedItems = items
      .map((item) => ({
        ...item,
        categories: item.categories
          .map((category) => ({
            ...category,
            items: category.items.filter(
              (catItem) => catItem.available === available
            ),
          }))
          .filter((category) => category.items.length > 0),
      }))
      .filter((item) => item.categories.length > 0);

    return formattedItems;
  };

  const handleTypesChange = useCallback(
    (types) => {
      setTypes(types);
      filterByType(
        handleSearchInputKeyUp({ value: filterText, skipFilter: true }),
        types
      );
    },
    [setTypes, filterText, handleSearchInputKeyUp, filterByType]
  );

  const debouncedSearchInputKeyUp = useMemo(
    () => debounce(handleSearchInputKeyUp, 300),
    [handleSearchInputKeyUp]
  );

  const content = useMemo(
    () =>
      selectedItem ? (
        <MenuItem item={selectedItem} />
      ) : (
        <NoContent type="item" />
      ),
    [selectedItem]
  );

  if (loading) return <Loading />;

  if (error)
    return (
      <Box flex="1" alignItems="center" justifyContent="center" display="flex">
        <Error />
      </Box>
    );

  return (
    <>
      {isDesktop ? (
        <Box className={classes.page}>
          <Box className={classes.left}>
            <Search
              placeholder="Search for item"
              onInputKeyUp={debouncedSearchInputKeyUp}
            />
            <Types types={types} onTypesChange={handleTypesChange} />
            <Lists />
          </Box>
          <Box className={classes.right}>{content}</Box>
        </Box>
      ) : (
        <Box className={classes.page}>
          <Box
            style={{
              position: 'fixed',
              height: '1px',
              left: '0px',
              top: '80px',
              width: '100%',
              boxShadow: '0px 2px 4px #0000001F',
            }}
          />
          {selectedItem ? (
            <Box height="100%">{content}</Box>
          ) : (
            <>
              <Types types={types} onTypesChange={handleTypesChange} />
              <Lists />
            </>
          )}
        </Box>
      )}
    </>
  );
}

export default ItemsPage;
