import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  MenuItem,
  Select,
  Switch,
  Typography,
  IconButton,
} from '@mui/material';
import { AddRounded, Delete } from '@mui/icons-material';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import { useStyles } from './WeekHours.styles';
import { weekDay } from '../../../../constants/time';
import { generateList } from '../../../../utils/hours';

export function IsOpen({ open }) {
  const classes = useStyles();

  return (
    <Typography
      variant="subtitle2"
      className={clsx({
        [classes.closed]: !open,
      })}
    >
      {open ? 'Open' : 'Closed'}
    </Typography>
  );
}

IsOpen.propTypes = {
  open: PropTypes.bool,
};

const hrs = generateList();

export function WeekHours({
  form,
  handleAddShift,
  handleRemoveShift,
  handleUpdateValues,
  disabled,
}) {
  const classes = useStyles();
  const { control, getErrors, watch, trigger, setValue } = form;
  const [error, setError] = useState(false);

  const handleSelect = useCallback(
    async (field, value) => {
      setValue(field, value);
      handleUpdateValues();
      const isCorrect = await trigger(field);
      setError(!isCorrect);
    },
    [handleUpdateValues, trigger, setValue]
  );

  const hours = useCallback(
    (label) =>
      hrs.map((h) => (
        <MenuItem
          data-test-id="bfL89PSJxQ_eGQJU5Cebh"
          key={h.label}
          value={h.value}
          style={{ backgroundColor: '#F5F5F5' }}
          onClick={() => handleSelect(label, h.value)}
        >
          {h.label}
        </MenuItem>
      )),
    [handleSelect]
  );

  return (
    <>
      {error && (
        <Typography className={classes.error}>
          Close time must be after open time
        </Typography>
      )}
      <Box display="flex" marginBottom="32px">
        <FormControl style={{ flex: 1, display: 'flex' }}>
          <FormGroup style={{ flex: 1 }}>
            {weekDay &&
              weekDay.map((day) => {
                const dayState = watch(day);
                const shift = watch(`${day}.shift`);
                return (
                  <Box key={day} className={classes.weekWrapper}>
                    <Box
                      flex="0.8"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginTop="2px"
                      marginRight="10px"
                    >
                      <Typography
                        variant="h4"
                        classes={{ h4: classes.weekDay }}
                      >
                        {day}
                      </Typography>
                      <Box className={classes.switchWrapper}>
                        <Controller
                          name={`${day}.open`}
                          control={control}
                          defaultValue
                          render={({
                            field: { onChange, value, ...props },
                          }) => (
                            <Switch
                              {...props}
                              color="primary"
                              disabled={disabled}
                              onChange={(e) => {
                                onChange(e.target.checked);
                                handleUpdateValues();
                              }}
                              checked={value}
                            />
                          )}
                        />
                        <IsOpen open={dayState?.open} />
                      </Box>
                    </Box>
                    <Box
                      flex="1"
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                      className={clsx(classes.select, {
                        [classes.hidden]: !dayState?.open,
                      })}
                    >
                      {shift &&
                        shift.map((_, i) => (
                          <Box
                            key={`${day}.${i}`}
                            display={
                              dayState?.open || i === 0 ? 'flex' : 'none'
                            }
                            data-testid={`${day}-shift`}
                          >
                            <Box className={classes.selectWrapper}>
                              <Box className={classes.selectInner}>
                                <FormControl
                                  variant="outlined"
                                  error={
                                    getErrors(`${day}.shift[${i}].open`).error
                                  }
                                  className={clsx(classes.selectControl, {
                                    [classes.formControlSelect]: i > 0,
                                  })}
                                  name={`${day}.shift[${i}].open`}
                                  {...getErrors(`${day}.shift[${i}].open`)}
                                >
                                  <Controller
                                    control={control}
                                    name={`${day}.shift[${i}].open`}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        name={`${day}.shift[${i}].open`}
                                        disabled={disabled}
                                        onChange={(e) =>
                                          handleSelect(
                                            `${day}.shift[${i}].open`,
                                            e.target.value
                                          )
                                        }
                                        className={classes.dropdownSelect}
                                      >
                                        <MenuItem
                                          value=""
                                          disabled
                                          style={{ backgroundColor: '#F5F5F5' }}
                                        >
                                          Select
                                        </MenuItem>
                                        {hours(`${day}.shift[${i}].open`)}
                                      </Select>
                                    )}
                                  />
                                </FormControl>
                              </Box>
                              <p className={classes.divider}>-</p>
                              <Box className={classes.selectInner}>
                                <FormControl
                                  variant="outlined"
                                  error={
                                    getErrors(`${day}.shift[${i}].close`).error
                                  }
                                  className={clsx(classes.selectControl, {
                                    [classes.formControlSelect]: i > 0,
                                  })}
                                  name={`${day}.shift[${i}].close`}
                                  {...getErrors(`${day}.shift[${i}].close`)}
                                >
                                  <Controller
                                    control={control}
                                    name={`${day}.shift[${i}].close`}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        name={`${day}.shift[${i}].close`}
                                        disabled={disabled}
                                        onChange={(e) =>
                                          handleSelect(
                                            `${day}.shift[${i}].close`,
                                            e.target.value
                                          )
                                        }
                                        className={classes.dropdownSelect}
                                      >
                                        <MenuItem
                                          value=""
                                          disabled
                                          style={{ backgroundColor: '#F5F5F5' }}
                                        >
                                          Select
                                        </MenuItem>
                                        {hours(`${day}.shift[${i}].close`)}
                                      </Select>
                                    )}
                                  />
                                </FormControl>
                                <Box display="flex" alignItems="center">
                                  {i > 0 ? (
                                    <IconButton
                                      data-test-id="e1dajjJfgwfYTFzPgezly"
                                      disabled={disabled}
                                      className={classes.removeShiftButton}
                                      onClick={() => handleRemoveShift(day, i)}
                                      data-testid={`${day}-remove-shift-${i}`}
                                      size="large"
                                    >
                                      <Delete fontSize="small" />
                                    </IconButton>
                                  ) : (
                                    <>
                                      {shift?.length < 2 && (
                                        <Button
                                          data-test-id="mNRG1tLPTuE8kMNQs0o2G"
                                          disabled={disabled}
                                          className={clsx(
                                            classes.addShiftButton
                                          )}
                                          onClick={() => handleAddShift(day)}
                                          data-testid={`${day}-add-shift`}
                                        >
                                          <AddRounded
                                            className={classes.shiftButtonIcon}
                                            fontSize="small"
                                          />
                                          <p
                                            className={classes.shiftButtonText}
                                          >
                                            Add Shift
                                          </p>
                                        </Button>
                                      )}
                                    </>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      {shift?.length < 2 && (
                        <Button
                          data-test-id="zVhGZlOJHH4jw7Of0zgPi"
                          disabled={disabled}
                          className={clsx(classes.addShiftMobile, {
                            [classes.hiddenButton]: !dayState?.open,
                          })}
                          onClick={() => handleAddShift(day)}
                          data-testid={`${day}-add-shift-mobile`}
                        >
                          <AddRounded
                            className={classes.shiftButtonIcon}
                            fontSize="small"
                          />
                          <p className={classes.shiftButtonText}>Add Shift</p>
                        </Button>
                      )}
                    </Box>
                  </Box>
                );
              })}
          </FormGroup>
        </FormControl>
      </Box>
    </>
  );
}

WeekHours.propTypes = {
  handleUpdateValues: PropTypes.func,
  value: PropTypes.object,
  setValue: PropTypes.func,
  form: PropTypes.object,
  handleAddShift: PropTypes.func,
  handleRemoveShift: PropTypes.func,
  disabled: PropTypes.bool,
};

export default WeekHours;
