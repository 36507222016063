import {
  differenceInMonths,
  differenceInDays,
  format,
  fromUnixTime,
  subDays,
  subMonths,
  subYears,
  isYesterday,
  endOfMonth,
  endOfYear,
  addDays,
  addMonths,
} from 'date-fns';
import { isEqual } from 'lodash';
import { capitalize } from './stringFormat';
import { isBetween } from '../date-utils';

const sliderImg = [
  'first_slide',
  'second_slide',
  'third_slide',
  'fourth_slide',
  'fifth_slide',
  'sixth_slide',
];
const sliderTitle = [
  'slider_01_title',
  'slider_02_title',
  'slider_03_title',
  'slider_04_title',
  'slider_05_title',
  'slider_06_title',
];
const sliderSubtitle = [
  'slider_01_sub',
  'slider_02_sub',
  'slider_03_sub',
  'slider_04_sub',
  'slider_05_sub',
  'slider_06_sub',
];

const weekToString = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const toShift = (shift) => {
  const formattedShift = [];
  shift.map((s) => {
    formattedShift.push({
      open: s.start,
      close: s.end,
    });
    return null;
  });
  return formattedShift;
};

export const formatItems = (data) => {
  const formattedData = data.map((d) => {
    const info = { ...d.info };

    const items = d.items.map((item) => ({
      ...item,
      available:
        item.status !== 'inactive' &&
        !isBetween(
          fromUnixTime(item.unavailable?.start),
          fromUnixTime(item.unavailable?.end)
        ),
      menuId: info.id,
    }));

    info.categories = d.categories.map((c) => ({
      ...c,
      items: items.filter((i) => Number(i.category) === Number(c.id)),
    }));

    return info;
  });
  return formattedData;
};

export const formatItem = (data) => {
  const formattedData = {
    ...data,
    type: data?.type
      ? data.type.toLowerCase().split(' ').join('_')
      : 'prepared_meal',
    questions: data?.questions?.map((q) => ({
      ...q,
      answers: data?.answers
        .filter((a) => q.id === a.parent)
        .map((a) => ({
          ...a,
          available:
            a.status !== 'inactive' &&
            !isBetween(
              fromUnixTime(a.unavailable?.start),
              fromUnixTime(a.unavailable?.end)
            ),
        })),
    })),
  };

  return formattedData;
};

const unflatten = (questions, answers) => {
  const mappedQuestionsAnswers = {};

  questions.forEach((question) => {
    const { id } = question;
    mappedQuestionsAnswers[id] = {
      ...question,
      type: 'question',
      available:
        question.status !== 'inactive' &&
        !isBetween(
          fromUnixTime(question.unavailable?.start),
          fromUnixTime(question.unavailable?.end)
        ),
      expand: false,
    };
    mappedQuestionsAnswers[id].child = [];
  });

  answers.forEach((answer) => {
    const { id } = answer;

    mappedQuestionsAnswers[answer.parent].child.push(id);

    mappedQuestionsAnswers[id] = {
      ...answer,
      child: !answer?.child
        ? []
        : Array.isArray(answer.child)
        ? answer.child
        : [answer.child],
      available:
        answer.status !== 'inactive' &&
        !isBetween(
          fromUnixTime(answer.unavailable?.start),
          fromUnixTime(answer.unavailable?.end)
        ),
      expand: false,
      prices: {
        takeout: { base: answer.prices?.takeout || 0 },
        delivery: { base: answer.prices?.delivery || 0 },
        wholesale: { base: answer.prices?.wholesale || 0 },
      },
    };
  });

  const getChildren = (item, mappedArr, root, parent) => {
    if (item.child.length > 0) {
      return {
        ...item,
        root,
        parent,
        children: item.child
          .map((childId) =>
            getChildren(mappedArr[childId], mappedArr, root, item.id)
          )
          .sort((a, b) => a.sort - b.sort),
      };
    }
    return {
      ...item,
      root,
      parent,
      children: [],
    };
  };

  const tree = questions.filter((q) => !q.sub);

  return tree.map((question) =>
    getChildren(
      mappedQuestionsAnswers[question.id],
      mappedQuestionsAnswers,
      question.id
    )
  );
};

export const formatQuestions = (data) =>
  unflatten(data.questions, data.answers);

export const formatQuestion = (data) => {
  const question = {
    ...data,
    available: true,
    answers: data.answers.map((answer) => ({
      ...answer,
      prices: {
        takeout: { base: answer.prices?.takeout || '' },
        delivery: { base: answer.prices?.delivery || '' },
        wholesale: { base: answer.prices?.wholesale || '' },
      },
    })),
  };

  return question;
};

export const setDate = (start, sub, type) => {
  const date = {
    day: format(subDays(fromUnixTime(start), sub), 'dd, MMM, yyyy'),
    month: format(subMonths(fromUnixTime(start), sub), 'MMM, yyyy'),
    year: format(subYears(fromUnixTime(start), sub), 'yyyy'),
    relative: isYesterday(fromUnixTime(start))
      ? 'Yesterday'
      : format(fromUnixTime(start), 'MM/dd/yyyy'),
  }[type];
  return date;
};

export const setTime = (date) => format(fromUnixTime(date), 'hh:mm a');

export const addEmpty = (array, range) => {
  const diff = differenceInMonths(
    fromUnixTime(range.start),
    fromUnixTime(range.end)
  );
  const total = array;

  for (let j = array.length; j < Math.abs(diff); j++) {
    total.push({
      date: setDate(range.start, j, range.interval),
      sales: 0,
      collected: 0,
    });
  }

  return array;
};

export const formatTaxSummaryResponse = ({ summary }) => ({
  info: {
    sales: summary.total_sales,
    collected: summary.total_collected,
    remitted: summary.total_remitted,
  },
});

export const formatTaxTableResponse = ({ table }) => {
  const array = [];
  table.timeline.data.map((info, i) =>
    array.push({
      date: setDate(table.timeline.start, i, table.timeline.interval),
      sales: info.sales,
      collected: info.collected,
    })
  );
  return {
    table_info: addEmpty(array, {
      start: table.timeline.start,
      end: table.start,
      interval: table.timeline.interval,
    }),
    total_pages: table.total_pages,
  };
};

export const formatStripeResponse = (data) => {
  const results = data.results.reduce((r, result) => {
    const date = setDate(result.date, 0, 'relative');
    r[date] = r[date] || [];
    r[date].push({
      ...result,
      id: result.id,
      date,
      time: setTime(result.date),
      type: capitalize(result.type),
    });
    return r;
  }, {});

  const formattedResults = Object.values(results).map((info) => ({
    info,
    summary: {
      id: '/',
      date: info[0].date,
      type: '/',
      transaction: data.total_transaction,
      driver: data.total_driver,
      discount: data.total_discount,
      adjustments: data.total_adjustments,
      fee: data.total_fee,
      deposit: data.total_deposit,
    },
  }));

  return { total_pages: data.total_pages, page: data.page, formattedResults };
};

export const formatPOSResponse = (data) => {
  const results = data.results.reduce((r, result) => {
    const date = setDate(result.date, 0, 'relative');
    r[date] = r[date] || [];
    r[date].push({
      id: result.id,
      date,
      time: setTime(result.date),
      type: capitalize(result.type),
      inhouse: result.inhouse,
      retail: result.retail,
      wholesale: result.wholesale,
      discount: result.discount,
    });
    return r;
  }, {});

  const formattedResults = Object.values(results).map((info) => ({
    info,
    summary: {
      id: '/',
      date: info[0].date,
      type: '/',
      inhouse: data.total_inhouse,
      retail: data.total_retail,
      wholesale: data.total_wholesale,
      discount: data.average_discount,
    },
  }));

  return { total_pages: data.total_pages, page: data.page, formattedResults };
};

export const formatOverviewResponse = (data) => ({
  total: {
    average_ticket:
      data.sales.total_sales /
      data.sales.aggregate.orders.reduce(
        (acc, cur) => acc + cur.deliveries + cur.takeouts,
        0
      ),
    returning_customers: {
      percentage:
        data.customers.total_returning / data.customers.total_customers,
      total: data.customers.total_returning,
    },
    new_customers: {
      percentage: data.customers.total_new / data.customers.total_customers,
      total: data.customers.total_new,
    },
    food_cost: data.food_cost.costs_food_ratio,
    customers_total: data.customers.total_customers,
    income:
      data.sales.total_wholesale -
      data.sales.total_sales * data.food_cost.costs_food_ratio,
    revenue: data.sales.total_sales,
    timeline: {
      start: data.start,
      end: data.end,
      interval: 'month',
      data: data.sales.aggregate.sales.map((s, i) => ({
        sales: s,
        profit: data.sales.aggregate.profit[i],
      })),
    },
  },
  delivery: {
    average_ticket:
      data.sales.aggregate.sales.reduce((acc, cur) => acc + cur.deliveries, 0) /
      data.sales.aggregate.orders.reduce((acc, cur) => acc + cur.deliveries, 0),
    food_cost: data.food_cost.costs_food_ratio,
    income:
      data.sales.aggregate.wholesale.reduce(
        (acc, cur) => acc + cur.deliveries,
        0
      ) *
      (1 - data.food_cost.costs_food_ratio),
    revenue: data.sales.aggregate.sales.reduce(
      (acc, cur) => acc + cur.deliveries,
      0
    ),
  },
  takeout: {
    average_ticket:
      data.sales.aggregate.sales.reduce((acc, cur) => acc + cur.takeouts, 0) /
      data.sales.aggregate.orders.reduce((acc, cur) => acc + cur.takeouts, 0),
    food_cost: data.food_cost.costs_food_ratio,
    income:
      data.sales.aggregate.wholesale.reduce(
        (acc, cur) => acc + cur.takeouts,
        0
      ) *
      (1 - data.food_cost.costs_food_ratio),
    revenue: data.sales.aggregate.sales.reduce(
      (acc, cur) => acc + cur.takeouts,
      0
    ),
  },
});

export const hourToObj = (payload, business) => {
  const workingHours = {};
  weekToString.map((day) => {
    const shift = payload.filter((s) => weekToString[s.day_in_week] === day);
    if (shift.length > 0) {
      workingHours[day] = { open: true, shift: toShift(shift) };
    } else {
      workingHours[day] = { open: false };
    }
    return null;
  });
  if (business)
    if (isEqual(workingHours, business)) {
      workingHours.same = true;
    } else {
      workingHours.same = false;
    }

  return workingHours;
};

const workingHoursToObject = (payload) => {
  const obj = { business: {}, delivery: {}, takeout: {} };
  obj.business = hourToObj(payload.business_hours);
  obj.delivery = hourToObj(payload.delivery_hours, obj.business);
  obj.takeout = hourToObj(payload.takeout_hours, obj.business);
  return obj;
};

const textToMeta = (text) => {
  const meta = { additional: [] };

  text.map((t) => {
    if (
      [
        'description',
        'keywords',
        'title',
        'about_us_title',
        'about_us_desc',
      ].includes(t.name)
    )
      meta[t.name] = t.text;
    else if (t.name.split('_')[0] === 'field') {
      if (t.name.split('_')[1] === 'title')
        meta.additional.push({
          field: text.filter(
            (f) => f.name === `field_title_${t.name.split('_')[2]}`
          )[0]?.text,
          info: text.filter(
            (i) => i.name === `field_text_${t.name.split('_')[2]}`
          )[0]?.text,
        });
    }
    return null;
  });

  return meta;
};

export const mediaToObject = (media, text) => {
  const obj = {
    slider: [],
    logo: [],
    menu: [],
    favicon: [],
    about_bg: [],
  };

  media.map((m) => {
    if (['logo', 'favicon', 'about_bg'].includes(m.name))
      obj[m.name] = [{ id: m.upload_id, url: m.url }];
    return null;
  });

  sliderImg.map((title, i) => {
    const img = media.filter((m) => m.name === title);
    const imgTitle = text.filter((m) => m.name === sliderTitle[i]);
    const subtitle = text.filter((m) => m.name === sliderSubtitle[i]);
    if (img.length > 0 || imgTitle.length > 0 || subtitle.length > 0) {
      obj.slider.push({
        sliderTitle: imgTitle[0]?.text || '',
        sliderSubtitle: subtitle[0]?.text || '',
        image: [
          {
            id: img[0]?.upload_id || '',
            url: img[0]?.url || '',
          },
        ],
      });
    } else if (obj.slider.length < 3) {
      obj.slider.push({
        sliderTitle: '',
        sliderSubtitle: '',
        image: [],
      });
    }
    return null;
  });

  return obj;
};

const formatSettingsResponse = (data) => {
  const {
    id,
    name,
    address,
    category,
    tags,
    schedule,
    text,
    phone,
    media,
    description,
    appuser,
    fees,
    zones,
  } = data;

  const res = {
    id,
    name,
    fees,
    address: {
      ...address,
      formattedAddress: `${address?.street}, ${address?.city}, ${address?.state} ${address?.zip}`,
      location: {
        lat: address.location.latitude,
        lng: address.location.longitude,
      },
      latlng: {
        lat: address.location.latitude,
        lng: address.location.longitude,
      },
    },
    description,
    cuisine: category,
    tags,
    ...workingHoursToObject(schedule),
    meta: textToMeta(text),
    phone,
    appuser,
    ...mediaToObject(media, text),
    zones:
      zones?.reduce(
        (obj, item) => ((obj[item.zip] = item.location), obj),
        {}
      ) || {},
  };
  return { res, raw: data };
};

export const addEmptyDays = (arr, start) => {
  const end = endOfMonth(start);
  const newArr = arr;
  for (let i = arr.length; i <= differenceInDays(end, start); i++) {
    newArr.push({
      value: 0,
      day: format(addDays(start, i), 'dd'),
    });
  }
  return newArr;
};

export const addEmptyMonths = (arr, start) => {
  const end = endOfYear(start);
  const newArr = arr;
  for (let i = arr.length; i < differenceInMonths(end, start); i++) {
    newArr.push({
      value: 0,
      day: format(addMonths(start, i), 'MMM'),
    });
  }
  return newArr;
};

const getDates = (startDate, endDate, interval = 'day') => {
  const addInterval = {
    day: (date) => addDays(date, 1),
    week: (date) => addDays(date, 7),
    month: (date) => addMonths(date, 1),
  }[interval];

  const formatString = {
    day: 'dd',
    week: 'dd/MMM',
    month: 'MMM',
  }[interval];

  const dateArray = new Array();
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dateArray.push({
      day: format(new Date(currentDate), formatString),
      value: 0,
    });
    currentDate = addInterval(currentDate);
  }
  return dateArray;
};

export const formatSalesSummary = (data) => {
  const infoTypes = {
    sales: {
      delivery: getDates(data.start, data.end, data.interval),
      takeout: getDates(data.start, data.end, data.interval),
      total: getDates(data.start, data.end, data.interval),
    },
    wholesale: {
      delivery: getDates(data.start, data.end, data.interval),
      takeout: getDates(data.start, data.end, data.interval),
      total: getDates(data.start, data.end, data.interval),
    },
    inhouse: {
      delivery: getDates(data.start, data.end, data.interval),
      takeout: getDates(data.start, data.end, data.interval),
      total: getDates(data.start, data.end, data.interval),
    },
    profit: {
      delivery: getDates(data.start, data.end, data.interval),
      takeout: getDates(data.start, data.end, data.interval),
      total: getDates(data.start, data.end, data.interval),
    },
  };

  for (const type in infoTypes) {
    data.aggregate[type].forEach((info) => {
      infoTypes[type].total[info.index].value = info.takeouts + info.deliveries;
      infoTypes[type].delivery[info.index].value = info.deliveries;
      infoTypes[type].takeout[info.index].value = info.takeouts;
    });
  }

  return infoTypes;
};

export const formatSalesTodayEarnings = (data) => ({
  total: data.total_sales,
  delivery: data.aggregate.sales[0]?.deliveries || 0,
  takeout: data.aggregate.sales[0]?.takeouts || 0,
  chart: [
    {
      type: 'Delivery',
      Delivery:
        data.total_sales === 0
          ? 0
          : (data.aggregate.sales[0]?.deliveries * 100) / data.total_sales,
    },
    {
      type: 'Takeout',
      Takeout:
        data.total_sales === 0
          ? 0
          : (data.aggregate.sales[0]?.takeouts * 100) / data.total_sales,
    },
  ],
});

const compactChartData = (chart, infoTypes) => {
  const topValues = chart.sort((a, b) => b.total - a.total).slice(0, 5);
  const otherValues = chart
    .slice(5)
    .reduce((total, value) => total + value.total, 0);

  topValues.push({
    id: 'Other',
    value: Number(((otherValues / infoTypes.total) * 100).toFixed(0)),
    total: otherValues,
  });
  return [...topValues];
};

export const formatSalesDonutChart = (data) => {
  const infoTypes = {
    sales: { total: data.total_sales },
    wholesale: { total: data.total_wholesale },
    inhouse: { total: data.total_inhouse },
    profit: { total: data.total_profit },
  };

  for (const type in infoTypes) {
    const chart = [];
    for (const day in data.aggregate[type]) {
      for (const category in data.aggregate[type][day]) {
        const index = chart.findIndex(
          (item) => item.id === capitalize(category)
        );

        if (index >= 0) {
          chart[index] = {
            id: capitalize(category),
            value: Number(
              (
                ((data.aggregate[type][day][category] + chart[index].total) /
                  infoTypes[type].total) *
                100
              ).toFixed(0)
            ),
            total: data.aggregate[type][day][category] + chart[index].total,
          };
        } else {
          chart.push({
            id: capitalize(category),
            value: Number(
              (
                (data.aggregate[type][day][category] / infoTypes[type].total) *
                100
              ).toFixed(0)
            ),
            total: data.aggregate[type][day][category],
          });
        }
      }
    }

    if (chart.length > 6) {
      infoTypes[type].chart = compactChartData(chart, infoTypes[type]);
    } else infoTypes[type].chart = [...chart];
  }

  return infoTypes;
};

export const formatSalesZone = (data) => {
  const infoTypes = {
    sales: { total: data.total_sales },
    wholesale: {
      total: data.total_wholesale,
    },
    inhouse: {
      total: data.total_inhouse,
    },
    profit: { total: data.total_profit },
  };

  for (const type in infoTypes) {
    const chart = [];
    for (const day in data.aggregate[type]) {
      for (const category in data.aggregate[type][day]) {
        const index = chart.findIndex(
          (item) => item.id === capitalize(category)
        );

        if (index >= 0) {
          chart[index] = {
            id: capitalize(category),
            value: Number(
              (
                ((data.aggregate[type][day][category] + chart[index].total) /
                  infoTypes[type].total) *
                100
              ).toFixed(0)
            ),
            total: data.aggregate[type][day][category] + chart[index].total,
          };
        } else {
          chart.push({
            id: capitalize(category),
            value: Number(
              (
                (data.aggregate[type][day][category] / infoTypes[type].total) *
                100
              ).toFixed(0)
            ),
            total: data.aggregate[type][day][category],
          });
        }
      }
    }

    if (chart.length > 6) {
      infoTypes[type].chart = compactChartData(chart, infoTypes[type]);
    } else infoTypes[type].chart = [...chart];
  }

  return infoTypes;
};

export const formatHighestSale = (data) => data;

export const formatResponse = ({ data, type }) => {
  const cb = {
    'tax-summary': formatTaxSummaryResponse,
    'tax-table': formatTaxTableResponse,
    stripe: formatStripeResponse,
    pos: formatPOSResponse,
    overview: formatOverviewResponse,
    settings: formatSettingsResponse,
    items: formatItems,
    item: formatItem,
    questions: formatQuestions,
    question: formatQuestion,
    'sales-summary': formatSalesSummary,
    'sales-today': formatSalesTodayEarnings,
    'sales-type': formatSalesDonutChart,
    'sales-category': formatSalesDonutChart,
    'sales-shift': formatSalesDonutChart,
    'sales-zone': formatSalesZone,
    'highest-sale': formatHighestSale,
  }[type];

  return cb(data);
};
