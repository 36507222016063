import React from 'react';
import { CircularProgress, Button as MUIButton } from '@mui/material';
import PropTypes from 'prop-types';

function Button({ loading, children, loadingColor, size, ...props }) {
  return (
    <MUIButton
      data-testid="button-with-loading"
      {...props}
      disabled={props?.disabled || loading}
    >
      {loading ? (
        <CircularProgress size={size} color={loadingColor} />
      ) : (
        children
      )}
    </MUIButton>
  );
}

Button.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  loadingColor: PropTypes.string,
  size: PropTypes.number,
};
Button.defaultProps = {
  loading: false,
  disabled: false,
  children: null,
  loadingColor: 'primary',
  size: 24,
};

export default Button;
