import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useGetErrors } from '../../../hooks/useGetErrors/useGetErrors';
import { useStyles } from './Steps.styles';

import Register from './components/Register';
import Login from './components/Login';

const FormSchema = yup.object().shape({
  registerFirstName: yup.string().required('First name is required'),
  registerLastName: yup.string().required('Last name is required'),
  registerPhoneNumber: yup.string().required('Phone is required'),
  registerEmail: yup
    .string()
    .email('Provide a valid email')
    .required('Email is required'),
  registerPassword: yup.string().required('Password is required'),
  loginEmail: yup
    .string()
    .email('Provide a valid email')
    .required('Email is required'),
  loginPassword: yup.string().required('Password is required'),
});

function Authentication({
  isAuthenticated,
  title,
  onSubmit,
  onLogout,
  disableRegistration,
}) {
  const classes = useStyles();

  const methods = useForm({
    shouldUnregister: false,
    resolver: yupResolver(FormSchema),
  });

  const { getErrors } = useGetErrors({ errors: methods.formState.errors });

  const formObj = useMemo(
    () => ({ ...methods, getErrors }),
    [methods, getErrors]
  );

  const [tab, setTab] = useState(0);

  const formOptions = [
    <Login key="0" form={formObj} title={title} />,
    <Register key="1" form={formObj} title={title} />,
  ];

  const selectedForm = formOptions[tab];

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const handleChangeTab = (_, newTab) => {
    setTab(newTab);
  };

  const handleSubmit = useCallback(async () => {
    const isCorrect = await formObj.trigger(
      tab === 0
        ? ['loginEmail', 'loginPassword']
        : [
            'registerFirstName',
            'registerLastName',
            'registerPhoneNumber',
            'registerEmail',
            'registerPassword',
          ]
    );

    if (!isCorrect) {
      onSubmit(tab, isCorrect);
      return;
    }

    onSubmit(tab, formObj.getValues());
  }, [formObj, onSubmit, tab]);

  if (isAuthenticated) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>Already Signed In</Typography>
        <Button
          data-test-id="erfB96uQcKr3B1Jl4eAPf"
          onClick={onLogout}
          className={classes.logOutButton}
        >
          Logout
        </Button>
      </Box>
    );
  }

  return (
    <>
      {!disableRegistration && (
        <Box marginBottom="16px" marginTop="-24px">
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            style={{ width: '100%' }}
          >
            <Tab data-testid="login-tab" label="Login" {...a11yProps(0)} />
            <Tab
              data-testid="register-tab"
              label="Register"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
      )}
      <Box>{selectedForm}</Box>
      <Box className={classes.actionContainer}>
        <Button
          data-test-id="hEzVIpOfmYxVhOWvn1Pax"
          onClick={handleSubmit}
          className={classes.addTagButton}
          data-testid="submit-btn"
        >
          {tab === 0 ? 'Login' : 'Register'}
        </Button>
      </Box>
    </>
  );
}

Authentication.propTypes = {
  isAuthenticated: PropTypes.bool,
  disableRegistration: PropTypes.bool,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  onLogout: PropTypes.func,
};

Authentication.defaultProps = {
  isAuthenticated: false,
  disableRegistration: false,
  title: '',
};

export default Authentication;
