import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import Type from '../Type/Type';
import { useStyles } from './Types.styles';

function Types({ types, onTypesChange }) {
  const classes = useStyles();

  const handleTypeChange = useCallback(
    (type) => {
      const newTypes = types.map((item) => ({
        ...item,
        active: type.id === item.id ? type.active : false,
      }));
      onTypesChange(newTypes);
    },
    [types, onTypesChange]
  );

  const items = useMemo(
    () => (
      <>
        {types.map((type) => (
          <Type key={type.id} type={type} onTypeChange={handleTypeChange} />
        ))}
      </>
    ),
    [types, handleTypeChange]
  );

  return <Box className={classes.list}>{items}</Box>;
}

Types.propTypes = {
  types: PropTypes.array.isRequired,
  onTypesChange: PropTypes.func.isRequired,
};

export default Types;
