import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  reportsWrapper: {
    padding: '0 15px',
    display: 'flex',
    flex: '1',

    [theme.breakpoints.down('md')]: {
      padding: '160px 15px 0px',
    },
  },
}));
