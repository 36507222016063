import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    flexFlow: 'row',
    flex: '2',
    height: 'calc(100vh - 80px)',
    justifyContent: 'stretch',
    paddingBottom: '15px',

    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginTop: '90px',
    },
  },
  left: {
    display: 'flex',
    flex: '1 379px',
    flexFlow: 'column',
    width: '379px',
    height: 'auto',
  },
  right: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    height: '100%',
    marginLeft: '25px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  listContainer: {
    height: '100%',
  },
  list: {
    display: 'flex',
    overflowX: 'hidden',
    overflowY: 'auto',
    flexFlow: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    height: 'calc(100% - 50px)',
    padding: '18px 16px',
    scrollbarWidth: 'none',

    border: '1px solid #DADCE0',
    background: '#FFFFFF',
    borderRadius: '12px',
    opacity: '1',
  },
  divider: {
    position: 'fixed',
    height: '1px',
    left: '0px',
    top: '80px',
    width: '100%',
    boxShadow: '0px 2px 4px #0000001F',
  },
}));
