import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '2px 4px',

    '&:hover': {
      background: '#0058FF1F',
    },

    '&:not(:last-child)': {
      marginRight: '6px',
    },

    [theme.breakpoints.down('md')]: {
      minWidth: 'max-content',
      padding: '6px 10px',
    },
  },
  buttonActive: {
    background: '#0058FF1F',
  },
  title: {
    display: 'inline-flex',
    color: '#282B31',
    fontSize: '11px',
    letterSpacing: '0px',
    opacity: '0.6',

    '&:hover': {
      opacity: '1',
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
  },
  titleActive: {
    color: '#0058FF',
    opacity: '1',
  },
}));
