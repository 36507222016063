import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@mui/material';
import {
  ArrowDropDown,
  ArrowRight,
  LocalDining,
  RoomService,
  Settings,
  Timeline,
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useStyles } from './Menu.styles';
import { useMenuContext } from '../../../context/menuContext';

const showChart = process.env.REACT_APP_CHARTS_ACTIVE !== 'false';

export function MenuItem({
  to,
  icon: Icon,
  label,
  location,
  pages,
  setPage,
  selectedPage,
}) {
  const classes = useStyles();

  return (
    <>
      <Link
        to={to}
        style={{ textDecoration: 'none', display: 'flex', marginBottom: '6px' }}
      >
        <ListItem
          className={clsx(classes.listItem, {
            [classes.active]: location.pathname === to,
          })}
        >
          <Divider
            orientation="vertical"
            flexItem
            className={clsx(classes.bar, {
              [classes.activeBar]: location.pathname === to,
            })}
          />
          <Box
            padding="12px 16px"
            display="flex"
            justifyContent="space-between"
            flex="1"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Icon className={classes.icon} fontSize="small" />
              <p className={classes.textMenu}>{label}</p>
            </ListItemIcon>
            {location.pathname === to ? (
              pages.length > 0 && (
                <ArrowDropDown
                  data-testid="arrowdown"
                  style={{ fill: '#0058ff' }}
                  fontSize="small"
                />
              )
            ) : (
              <ArrowRight style={{ fill: '#3C4043' }} fontSize="small" />
            )}
          </Box>
        </ListItem>
      </Link>

      {location.pathname === to &&
        pages.map((page, i) => (
          <Button
            data-test-id="9u-d9j1E7OArzhXyMQJBU"
            data-testid={`${page.label}-${i}`}
            key={page.label}
            className={clsx(classes.subItem, {
              [classes.activeSubItem]: i === selectedPage,
            })}
            onClick={() => setPage(i)}
          >
            <Typography variant="caption">{page.label}</Typography>
          </Button>
        ))}
    </>
  );
}

MenuItem.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.string,
  location: PropTypes.object,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ),
  setPage: PropTypes.func,
  selectedPage: PropTypes.number,
};

export function Menu({ mobile }) {
  const classes = useStyles();
  const location = useLocation();
  const menuContext = useMenuContext();
  const { selectedRestaurant, isLoading, error } = menuContext;

  return (
    <div className={clsx(classes.root, { [classes.mobile]: mobile })}>
      <Box marginLeft="24px" marginBottom="24px">
        {isLoading && (
          <CircularProgress
            style={{
              margin: '0 auto',
              display: 'flex',
              justifyContent: 'center',
            }}
          />
        )}

        {error && (
          <>
            <Typography
              variant="h2"
              style={{
                margin: '0 auto',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Something went wrong
            </Typography>
            <Typography variant="h5" style={{ opacity: '0.6' }}>
              Please, try again.
            </Typography>
          </>
        )}

        {!(isLoading || error) && !selectedRestaurant && (
          <>
            <Typography variant="h2">
              No registered restaurants found
            </Typography>
            <Typography variant="h5" style={{ opacity: '0.6' }}>
              Please, register your restaurant.
            </Typography>
          </>
        )}

        {!(isLoading || error) && selectedRestaurant?.name && (
          <>
            <Typography variant="h2">{selectedRestaurant?.name}</Typography>
            <Typography variant="h5" style={{ opacity: '0.6' }}>
              {selectedRestaurant?.address}
            </Typography>
          </>
        )}
      </Box>
      <List component="nav">
        {showChart && (
          <MenuItem
            to="/reports"
            icon={Timeline}
            label="Reports"
            location={location}
            {...menuContext}
          />
        )}
        <MenuItem
          to="/menu"
          icon={LocalDining}
          label="Menu"
          location={location}
          {...menuContext}
        />
        <MenuItem
          to="/orders"
          icon={RoomService}
          label="Orders"
          location={location}
          {...menuContext}
        />
        <MenuItem
          to="/settings"
          icon={Settings}
          label="Settings"
          location={location}
          {...menuContext}
        />
      </List>
    </div>
  );
}

Menu.propTypes = {
  mobile: PropTypes.bool,
};
