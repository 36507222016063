import { combineReducers } from 'redux';
import user from './UserDucks';
import snackbar from './SnackbarDucks';
import restaurant from './RestaurantDucks';

export default combineReducers({
  user,
  snackbar,
  restaurant,
});
