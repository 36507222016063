import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { endOfDay, getUnixTime, startOfDay } from 'date-fns';
import { Box, Button, Modal, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { Loading } from '../../layout/Loading/Loading';
import useNotifications from '../../../hooks/useNotifications/useNotifications';
import { useStyles } from './RemoveModal.styles';
import { adjustTimezone } from '../../../utils/date-utils';

export function RemoveModal({
  isOpen,
  handleClose,
  closeModal = () => null,
  onDeleteItem,
}) {
  const [showCalendar, setShowCalendar] = useState(false);
  const classes = useStyles({ showCalendar });
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useNotifications();
  const [customDate, setCustomDate] = useState([null, null]);

  const handleSelectedDate = (selectedDates) => {
    if (
      _.isArray(selectedDates) &&
      selectedDates.length === 2 &&
      !_.isNil(selectedDates[0])
    ) {
      const startDate = selectedDates[0];
      const endDate = _.isNil(selectedDates[1]) ? startDate : selectedDates[1];
      setCustomDate([startDate, endDate]);
    } else {
      const now = new Date();
      setCustomDate([now, now]);
    }
  };

  const handleSubmit = async () => {
    const start = getUnixTime(adjustTimezone(startOfDay(customDate[0])));
    const end = getUnixTime(adjustTimezone(endOfDay(customDate[1])));
    const permanent = false;

    try {
      setIsLoading(true);
      await onDeleteItem({
        ...(start && end && { start, end }),
        ...(permanent && { permanent }),
      });
      closeModal();
      enqueueSnackbar({
        message: 'Item 86d',
        options: {
          variant: 'success',
        },
      });
    } catch (err) {
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error',
        },
      });
    } finally {
      setCustomDate([null, null]);
      setShowCalendar(false);
      handleClose();
    }
  };

  const handleCancel = useCallback(() => {
    setShowCalendar(false);
    handleClose();
  }, [handleClose]);

  const handleRemove = useCallback(
    async ({ start, end, permanent }) => {
      try {
        setIsLoading(true);
        await onDeleteItem({
          ...(start && end && { start, end }),
          ...(permanent && { permanent }),
        });
        closeModal();
        enqueueSnackbar({
          message: 'Item 86d',
          options: {
            variant: 'success',
          },
        });
      } catch (err) {
        enqueueSnackbar({
          message: err.message,
          options: {
            variant: 'error',
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar, onDeleteItem, closeModal]
  );

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      data-testid="remove-modal"
    >
      <Box className={classes.container}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {!showCalendar ? (
              <>
                <Typography className={classes.modalTitle}>
                  How long it should be 86d?
                </Typography>
                <Box display="flex" flexDirection="column">
                  <Button
                    data-test-id="6cDQsuqVx92c-v0NsFQlC"
                    className={classes.deleteButton}
                    onClick={() =>
                      handleRemove({
                        start: getUnixTime(
                          adjustTimezone(startOfDay(new Date()))
                        ),
                        end: getUnixTime(adjustTimezone(endOfDay(new Date()))),
                      })
                    }
                    data-testid="remove-today"
                  >
                    Today
                  </Button>
                  <Button
                    data-test-id="YA3edRnGy-YWecAd1Gxaa"
                    className={classes.deleteButton}
                    onClick={() => setShowCalendar(true)}
                    data-testid="remove-until"
                  >
                    <Box className={classes.untilDateButton}>
                      Until [Date]
                      <ChevronRightIcon
                        style={{
                          fill: '#171725',
                          opacity: '0.5',
                          fontSize: '28px',
                        }}
                      />
                    </Box>
                  </Button>
                  <Button
                    data-test-id="mwC1tCILnqoiHaUHoyr-i"
                    className={classes.deleteButton}
                    onClick={handleRemove}
                    data-testid="remove-until-add-back"
                  >
                    Until I Add it Back
                  </Button>
                  <Button
                    data-test-id="3mGqDvKwFW7xeKMO2-kcf"
                    className={classes.deleteButton}
                    onClick={() => handleRemove({ permanent: true })}
                    data-testid="remove-forever"
                  >
                    Remove Forever
                  </Button>
                </Box>
                <Button
                  data-test-id="zG5QSX2M7nWg-skPlaTlE"
                  className={classes.cancelButton}
                  onClick={handleClose}
                  data-testid="cancel-remove"
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <StaticDateRangePicker
                  displayStaticWrapperAs="desktop"
                  calendars={1}
                  value={customDate}
                  className={classes.calendarWrapper}
                  onChange={(newValue) => handleSelectedDate(newValue)}
                />

                <Box className={classes.containerButtons}>
                  <Button
                    data-test-id="14ro0G1oqR_vK4Ryz8qIf"
                    className={classes.saveButton}
                    onClick={handleSubmit}
                    data-testid="save-button"
                  >
                    Save
                  </Button>
                  <Button
                    data-test-id="bkCDHSIfccl9AJ-Q6U26h"
                    className={classes.cancelButton}
                    onClick={handleCancel}
                    data-testid="cancel-button"
                  >
                    Cancel
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
}

RemoveModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  closeModal: PropTypes.func,
  onDeleteItem: PropTypes.func,
};

export default RemoveModal;
